import * as ActionTypes from '../constants/filters.constants'

const initialState = {
  filtersAll: [
    {
      i: 1,
      checked: false,
      name: 'Брелоки и ключницы',
      type: 1,
    },
    {
      i: 2,
      checked: false,
      name: 'Галстуки',
      type: 1,
    },
    {
      i: 3,
      checked: false,
      name: 'Для волос',
      type: 1,
    },
    {
      i: 4,
      checked: false,
      name: 'Зеркала',
      type: 1,
    },
    {
      i: 5,
      checked: false,
      name: 'Зонты',
      type: 1,
    },
    {
      i: 6,
      checked: false,
      name: 'Косметички',
      type: 1,
    },
    {
      i: 7,
      checked: false,
      name: 'Кошельки',
      type: 1,
    },
    {
      i: 8,
      checked: false,
      name: 'Очки',
      type: 1,
    },
    {
      i: 9,
      checked: false,
      name: 'Перчатки и варежки',
      type: 1,
    },
    {
      i: 10,
      checked: false,
      name: 'Ремни и пояса',
      type: 1,
    },
    {
      i: 11,
      checked: false,
      name: 'Рюкзаки',
      type: 1,
    },
    {
      i: 12,
      checked: false,
      name: 'Свадебные',
      type: 1,
    },
    {
      i: 13,
      checked: false,
      name: 'Сумки',
      type: 1,
    },
    {
      i: 14,
      checked: false,
      name: 'Украшения',
      type: 1,
    },
    {
      i: 15,
      checked: false,
      name: 'Часы',
      type: 1,
    },
    {
      i: 16,
      checked: false,
      name: 'Чемоданы',
      type: 1,
    },
    {
      i: 17,
      checked: false,
      name: 'Шарфы и платки',
      type: 1,
    },
    {
      i: 18,
      checked: false,
      name: 'A Bathing Ape (BAPE)',
      type: 'accessoriesBrand',
    },
    {
      i: 19,
      checked: false,
      name: 'ACNE STUDIOS',
      type: 'accessoriesBrand',
    },
    {
      i: 20,
      checked: false,
      name: 'Adidas',
      type: 'accessoriesBrand',
    },
    {
      i: 21,
      checked: false,
      name: 'Alba',
      type: 'accessoriesBrand',
    },
    {
      i: 22,
      checked: false,
      name: 'Alexander McQueen',
      type: 'accessoriesBrand',
    },
    {
      i: 23,
      checked: false,
      name: 'Alexander Wang',
      type: 'accessoriesBrand',
    },
    {
      i: 24,
      checked: false,
      name: 'Твое',
      type: 'accessoriesBrand',
    },
    {
      i: 25,
      checked: false,
      name: 'Ann Demeulemeester',
      type: 'accessoriesBrand',
    },
    {
      i: 26,
      checked: false,
      name: 'Armani',
      type: 'accessoriesBrand',
    },
    {
      i: 27,
      checked: false,
      name: 'Asos',
      type: 'accessoriesBrand',
    },
    {
      i: 28,
      checked: false,
      name: 'Avon',
      type: 'accessoriesBrand',
    },
    {
      i: 29,
      checked: false,
      name: 'Baldinini',
      type: 'accessoriesBrand',
    },
    {
      i: 30,
      checked: false,
      name: 'Balenciaga',
      type: 'accessoriesBrand',
    },
    {
      i: 31,
      checked: false,
      name: 'Befree',
      type: 'accessoriesBrand',
    },
    {
      i: 32,
      checked: false,
      name: 'Braccialini',
      type: 'accessoriesBrand',
    },
    {
      i: 33,
      checked: false,
      name: 'Burberry',
      type: 'accessoriesBrand',
    },
    {
      i: 34,
      checked: false,
      name: 'Calipso',
      type: 'accessoriesBrand',
    },
    {
      i: 35,
      checked: false,
      name: 'Calvin Klein',
      type: 'accessoriesBrand',
    },
    {
      i: 36,
      checked: false,
      name: 'Casio',
      type: 'accessoriesBrand',
    },
    {
      i: 37,
      checked: false,
      name: 'Celine',
      type: 'accessoriesBrand',
    },
    {
      i: 38,
      checked: false,
      name: 'Champion',
      type: 'accessoriesBrand',
    },
    {
      i: 39,
      checked: false,
      name: 'Chanel',
      type: 'accessoriesBrand',
    },
    {
      i: 40,
      checked: false,
      name: 'Chloe',
      type: 'accessoriesBrand',
    },
    {
      i: 41,
      checked: false,
      name: 'Christian Dior',
      type: 'accessoriesBrand',
    },
    {
      i: 42,
      checked: false,
      name: "Colin's",
      type: 'accessoriesBrand',
    },
    {
      i: 43,
      checked: false,
      name: 'Comme des Garcons',
      type: 'accessoriesBrand',
    },
    {
      i: 44,
      checked: false,
      name: 'Cos',
      type: 'accessoriesBrand',
    },
    {
      i: 45,
      checked: false,
      name: 'DKNY',
      type: 'accessoriesBrand',
    },
    {
      i: 46,
      checked: false,
      name: 'Daniel Wellington',
      type: 'accessoriesBrand',
    },
    {
      i: 47,
      checked: false,
      name: 'Desigual',
      type: 'accessoriesBrand',
    },
    {
      i: 48,
      checked: false,
      name: 'Diesel',
      type: 'accessoriesBrand',
    },
    {
      i: 49,
      checked: false,
      name: 'Diva',
      type: 'accessoriesBrand',
    },
    {
      i: 50,
      checked: false,
      name: 'Dolce&Gabbana',
      type: 'accessoriesBrand',
    },
    {
      i: 51,
      checked: false,
      name: 'Dries Van Noten',
      type: 'accessoriesBrand',
    },
    {
      i: 52,
      checked: false,
      name: 'Ecco',
      type: 'accessoriesBrand',
    },
    {
      i: 53,
      checked: false,
      name: 'Ellesse',
      type: 'accessoriesBrand',
    },
    {
      i: 54,
      checked: false,
      name: 'Escada',
      type: 'accessoriesBrand',
    },
    {
      i: 55,
      checked: false,
      name: 'Etro',
      type: 'accessoriesBrand',
    },
    {
      i: 56,
      checked: false,
      name: 'Fendi',
      type: 'accessoriesBrand',
    },
    {
      i: 57,
      checked: false,
      name: 'Ferrari',
      type: 'accessoriesBrand',
    },
    {
      i: 58,
      checked: false,
      name: 'Finntrail',
      type: 'accessoriesBrand',
    },
    {
      i: 59,
      checked: false,
      name: 'Fjallraven',
      type: 'accessoriesBrand',
    },
    {
      i: 60,
      checked: false,
      name: 'Furla',
      type: 'accessoriesBrand',
    },
    {
      i: 61,
      checked: false,
      name: 'Gant',
      type: 'accessoriesBrand',
    },
    {
      i: 62,
      checked: false,
      name: 'Giorgio Armani',
      type: 'accessoriesBrand',
    },
    {
      i: 63,
      checked: false,
      name: 'Givenchy',
      type: 'accessoriesBrand',
    },
    {
      i: 64,
      checked: false,
      name: 'Gucci',
      type: 'accessoriesBrand',
    },
    {
      i: 65,
      checked: false,
      name: 'Guess',
      type: 'accessoriesBrand',
    },
    {
      i: 66,
      checked: false,
      name: 'H&M',
      type: 'accessoriesBrand',
    },
    {
      i: 67,
      checked: false,
      name: 'Helmut Lang',
      type: 'accessoriesBrand',
    },
    {
      i: 68,
      checked: false,
      name: 'Hugo Boss',
      type: 'accessoriesBrand',
    },
    {
      i: 69,
      checked: false,
      name: 'Incanto',
      type: 'accessoriesBrand',
    },
    {
      i: 70,
      checked: false,
      name: 'Incity',
      type: 'accessoriesBrand',
    },
    {
      i: 71,
      checked: false,
      name: 'Isabel Marant',
      type: 'accessoriesBrand',
    },
    {
      i: 72,
      checked: false,
      name: 'Jil Sander',
      type: 'accessoriesBrand',
    },
    {
      i: 73,
      checked: false,
      name: 'Jimmy Choo',
      type: 'accessoriesBrand',
    },
    {
      i: 74,
      checked: false,
      name: 'Just Cavalli',
      type: 'accessoriesBrand',
    },
    {
      i: 75,
      checked: false,
      name: 'Karen Millen',
      type: 'accessoriesBrand',
    },
    {
      i: 76,
      checked: false,
      name: 'Karl Lagerfeld',
      type: 'accessoriesBrand',
    },
    {
      i: 77,
      checked: false,
      name: 'Kenzo',
      type: 'accessoriesBrand',
    },
    {
      i: 78,
      checked: false,
      name: 'King Hunter',
      type: 'accessoriesBrand',
    },
    {
      i: 79,
      checked: false,
      name: 'Klim',
      type: 'accessoriesBrand',
    },
    {
      i: 80,
      checked: false,
      name: 'Lacoste',
      type: 'accessoriesBrand',
    },
    {
      i: 81,
      checked: false,
      name: "Levi's",
      type: 'accessoriesBrand',
    },
    {
      i: 82,
      checked: false,
      name: 'Louis Vuitton',
      type: 'accessoriesBrand',
    },
    {
      i: 83,
      checked: false,
      name: 'Love Republic',
      type: 'accessoriesBrand',
    },
    {
      i: 84,
      checked: false,
      name: 'Maison  Margiela',
      type: 'accessoriesBrand',
    },
    {
      i: 85,
      checked: false,
      name: 'Maison Kitsune',
      type: 'accessoriesBrand',
    },
    {
      i: 86,
      checked: false,
      name: 'Mango',
      type: 'accessoriesBrand',
    },
    {
      i: 87,
      checked: false,
      name: 'Marc Jacobs',
      type: 'accessoriesBrand',
    },
    {
      i: 88,
      checked: false,
      name: 'Marni',
      type: 'accessoriesBrand',
    },
    {
      i: 89,
      checked: false,
      name: 'Mascotte',
      type: 'accessoriesBrand',
    },
    {
      i: 90,
      checked: false,
      name: 'Massimo Dutti',
      type: 'accessoriesBrand',
    },
    {
      i: 91,
      checked: false,
      name: 'Max Mara',
      type: 'accessoriesBrand',
    },
    {
      i: 92,
      checked: false,
      name: 'Mexx',
      type: 'accessoriesBrand',
    },
    {
      i: 93,
      checked: false,
      name: 'Michael Kors',
      type: 'accessoriesBrand',
    },
    {
      i: 94,
      checked: false,
      name: 'Missoni',
      type: 'accessoriesBrand',
    },
    {
      i: 95,
      checked: false,
      name: 'Miu Miu',
      type: 'accessoriesBrand',
    },
    {
      i: 96,
      checked: false,
      name: 'Mohito',
      type: 'accessoriesBrand',
    },
    {
      i: 97,
      checked: false,
      name: 'Moschino',
      type: 'accessoriesBrand',
    },
    {
      i: 98,
      checked: false,
      name: 'Motivi',
      type: 'accessoriesBrand',
    },
    {
      i: 99,
      checked: false,
      name: 'Neil Barrett',
      type: 'accessoriesBrand',
    },
    {
      i: 100,
      checked: false,
      name: 'New Yorker',
      type: 'accessoriesBrand',
    },
    {
      i: 101,
      checked: false,
      name: 'Next',
      type: 'accessoriesBrand',
    },
    {
      i: 102,
      checked: false,
      name: 'Nike',
      type: 'accessoriesBrand',
    },
    {
      i: 103,
      checked: false,
      name: 'Nitro',
      type: 'accessoriesBrand',
    },
    {
      i: 104,
      checked: false,
      name: "O'Stin",
      type: 'accessoriesBrand',
    },
    {
      i: 105,
      checked: false,
      name: 'Off-White',
      type: 'accessoriesBrand',
    },
    {
      i: 106,
      checked: false,
      name: 'Officine Creative',
      type: 'accessoriesBrand',
    },
    {
      i: 107,
      checked: false,
      name: 'Oysho',
      type: 'accessoriesBrand',
    },
    {
      i: 108,
      checked: false,
      name: 'Pandora',
      type: 'accessoriesBrand',
    },
    {
      i: 109,
      checked: false,
      name: 'Patrizia Pepe',
      type: 'accessoriesBrand',
    },
    {
      i: 110,
      checked: false,
      name: 'Pinko',
      type: 'accessoriesBrand',
    },
    {
      i: 111,
      checked: false,
      name: 'Piquadro',
      type: 'accessoriesBrand',
    },
    {
      i: 112,
      checked: false,
      name: 'Prada',
      type: 'accessoriesBrand',
    },
    {
      i: 113,
      checked: false,
      name: 'Pull&Bear',
      type: 'accessoriesBrand',
    },
    {
      i: 114,
      checked: false,
      name: 'RAF SIMONS',
      type: 'accessoriesBrand',
    },
    {
      i: 115,
      checked: false,
      name: 'Ralph Lauren',
      type: 'accessoriesBrand',
    },
    {
      i: 116,
      checked: false,
      name: 'Ray-Ban',
      type: 'accessoriesBrand',
    },
    {
      i: 117,
      checked: false,
      name: 'Reebok',
      type: 'accessoriesBrand',
    },
    {
      i: 118,
      checked: false,
      name: 'Reiss',
      type: 'accessoriesBrand',
    },
    {
      i: 119,
      checked: false,
      name: 'Reserved',
      type: 'accessoriesBrand',
    },
    {
      i: 120,
      checked: false,
      name: 'Rick Owens',
      type: 'accessoriesBrand',
    },
    {
      i: 121,
      checked: false,
      name: 'River Island',
      type: 'accessoriesBrand',
    },
    {
      i: 122,
      checked: false,
      name: 'Roxy',
      type: 'accessoriesBrand',
    },
    {
      i: 123,
      checked: false,
      name: 'Salvatore Ferragamo',
      type: 'accessoriesBrand',
    },
    {
      i: 124,
      checked: false,
      name: 'Sisley',
      type: 'accessoriesBrand',
    },
    {
      i: 125,
      checked: false,
      name: 'Skagen',
      type: 'accessoriesBrand',
    },
    {
      i: 126,
      checked: false,
      name: 'Sokolov',
      type: 'accessoriesBrand',
    },
    {
      i: 127,
      checked: false,
      name: 'Stella Mccartney',
      type: 'accessoriesBrand',
    },
    {
      i: 128,
      checked: false,
      name: 'Stradivarius',
      type: 'accessoriesBrand',
    },
    {
      i: 129,
      checked: false,
      name: 'Sunlight',
      type: 'accessoriesBrand',
    },
    {
      i: 130,
      checked: false,
      name: 'Swarovski',
      type: 'accessoriesBrand',
    },
    {
      i: 131,
      checked: false,
      name: 'TED BAKER',
      type: 'accessoriesBrand',
    },
    {
      i: 132,
      checked: false,
      name: 'Terranova',
      type: 'accessoriesBrand',
    },
    {
      i: 133,
      checked: false,
      name: 'Tissot',
      type: 'accessoriesBrand',
    },
    {
      i: 134,
      checked: false,
      name: 'Tods',
      type: 'accessoriesBrand',
    },
    {
      i: 135,
      checked: false,
      name: 'Tommy Hilfiger',
      type: 'accessoriesBrand',
    },
    {
      i: 136,
      checked: false,
      name: 'Tommy Jeans',
      type: 'accessoriesBrand',
    },
    {
      i: 137,
      checked: false,
      name: 'Topshop',
      type: 'accessoriesBrand',
    },
    {
      i: 138,
      checked: false,
      name: 'Tosca Blu',
      type: 'accessoriesBrand',
    },
    {
      i: 139,
      checked: false,
      name: 'Tous',
      type: 'accessoriesBrand',
    },
    {
      i: 140,
      checked: false,
      name: 'Undercover',
      type: 'accessoriesBrand',
    },
    {
      i: 141,
      checked: false,
      name: 'United Colors of Benetton',
      type: 'accessoriesBrand',
    },
    {
      i: 142,
      checked: false,
      name: 'Valentino',
      type: 'accessoriesBrand',
    },
    {
      i: 143,
      checked: false,
      name: 'Valtera',
      type: 'accessoriesBrand',
    },
    {
      i: 144,
      checked: false,
      name: 'Vans',
      type: 'accessoriesBrand',
    },
    {
      i: 145,
      checked: false,
      name: 'Versace',
      type: 'accessoriesBrand',
    },
    {
      i: 146,
      checked: false,
      name: "Victoria's Secret",
      type: 'accessoriesBrand',
    },
    {
      i: 147,
      checked: false,
      name: 'Vivienne Westwood',
      type: 'accessoriesBrand',
    },
    {
      i: 148,
      checked: false,
      name: 'YSL',
      type: 'accessoriesBrand',
    },
    {
      i: 149,
      checked: false,
      name: 'Yohji Yamamoto',
      type: 'accessoriesBrand',
    },
    {
      i: 150,
      checked: false,
      name: 'Yves Saint Laurent',
      type: 'accessoriesBrand',
    },
    {
      i: 151,
      checked: false,
      name: 'Zara',
      type: 'accessoriesBrand',
    },
    {
      i: 152,
      checked: false,
      name: 'Zarina',
      type: 'accessoriesBrand',
    },
    {
      i: 153,
      checked: false,
      name: 'blue motion',
      type: 'accessoriesBrand',
    },
    {
      i: 154,
      checked: false,
      name: 'Дикая Орхидея',
      type: 'accessoriesBrand',
    },
    {
      i: 155,
      checked: false,
      name: 'Новая Заря',
      type: 'accessoriesBrand',
    },
    {
      i: 156,
      checked: false,
      name: 'Эконика',
      type: 'accessoriesBrand',
    },
    {
      i: 157,
      checked: false,
      name: 'Ювелирочка',
      type: 'accessoriesBrand',
    },
    {
      i: 158,
      checked: false,
      name: 'Б/у',
      type: 'stateProduct',
    },
    {
      i: 159,
      checked: false,
      name: 'Новое',
      type: 'stateProduct',
    },
    {
      i: 160,
      checked: false,
      name: 'Черный',
      type: 'colorProduct',
    },
    {
      i: 161,
      checked: false,
      name: 'Серый',
      type: 'colorProduct',
    },
    {
      i: 162,
      checked: false,
      name: 'Белый',
      type: 'colorProduct',
    },
    {
      i: 163,
      checked: false,
      name: 'Бежевый',
      type: 'colorProduct',
    },
    {
      i: 164,
      checked: false,
      name: 'Оранжевый',
      type: 'colorProduct',
    },
    {
      i: 165,
      checked: false,
      name: 'Желтый',
      type: 'colorProduct',
    },
    {
      i: 166,
      checked: false,
      name: 'Хаки',
      type: 'colorProduct',
    },
    {
      i: 167,
      checked: false,
      name: 'Зеленый',
      type: 'colorProduct',
    },
    {
      i: 168,
      checked: false,
      name: 'Голубой',
      type: 'colorProduct',
    },
    {
      i: 169,
      checked: false,
      name: 'Синий',
      type: 'colorProduct',
    },
    {
      i: 170,
      checked: false,
      name: 'Фиолетовый',
      type: 'colorProduct',
    },
    {
      i: 171,
      checked: false,
      name: 'Розовый',
      type: 'colorProduct',
    },
    {
      i: 172,
      checked: false,
      name: 'Красный',
      type: 'colorProduct',
    },
    {
      i: 173,
      checked: false,
      name: 'Бордовый',
      type: 'colorProduct',
    },
    {
      i: 174,
      checked: false,
      name: 'Коричневый',
      type: 'colorProduct',
    },
    {
      i: 175,
      checked: false,
      name: 'Разноцветный',
      type: 'colorProduct',
    },
    {
      i: 176,
      checked: false,
      name: 'Продаю свое',
      type: 'typeProduct',
    },
    {
      i: 177,
      checked: false,
      name: 'Приобрел на продажу',
      type: 'typeProduct',
    },
    {
      i: 178,
      checked: false,
      name: 'Магазин',
      type: 'typeProduct',
    },
    {
      i: 179,
      checked: false,
      name: 'Блузки-боди',
      type: 4,
    },
    {
      i: 180,
      checked: false,
      name: 'Блузы',
      type: 4,
    },
    {
      i: 181,
      checked: false,
      name: 'Рубашки',
      type: 4,
    },
    {
      i: 182,
      checked: false,
      name: '40-42 (XS)',
      type: 'sizeProduct',
    },
    {
      i: 183,
      checked: false,
      name: '42-44 (S)',
      type: 'sizeProduct',
    },
    {
      i: 184,
      checked: false,
      name: '44-46 (М)',
      type: 'sizeProduct',
    },
    {
      i: 185,
      checked: false,
      name: '46-48 (L)',
      type: 'sizeProduct',
    },
    {
      i: 186,
      checked: false,
      name: '48-50 (XL)',
      type: 'sizeProduct',
    },
    {
      i: 187,
      checked: false,
      name: '50-52 (XXL)',
      type: 'sizeProduct',
    },
    {
      i: 188,
      checked: false,
      name: '52-54 (3XL)',
      type: 'sizeProduct',
    },
    {
      i: 189,
      checked: false,
      name: '54 - 56 (4XL)',
      type: 'sizeProduct',
    },
    {
      i: 190,
      checked: false,
      name: '56 - 58 (5XL)',
      type: 'sizeProduct',
    },
    {
      i: 191,
      checked: false,
      name: '58 - 60 (6XL) и больше',
      type: 'sizeProduct',
    },
    {
      i: 192,
      checked: false,
      name: 'Блузы и рубашки',
      type: 5,
    },
    {
      i: 193,
      checked: false,
      name: 'Верхняя одежда',
      type: 5,
    },
    {
      i: 194,
      checked: false,
      name: 'Домашняя одежда',
      type: 5,
    },
    {
      i: 195,
      checked: false,
      name: 'Комбинезоны',
      type: 5,
    },
    {
      i: 196,
      checked: false,
      name: 'Купальники',
      type: 5,
    },
    {
      i: 197,
      checked: false,
      name: 'Нижнее белье',
      type: 5,
    },
    {
      i: 198,
      checked: false,
      name: 'Пиджаки и костюмы',
      type: 5,
    },
    {
      i: 199,
      checked: false,
      name: 'Платья и юбки',
      type: 5,
    },
    {
      i: 200,
      checked: false,
      name: 'Свитеры и толстовки',
      type: 5,
    },
    {
      i: 201,
      checked: false,
      name: 'Футболки и топы',
      type: 5,
    },
    {
      i: 202,
      checked: false,
      name: 'Демисезон',
      type: 'seasonProduct',
    },
    {
      i: 203,
      checked: false,
      name: 'Зима',
      type: 'seasonProduct',
    },
    {
      i: 204,
      checked: false,
      name: 'Лето',
      type: 'seasonProduct',
    },
    {
      i: 205,
      checked: false,
      name: 'Бомберы',
      type: 6,
    },
    {
      i: 206,
      checked: false,
      name: 'Ветровки',
      type: 6,
    },
    {
      i: 207,
      checked: false,
      name: 'Горнолыжные куртки',
      type: 6,
    },
    {
      i: 208,
      checked: false,
      name: 'Демисезонные куртки',
      type: 6,
    },
    {
      i: 209,
      checked: false,
      name: 'Джинсовые куртки',
      type: 6,
    },
    {
      i: 210,
      checked: false,
      name: 'Дождевики',
      type: 6,
    },
    {
      i: 211,
      checked: false,
      name: 'Дубленки',
      type: 6,
    },
    {
      i: 212,
      checked: false,
      name: 'Жилеты',
      type: 6,
    },
    {
      i: 213,
      checked: false,
      name: 'Зимние комбинезоны',
      type: 6,
    },
    {
      i: 214,
      checked: false,
      name: 'Зимние костюмы',
      type: 6,
    },
    {
      i: 215,
      checked: false,
      name: 'Кожаные куртки',
      type: 6,
    },
    {
      i: 216,
      checked: false,
      name: 'Косухи',
      type: 6,
    },
    {
      i: 217,
      checked: false,
      name: 'Куртки',
      type: 6,
    },
    {
      i: 218,
      checked: false,
      name: 'Меховые воротники',
      type: 6,
    },
    {
      i: 219,
      checked: false,
      name: 'Накидки и пончо',
      type: 6,
    },
    {
      i: 220,
      checked: false,
      name: 'Пальто',
      type: 6,
    },
    {
      i: 221,
      checked: false,
      name: 'Парки',
      type: 6,
    },
    {
      i: 222,
      checked: false,
      name: 'Плащи и тренчи',
      type: 6,
    },
    {
      i: 223,
      checked: false,
      name: 'Полупальто',
      type: 6,
    },
    {
      i: 224,
      checked: false,
      name: 'Пуховики',
      type: 6,
    },
    {
      i: 225,
      checked: false,
      name: 'Шубы',
      type: 6,
    },
    {
      i: 226,
      checked: false,
      name: 'Бейсболки и кепки',
      type: 7,
    },
    {
      i: 227,
      checked: false,
      name: 'Береты',
      type: 7,
    },
    {
      i: 228,
      checked: false,
      name: 'Платки и косынки',
      type: 7,
    },
    {
      i: 229,
      checked: false,
      name: 'Повязки',
      type: 7,
    },
    {
      i: 230,
      checked: false,
      name: 'Шапки',
      type: 7,
    },
    {
      i: 231,
      checked: false,
      name: 'Шарфы',
      type: 7,
    },
    {
      i: 232,
      checked: false,
      name: 'Шляпы',
      type: 7,
    },
    {
      i: 233,
      checked: false,
      name: 'Ночные сорочки',
      type: 8,
    },
    {
      i: 234,
      checked: false,
      name: 'Пеньюары',
      type: 8,
    },
    {
      i: 235,
      checked: false,
      name: 'Пижамы',
      type: 8,
    },
    {
      i: 236,
      checked: false,
      name: 'Халаты',
      type: 8,
    },
    {
      i: 237,
      checked: false,
      name: 'Anita',
      type: 'brandAFromMomProduct',
    },
    {
      i: 238,
      checked: false,
      name: 'Asos',
      type: 'brandAFromMomProduct',
    },
    {
      i: 239,
      checked: false,
      name: 'Befree',
      type: 'brandAFromMomProduct',
    },
    {
      i: 240,
      checked: false,
      name: 'Bonprix',
      type: 'brandAFromMomProduct',
    },
    {
      i: 241,
      checked: false,
      name: 'Diva',
      type: 'brandAFromMomProduct',
    },
    {
      i: 242,
      checked: false,
      name: 'Ellesse',
      type: 'brandAFromMomProduct',
    },
    {
      i: 243,
      checked: false,
      name: 'Ergoforma',
      type: 'brandAFromMomProduct',
    },
    {
      i: 244,
      checked: false,
      name: 'Euromama',
      type: 'brandAFromMomProduct',
    },
    {
      i: 245,
      checked: false,
      name: 'Ferrari',
      type: 'brandAFromMomProduct',
    },
    {
      i: 246,
      checked: false,
      name: 'Finntrail',
      type: 'brandAFromMomProduct',
    },
    {
      i: 247,
      checked: false,
      name: 'Fosta',
      type: 'brandAFromMomProduct',
    },
    {
      i: 248,
      checked: false,
      name: 'Gant',
      type: 'brandAFromMomProduct',
    },
    {
      i: 249,
      checked: false,
      name: 'H&M',
      type: 'brandAFromMomProduct',
    },
    {
      i: 250,
      checked: false,
      name: 'HOBBS',
      type: 'brandAFromMomProduct',
    },
    {
      i: 251,
      checked: false,
      name: 'Happy Moms',
      type: 'brandAFromMomProduct',
    },
    {
      i: 252,
      checked: false,
      name: 'Hunny Mammy',
      type: 'brandAFromMomProduct',
    },
    {
      i: 253,
      checked: false,
      name: 'Incity',
      type: 'brandAFromMomProduct',
    },
    {
      i: 254,
      checked: false,
      name: 'Kiabi',
      type: 'brandAFromMomProduct',
    },
    {
      i: 255,
      checked: false,
      name: 'King Hunter',
      type: 'brandAFromMomProduct',
    },
    {
      i: 256,
      checked: false,
      name: 'Klim',
      type: 'brandAFromMomProduct',
    },
    {
      i: 257,
      checked: false,
      name: 'MONSOON',
      type: 'brandAFromMomProduct',
    },
    {
      i: 258,
      checked: false,
      name: 'Mama Comfort',
      type: 'brandAFromMomProduct',
    },
    {
      i: 259,
      checked: false,
      name: 'Mamaline',
      type: 'brandAFromMomProduct',
    },
    {
      i: 260,
      checked: false,
      name: 'Mamita',
      type: 'brandAFromMomProduct',
    },
    {
      i: 261,
      checked: false,
      name: 'Mammysize',
      type: 'brandAFromMomProduct',
    },
    {
      i: 262,
      checked: false,
      name: 'Mam',
      type: 'brandAFromMomProduct',
    },
    {
      i: 263,
      checked: false,
      name: 'Mango',
      type: 'brandAFromMomProduct',
    },
    {
      i: 264,
      checked: false,
      name: 'Massimo Dutti',
      type: 'brandAFromMomProduct',
    },
    {
      i: 265,
      checked: false,
      name: 'Medela',
      type: 'brandAFromMomProduct',
    },
    {
      i: 266,
      checked: false,
      name: 'Milavitsa',
      type: 'brandAFromMomProduct',
    },
    {
      i: 267,
      checked: false,
      name: 'Mother',
      type: 'brandAFromMomProduct',
    },
    {
      i: 268,
      checked: false,
      name: 'Next',
      type: 'brandAFromMomProduct',
    },
    {
      i: 269,
      checked: false,
      name: 'Nitro',
      type: 'brandAFromMomProduct',
    },
    {
      i: 270,
      checked: false,
      name: "O'Stin",
      type: 'brandAFromMomProduct',
    },
    {
      i: 271,
      checked: false,
      name: 'Patrizia Pepe',
      type: 'brandAFromMomProduct',
    },
    {
      i: 272,
      checked: false,
      name: 'Proud Mom',
      type: 'brandAFromMomProduct',
    },
    {
      i: 273,
      checked: false,
      name: 'Relaxsan',
      type: 'brandAFromMomProduct',
    },
    {
      i: 274,
      checked: false,
      name: 'Riah concept',
      type: 'brandAFromMomProduct',
    },
    {
      i: 275,
      checked: false,
      name: 'Sweet',
      type: 'brandAFromMomProduct',
    },
    {
      i: 276,
      checked: false,
      name: 'TED BAKER',
      type: 'brandAFromMomProduct',
    },
    {
      i: 277,
      checked: false,
      name: 'Tonus Elast',
      type: 'brandAFromMomProduct',
    },
    {
      i: 278,
      checked: false,
      name: 'Uniostar',
      type: 'brandAFromMomProduct',
    },
    {
      i: 279,
      checked: false,
      name: 'Zara',
      type: 'brandAFromMomProduct',
    },
    {
      i: 280,
      checked: false,
      name: 'blue motion',
      type: 'brandAFromMomProduct',
    },
    {
      i: 281,
      checked: false,
      name: 'Адель',
      type: 'brandAFromMomProduct',
    },
    {
      i: 282,
      checked: false,
      name: 'Буду Мамой',
      type: 'brandAFromMomProduct',
    },
    {
      i: 283,
      checked: false,
      name: 'Ехидна',
      type: 'brandAFromMomProduct',
    },
    {
      i: 284,
      checked: false,
      name: 'Крейт',
      type: 'brandAFromMomProduct',
    },
    {
      i: 285,
      checked: false,
      name: 'Мамабэль',
      type: 'brandAFromMomProduct',
    },
    {
      i: 286,
      checked: false,
      name: 'Мамуля Красотуля',
      type: 'brandAFromMomProduct',
    },
    {
      i: 287,
      checked: false,
      name: 'Фэст',
      type: 'brandAFromMomProduct',
    },
    {
      i: 288,
      checked: false,
      name: 'Снежная Королева',
      type: 'accessoriesBrand',
    },
    {
      i: 289,
      checked: false,
      name: 'A Bathing Ape (BAPE)',
      type: 'womanHatsBrand',
    },
    {
      i: 290,
      checked: false,
      name: 'Champion',
      type: 'womanHatsBrand',
    },
    {
      i: 291,
      checked: false,
      name: 'DKNY',
      type: 'womanHatsBrand',
    },
    {
      i: 292,
      checked: false,
      name: 'Ellesse',
      type: 'womanHatsBrand',
    },
    {
      i: 293,
      checked: false,
      name: 'Ferrari',
      type: 'womanHatsBrand',
    },
    {
      i: 294,
      checked: false,
      name: 'Finntrail',
      type: 'womanHatsBrand',
    },
    {
      i: 295,
      checked: false,
      name: 'Gant',
      type: 'womanHatsBrand',
    },
    {
      i: 296,
      checked: false,
      name: 'Guess',
      type: 'womanHatsBrand',
    },
    {
      i: 297,
      checked: false,
      name: 'HOBBS',
      type: 'womanHatsBrand',
    },
    {
      i: 298,
      checked: false,
      name: 'Hugo Boss',
      type: 'womanHatsBrand',
    },
    {
      i: 299,
      checked: false,
      name: 'Karl Lagerfeld',
      type: 'womanHatsBrand',
    },
    {
      i: 300,
      checked: false,
      name: 'King Hunter',
      type: 'womanHatsBrand',
    },
    {
      i: 301,
      checked: false,
      name: 'Klim',
      type: 'womanHatsBrand',
    },
    {
      i: 302,
      checked: false,
      name: 'MONSOON',
      type: 'womanHatsBrand',
    },
    {
      i: 303,
      checked: false,
      name: 'Massimo Dutti',
      type: 'womanHatsBrand',
    },
    {
      i: 304,
      checked: false,
      name: 'Nitro',
      type: 'womanHatsBrand',
    },
    {
      i: 305,
      checked: false,
      name: 'Patrizia Pepe',
      type: 'womanHatsBrand',
    },
    {
      i: 306,
      checked: false,
      name: 'Reiss',
      type: 'womanHatsBrand',
    },
    {
      i: 307,
      checked: false,
      name: 'TED BAKER',
      type: 'womanHatsBrand',
    },
    {
      i: 308,
      checked: false,
      name: 'Tommy Jeans',
      type: 'womanHatsBrand',
    },
    {
      i: 309,
      checked: false,
      name: 'blue motion',
      type: 'womanHatsBrand',
    },
    {
      i: 310,
      checked: false,
      name: 'Adidas',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 311,
      checked: false,
      name: 'Avon',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 312,
      checked: false,
      name: 'Champion',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 313,
      checked: false,
      name: 'DKNY',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 314,
      checked: false,
      name: 'Ellesse',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 315,
      checked: false,
      name: 'Etam',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 316,
      checked: false,
      name: 'Ferrari',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 317,
      checked: false,
      name: 'Finntrail',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 318,
      checked: false,
      name: 'Gant',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 319,
      checked: false,
      name: 'Guess',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 320,
      checked: false,
      name: 'H&M',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 321,
      checked: false,
      name: 'HOBBS',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 322,
      checked: false,
      name: 'Hugo Boss',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 323,
      checked: false,
      name: 'Incanto',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 324,
      checked: false,
      name: 'Incity',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 325,
      checked: false,
      name: 'Indefini',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 326,
      checked: false,
      name: 'Karl Lagerfeld',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 327,
      checked: false,
      name: 'King Hunter',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 328,
      checked: false,
      name: 'Klim',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 329,
      checked: false,
      name: 'Laete',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 330,
      checked: false,
      name: 'Love Republic',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 331,
      checked: false,
      name: 'MONSOON',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 332,
      checked: false,
      name: 'Massimo Dutti',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 333,
      checked: false,
      name: 'Mia-Mia',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 334,
      checked: false,
      name: 'Mia',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 335,
      checked: false,
      name: 'New Balance',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 336,
      checked: false,
      name: 'Nitro',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 337,
      checked: false,
      name: 'Oodji',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 338,
      checked: false,
      name: 'Oysho',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 339,
      checked: false,
      name: 'Patrizia Pepe',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 340,
      checked: false,
      name: 'Pelican',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 341,
      checked: false,
      name: 'Reiss',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 342,
      checked: false,
      name: 'Reserved',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 343,
      checked: false,
      name: 'Riah concept',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 344,
      checked: false,
      name: 'TED BAKER',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 345,
      checked: false,
      name: 'Tommy Jeans',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 346,
      checked: false,
      name: "Victoria's Secret",
      type: 'brandHomeWomanProduct',
    },
    {
      i: 347,
      checked: false,
      name: 'Vis-A-Vis',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 348,
      checked: false,
      name: 'blue motion',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 349,
      checked: false,
      name: 'Дефиле',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 350,
      checked: false,
      name: 'Славянка',
      type: 'brandHomeWomanProduct',
    },
    {
      i: 351,
      checked: false,
      name: 'Полукомбинезоны',
      type: 9,
    },
    {
      i: 352,
      checked: false,
      name: 'С брюками',
      type: 9,
    },
    {
      i: 353,
      checked: false,
      name: 'С шортами',
      type: 9,
    },
    {
      i: 354,
      checked: false,
      name: 'Бикини',
      type: 10,
    },
    {
      i: 355,
      checked: false,
      name: 'Лифы',
      type: 10,
    },
    {
      i: 356,
      checked: false,
      name: 'Плавки',
      type: 10,
    },
    {
      i: 357,
      checked: false,
      name: 'Раздельные купальники',
      type: 10,
    },
    {
      i: 358,
      checked: false,
      name: 'Слитные купальники',
      type: 10,
    },
    {
      i: 359,
      checked: false,
      name: 'Боди',
      type: 11,
    },
    {
      i: 360,
      checked: false,
      name: 'Бюстгальтеры',
      type: 11,
    },
    {
      i: 361,
      checked: false,
      name: 'Гольфы и гетры',
      type: 11,
    },
    {
      i: 362,
      checked: false,
      name: 'Колготки',
      type: 11,
    },
    {
      i: 363,
      checked: false,
      name: 'Корректирующее',
      type: 11,
    },
    {
      i: 364,
      checked: false,
      name: 'Корсеты',
      type: 11,
    },
    {
      i: 365,
      checked: false,
      name: 'Носки',
      type: 11,
    },
    {
      i: 366,
      checked: false,
      name: 'Пояса для чулок',
      type: 11,
    },
    {
      i: 367,
      checked: false,
      name: 'Сорочки',
      type: 11,
    },
    {
      i: 368,
      checked: false,
      name: 'Термобелье',
      type: 11,
    },
    {
      i: 369,
      checked: false,
      name: 'Трусы',
      type: 11,
    },
    {
      i: 370,
      checked: false,
      name: 'Чулки',
      type: 11,
    },
    {
      i: 371,
      checked: false,
      name: '',
      type: 'accessoriesBrand',
    },
    {
      i: 372,
      checked: false,
      name: 'Adl',
      type: 'accessoriesBrand',
    },
    {
      i: 373,
      checked: false,
      name: 'Agent Provocateur',
      type: 'accessoriesBrand',
    },
    {
      i: 374,
      checked: false,
      name: 'Aldo',
      type: 'accessoriesBrand',
    },
    {
      i: 375,
      checked: false,
      name: 'Ash',
      type: 'accessoriesBrand',
    },
    {
      i: 376,
      checked: false,
      name: 'Asics',
      type: 'accessoriesBrand',
    },
    {
      i: 377,
      checked: false,
      name: 'Banana Republic',
      type: 'accessoriesBrand',
    },
    {
      i: 378,
      checked: false,
      name: 'Baon',
      type: 'accessoriesBrand',
    },
    {
      i: 379,
      checked: false,
      name: 'Basconi',
      type: 'accessoriesBrand',
    },
    {
      i: 380,
      checked: false,
      name: 'Bebe',
      type: 'accessoriesBrand',
    },
    {
      i: 381,
      checked: false,
      name: 'Bershka',
      type: 'accessoriesBrand',
    },
    {
      i: 382,
      checked: false,
      name: 'Betsy',
      type: 'accessoriesBrand',
    },
    {
      i: 383,
      checked: false,
      name: 'Bosco',
      type: 'accessoriesBrand',
    },
    {
      i: 384,
      checked: false,
      name: 'Calzedonia',
      type: 'accessoriesBrand',
    },
    {
      i: 385,
      checked: false,
      name: 'Caprice',
      type: 'accessoriesBrand',
    },
    {
      i: 386,
      checked: false,
      name: 'Carlo Pazolini',
      type: 'accessoriesBrand',
    },
    {
      i: 387,
      checked: false,
      name: 'Casadei',
      type: 'accessoriesBrand',
    },
    {
      i: 388,
      checked: false,
      name: 'Centro',
      type: 'accessoriesBrand',
    },
    {
      i: 389,
      checked: false,
      name: 'Chopard',
      type: 'accessoriesBrand',
    },
    {
      i: 390,
      checked: false,
      name: 'Clarks',
      type: 'accessoriesBrand',
    },
    {
      i: 391,
      checked: false,
      name: 'Columbia',
      type: 'accessoriesBrand',
    },
    {
      i: 392,
      checked: false,
      name: 'Converse',
      type: 'accessoriesBrand',
    },
    {
      i: 393,
      checked: false,
      name: 'Covani',
      type: 'accessoriesBrand',
    },
    {
      i: 394,
      checked: false,
      name: 'Craft',
      type: 'accessoriesBrand',
    },
    {
      i: 395,
      checked: false,
      name: 'Crocs',
      type: 'accessoriesBrand',
    },
    {
      i: 396,
      checked: false,
      name: 'Cropp',
      type: 'accessoriesBrand',
    },
    {
      i: 397,
      checked: false,
      name: 'Decathlon',
      type: 'accessoriesBrand',
    },
    {
      i: 398,
      checked: false,
      name: 'Demix',
      type: 'accessoriesBrand',
    },
    {
      i: 399,
      checked: false,
      name: 'Didriksons',
      type: 'accessoriesBrand',
    },
    {
      i: 400,
      checked: false,
      name: 'District',
      type: 'accessoriesBrand',
    },
    {
      i: 401,
      checked: false,
      name: 'Domyos',
      type: 'accessoriesBrand',
    },
    {
      i: 402,
      checked: false,
      name: 'Dorothy Perkins',
      type: 'accessoriesBrand',
    },
    {
      i: 403,
      checked: false,
      name: 'Dr.Martens',
      type: 'accessoriesBrand',
    },
    {
      i: 404,
      checked: false,
      name: 'Elis',
      type: 'accessoriesBrand',
    },
    {
      i: 405,
      checked: false,
      name: 'Fabi',
      type: 'accessoriesBrand',
    },
    {
      i: 406,
      checked: false,
      name: 'Fabiani',
      type: 'accessoriesBrand',
    },
    {
      i: 407,
      checked: false,
      name: 'Fila',
      type: 'accessoriesBrand',
    },
    {
      i: 408,
      checked: false,
      name: 'Finn Flare',
      type: 'accessoriesBrand',
    },
    {
      i: 409,
      checked: false,
      name: 'Fly Bra',
      type: 'accessoriesBrand',
    },
    {
      i: 410,
      checked: false,
      name: 'Forever',
      type: 'accessoriesBrand',
    },
    {
      i: 411,
      checked: false,
      name: 'Forward',
      type: 'accessoriesBrand',
    },
    {
      i: 412,
      checked: false,
      name: 'Francesco Donni',
      type: 'accessoriesBrand',
    },
    {
      i: 413,
      checked: false,
      name: 'Fred Perry',
      type: 'accessoriesBrand',
    },
    {
      i: 414,
      checked: false,
      name: 'Gabor',
      type: 'accessoriesBrand',
    },
    {
      i: 415,
      checked: false,
      name: 'Gap',
      type: 'accessoriesBrand',
    },
    {
      i: 416,
      checked: false,
      name: 'Gas',
      type: 'accessoriesBrand',
    },
    {
      i: 417,
      checked: false,
      name: 'Geox',
      type: 'accessoriesBrand',
    },
    {
      i: 418,
      checked: false,
      name: 'Gerry Weber',
      type: 'accessoriesBrand',
    },
    {
      i: 419,
      checked: false,
      name: 'Gianmarco Lorenzi',
      type: 'accessoriesBrand',
    },
    {
      i: 420,
      checked: false,
      name: 'Giuseppe Zanotti',
      type: 'accessoriesBrand',
    },
    {
      i: 421,
      checked: false,
      name: 'Glance',
      type: 'accessoriesBrand',
    },
    {
      i: 422,
      checked: false,
      name: 'Glenfield',
      type: 'accessoriesBrand',
    },
    {
      i: 423,
      checked: false,
      name: 'Gloria Jeans',
      type: 'accessoriesBrand',
    },
    {
      i: 424,
      checked: false,
      name: 'Grishko',
      type: 'accessoriesBrand',
    },
    {
      i: 425,
      checked: false,
      name: 'Helly Hansen',
      type: 'accessoriesBrand',
    },
    {
      i: 426,
      checked: false,
      name: 'Hogl',
      type: 'accessoriesBrand',
    },
    {
      i: 427,
      checked: false,
      name: 'Hollister',
      type: 'accessoriesBrand',
    },
    {
      i: 428,
      checked: false,
      name: 'House',
      type: 'accessoriesBrand',
    },
    {
      i: 429,
      checked: false,
      name: 'Iceberg',
      type: 'accessoriesBrand',
    },
    {
      i: 430,
      checked: false,
      name: 'Icepeak',
      type: 'accessoriesBrand',
    },
    {
      i: 431,
      checked: false,
      name: 'Imperial',
      type: 'accessoriesBrand',
    },
    {
      i: 432,
      checked: false,
      name: 'Infinity',
      type: 'accessoriesBrand',
    },
    {
      i: 433,
      checked: false,
      name: 'Intimissimi',
      type: 'accessoriesBrand',
    },
    {
      i: 434,
      checked: false,
      name: 'Janita',
      type: 'accessoriesBrand',
    },
    {
      i: 435,
      checked: false,
      name: 'Jennyfer',
      type: 'accessoriesBrand',
    },
    {
      i: 436,
      checked: false,
      name: 'Juicy Couture',
      type: 'accessoriesBrand',
    },
    {
      i: 437,
      checked: false,
      name: 'Kappa',
      type: 'accessoriesBrand',
    },
    {
      i: 438,
      checked: false,
      name: 'Kari',
      type: 'accessoriesBrand',
    },
    {
      i: 439,
      checked: false,
      name: 'Keddo',
      type: 'accessoriesBrand',
    },
    {
      i: 440,
      checked: false,
      name: 'Koton',
      type: 'accessoriesBrand',
    },
    {
      i: 441,
      checked: false,
      name: 'La Redoute',
      type: 'accessoriesBrand',
    },
    {
      i: 442,
      checked: false,
      name: 'Lee',
      type: 'accessoriesBrand',
    },
    {
      i: 443,
      checked: false,
      name: 'Lime',
      type: 'accessoriesBrand',
    },
    {
      i: 444,
      checked: false,
      name: 'Liu Jo',
      type: 'accessoriesBrand',
    },
    {
      i: 445,
      checked: false,
      name: 'Loriblu',
      type: 'accessoriesBrand',
    },
    {
      i: 446,
      checked: false,
      name: 'Lost Ink',
      type: 'accessoriesBrand',
    },
    {
      i: 447,
      checked: false,
      name: 'Luhta',
      type: 'accessoriesBrand',
    },
    {
      i: 448,
      checked: false,
      name: 'Lusio',
      type: 'accessoriesBrand',
    },
    {
      i: 449,
      checked: false,
      name: 'M.shoes',
      type: 'accessoriesBrand',
    },
    {
      i: 450,
      checked: false,
      name: 'Magistral',
      type: 'accessoriesBrand',
    },
    {
      i: 451,
      checked: false,
      name: 'Maje',
      type: 'accessoriesBrand',
    },
    {
      i: 452,
      checked: false,
      name: 'Marella',
      type: 'accessoriesBrand',
    },
    {
      i: 453,
      checked: false,
      name: 'Marko',
      type: 'accessoriesBrand',
    },
    {
      i: 454,
      checked: false,
      name: 'Marks&Spencer',
      type: 'accessoriesBrand',
    },
    {
      i: 455,
      checked: false,
      name: 'Microsoft',
      type: 'accessoriesBrand',
    },
    {
      i: 456,
      checked: false,
      name: 'Mizuno',
      type: 'accessoriesBrand',
    },
    {
      i: 457,
      checked: false,
      name: 'Modis',
      type: 'accessoriesBrand',
    },
    {
      i: 458,
      checked: false,
      name: 'Moncler',
      type: 'accessoriesBrand',
    },
    {
      i: 459,
      checked: false,
      name: 'Monki',
      type: 'accessoriesBrand',
    },
    {
      i: 460,
      checked: false,
      name: 'Montana',
      type: 'accessoriesBrand',
    },
    {
      i: 461,
      checked: false,
      name: 'Morgan',
      type: 'accessoriesBrand',
    },
    {
      i: 462,
      checked: false,
      name: 'Mustang',
      type: 'accessoriesBrand',
    },
    {
      i: 463,
      checked: false,
      name: 'NILA & NILA',
      type: 'accessoriesBrand',
    },
    {
      i: 464,
      checked: false,
      name: 'Nando Muzi',
      type: 'accessoriesBrand',
    },
    {
      i: 465,
      checked: false,
      name: 'North Face',
      type: 'accessoriesBrand',
    },
    {
      i: 466,
      checked: false,
      name: "O'Neill",
      type: 'accessoriesBrand',
    },
    {
      i: 467,
      checked: false,
      name: 'Oasis',
      type: 'accessoriesBrand',
    },
    {
      i: 468,
      checked: false,
      name: 'Odlo',
      type: 'accessoriesBrand',
    },
    {
      i: 469,
      checked: false,
      name: 'Omsa',
      type: 'accessoriesBrand',
    },
    {
      i: 470,
      checked: false,
      name: 'Only',
      type: 'accessoriesBrand',
    },
    {
      i: 471,
      checked: false,
      name: 'Orsay',
      type: 'accessoriesBrand',
    },
    {
      i: 472,
      checked: false,
      name: 'Outventure',
      type: 'accessoriesBrand',
    },
    {
      i: 473,
      checked: false,
      name: 'Paolo Conte',
      type: 'accessoriesBrand',
    },
    {
      i: 474,
      checked: false,
      name: 'Paolo Conte',
      type: 'accessoriesBrand',
    },
    {
      i: 475,
      checked: false,
      name: 'Балетки',
      type: 12,
    },
    {
      i: 476,
      checked: false,
      name: 'Босоножки',
      type: 12,
    },
    {
      i: 477,
      checked: false,
      name: 'Ботильоны',
      type: 12,
    },
    {
      i: 478,
      checked: false,
      name: 'Ботинки и полуботинки',
      type: 12,
    },
    {
      i: 479,
      checked: false,
      name: 'Ботфорты',
      type: 12,
    },
    {
      i: 480,
      checked: false,
      name: 'Валенки и галоши',
      type: 12,
    },
    {
      i: 481,
      checked: false,
      name: 'Домашняя обувь',
      type: 12,
    },
    {
      i: 482,
      checked: false,
      name: 'Дутики и луноходы',
      type: 12,
    },
    {
      i: 483,
      checked: false,
      name: 'Кеды',
      type: 12,
    },
    {
      i: 484,
      checked: false,
      name: 'Кроссовки',
      type: 12,
    },
    {
      i: 485,
      checked: false,
      name: 'Мокасины',
      type: 12,
    },
    {
      i: 486,
      checked: false,
      name: 'Полусапожки',
      type: 12,
    },
    {
      i: 487,
      checked: false,
      name: 'Резиновые сапоги',
      type: 12,
    },
    {
      i: 488,
      checked: false,
      name: 'Сабо и мюли',
      type: 12,
    },
    {
      i: 489,
      checked: false,
      name: 'Сандалии',
      type: 12,
    },
    {
      i: 490,
      checked: false,
      name: 'Сапоги',
      type: 12,
    },
    {
      i: 491,
      checked: false,
      name: 'Сланцы и аквасоки',
      type: 12,
    },
    {
      i: 492,
      checked: false,
      name: 'Слипоны',
      type: 12,
    },
    {
      i: 493,
      checked: false,
      name: 'Тапочки',
      type: 12,
    },
    {
      i: 494,
      checked: false,
      name: 'Топсайдеры',
      type: 12,
    },
    {
      i: 495,
      checked: false,
      name: 'Туфли',
      type: 12,
    },
    {
      i: 496,
      checked: false,
      name: 'Угги и унты',
      type: 12,
    },
    {
      i: 497,
      checked: false,
      name: 'Чешки и джазовки',
      type: 12,
    },
    {
      i: 498,
      checked: false,
      name: 'Шлепанцы',
      type: 12,
    },
    {
      i: 499,
      checked: false,
      name: 'Эспадрильи',
      type: 12,
    },
    {
      i: 500,
      checked: false,
      name: '33',
      type: 'shoesSize',
    },
    {
      i: 501,
      checked: false,
      name: '34',
      type: 'shoesSize',
    },
    {
      i: 502,
      checked: false,
      name: '34,5',
      type: 'shoesSize',
    },
    {
      i: 503,
      checked: false,
      name: '35',
      type: 'shoesSize',
    },
    {
      i: 504,
      checked: false,
      name: '35,5',
      type: 'shoesSize',
    },
    {
      i: 505,
      checked: false,
      name: '36',
      type: 'shoesSize',
    },
    {
      i: 506,
      checked: false,
      name: '36,5',
      type: 'shoesSize',
    },
    {
      i: 507,
      checked: false,
      name: '37',
      type: 'shoesSize',
    },
    {
      i: 508,
      checked: false,
      name: '37,5',
      type: 'shoesSize',
    },
    {
      i: 509,
      checked: false,
      name: '38',
      type: 'shoesSize',
    },
    {
      i: 510,
      checked: false,
      name: '38,5',
      type: 'shoesSize',
    },
    {
      i: 511,
      checked: false,
      name: '39',
      type: 'shoesSize',
    },
    {
      i: 512,
      checked: false,
      name: '39,5',
      type: 'shoesSize',
    },
    {
      i: 513,
      checked: false,
      name: '40',
      type: 'shoesSize',
    },
    {
      i: 514,
      checked: false,
      name: '40,5',
      type: 'shoesSize',
    },
    {
      i: 515,
      checked: false,
      name: '41',
      type: 'shoesSize',
    },
    {
      i: 516,
      checked: false,
      name: '41,5',
      type: 'shoesSize',
    },
    {
      i: 517,
      checked: false,
      name: '42',
      type: 'shoesSize',
    },
    {
      i: 518,
      checked: false,
      name: '42,5',
      type: 'shoesSize',
    },
    {
      i: 519,
      checked: false,
      name: '43',
      type: 'shoesSize',
    },
    {
      i: 520,
      checked: false,
      name: '43,5',
      type: 'shoesSize',
    },
    {
      i: 521,
      checked: false,
      name: '44',
      type: 'shoesSize',
    },
    {
      i: 522,
      checked: false,
      name: '44,5',
      type: 'shoesSize',
    },
    {
      i: 523,
      checked: false,
      name: '45',
      type: 'shoesSize',
    },
    {
      i: 524,
      checked: false,
      name: 'Блейзеры',
      type: 13,
    },
    {
      i: 525,
      checked: false,
      name: 'Болеро',
      type: 13,
    },
    {
      i: 526,
      checked: false,
      name: 'Жакеты',
      type: 13,
    },
    {
      i: 527,
      checked: false,
      name: 'Жилетки',
      type: 13,
    },
    {
      i: 528,
      checked: false,
      name: 'Костюмы с брюками',
      type: 13,
    },
    {
      i: 529,
      checked: false,
      name: 'Костюмы с юбкой',
      type: 13,
    },
    {
      i: 530,
      checked: false,
      name: 'Пиджаки',
      type: 13,
    },
    {
      i: 531,
      checked: false,
      name: 'Макси',
      type: 'lengthProduct',
    },
    {
      i: 532,
      checked: false,
      name: 'Миди',
      type: 'lengthProduct',
    },
    {
      i: 533,
      checked: false,
      name: 'Мини',
      type: 'lengthProduct',
    },
    {
      i: 534,
      checked: false,
      name: 'Вечерние платья',
      type: 14,
    },
    {
      i: 535,
      checked: false,
      name: 'Вязаные платья',
      type: 14,
    },
    {
      i: 536,
      checked: false,
      name: 'Джинсовые платья',
      type: 14,
    },
    {
      i: 537,
      checked: false,
      name: 'Офисные платья',
      type: 14,
    },
    {
      i: 538,
      checked: false,
      name: 'Платья мини',
      type: 14,
    },
    {
      i: 539,
      checked: false,
      name: 'Платья-рубашки',
      type: 14,
    },
    {
      i: 540,
      checked: false,
      name: 'Повседневные платья',
      type: 14,
    },
    {
      i: 541,
      checked: false,
      name: 'Сарафаны',
      type: 14,
    },
    {
      i: 542,
      checked: false,
      name: 'Свадебные платья',
      type: 14,
    },
    {
      i: 543,
      checked: false,
      name: 'Туники',
      type: 14,
    },
    {
      i: 544,
      checked: false,
      name: 'Юбки',
      type: 14,
    },
    {
      i: 545,
      checked: false,
      name: 'Водолазки',
      type: 15,
    },
    {
      i: 546,
      checked: false,
      name: 'Джемперы',
      type: 15,
    },
    {
      i: 547,
      checked: false,
      name: 'Кардиганы',
      type: 15,
    },
    {
      i: 548,
      checked: false,
      name: 'Кофты',
      type: 15,
    },
    {
      i: 549,
      checked: false,
      name: 'Олимпийки',
      type: 15,
    },
    {
      i: 550,
      checked: false,
      name: 'Пуловеры',
      type: 15,
    },
    {
      i: 551,
      checked: false,
      name: 'Свитеры',
      type: 15,
    },
    {
      i: 552,
      checked: false,
      name: 'Свитшоты',
      type: 15,
    },
    {
      i: 553,
      checked: false,
      name: 'Твинсеты',
      type: 15,
    },
    {
      i: 554,
      checked: false,
      name: 'Толстовки и худи',
      type: 15,
    },
    {
      i: 555,
      checked: false,
      name: 'Верхняя одежда',
      type: 16,
    },
    {
      i: 556,
      checked: false,
      name: 'Комбинезоны',
      type: 16,
    },
    {
      i: 557,
      checked: false,
      name: 'Платья и юбки',
      type: 16,
    },
    {
      i: 558,
      checked: false,
      name: 'Спортивные костюмы',
      type: 16,
    },
    {
      i: 559,
      checked: false,
      name: 'Футболки и топы',
      type: 16,
    },
    {
      i: 560,
      checked: false,
      name: 'Штаны и шорты',
      type: 16,
    },
    {
      i: 561,
      checked: false,
      name: 'Лонгсливы',
      type: 17,
    },
    {
      i: 562,
      checked: false,
      name: 'Майки',
      type: 17,
    },
    {
      i: 563,
      checked: false,
      name: 'Поло',
      type: 17,
    },
    {
      i: 564,
      checked: false,
      name: 'Топы',
      type: 17,
    },
    {
      i: 565,
      checked: false,
      name: 'Футболки',
      type: 17,
    },
    {
      i: 566,
      checked: false,
      name: 'Бриджи',
      type: 18,
    },
    {
      i: 567,
      checked: false,
      name: 'Брюки',
      type: 18,
    },
    {
      i: 568,
      checked: false,
      name: 'Джинсы',
      type: 18,
    },
    {
      i: 569,
      checked: false,
      name: 'Капри',
      type: 18,
    },
    {
      i: 570,
      checked: false,
      name: 'Леггинсы',
      type: 18,
    },
    {
      i: 571,
      checked: false,
      name: 'Шорты',
      type: 18,
    },
    {
      i: 572,
      checked: false,
      name: 'Брелоки и ключницы',
      type: 2,
    },
    {
      i: 573,
      checked: false,
      name: 'Галстуки и бабочки',
      type: 2,
    },
    {
      i: 574,
      checked: false,
      name: 'Зонты',
      type: 2,
    },
    {
      i: 575,
      checked: false,
      name: 'Кошельки',
      type: 2,
    },
    {
      i: 576,
      checked: false,
      name: 'Очки',
      type: 2,
    },
    {
      i: 577,
      checked: false,
      name: 'Перчатки и варежки',
      type: 2,
    },
    {
      i: 578,
      checked: false,
      name: 'Ремни и пояса',
      type: 2,
    },
    {
      i: 579,
      checked: false,
      name: 'Рюкзаки',
      type: 2,
    },
    {
      i: 580,
      checked: false,
      name: 'Сумки',
      type: 2,
    },
    {
      i: 581,
      checked: false,
      name: 'Украшения',
      type: 2,
    },
    {
      i: 582,
      checked: false,
      name: 'Часы',
      type: 2,
    },
    {
      i: 583,
      checked: false,
      name: 'Чемоданы',
      type: 2,
    },
    {
      i: 584,
      checked: false,
      name: 'Шарфы и платки',
      type: 2,
    },
    {
      i: 585,
      checked: false,
      name: 'Ветровки',
      type: 20,
    },
    {
      i: 586,
      checked: false,
      name: 'Джинсовые куртки',
      type: 20,
    },
    {
      i: 587,
      checked: false,
      name: 'Дождевики',
      type: 20,
    },
    {
      i: 588,
      checked: false,
      name: 'Дубленки',
      type: 20,
    },
    {
      i: 589,
      checked: false,
      name: 'Жилеты',
      type: 20,
    },
    {
      i: 590,
      checked: false,
      name: 'Кожаные куртки',
      type: 20,
    },
    {
      i: 591,
      checked: false,
      name: 'Куртки',
      type: 20,
    },
    {
      i: 592,
      checked: false,
      name: 'Пальто',
      type: 20,
    },
    {
      i: 593,
      checked: false,
      name: 'Парки',
      type: 20,
    },
    {
      i: 594,
      checked: false,
      name: 'Плащи и тренчи',
      type: 20,
    },
    {
      i: 595,
      checked: false,
      name: 'Пуховики',
      type: 20,
    },
    {
      i: 596,
      checked: false,
      name: 'Шубы',
      type: 20,
    },
    {
      i: 597,
      checked: false,
      name: 'Банданы',
      type: 21,
    },
    {
      i: 598,
      checked: false,
      name: 'Бейсболки и кепки',
      type: 21,
    },
    {
      i: 599,
      checked: false,
      name: 'Панамы',
      type: 21,
    },
    {
      i: 600,
      checked: false,
      name: 'Шапки',
      type: 21,
    },
    {
      i: 601,
      checked: false,
      name: 'Шарфы',
      type: 21,
    },
    {
      i: 602,
      checked: false,
      name: 'Шляпы',
      type: 21,
    },
    {
      i: 603,
      checked: false,
      name: 'Пижамы',
      type: 22,
    },
    {
      i: 604,
      checked: false,
      name: 'Халаты',
      type: 22,
    },
    {
      i: 605,
      checked: false,
      name: 'Комбинезоны',
      type: 23,
    },
    {
      i: 606,
      checked: false,
      name: 'Полукомбинезоны',
      type: 23,
    },
    {
      i: 607,
      checked: false,
      name: 'Носки',
      type: 24,
    },
    {
      i: 608,
      checked: false,
      name: 'Плавки',
      type: 24,
    },
    {
      i: 609,
      checked: false,
      name: 'Термобелье',
      type: 24,
    },
    {
      i: 610,
      checked: false,
      name: 'Трусы',
      type: 24,
    },
    {
      i: 611,
      checked: false,
      name: 'Ботинки',
      type: 25,
    },
    {
      i: 612,
      checked: false,
      name: 'Валенки и галоши',
      type: 25,
    },
    {
      i: 613,
      checked: false,
      name: 'Домашняя обувь',
      type: 25,
    },
    {
      i: 614,
      checked: false,
      name: 'Кеды',
      type: 25,
    },
    {
      i: 615,
      checked: false,
      name: 'Кроссовки',
      type: 25,
    },
    {
      i: 616,
      checked: false,
      name: 'Мокасины',
      type: 25,
    },
    {
      i: 617,
      checked: false,
      name: 'Сандалии',
      type: 25,
    },
    {
      i: 618,
      checked: false,
      name: 'Сапоги',
      type: 25,
    },
    {
      i: 619,
      checked: false,
      name: 'Слипоны',
      type: 25,
    },
    {
      i: 620,
      checked: false,
      name: 'Тапочки',
      type: 25,
    },
    {
      i: 621,
      checked: false,
      name: 'Туфли',
      type: 25,
    },
    {
      i: 622,
      checked: false,
      name: 'Угги и унты',
      type: 25,
    },
    {
      i: 623,
      checked: false,
      name: 'Шлепанцы',
      type: 25,
    },
    {
      i: 624,
      checked: false,
      name: 'Жакеты',
      type: 26,
    },
    {
      i: 625,
      checked: false,
      name: 'Жилетки',
      type: 26,
    },
    {
      i: 626,
      checked: false,
      name: 'Костюмы',
      type: 26,
    },
    {
      i: 627,
      checked: false,
      name: 'Пиджаки',
      type: 26,
    },
    {
      i: 628,
      checked: false,
      name: 'Джинсовые',
      type: 27,
    },
    {
      i: 629,
      checked: false,
      name: 'Длинный рукав',
      type: 27,
    },
    {
      i: 630,
      checked: false,
      name: 'Короткий рукав',
      type: 27,
    },
    {
      i: 631,
      checked: false,
      name: 'Водолазки',
      type: 28,
    },
    {
      i: 632,
      checked: false,
      name: 'Джемперы',
      type: 28,
    },
    {
      i: 633,
      checked: false,
      name: 'Кардиганы',
      type: 28,
    },
    {
      i: 634,
      checked: false,
      name: 'Кофты',
      type: 28,
    },
    {
      i: 635,
      checked: false,
      name: 'Олимпийки',
      type: 28,
    },
    {
      i: 636,
      checked: false,
      name: 'Пуловеры',
      type: 28,
    },
    {
      i: 637,
      checked: false,
      name: 'Свитеры',
      type: 28,
    },
    {
      i: 638,
      checked: false,
      name: 'Толстовки и худи',
      type: 28,
    },
    {
      i: 639,
      checked: false,
      name: 'Верхняя одежда',
      type: 30,
    },
    {
      i: 640,
      checked: false,
      name: 'Спортивные костюмы',
      type: 30,
    },
    {
      i: 641,
      checked: false,
      name: 'Футболки и поло',
      type: 30,
    },
    {
      i: 642,
      checked: false,
      name: 'Штаны и шорты',
      type: 30,
    },
    {
      i: 643,
      checked: false,
      name: 'Лонгсливы',
      type: 31,
    },
    {
      i: 644,
      checked: false,
      name: 'Майки',
      type: 31,
    },
    {
      i: 645,
      checked: false,
      name: 'Поло',
      type: 31,
    },
    {
      i: 646,
      checked: false,
      name: 'Футболки',
      type: 31,
    },
    {
      i: 647,
      checked: false,
      name: 'Бриджи',
      type: 32,
    },
    {
      i: 648,
      checked: false,
      name: 'Брюки',
      type: 32,
    },
    {
      i: 649,
      checked: false,
      name: 'Джинсы',
      type: 32,
    },
    {
      i: 650,
      checked: false,
      name: 'Шорты',
      type: 32,
    },
    {
      i: 651,
      checked: false,
      name: '28',
      type: 'sizeManProduct',
    },
    {
      i: 652,
      checked: false,
      name: '29',
      type: 'sizeManProduct',
    },
    {
      i: 653,
      checked: false,
      name: '30',
      type: 'sizeManProduct',
    },
    {
      i: 654,
      checked: false,
      name: '31',
      type: 'sizeManProduct',
    },
    {
      i: 655,
      checked: false,
      name: '32',
      type: 'sizeManProduct',
    },
    {
      i: 656,
      checked: false,
      name: '33',
      type: 'sizeManProduct',
    },
    {
      i: 657,
      checked: false,
      name: '34',
      type: 'sizeManProduct',
    },
    {
      i: 658,
      checked: false,
      name: '35',
      type: 'sizeManProduct',
    },
    {
      i: 659,
      checked: false,
      name: '36',
      type: 'sizeManProduct',
    },
    {
      i: 660,
      checked: false,
      name: '37',
      type: 'sizeManProduct',
    },
    {
      i: 661,
      checked: false,
      name: '38 и больше',
      type: 'sizeManProduct',
    },
    {
      i: 662,
      checked: false,
      name: 'Мальчик',
      type: 'sexChild',
    },
    {
      i: 663,
      checked: false,
      name: 'Девочка',
      type: 'sexChild',
    },
    {
      i: 664,
      checked: false,
      name: 'Банты',
      type: 3,
    },
    {
      i: 665,
      checked: false,
      name: 'Бижутерия',
      type: 3,
    },
    {
      i: 666,
      checked: false,
      name: 'Галстуки и бабочки',
      type: 3,
    },
    {
      i: 667,
      checked: false,
      name: 'Заколки, резинки и ободки',
      type: 3,
    },
    {
      i: 668,
      checked: false,
      name: 'Зонты',
      type: 3,
    },
    {
      i: 669,
      checked: false,
      name: 'Карнавальные костюмы',
      type: 3,
    },
    {
      i: 670,
      checked: false,
      name: 'Кошельки',
      type: 3,
    },
    {
      i: 671,
      checked: false,
      name: 'Очки',
      type: 3,
    },
    {
      i: 672,
      checked: false,
      name: 'Перчатки и варежки',
      type: 3,
    },
    {
      i: 673,
      checked: false,
      name: 'Ремни и пояса',
      type: 3,
    },
    {
      i: 674,
      checked: false,
      name: 'Рюкзаки',
      type: 3,
    },
    {
      i: 675,
      checked: false,
      name: 'Сумки',
      type: 3,
    },
    {
      i: 676,
      checked: false,
      name: 'Украшения',
      type: 3,
    },
    {
      i: 677,
      checked: false,
      name: 'Часы',
      type: 3,
    },
    {
      i: 678,
      checked: false,
      name: 'Шарфы и платки',
      type: 3,
    },
    {
      i: 679,
      checked: false,
      name: 'Блузы',
      type: 34,
    },
    {
      i: 680,
      checked: false,
      name: 'Рубашки',
      type: 34,
    },
    {
      i: 681,
      checked: false,
      name: '46-50 (0-1 месяц)',
      type: 'yearsChild',
    },
    {
      i: 682,
      checked: false,
      name: '51-56 (2 месяца)',
      type: 'yearsChild',
    },
    {
      i: 683,
      checked: false,
      name: '57-62 (3 месяца)',
      type: 'yearsChild',
    },
    {
      i: 684,
      checked: false,
      name: '63-68 (6 месяцев)',
      type: 'yearsChild',
    },
    {
      i: 685,
      checked: false,
      name: '69-74 (9 месяцев)',
      type: 'yearsChild',
    },
    {
      i: 686,
      checked: false,
      name: '75-80 (1 год)',
      type: 'yearsChild',
    },
    {
      i: 687,
      checked: false,
      name: '81-86 (1,5 года)',
      type: 'yearsChild',
    },
    {
      i: 688,
      checked: false,
      name: '87-92 (2 года)',
      type: 'yearsChild',
    },
    {
      i: 689,
      checked: false,
      name: '93-98 (3 года)',
      type: 'yearsChild',
    },
    {
      i: 690,
      checked: false,
      name: '99-104 (4 года)',
      type: 'yearsChild',
    },
    {
      i: 691,
      checked: false,
      name: '105-110 (5 лет)',
      type: 'yearsChild',
    },
    {
      i: 692,
      checked: false,
      name: '111-116 (6 лет)',
      type: 'yearsChild',
    },
    {
      i: 693,
      checked: false,
      name: '117-122 (7 лет)',
      type: 'yearsChild',
    },
    {
      i: 694,
      checked: false,
      name: '123-128 (8 лет)',
      type: 'yearsChild',
    },
    {
      i: 695,
      checked: false,
      name: '129-134 (9 лет)',
      type: 'yearsChild',
    },
    {
      i: 696,
      checked: false,
      name: '135-140 (10 лет)',
      type: 'yearsChild',
    },
    {
      i: 697,
      checked: false,
      name: '141-146 (11 лет)',
      type: 'yearsChild',
    },
    {
      i: 698,
      checked: false,
      name: '147-152 (12 лет)',
      type: 'yearsChild',
    },
    {
      i: 699,
      checked: false,
      name: '153-158 (13 лет)',
      type: 'yearsChild',
    },
    {
      i: 700,
      checked: false,
      name: '159-164 (14 лет)',
      type: 'yearsChild',
    },
    {
      i: 701,
      checked: false,
      name: 'Дождевики',
      type: 35,
    },
    {
      i: 702,
      checked: false,
      name: 'Дубленки',
      type: 35,
    },
    {
      i: 703,
      checked: false,
      name: 'Зимние брюки',
      type: 35,
    },
    {
      i: 704,
      checked: false,
      name: 'Жилеты',
      type: 35,
    },
    {
      i: 705,
      checked: false,
      name: 'Кожаные куртки',
      type: 35,
    },
    {
      i: 706,
      checked: false,
      name: 'Комбинезоны и костюмы',
      type: 35,
    },
    {
      i: 707,
      checked: false,
      name: 'Куртки',
      type: 35,
    },
    {
      i: 708,
      checked: false,
      name: 'Накидки и пончо',
      type: 35,
    },
    {
      i: 709,
      checked: false,
      name: 'Пальто',
      type: 35,
    },
    {
      i: 710,
      checked: false,
      name: 'Парки',
      type: 35,
    },
    {
      i: 711,
      checked: false,
      name: 'Плащи',
      type: 35,
    },
    {
      i: 712,
      checked: false,
      name: 'Пуховики',
      type: 35,
    },
    {
      i: 713,
      checked: false,
      name: 'Шубы',
      type: 35,
    },
    {
      i: 714,
      checked: false,
      name: 'Бейсболки и кепки',
      type: 36,
    },
    {
      i: 715,
      checked: false,
      name: 'Косынки и банданы',
      type: 36,
    },
    {
      i: 716,
      checked: false,
      name: 'Панамы',
      type: 36,
    },
    {
      i: 717,
      checked: false,
      name: 'Повязки',
      type: 36,
    },
    {
      i: 718,
      checked: false,
      name: 'Чепчики',
      type: 36,
    },
    {
      i: 719,
      checked: false,
      name: 'Шапки',
      type: 36,
    },
    {
      i: 720,
      checked: false,
      name: 'Шляпы',
      type: 36,
    },
    {
      i: 721,
      checked: false,
      name: 'Пижамы',
      type: 37,
    },
    {
      i: 722,
      checked: false,
      name: 'Халаты',
      type: 37,
    },
    {
      i: 723,
      checked: false,
      name: 'Боди',
      type: 38,
    },
    {
      i: 724,
      checked: false,
      name: 'Комбинезоны',
      type: 38,
    },
    {
      i: 725,
      checked: false,
      name: 'Бюстгальтеры',
      type: 40,
    },
    {
      i: 726,
      checked: false,
      name: 'Кальсоны',
      type: 40,
    },
    {
      i: 727,
      checked: false,
      name: 'Колготки',
      type: 40,
    },
    {
      i: 728,
      checked: false,
      name: 'Купальники',
      type: 40,
    },
    {
      i: 729,
      checked: false,
      name: 'Носки',
      type: 40,
    },
    {
      i: 730,
      checked: false,
      name: 'Плавки',
      type: 40,
    },
    {
      i: 731,
      checked: false,
      name: 'Термобелье',
      type: 40,
    },
    {
      i: 732,
      checked: false,
      name: 'Трусы',
      type: 40,
    },
    {
      i: 733,
      checked: false,
      name: 'Балетки',
      type: 41,
    },
    {
      i: 734,
      checked: false,
      name: 'Босоножки и сабо',
      type: 41,
    },
    {
      i: 735,
      checked: false,
      name: 'Ботинки',
      type: 41,
    },
    {
      i: 736,
      checked: false,
      name: 'Валенки и угги',
      type: 41,
    },
    {
      i: 737,
      checked: false,
      name: 'Домашняя обувь',
      type: 41,
    },
    {
      i: 738,
      checked: false,
      name: 'Дутики',
      type: 41,
    },
    {
      i: 739,
      checked: false,
      name: 'Кеды',
      type: 41,
    },
    {
      i: 740,
      checked: false,
      name: 'Кроссовки',
      type: 41,
    },
    {
      i: 741,
      checked: false,
      name: 'Мокасины',
      type: 41,
    },
    {
      i: 742,
      checked: false,
      name: 'Обувь на первый шаг',
      type: 41,
    },
    {
      i: 743,
      checked: false,
      name: 'Ортопедическая обувь',
      type: 41,
    },
    {
      i: 744,
      checked: false,
      name: 'Пинетки',
      type: 41,
    },
    {
      i: 745,
      checked: false,
      name: 'Полуботинки',
      type: 41,
    },
    {
      i: 746,
      checked: false,
      name: 'Резиновые сапоги',
      type: 41,
    },
    {
      i: 747,
      checked: false,
      name: 'Сандалии',
      type: 41,
    },
    {
      i: 748,
      checked: false,
      name: 'Сапоги',
      type: 41,
    },
    {
      i: 749,
      checked: false,
      name: 'Слипоны',
      type: 41,
    },
    {
      i: 750,
      checked: false,
      name: 'Тапочки',
      type: 41,
    },
    {
      i: 751,
      checked: false,
      name: 'Туфли',
      type: 41,
    },
    {
      i: 752,
      checked: false,
      name: 'Чешки',
      type: 41,
    },
    {
      i: 753,
      checked: false,
      name: 'Шлепанцы и пляжная обувь',
      type: 41,
    },
    {
      i: 754,
      checked: false,
      name: '19 и меньше',
      type: 'shoesChild',
    },
    {
      i: 755,
      checked: false,
      name: '19,5',
      type: 'shoesChild',
    },
    {
      i: 756,
      checked: false,
      name: '20',
      type: 'shoesChild',
    },
    {
      i: 757,
      checked: false,
      name: '20,5',
      type: 'shoesChild',
    },
    {
      i: 758,
      checked: false,
      name: '21',
      type: 'shoesChild',
    },
    {
      i: 759,
      checked: false,
      name: '21,5',
      type: 'shoesChild',
    },
    {
      i: 760,
      checked: false,
      name: '22',
      type: 'shoesChild',
    },
    {
      i: 761,
      checked: false,
      name: '22,5',
      type: 'shoesChild',
    },
    {
      i: 762,
      checked: false,
      name: '23',
      type: 'shoesChild',
    },
    {
      i: 763,
      checked: false,
      name: '23,5',
      type: 'shoesChild',
    },
    {
      i: 764,
      checked: false,
      name: '24',
      type: 'shoesChild',
    },
    {
      i: 765,
      checked: false,
      name: '24,5',
      type: 'shoesChild',
    },
    {
      i: 766,
      checked: false,
      name: '25',
      type: 'shoesChild',
    },
    {
      i: 767,
      checked: false,
      name: '25,5',
      type: 'shoesChild',
    },
    {
      i: 768,
      checked: false,
      name: '26',
      type: 'shoesChild',
    },
    {
      i: 769,
      checked: false,
      name: '26,5',
      type: 'shoesChild',
    },
    {
      i: 770,
      checked: false,
      name: '27',
      type: 'shoesChild',
    },
    {
      i: 771,
      checked: false,
      name: '27,5',
      type: 'shoesChild',
    },
    {
      i: 772,
      checked: false,
      name: '28',
      type: 'shoesChild',
    },
    {
      i: 773,
      checked: false,
      name: '28,5',
      type: 'shoesChild',
    },
    {
      i: 774,
      checked: false,
      name: '29',
      type: 'shoesChild',
    },
    {
      i: 775,
      checked: false,
      name: '29,5',
      type: 'shoesChild',
    },
    {
      i: 776,
      checked: false,
      name: '30',
      type: 'shoesChild',
    },
    {
      i: 777,
      checked: false,
      name: '30,5',
      type: 'shoesChild',
    },
    {
      i: 778,
      checked: false,
      name: '31',
      type: 'shoesChild',
    },
    {
      i: 779,
      checked: false,
      name: '31,5',
      type: 'shoesChild',
    },
    {
      i: 780,
      checked: false,
      name: '32',
      type: 'shoesChild',
    },
    {
      i: 781,
      checked: false,
      name: '32,5',
      type: 'shoesChild',
    },
    {
      i: 782,
      checked: false,
      name: '33',
      type: 'shoesChild',
    },
    {
      i: 783,
      checked: false,
      name: '33,5',
      type: 'shoesChild',
    },
    {
      i: 784,
      checked: false,
      name: '34',
      type: 'shoesChild',
    },
    {
      i: 785,
      checked: false,
      name: '34,5',
      type: 'shoesChild',
    },
    {
      i: 786,
      checked: false,
      name: '35',
      type: 'shoesChild',
    },
    {
      i: 787,
      checked: false,
      name: '35,5',
      type: 'shoesChild',
    },
    {
      i: 788,
      checked: false,
      name: '36',
      type: 'shoesChild',
    },
    {
      i: 789,
      checked: false,
      name: '36,5',
      type: 'shoesChild',
    },
    {
      i: 790,
      checked: false,
      name: '37 и больше',
      type: 'shoesChild',
    },
    {
      i: 791,
      checked: false,
      name: 'Жилетки',
      type: 42,
    },
    {
      i: 792,
      checked: false,
      name: 'Костюмы',
      type: 42,
    },
    {
      i: 793,
      checked: false,
      name: 'Пиджаки',
      type: 42,
    },
    {
      i: 794,
      checked: false,
      name: 'Школьная форма',
      type: 42,
    },
    {
      i: 795,
      checked: false,
      name: 'Платья',
      type: 43,
    },
    {
      i: 796,
      checked: false,
      name: 'Сарафаны',
      type: 43,
    },
    {
      i: 797,
      checked: false,
      name: 'Туники',
      type: 43,
    },
    {
      i: 798,
      checked: false,
      name: 'Юбки',
      type: 43,
    },
    {
      i: 799,
      checked: false,
      name: 'Водолазки',
      type: 45,
    },
    {
      i: 800,
      checked: false,
      name: 'Джемперы',
      type: 45,
    },
    {
      i: 801,
      checked: false,
      name: 'Кардиганы',
      type: 45,
    },
    {
      i: 802,
      checked: false,
      name: 'Лонгсливы',
      type: 45,
    },
    {
      i: 803,
      checked: false,
      name: 'Олимпийки',
      type: 45,
    },
    {
      i: 804,
      checked: false,
      name: 'Свитеры',
      type: 45,
    },
    {
      i: 805,
      checked: false,
      name: 'Свитшоты',
      type: 45,
    },
    {
      i: 806,
      checked: false,
      name: 'Толстовки и худи',
      type: 45,
    },
    {
      i: 807,
      checked: false,
      name: 'Песочники',
      type: 44,
    },
    {
      i: 808,
      checked: false,
      name: 'Ползунки',
      type: 44,
    },
    {
      i: 809,
      checked: false,
      name: 'Распашонки',
      type: 44,
    },
    {
      i: 810,
      checked: false,
      name: 'Верхняя одежда',
      type: 46,
    },
    {
      i: 811,
      checked: false,
      name: 'Комбинезоны',
      type: 46,
    },
    {
      i: 812,
      checked: false,
      name: 'Купальники',
      type: 46,
    },
    {
      i: 813,
      checked: false,
      name: 'Лосины и гетры',
      type: 46,
    },
    {
      i: 814,
      checked: false,
      name: 'Платья и юбки',
      type: 46,
    },
    {
      i: 815,
      checked: false,
      name: 'Спортивные костюмы',
      type: 46,
    },
    {
      i: 816,
      checked: false,
      name: 'Футболки и топы',
      type: 46,
    },
    {
      i: 817,
      checked: false,
      name: 'Штаны и шорты',
      type: 46,
    },
    {
      i: 818,
      checked: false,
      name: 'Майки',
      type: 47,
    },
    {
      i: 819,
      checked: false,
      name: 'Поло',
      type: 47,
    },
    {
      i: 820,
      checked: false,
      name: 'Топы',
      type: 47,
    },
    {
      i: 821,
      checked: false,
      name: 'Футболки',
      type: 47,
    },
    {
      i: 822,
      checked: false,
      name: 'Бриджи',
      type: 48,
    },
    {
      i: 823,
      checked: false,
      name: 'Брюки',
      type: 48,
    },
    {
      i: 824,
      checked: false,
      name: 'Джинсы',
      type: 48,
    },
    {
      i: 825,
      checked: false,
      name: 'Леггинсы',
      type: 48,
    },
    {
      i: 826,
      checked: false,
      name: 'Шорты',
      type: 48,
    },
    {
      i: 827,
      checked: false,
      name: 'BRITAX RÖMER',
      type: 'brandCarSeats',
    },
    {
      i: 828,
      checked: false,
      name: 'Baby Care',
      type: 'brandCarSeats',
    },
    {
      i: 829,
      checked: false,
      name: 'Bebe confort',
      type: 'brandCarSeats',
    },
    {
      i: 830,
      checked: false,
      name: 'Bertoni Lorelli',
      type: 'brandCarSeats',
    },
    {
      i: 831,
      checked: false,
      name: 'Brevi',
      type: 'brandCarSeats',
    },
    {
      i: 832,
      checked: false,
      name: 'Carmate',
      type: 'brandCarSeats',
    },
    {
      i: 833,
      checked: false,
      name: 'Chicco',
      type: 'brandCarSeats',
    },
    {
      i: 834,
      checked: false,
      name: 'Coto Baby',
      type: 'brandCarSeats',
    },
    {
      i: 835,
      checked: false,
      name: 'Cybex',
      type: 'brandCarSeats',
    },
    {
      i: 836,
      checked: false,
      name: 'Graco',
      type: 'brandCarSeats',
    },
    {
      i: 837,
      checked: false,
      name: 'Happy Baby',
      type: 'brandCarSeats',
    },
    {
      i: 838,
      checked: false,
      name: 'Inglesina',
      type: 'brandCarSeats',
    },
    {
      i: 839,
      checked: false,
      name: 'Kenga',
      type: 'brandCarSeats',
    },
    {
      i: 840,
      checked: false,
      name: 'Lider Kids',
      type: 'brandCarSeats',
    },
    {
      i: 841,
      checked: false,
      name: 'Maxi-Cosi',
      type: 'brandCarSeats',
    },
    {
      i: 842,
      checked: false,
      name: 'Nania',
      type: 'brandCarSeats',
    },
    {
      i: 843,
      checked: false,
      name: 'Peg-Perego',
      type: 'brandCarSeats',
    },
    {
      i: 844,
      checked: false,
      name: 'Recaro',
      type: 'brandCarSeats',
    },
    {
      i: 845,
      checked: false,
      name: 'Siger',
      type: 'brandCarSeats',
    },
    {
      i: 846,
      checked: false,
      name: 'Рант',
      type: 'brandCarSeats',
    },
    {
      i: 847,
      checked: false,
      name: 'Мишутка',
      type: 'brandCarSeats',
    },
    {
      i: 848,
      checked: false,
      name: '0 (до 10 кг)',
      type: 'groupChild',
    },
    {
      i: 849,
      checked: false,
      name: '0+ (до 13 кг)',
      type: 'groupChild',
    },
    {
      i: 850,
      checked: false,
      name: '0/1 (до 18 кг)',
      type: 'groupChild',
    },
    {
      i: 851,
      checked: false,
      name: '0/1/2 (до 25 кг)',
      type: 'groupChild',
    },
    {
      i: 852,
      checked: false,
      name: '0/1/2/3 (до 36 кг)',
      type: 'groupChild',
    },
    {
      i: 853,
      checked: false,
      name: '1 (9-18 кг)',
      type: 'groupChild',
    },
    {
      i: 854,
      checked: false,
      name: '1/2 (9-25 кг)',
      type: 'groupChild',
    },
    {
      i: 855,
      checked: false,
      name: '1/2/3 (9-36 кг)',
      type: 'groupChild',
    },
    {
      i: 856,
      checked: false,
      name: '2/3 (15-36 кг)',
      type: 'groupChild',
    },
    {
      i: 857,
      checked: false,
      name: '3 (22-26 кг)',
      type: 'groupChild',
    },
    {
      i: 858,
      checked: false,
      name: 'Isofix',
      type: 'setFasteningCChild',
    },
    {
      i: 859,
      checked: false,
      name: 'Latch',
      type: 'setFasteningCChild',
    },
    {
      i: 860,
      checked: false,
      name: 'Автомобильные ремни',
      type: 'setFasteningCChild',
    },
    {
      i: 861,
      checked: false,
      name: 'Ручка для переноски',
      type: 'peculiaritiesCChild',
    },
    {
      i: 862,
      checked: false,
      name: 'Поворотный механизм',
      type: 'peculiaritiesCChild',
    },
    {
      i: 863,
      checked: false,
      name: 'Автоматическое натяжение ремня',
      type: 'peculiaritiesCChild',
    },
    {
      i: 864,
      checked: false,
      name: 'Фиксатор натяжения ремня',
      type: 'peculiaritiesCChild',
    },
    {
      i: 865,
      checked: false,
      name: 'Система упора в пол',
      type: 'peculiaritiesCChild',
    },
    {
      i: 866,
      checked: false,
      name: 'Пятиточечные ремни',
      type: 'peculiaritiesCChild',
    },
    {
      i: 867,
      checked: false,
      name: 'Съемная обивка',
      type: 'peculiaritiesCChild',
    },
    {
      i: 868,
      checked: false,
      name: 'Боковина',
      type: 'adjustmentsChild',
    },
    {
      i: 869,
      checked: false,
      name: 'Ручка',
      type: 'adjustmentsChild',
    },
    {
      i: 870,
      checked: false,
      name: 'Спинка',
      type: 'adjustmentsChild',
    },
    {
      i: 871,
      checked: false,
      name: 'Подголовник',
      type: 'adjustmentsChild',
    },
    {
      i: 872,
      checked: false,
      name: 'Положение для сна',
      type: 'adjustmentsChild',
    },
    {
      i: 873,
      checked: false,
      name: 'Гигиена полости рта',
      type: 51,
    },
    {
      i: 874,
      checked: false,
      name: 'Детские весы',
      type: 51,
    },
    {
      i: 875,
      checked: false,
      name: 'Кремы, присыпки',
      type: 51,
    },
    {
      i: 876,
      checked: false,
      name: 'Назальные аспираторы',
      type: 51,
    },
    {
      i: 877,
      checked: false,
      name: 'Прорезыватели',
      type: 51,
    },
    {
      i: 878,
      checked: false,
      name: 'Термометры',
      type: 51,
    },
    {
      i: 879,
      checked: false,
      name: 'Воздушные змеи',
      type: 52,
    },
    {
      i: 880,
      checked: false,
      name: 'Детские бассейны',
      type: 52,
    },
    {
      i: 881,
      checked: false,
      name: 'Детские батуты',
      type: 52,
    },
    {
      i: 882,
      checked: false,
      name: 'Железные дороги',
      type: 52,
    },
    {
      i: 883,
      checked: false,
      name: 'Играем в профессии',
      type: 52,
    },
    {
      i: 884,
      checked: false,
      name: 'Игровые домики, палатки',
      type: 52,
    },
    {
      i: 885,
      checked: false,
      name: 'Игровые комплексы, горки',
      type: 52,
    },
    {
      i: 886,
      checked: false,
      name: 'Игрушечное оружие',
      type: 52,
    },
    {
      i: 887,
      checked: false,
      name: 'Игрушки для ванной',
      type: 52,
    },
    {
      i: 888,
      checked: false,
      name: 'Каталки, качалки',
      type: 52,
    },
    {
      i: 889,
      checked: false,
      name: 'Квадрокоптеры',
      type: 52,
    },
    {
      i: 890,
      checked: false,
      name: 'Конструкторы',
      type: 52,
    },
    {
      i: 891,
      checked: false,
      name: 'Куклы',
      type: 52,
    },
    {
      i: 892,
      checked: false,
      name: 'Машинки, техника',
      type: 52,
    },
    {
      i: 893,
      checked: false,
      name: 'Мобили в кроватку',
      type: 52,
    },
    {
      i: 894,
      checked: false,
      name: 'Музыкальные игрушки',
      type: 52,
    },
    {
      i: 895,
      checked: false,
      name: 'Мыльные пузыри',
      type: 52,
    },
    {
      i: 896,
      checked: false,
      name: 'Мягкие игрушки',
      type: 52,
    },
    {
      i: 897,
      checked: false,
      name: 'Напольные коврики',
      type: 52,
    },
    {
      i: 898,
      checked: false,
      name: 'Обучающие игрушки',
      type: 52,
    },
    {
      i: 899,
      checked: false,
      name: 'Пазлы',
      type: 52,
    },
    {
      i: 900,
      checked: false,
      name: 'Песочницы и игрушки',
      type: 52,
    },
    {
      i: 901,
      checked: false,
      name: 'Погремушки',
      type: 52,
    },
    {
      i: 902,
      checked: false,
      name: 'Роботы',
      type: 52,
    },
    {
      i: 903,
      checked: false,
      name: 'Сборные модели',
      type: 52,
    },
    {
      i: 904,
      checked: false,
      name: 'Фигурки, солдатики',
      type: 52,
    },
    {
      i: 905,
      checked: false,
      name: 'Электромобили',
      type: 52,
    },
    {
      i: 906,
      checked: false,
      name: 'Люлька',
      type: 53,
    },
    {
      i: 907,
      checked: false,
      name: 'Прогулочная',
      type: 53,
    },
    {
      i: 908,
      checked: false,
      name: 'Трансформер',
      type: 53,
    },
    {
      i: 909,
      checked: false,
      name: 'Универсальная',
      type: 53,
    },
    {
      i: 910,
      checked: false,
      name: 'Adamex',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 911,
      checked: false,
      name: 'Anex',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 912,
      checked: false,
      name: 'Aprica',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 913,
      checked: false,
      name: 'Baby Design',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 914,
      checked: false,
      name: 'Baby-Merc',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 915,
      checked: false,
      name: 'BabyZz',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 916,
      checked: false,
      name: 'Babyhit',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 917,
      checked: false,
      name: 'BeBe-Mobile',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 918,
      checked: false,
      name: 'Bebbetto',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 919,
      checked: false,
      name: 'CAM',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 920,
      checked: false,
      name: 'Camarelo',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 921,
      checked: false,
      name: 'Capella',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 922,
      checked: false,
      name: 'Cosatto',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 923,
      checked: false,
      name: 'Emmaljunga',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 924,
      checked: false,
      name: 'Esspero',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 925,
      checked: false,
      name: 'FD Design',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 926,
      checked: false,
      name: 'Farfello',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 927,
      checked: false,
      name: 'Foppapedretti',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 928,
      checked: false,
      name: 'Geoby',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 929,
      checked: false,
      name: 'Hauck',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 930,
      checked: false,
      name: 'Indigo',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 931,
      checked: false,
      name: 'Jane',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 932,
      checked: false,
      name: 'Jedo',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 933,
      checked: false,
      name: 'Jetem',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 934,
      checked: false,
      name: 'Lonex',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 935,
      checked: false,
      name: 'Maclaren',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 936,
      checked: false,
      name: 'Marimex',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 937,
      checked: false,
      name: 'Maxima',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 938,
      checked: false,
      name: 'Navington',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 939,
      checked: false,
      name: 'Noordline',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 940,
      checked: false,
      name: 'Phil&Teds',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 941,
      checked: false,
      name: 'ROAN',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 942,
      checked: false,
      name: 'Reindeer',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 943,
      checked: false,
      name: 'Riko',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 944,
      checked: false,
      name: 'Silver Cross',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 945,
      checked: false,
      name: 'Slaro',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 946,
      checked: false,
      name: 'Teutonia',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 947,
      checked: false,
      name: 'Tutek',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 948,
      checked: false,
      name: 'Tutic',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 949,
      checked: false,
      name: 'Tutis',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 950,
      checked: false,
      name: 'Valco Baby',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 951,
      checked: false,
      name: 'Verdi',
      type: 'brandCarSeatsStrollers',
    },
    {
      i: 952,
      checked: false,
      name: 'До 1 года',
      type: 'ageChild',
    },
    {
      i: 953,
      checked: false,
      name: 'До 2 лет',
      type: 'ageChild',
    },
    {
      i: 954,
      checked: false,
      name: 'До 3 лет',
      type: 'ageChild',
    },
    {
      i: 955,
      checked: false,
      name: 'До 4 лет',
      type: 'ageChild',
    },
    {
      i: 956,
      checked: false,
      name: 'До 5 лет',
      type: 'ageChild',
    },
    {
      i: 957,
      checked: false,
      name: 'Для одного',
      type: 'strollersBlChild',
    },
    {
      i: 958,
      checked: false,
      name: 'Для двойни',
      type: 'strollersBlChild',
    },
    {
      i: 959,
      checked: false,
      name: 'Для тройни',
      type: 'strollersBlChild',
    },
    {
      i: 960,
      checked: false,
      name: 'Для погодок',
      type: 'strollersBlChild',
    },
    {
      i: 961,
      checked: false,
      name: 'Пневматические',
      type: 'strollersCirChild',
    },
    {
      i: 962,
      checked: false,
      name: 'Пластиковые',
      type: 'strollersCirChild',
    },
    {
      i: 963,
      checked: false,
      name: 'Гелевые',
      type: 'strollersCirChild',
    },
    {
      i: 964,
      checked: false,
      name: 'Резиновые',
      type: 'strollersCirChild',
    },
    {
      i: 965,
      checked: false,
      name: 'Сумка',
      type: 'strollersTChild',
    },
    {
      i: 966,
      checked: false,
      name: 'Дождевик',
      type: 'strollersTChild',
    },
    {
      i: 967,
      checked: false,
      name: 'Матрас',
      type: 'strollersTChild',
    },
    {
      i: 968,
      checked: false,
      name: 'Корзина для покупок',
      type: 'strollersTChild',
    },
    {
      i: 969,
      checked: false,
      name: 'Конверт',
      type: 'strollersTChild',
    },
    {
      i: 970,
      checked: false,
      name: 'Муфта',
      type: 'strollersTChild',
    },
    {
      i: 971,
      checked: false,
      name: 'Москитная сетка',
      type: 'strollersTChild',
    },
    {
      i: 972,
      checked: false,
      name: 'Чехол на ноги',
      type: 'strollersTChild',
    },
    {
      i: 973,
      checked: false,
      name: 'Ремни',
      type: 'strollersTChild',
    },
    {
      i: 974,
      checked: false,
      name: 'Бампер',
      type: 'strollersTChild',
    },
    {
      i: 975,
      checked: false,
      name: 'Козырек от солнца',
      type: 'strollersTChild',
    },
    {
      i: 976,
      checked: false,
      name: 'Для бега',
      type: 'strollersTChild',
    },
    {
      i: 977,
      checked: false,
      name: 'Ручка',
      type: 'adjustmentsStrollChild',
    },
    {
      i: 978,
      checked: false,
      name: 'Наклон спинки',
      type: 'adjustmentsStrollChild',
    },
    {
      i: 979,
      checked: false,
      name: 'Фиксация колёс',
      type: 'adjustmentsStrollChild',
    },
    {
      i: 980,
      checked: false,
      name: 'Бутылочки, ниблеры',
      type: 54,
    },
    {
      i: 981,
      checked: false,
      name: 'Детская посуда',
      type: 54,
    },
    {
      i: 982,
      checked: false,
      name: 'Детское питание',
      type: 54,
    },
    {
      i: 983,
      checked: false,
      name: 'Молокоотсосы',
      type: 54,
    },
    {
      i: 984,
      checked: false,
      name: 'Нагрудники, слюнявчики',
      type: 54,
    },
    {
      i: 985,
      checked: false,
      name: 'Накладки для груди',
      type: 54,
    },
    {
      i: 986,
      checked: false,
      name: 'Поильники',
      type: 54,
    },
    {
      i: 987,
      checked: false,
      name: 'Соски',
      type: 54,
    },
    {
      i: 988,
      checked: false,
      name: 'Стерилизаторы, подогреватели',
      type: 54,
    },
    {
      i: 989,
      checked: false,
      name: 'Хранение грудного молока',
      type: 54,
    },
    {
      i: 990,
      checked: false,
      name: 'Ванночки',
      type: 55,
    },
    {
      i: 991,
      checked: false,
      name: 'Круги на шею',
      type: 55,
    },
    {
      i: 992,
      checked: false,
      name: 'Мочалки, губки',
      type: 55,
    },
    {
      i: 993,
      checked: false,
      name: 'Нарукавники',
      type: 55,
    },
    {
      i: 994,
      checked: false,
      name: 'Сиденья, горки',
      type: 55,
    },
    {
      i: 995,
      checked: false,
      name: 'Шампуни, мыло',
      type: 55,
    },
    {
      i: 996,
      checked: false,
      name: 'Защитные барьеры, накладки',
      type: 56,
    },
    {
      i: 997,
      checked: false,
      name: 'Качели, шезлонги',
      type: 56,
    },
    {
      i: 998,
      checked: false,
      name: 'Колыбели, люльки',
      type: 56,
    },
    {
      i: 999,
      checked: false,
      name: 'Кроватки',
      type: 56,
    },
    {
      i: 1000,
      checked: false,
      name: 'Манежи',
      type: 56,
    },
    {
      i: 1001,
      checked: false,
      name: 'Ночники',
      type: 56,
    },
    {
      i: 1002,
      checked: false,
      name: 'Пеленальные столики',
      type: 56,
    },
    {
      i: 1003,
      checked: false,
      name: 'Постельные принадлежности',
      type: 56,
    },
    {
      i: 1004,
      checked: false,
      name: 'Ростомеры',
      type: 56,
    },
    {
      i: 1005,
      checked: false,
      name: 'Стульчики для кормления',
      type: 56,
    },
    {
      i: 1006,
      checked: false,
      name: 'Ходунки, прыгунки',
      type: 56,
    },
    {
      i: 1007,
      checked: false,
      name: 'Горшки, сиденья',
      type: 57,
    },
    {
      i: 1008,
      checked: false,
      name: 'Накопители подгузников',
      type: 57,
    },
    {
      i: 1009,
      checked: false,
      name: 'Пеленки, клеенки',
      type: 57,
    },
    {
      i: 1010,
      checked: false,
      name: 'Подгузники',
      type: 57,
    },
    {
      i: 1011,
      checked: false,
      name: 'Видеоняня',
      type: 58,
    },
    {
      i: 1012,
      checked: false,
      name: 'Радионяня',
      type: 58,
    },
    {
      i: 1013,
      checked: false,
      name: 'Бандажи',
      type: 59,
    },
    {
      i: 1014,
      checked: false,
      name: 'Подушки, кресла для мам',
      type: 59,
    },
    {
      i: 1015,
      checked: false,
      name: 'Сумки-кенгуру, слинги',
      type: 59,
    },
    {
      i: 1016,
      checked: false,
      name: 'Глобусы, карты',
      type: 60,
    },
    {
      i: 1017,
      checked: false,
      name: 'Доски, мольберты',
      type: 60,
    },
    {
      i: 1018,
      checked: false,
      name: 'Канцтовары',
      type: 60,
    },
    {
      i: 1019,
      checked: false,
      name: 'Пеналы',
      type: 60,
    },
    {
      i: 1020,
      checked: false,
      name: 'Учебники',
      type: 60,
    },
    {
      i: 1021,
      checked: false,
      name: 'Apple',
      type: 'brandMobilePhone',
    },
    {
      i: 1022,
      checked: false,
      name: 'Samsung',
      type: 'brandMobilePhone',
    },
    {
      i: 1023,
      checked: false,
      name: 'Xiaomi',
      type: 'brandMobilePhone',
    },
    {
      i: 1024,
      checked: false,
      name: 'Nokia',
      type: 'brandMobilePhone',
    },
    {
      i: 1025,
      checked: false,
      name: 'Honor',
      type: 'brandMobilePhone',
    },
    {
      i: 1026,
      checked: false,
      name: 'Huawei',
      type: 'brandMobilePhone',
    },
    {
      i: 1027,
      checked: false,
      name: 'Infinix',
      type: 'brandMobilePhone',
    },
    {
      i: 1028,
      checked: false,
      name: 'OnePlus',
      type: 'brandMobilePhone',
    },
    {
      i: 1029,
      checked: false,
      name: 'ASUS',
      type: 'brandMobilePhone',
    },
    {
      i: 1030,
      checked: false,
      name: 'Acer',
      type: 'brandMobilePhone',
    },
    {
      i: 1031,
      checked: false,
      name: 'Alcatel',
      type: 'brandMobilePhone',
    },
    {
      i: 1032,
      checked: false,
      name: 'BQ',
      type: 'brandMobilePhone',
    },
    {
      i: 1033,
      checked: false,
      name: 'BlackBerry',
      type: 'brandMobilePhone',
    },
    {
      i: 1034,
      checked: false,
      name: 'Dexp',
      type: 'brandMobilePhone',
    },
    {
      i: 1035,
      checked: false,
      name: 'Digma',
      type: 'brandMobilePhone',
    },
    {
      i: 1036,
      checked: false,
      name: 'DNS',
      type: 'brandMobilePhone',
    },
    {
      i: 1037,
      checked: false,
      name: 'Doogee',
      type: 'brandMobilePhone',
    },
    {
      i: 1038,
      checked: false,
      name: 'Oppo',
      type: 'brandMobilePhone',
    },
    {
      i: 1039,
      checked: false,
      name: 'Panasonic',
      type: 'brandMobilePhone',
    },
    {
      i: 1040,
      checked: false,
      name: 'Realme',
      type: 'brandMobilePhone',
    },
    {
      i: 1041,
      checked: false,
      name: 'Vivo',
      type: 'brandMobilePhone',
    },
    {
      i: 1042,
      checked: false,
      name: 'Explay',
      type: 'brandMobilePhone',
    },
    {
      i: 1043,
      checked: false,
      name: 'Fly',
      type: 'brandMobilePhone',
    },
    {
      i: 1044,
      checked: false,
      name: 'Ginzzu',
      type: 'brandMobilePhone',
    },
    {
      i: 1045,
      checked: false,
      name: 'Google',
      type: 'brandMobilePhone',
    },
    {
      i: 1046,
      checked: false,
      name: 'Highscreen',
      type: 'brandMobilePhone',
    },
    {
      i: 1047,
      checked: false,
      name: 'Homtom',
      type: 'brandMobilePhone',
    },
    {
      i: 1048,
      checked: false,
      name: 'HTC',
      type: 'brandMobilePhone',
    },
    {
      i: 1049,
      checked: false,
      name: 'Jinga',
      type: 'brandMobilePhone',
    },
    {
      i: 1050,
      checked: false,
      name: 'LeEco',
      type: 'brandMobilePhone',
    },
    {
      i: 1051,
      checked: false,
      name: 'Lenovo',
      type: 'brandMobilePhone',
    },
    {
      i: 1052,
      checked: false,
      name: 'LG',
      type: 'brandMobilePhone',
    },
    {
      i: 1053,
      checked: false,
      name: 'Meizu',
      type: 'brandMobilePhone',
    },
    {
      i: 1054,
      checked: false,
      name: 'Micromax',
      type: 'brandMobilePhone',
    },
    {
      i: 1055,
      checked: false,
      name: 'Microsoft',
      type: 'brandMobilePhone',
    },
    {
      i: 1056,
      checked: false,
      name: 'Motorola',
      type: 'brandMobilePhone',
    },
    {
      i: 1057,
      checked: false,
      name: 'Philips',
      type: 'brandMobilePhone',
    },
    {
      i: 1058,
      checked: false,
      name: 'Prestigio',
      type: 'brandMobilePhone',
    },
    {
      i: 1059,
      checked: false,
      name: 'Siemens',
      type: 'brandMobilePhone',
    },
    {
      i: 1060,
      checked: false,
      name: 'Sony',
      type: 'brandMobilePhone',
    },
    {
      i: 1061,
      checked: false,
      name: 'Texet',
      type: 'brandMobilePhone',
    },
    {
      i: 1062,
      checked: false,
      name: 'TAG Heuer',
      type: 'brandMobilePhone',
    },
    {
      i: 1063,
      checked: false,
      name: 'Vertex',
      type: 'brandMobilePhone',
    },
    {
      i: 1064,
      checked: false,
      name: 'Vertu',
      type: 'brandMobilePhone',
    },
    {
      i: 1065,
      checked: false,
      name: 'Wileyfox',
      type: 'brandMobilePhone',
    },
    {
      i: 1066,
      checked: false,
      name: 'ZTE',
      type: 'brandMobilePhone',
    },
    {
      i: 1067,
      checked: false,
      name: 'МТС, MTS',
      type: 'brandMobilePhone',
    },
    {
      i: 1068,
      checked: false,
      name: 'Билайн, Beeline',
      type: 'brandMobilePhone',
    },
    {
      i: 1069,
      checked: false,
      name: 'Мегафон, Megafon',
      type: 'brandMobilePhone',
    },
    {
      i: 1070,
      checked: false,
      name: 'Tele2',
      type: 'brandMobilePhone',
    },
    {
      i: 1071,
      checked: false,
      name: 'Другая',
      type: 'brandMobilePhone',
    },
    {
      i: 1072,
      checked: false,
      name: 'Android',
      type: 'operatingOs',
    },
    {
      i: 1073,
      checked: false,
      name: 'iOS',
      type: 'operatingOs',
    },
    {
      i: 1074,
      checked: false,
      name: 'Windows',
      type: 'operatingOs',
    },
    {
      i: 1075,
      checked: false,
      name: 'Symbian',
      type: 'operatingOs',
    },
    {
      i: 1076,
      checked: false,
      name: 'Bada',
      type: 'operatingOs',
    },
    {
      i: 1077,
      checked: false,
      name: 'BlackBerry OS',
      type: 'operatingOs',
    },
    {
      i: 1078,
      checked: false,
      name: 'Linux',
      type: 'operatingOs',
    },
    {
      i: 1079,
      checked: false,
      name: 'Другая',
      type: 'operatingOs',
    },
    {
      i: 1080,
      checked: false,
      name: '2 Гб и менее',
      type: 'builtMemory',
    },
    {
      i: 1081,
      checked: false,
      name: '4 Гб',
      type: 'builtMemory',
    },
    {
      i: 1082,
      checked: false,
      name: '8 Гб',
      type: 'builtMemory',
    },
    {
      i: 1083,
      checked: false,
      name: '16 Гб',
      type: 'builtMemory',
    },
    {
      i: 1084,
      checked: false,
      name: '32 Гб',
      type: 'builtMemory',
    },
    {
      i: 1085,
      checked: false,
      name: '64 Гб',
      type: 'builtMemory',
    },
    {
      i: 1086,
      checked: false,
      name: '128 Гб',
      type: 'builtMemory',
    },
    {
      i: 1087,
      checked: false,
      name: '256 Гб',
      type: 'builtMemory',
    },
    {
      i: 1088,
      checked: false,
      name: '512 Гб',
      type: 'builtMemory',
    },
    {
      i: 1089,
      checked: false,
      name: '1024 Гб',
      type: 'builtMemory',
    },
    {
      i: 1090,
      checked: false,
      name: 'Новый',
      type: 'periodOfUse',
    },
    {
      i: 1091,
      checked: false,
      name: 'До 6 месяцев',
      type: 'periodOfUse',
    },
    {
      i: 1092,
      checked: false,
      name: '1 год',
      type: 'periodOfUse',
    },
    {
      i: 1093,
      checked: false,
      name: '2 года',
      type: 'periodOfUse',
    },
    {
      i: 1094,
      checked: false,
      name: '3 года',
      type: 'periodOfUse',
    },
    {
      i: 1095,
      checked: false,
      name: '4 года и более',
      type: 'periodOfUse',
    },
    {
      i: 1096,
      checked: false,
      name: 'Archos',
      type: 'brandMobilePhone',
    },
    {
      i: 1097,
      checked: false,
      name: 'DELL',
      type: 'brandMobilePhone',
    },
    {
      i: 1098,
      checked: false,
      name: 'Haier',
      type: 'brandMobilePhone',
    },
    {
      i: 1099,
      checked: false,
      name: 'Iconbit',
      type: 'brandMobilePhone',
    },
    {
      i: 1100,
      checked: false,
      name: 'Irbis',
      type: 'brandMobilePhone',
    },
    {
      i: 1101,
      checked: false,
      name: 'Nvidia',
      type: 'brandMobilePhone',
    },
    {
      i: 1102,
      checked: false,
      name: 'Oysters',
      type: 'brandMobilePhone',
    },
    {
      i: 1103,
      checked: false,
      name: 'PlayPad',
      type: 'brandMobilePhone',
    },
    {
      i: 1104,
      checked: false,
      name: 'Qumo',
      type: 'brandMobilePhone',
    },
    {
      i: 1105,
      checked: false,
      name: 'Supra',
      type: 'brandMobilePhone',
    },
    {
      i: 1106,
      checked: false,
      name: 'Telefunken',
      type: 'brandMobilePhone',
    },
    {
      i: 1107,
      checked: false,
      name: 'Wexler',
      type: 'brandMobilePhone',
    },
    {
      i: 1108,
      checked: false,
      name: '3Q',
      type: 'brandMobilePhone',
    },
    {
      i: 1109,
      checked: false,
      name: '4Good',
      type: 'brandMobilePhone',
    },
    {
      i: 1110,
      checked: false,
      name: 'Менее 7',
      type: 'diagonalTablet',
    },
    {
      i: 1111,
      checked: false,
      name: 'От 7 до 7.9',
      type: 'diagonalTablet',
    },
    {
      i: 1112,
      checked: false,
      name: 'От 8" до 8.9"',
      type: 'diagonalTablet',
    },
    {
      i: 1113,
      checked: false,
      name: 'От 9" до 9.9"',
      type: 'diagonalTablet',
    },
    {
      i: 1114,
      checked: false,
      name: 'От 10" до 11.9"',
      type: 'diagonalTablet',
    },
    {
      i: 1115,
      checked: false,
      name: '12" и более',
      type: 'diagonalTablet',
    },
    {
      i: 1116,
      checked: false,
      name: '128 Мб',
      type: 'ozuTablet',
    },
    {
      i: 1117,
      checked: false,
      name: '256 Мб',
      type: 'ozuTablet',
    },
    {
      i: 1118,
      checked: false,
      name: '512 МБ',
      type: 'ozuTablet',
    },
    {
      i: 1119,
      checked: false,
      name: '1 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1120,
      checked: false,
      name: '1.5 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1121,
      checked: false,
      name: '2 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1122,
      checked: false,
      name: '3 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1123,
      checked: false,
      name: '4 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1124,
      checked: false,
      name: '6 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1125,
      checked: false,
      name: '8 Гб',
      type: 'ozuTablet',
    },
    {
      i: 1126,
      checked: false,
      name: '16 Гб и более',
      type: 'ozuTablet',
    },
    {
      i: 1127,
      checked: false,
      name: 'Нет камеры',
      type: 'pixCam',
    },
    {
      i: 1128,
      checked: false,
      name: '0.3 Мпикс и менее',
      type: 'pixCam',
    },
    {
      i: 1129,
      checked: false,
      name: 'От 0.4 до 2.9 Мпикс',
      type: 'pixCam',
    },
    {
      i: 1130,
      checked: false,
      name: 'От 3 до 4.9 Мпикс',
      type: 'pixCam',
    },
    {
      i: 1131,
      checked: false,
      name: '5 Мпикс и более',
      type: 'pixCam',
    },
    {
      i: 1132,
      checked: false,
      name: 'Детские смарт-часы',
      type: 73,
    },
    {
      i: 1133,
      checked: false,
      name: 'Умные часы',
      type: 73,
    },
    {
      i: 1134,
      checked: false,
      name: 'Фитнес-браслеты',
      type: 73,
    },
    {
      i: 1135,
      checked: false,
      name: 'Ремешки, аксессуары',
      type: 73,
    },
    {
      i: 1136,
      checked: false,
      name: 'Есть',
      type: 'compatibilityA',
    },
    {
      i: 1137,
      checked: false,
      name: 'Нет',
      type: 'compatibilityA',
    },
    {
      i: 1138,
      checked: false,
      name: 'Есть',
      type: 'compatibilityI',
    },
    {
      i: 1139,
      checked: false,
      name: 'Нет',
      type: 'compatibilityI',
    },
    {
      i: 1140,
      checked: false,
      name: 'Есть',
      type: 'moistureProtection',
    },
    {
      i: 1141,
      checked: false,
      name: 'Нет',
      type: 'moistureProtection',
    },
    {
      i: 1142,
      checked: false,
      name: 'Есть',
      type: 'pedometer',
    },
    {
      i: 1143,
      checked: false,
      name: 'Нет',
      type: 'pedometer',
    },
    {
      i: 1144,
      checked: false,
      name: 'Есть',
      type: 'heartMete',
    },
    {
      i: 1145,
      checked: false,
      name: 'Нет',
      type: 'heartMete',
    },
    {
      i: 1146,
      checked: false,
      name: 'Есть',
      type: 'sleepMete',
    },
    {
      i: 1147,
      checked: false,
      name: 'Нет',
      type: 'sleepMete',
    },
    {
      i: 1148,
      checked: false,
      name: 'С помощью телефона/планшета',
      type: 'phoneCall',
    },
    {
      i: 1149,
      checked: false,
      name: 'Собственная SIM-карта',
      type: 'phoneCall',
    },
    {
      i: 1150,
      checked: false,
      name: 'Проводные телефоны',
      type: 74,
    },
    {
      i: 1151,
      checked: false,
      name: 'Радиотелефоны',
      type: 74,
    },
    {
      i: 1152,
      checked: false,
      name: 'Есть',
      type: 'autoResp',
    },
    {
      i: 1153,
      checked: false,
      name: 'Нет',
      type: 'autoResp',
    },
    {
      i: 1154,
      checked: false,
      name: 'Есть',
      type: 'aon',
    },
    {
      i: 1155,
      checked: false,
      name: 'Нет',
      type: 'aon',
    },
    {
      i: 1156,
      checked: false,
      name: 'Аналоговые рации',
      type: 75,
    },
    {
      i: 1157,
      checked: false,
      name: 'Цифровые рации',
      type: 75,
    },
    {
      i: 1158,
      checked: false,
      name: 'Спутниковые телефоны',
      type: 75,
    },
    {
      i: 1159,
      checked: false,
      name: 'Аккумуляторы, батареи',
      type: 76,
    },
    {
      i: 1160,
      checked: false,
      name: 'Антенны',
      type: 76,
    },
    {
      i: 1161,
      checked: false,
      name: 'Вибромоторы',
      type: 76,
    },
    {
      i: 1162,
      checked: false,
      name: 'Держатели SIM-карт',
      type: 76,
    },
    {
      i: 1163,
      checked: false,
      name: 'Джойстики',
      type: 76,
    },
    {
      i: 1164,
      checked: false,
      name: 'Динамики',
      type: 76,
    },
    {
      i: 1165,
      checked: false,
      name: 'Дисплеи, тачскрины',
      type: 76,
    },
    {
      i: 1166,
      checked: false,
      name: 'Задние крышки',
      type: 76,
    },
    {
      i: 1167,
      checked: false,
      name: 'Инструменты для ремонта',
      type: 76,
    },
    {
      i: 1168,
      checked: false,
      name: 'Камеры',
      type: 76,
    },
    {
      i: 1169,
      checked: false,
      name: 'Клавиатуры',
      type: 76,
    },
    {
      i: 1170,
      checked: false,
      name: 'Кнопки',
      type: 76,
    },
    {
      i: 1171,
      checked: false,
      name: 'Корпусы',
      type: 76,
    },
    {
      i: 1172,
      checked: false,
      name: 'Крышки аккумуляторов',
      type: 76,
    },
    {
      i: 1173,
      checked: false,
      name: 'Микросхемы, платы',
      type: 76,
    },
    {
      i: 1174,
      checked: false,
      name: 'Микросхемы, платы',
      type: 76,
    },
    {
      i: 1175,
      checked: false,
      name: 'Панели',
      type: 76,
    },
    {
      i: 1176,
      checked: false,
      name: 'Разъемы',
      type: 76,
    },
    {
      i: 1177,
      checked: false,
      name: 'Шлейфы',
      type: 76,
    },
    {
      i: 1178,
      checked: false,
      name: 'Для смартфонов',
      type: 'device',
    },
    {
      i: 1179,
      checked: false,
      name: 'Для планшетов',
      type: 'device',
    },
    {
      i: 1180,
      checked: false,
      name: 'Для телефонов',
      type: 'device',
    },
    {
      i: 1181,
      checked: false,
      name: 'До 3000 мАч',
      type: 'amperes',
    },
    {
      i: 1182,
      checked: false,
      name: 'от 3000 до 4990 мАч',
      type: 'amperes',
    },
    {
      i: 1183,
      checked: false,
      name: 'от 5000 до 9990 мАч',
      type: 'amperes',
    },
    {
      i: 1184,
      checked: false,
      name: 'от 10000 до 14990 мАч',
      type: 'amperes',
    },
    {
      i: 1185,
      checked: false,
      name: 'от 15000 до 19990 мАч',
      type: 'amperes',
    },
    {
      i: 1186,
      checked: false,
      name: '20000 мАч и более',
      type: 'amperes',
    },
    {
      i: 1187,
      checked: false,
      name: '1',
      type: 'usbCount',
    },
    {
      i: 1188,
      checked: false,
      name: '2',
      type: 'usbCount',
    },
    {
      i: 1189,
      checked: false,
      name: '3 и более',
      type: 'usbCount',
    },
    {
      i: 1190,
      checked: false,
      name: 'Автомобильные',
      type: 78,
    },
    {
      i: 1191,
      checked: false,
      name: 'Адаптеры в розетку',
      type: 78,
    },
    {
      i: 1192,
      checked: false,
      name: 'Беспроводные',
      type: 78,
    },
    {
      i: 1193,
      checked: false,
      name: 'Док - станции',
      type: 78,
    },
    {
      i: 1194,
      checked: false,
      name: 'Apple (30pin)',
      type: 'connectT',
    },
    {
      i: 1195,
      checked: false,
      name: 'Apple (lightning)',
      type: 'connectT',
    },
    {
      i: 1196,
      checked: false,
      name: 'Micro USB',
      type: 'connectT',
    },
    {
      i: 1197,
      checked: false,
      name: 'Mini USB',
      type: 'connectT',
    },
    {
      i: 1198,
      checked: false,
      name: 'USB Type-C',
      type: 'connectT',
    },
    {
      i: 1199,
      checked: false,
      name: 'USB',
      type: 'connectT',
    },
    {
      i: 1200,
      checked: false,
      name: 'Проприетарные (старые телефоны)',
      type: 'connectT',
    },
    {
      i: 1201,
      checked: false,
      name: 'Набор переходников',
      type: 'connectT',
    },
    {
      i: 1202,
      checked: false,
      name: 'Без кабеля',
      type: 'cable',
    },
    {
      i: 1203,
      checked: false,
      name: 'Кабель в комплекте',
      type: 'cable',
    },
    {
      i: 1204,
      checked: false,
      name: 'Встроенный кабель',
      type: 'cable',
    },
    {
      i: 1205,
      checked: false,
      name: 'До 1 А',
      type: 'amperesC',
    },
    {
      i: 1206,
      checked: false,
      name: 'От 1 до 1.9 А',
      type: 'amperesC',
    },
    {
      i: 1207,
      checked: false,
      name: 'От 2 до 2.9 А',
      type: 'amperesC',
    },
    {
      i: 1208,
      checked: false,
      name: 'Накладки, задние панели',
      type: 79,
    },
    {
      i: 1209,
      checked: false,
      name: 'Книжки, обложки, флип-кейсы',
      type: 79,
    },
    {
      i: 1210,
      checked: false,
      name: 'Чехлы - аккумуляторы',
      type: 79,
    },
    {
      i: 1211,
      checked: false,
      name: 'Бамперы',
      type: 79,
    },
    {
      i: 1212,
      checked: false,
      name: 'Кобуры',
      type: 79,
    },
    {
      i: 1213,
      checked: false,
      name: 'Спортивные',
      type: 79,
    },
    {
      i: 1214,
      checked: false,
      name: 'Сумки, футляры, кармашки',
      type: 79,
    },
    {
      i: 1215,
      checked: false,
      name: 'Металл',
      type: 'materialCh',
    },
    {
      i: 1216,
      checked: false,
      name: 'Дерево',
      type: 'materialCh',
    },
    {
      i: 1217,
      checked: false,
      name: 'Искусственная кожа',
      type: 'materialCh',
    },
    {
      i: 1218,
      checked: false,
      name: 'Натуральная кожа',
      type: 'materialCh',
    },
    {
      i: 1219,
      checked: false,
      name: 'Пластик, полиуретан, резина',
      type: 'materialCh',
    },
    {
      i: 1220,
      checked: false,
      name: 'Силикон',
      type: 'materialCh',
    },
    {
      i: 1221,
      checked: false,
      name: 'Ткань',
      type: 'materialCh',
    },
    {
      i: 1222,
      checked: false,
      name: 'Для телефонов',
      type: 'deviceC',
    },
    {
      i: 1223,
      checked: false,
      name: 'Для планшетов',
      type: 'deviceC',
    },
    {
      i: 1224,
      checked: false,
      name: 'Золотистый',
      type: 'colorProduct',
    },
    {
      i: 1225,
      checked: false,
      name: 'Прозрачный',
      type: 'colorProduct',
    },
    {
      i: 1226,
      checked: false,
      name: 'Серебристый',
      type: 'colorProduct',
    },
    {
      i: 1227,
      checked: false,
      name: 'Другой',
      type: 'colorProduct',
    },
    {
      i: 1228,
      checked: false,
      name: 'Проводные гарнитуры',
      type: 80,
    },
    {
      i: 1229,
      checked: false,
      name: 'Bluetooth-гарнитуры',
      type: 80,
    },
    {
      i: 1230,
      checked: false,
      name: 'Моноподы, пульты для селфи',
      type: 80,
    },
    {
      i: 1231,
      checked: false,
      name: 'Защитные пленки, стекла',
      type: 80,
    },
    {
      i: 1232,
      checked: false,
      name: 'Наклейки, стикеры',
      type: 80,
    },
    {
      i: 1233,
      checked: false,
      name: 'Кабели, переходники',
      type: 80,
    },
    {
      i: 1234,
      checked: false,
      name: 'Очки виртуальной реальности',
      type: 80,
    },
    {
      i: 1235,
      checked: false,
      name: 'Стилусы',
      type: 80,
    },
    {
      i: 1236,
      checked: false,
      name: 'Объективы',
      type: 80,
    },
    {
      i: 1237,
      checked: false,
      name: 'Вспышки, селфи кольца',
      type: 80,
    },
    {
      i: 1238,
      checked: false,
      name: 'Держатели, коврики',
      type: 80,
    },
    {
      i: 1239,
      checked: false,
      name: 'Коробка от телефона',
      type: 80,
    },
    {
      i: 1240,
      checked: false,
      name: 'Клавиатуры',
      type: 80,
    },
    {
      i: 1241,
      checked: false,
      name: 'Геймпады',
      type: 80,
    },
    {
      i: 1242,
      checked: false,
      name: 'Пленочные',
      type: 81,
    },
    {
      i: 1243,
      checked: false,
      name: 'Цифровые',
      type: 81,
    },
    {
      i: 1244,
      checked: false,
      name: 'Компактные',
      type: 'categCam',
    },
    {
      i: 1245,
      checked: false,
      name: 'Зеркальные',
      type: 'categCam',
    },
    {
      i: 1246,
      checked: false,
      name: 'Беззеркальные со сменной оптикой',
      type: 'categCam',
    },
    {
      i: 1247,
      checked: false,
      name: 'Моментальной печати',
      type: 'categCam',
    },
    {
      i: 1248,
      checked: false,
      name: 'AEE',
      type: 'brandCam',
    },
    {
      i: 1249,
      checked: false,
      name: 'BBK',
      type: 'brandCam',
    },
    {
      i: 1250,
      checked: false,
      name: 'Canon',
      type: 'brandCam',
    },
    {
      i: 1251,
      checked: false,
      name: 'Casio',
      type: 'brandCam',
    },
    {
      i: 1252,
      checked: false,
      name: 'Digicare',
      type: 'brandCam',
    },
    {
      i: 1253,
      checked: false,
      name: 'Fujifilm',
      type: 'brandCam',
    },
    {
      i: 1254,
      checked: false,
      name: 'Genius',
      type: 'brandCam',
    },
    {
      i: 1255,
      checked: false,
      name: 'HP',
      type: 'brandCam',
    },
    {
      i: 1256,
      checked: false,
      name: 'Insignia',
      type: 'brandCam',
    },
    {
      i: 1257,
      checked: false,
      name: 'Kodak',
      type: 'brandCam',
    },
    {
      i: 1258,
      checked: false,
      name: 'Konica Minolta',
      type: 'brandCam',
    },
    {
      i: 1259,
      checked: false,
      name: 'Leica',
      type: 'brandCam',
    },
    {
      i: 1260,
      checked: false,
      name: 'Lytro',
      type: 'brandCam',
    },
    {
      i: 1261,
      checked: false,
      name: 'Nikon',
      type: 'brandCam',
    },
    {
      i: 1262,
      checked: false,
      name: 'Olympus',
      type: 'brandCam',
    },
    {
      i: 1263,
      checked: false,
      name: 'Polaroid',
      type: 'brandCam',
    },
    {
      i: 1264,
      checked: false,
      name: 'Panasonic Lumix',
      type: 'brandCam',
    },
    {
      i: 1265,
      checked: false,
      name: 'Pentax',
      type: 'brandCam',
    },
    {
      i: 1266,
      checked: false,
      name: 'Rekam',
      type: 'brandCam',
    },
    {
      i: 1267,
      checked: false,
      name: 'Ricoh',
      type: 'brandCam',
    },
    {
      i: 1268,
      checked: false,
      name: 'Samsung',
      type: 'brandCam',
    },
    {
      i: 1269,
      checked: false,
      name: 'Sanyo',
      type: 'brandCam',
    },
    {
      i: 1270,
      checked: false,
      name: 'Sigma',
      type: 'brandCam',
    },
    {
      i: 1271,
      checked: false,
      name: 'Sony',
      type: 'brandCam',
    },
    {
      i: 1272,
      checked: false,
      name: 'Xiaomi, YI',
      type: 'brandCam',
    },
    {
      i: 1273,
      checked: false,
      name: 'Зенит (Zenit)',
      type: 'brandCam',
    },
    {
      i: 1274,
      checked: false,
      name: 'Есть',
      type: 'viewfinder',
    },
    {
      i: 1275,
      checked: false,
      name: 'Нет',
      type: 'viewfinder',
    },
    {
      i: 1276,
      checked: false,
      name: 'Нет',
      type: 'imageStabilizer',
    },
    {
      i: 1277,
      checked: false,
      name: 'Оптический',
      type: 'imageStabilizer',
    },
    {
      i: 1278,
      checked: false,
      name: 'Цифровой',
      type: 'imageStabilizer',
    },
    {
      i: 1279,
      checked: false,
      name: 'Eken',
      type: 'brandCam',
    },
    {
      i: 1280,
      checked: false,
      name: 'Gmini',
      type: 'brandCam',
    },
    {
      i: 1281,
      checked: false,
      name: 'GoPro',
      type: 'brandCam',
    },
    {
      i: 1282,
      checked: false,
      name: 'JVC',
      type: 'brandCam',
    },
    {
      i: 1283,
      checked: false,
      name: 'Lexand',
      type: 'brandCam',
    },
    {
      i: 1284,
      checked: false,
      name: 'Panasonic',
      type: 'brandCam',
    },
    {
      i: 1285,
      checked: false,
      name: 'SJCAM',
      type: 'brandCam',
    },
    {
      i: 1286,
      checked: false,
      name: 'X-TRY',
      type: 'brandCam',
    },
    {
      i: 1287,
      checked: false,
      name: '576p и менее',
      type: 'videoQuality',
    },
    {
      i: 1288,
      checked: false,
      name: '720p HD',
      type: 'videoQuality',
    },
    {
      i: 1289,
      checked: false,
      name: '1080p Full HD',
      type: 'videoQuality',
    },
    {
      i: 1290,
      checked: false,
      name: '2.5K QHD',
      type: 'videoQuality',
    },
    {
      i: 1291,
      checked: false,
      name: '4K UHD',
      type: 'videoQuality',
    },
    {
      i: 1292,
      checked: false,
      name: 'Жесткий диск, Flash',
      type: 'typeSd',
    },
    {
      i: 1293,
      checked: false,
      name: 'Кассета',
      type: 'typeSd',
    },
    {
      i: 1294,
      checked: false,
      name: 'Оптический диск',
      type: 'typeSd',
    },
    {
      i: 1295,
      checked: false,
      name: 'Нет',
      type: 'zoomOp',
    },
    {
      i: 1296,
      checked: false,
      name: 'Менее 5x',
      type: 'zoomOp',
    },
    {
      i: 1297,
      checked: false,
      name: 'От 5 до 9.9x',
      type: 'zoomOp',
    },
    {
      i: 1298,
      checked: false,
      name: 'От 10 до 19.9x',
      type: 'zoomOp',
    },
    {
      i: 1299,
      checked: false,
      name: 'От 20 до 29.9x',
      type: 'zoomOp',
    },
    {
      i: 1300,
      checked: false,
      name: 'От 30 до 39.9x',
      type: 'zoomOp',
    },
    {
      i: 1301,
      checked: false,
      name: 'От 40 до 49.9x',
      type: 'zoomOp',
    },
    {
      i: 1302,
      checked: false,
      name: '50x и более',
      type: 'zoomOp',
    },
    {
      i: 1303,
      checked: false,
      name: 'Есть',
      type: 'actionCamera',
    },
    {
      i: 1304,
      checked: false,
      name: 'Нет',
      type: 'actionCamera',
    },
    {
      i: 1305,
      checked: false,
      name: 'Есть',
      type: 'r360',
    },
    {
      i: 1306,
      checked: false,
      name: 'Нет',
      type: 'r360',
    },
    {
      i: 1307,
      checked: false,
      name: 'IP - камеры',
      type: 83,
    },
    {
      i: 1308,
      checked: false,
      name: 'Аналоговые камеры',
      type: 83,
    },
    {
      i: 1309,
      checked: false,
      name: 'Есть',
      type: 'wifi',
    },
    {
      i: 1310,
      checked: false,
      name: 'Нет',
      type: 'wifi',
    },
    {
      i: 1311,
      checked: false,
      name: 'Есть',
      type: 'motionSen',
    },
    {
      i: 1312,
      checked: false,
      name: 'Нет',
      type: 'motionSen',
    },
    {
      i: 1313,
      checked: false,
      name: 'Есть',
      type: 'microphone',
    },
    {
      i: 1314,
      checked: false,
      name: 'Нет',
      type: 'microphone',
    },
    {
      i: 1315,
      checked: false,
      name: 'Проводные',
      type: 'conType',
    },
    {
      i: 1316,
      checked: false,
      name: 'Беспроводные',
      type: 'conType',
    },
    {
      i: 1317,
      checked: false,
      name: 'Рыбий глаз',
      type: 84,
    },
    {
      i: 1318,
      checked: false,
      name: 'Макрообъективы',
      type: 84,
    },
    {
      i: 1319,
      checked: false,
      name: 'Стандартные объективы',
      type: 84,
    },
    {
      i: 1320,
      checked: false,
      name: 'Телеобъективы',
      type: 84,
    },
    {
      i: 1321,
      checked: false,
      name: 'Широкоугольные объективы',
      type: 84,
    },
    {
      i: 1322,
      checked: false,
      name: 'Canon',
      type: 'brandLenses',
    },
    {
      i: 1323,
      checked: false,
      name: 'Carl Zeiss',
      type: 'brandLenses',
    },
    {
      i: 1324,
      checked: false,
      name: 'Fujifilm',
      type: 'brandLenses',
    },
    {
      i: 1325,
      checked: false,
      name: 'Nikon',
      type: 'brandLenses',
    },
    {
      i: 1326,
      checked: false,
      name: 'Olympus',
      type: 'brandLenses',
    },
    {
      i: 1327,
      checked: false,
      name: 'Panasonic Lumix',
      type: 'brandLenses',
    },
    {
      i: 1328,
      checked: false,
      name: 'Pentax',
      type: 'brandLenses',
    },
    {
      i: 1329,
      checked: false,
      name: 'Samyang',
      type: 'brandLenses',
    },
    {
      i: 1330,
      checked: false,
      name: 'Sigma',
      type: 'brandLenses',
    },
    {
      i: 1331,
      checked: false,
      name: 'Sony',
      type: 'brandLenses',
    },
    {
      i: 1332,
      checked: false,
      name: 'Tamron',
      type: 'brandLenses',
    },
    {
      i: 1333,
      checked: false,
      name: 'YongNuo',
      type: 'brandLenses',
    },
    {
      i: 1334,
      checked: false,
      name: 'Зенит',
      type: 'brandLenses',
    },
    {
      i: 1335,
      checked: false,
      name: 'Юпитер',
      type: 'brandLenses',
    },
    {
      i: 1336,
      checked: false,
      name: '4/3',
      type: 'mountType',
    },
    {
      i: 1337,
      checked: false,
      name: 'Bronica',
      type: 'mountType',
    },
    {
      i: 1338,
      checked: false,
      name: 'Canon EF',
      type: 'mountType',
    },
    {
      i: 1339,
      checked: false,
      name: 'Canon EF-M',
      type: 'mountType',
    },
    {
      i: 1340,
      checked: false,
      name: 'Canon EF-S',
      type: 'mountType',
    },
    {
      i: 1341,
      checked: false,
      name: 'Canon FD',
      type: 'mountType',
    },
    {
      i: 1342,
      checked: false,
      name: 'Canon FL',
      type: 'mountType',
    },
    {
      i: 1343,
      checked: false,
      name: 'Fujifilm G Mount',
      type: 'mountType',
    },
    {
      i: 1344,
      checked: false,
      name: 'Fujifilm X Mount',
      type: 'mountType',
    },
    {
      i: 1345,
      checked: false,
      name: 'Hasselblad CF',
      type: 'mountType',
    },
    {
      i: 1346,
      checked: false,
      name: 'Hasselblad HC',
      type: 'mountType',
    },
    {
      i: 1347,
      checked: false,
      name: 'Hasselblad XCD',
      type: 'mountType',
    },
    {
      i: 1348,
      checked: false,
      name: 'Konica AR',
      type: 'mountType',
    },
    {
      i: 1349,
      checked: false,
      name: 'Leica L',
      type: 'mountType',
    },
    {
      i: 1350,
      checked: false,
      name: 'Leica M',
      type: 'mountType',
    },
    {
      i: 1351,
      checked: false,
      name: 'Leica R',
      type: 'mountType',
    },
    {
      i: 1352,
      checked: false,
      name: 'Leica S',
      type: 'mountType',
    },
    {
      i: 1353,
      checked: false,
      name: 'Leica T',
      type: 'mountType',
    },
    {
      i: 1354,
      checked: false,
      name: 'Mamiya 645',
      type: 'mountType',
    },
    {
      i: 1355,
      checked: false,
      name: 'Mamiya 7',
      type: 'mountType',
    },
    {
      i: 1356,
      checked: false,
      name: 'Micro 4/3',
      type: 'mountType',
    },
    {
      i: 1357,
      checked: false,
      name: 'Minolta A (Sony Alpha)',
      type: 'mountType',
    },
    {
      i: 1358,
      checked: false,
      name: 'Minolta SR',
      type: 'mountType',
    },
    {
      i: 1359,
      checked: false,
      name: 'Nikon 1',
      type: 'mountType',
    },
    {
      i: 1360,
      checked: false,
      name: 'Nikon F',
      type: 'mountType',
    },
    {
      i: 1361,
      checked: false,
      name: 'Nikon Z',
      type: 'mountType',
    },
    {
      i: 1362,
      checked: false,
      name: 'Olympus Micro 4/3',
      type: 'mountType',
    },
    {
      i: 1363,
      checked: false,
      name: 'Olympus OM',
      type: 'mountType',
    },
    {
      i: 1364,
      checked: false,
      name: 'Pentacon 6',
      type: 'mountType',
    },
    {
      i: 1365,
      checked: false,
      name: 'Pentax 645',
      type: 'mountType',
    },
    {
      i: 1366,
      checked: false,
      name: 'Pentax 67',
      type: 'mountType',
    },
    {
      i: 1367,
      checked: false,
      name: 'Pentax KA/KAF/KAF2/KAF3',
      type: 'mountType',
    },
    {
      i: 1368,
      checked: false,
      name: 'Pentax Q',
      type: 'mountType',
    },
    {
      i: 1369,
      checked: false,
      name: 'Ricoh GXR',
      type: 'mountType',
    },
    {
      i: 1370,
      checked: false,
      name: 'Samsung NX',
      type: 'mountType',
    },
    {
      i: 1371,
      checked: false,
      name: 'Samsung NX-M',
      type: 'mountType',
    },
    {
      i: 1372,
      checked: false,
      name: 'Sigma SA',
      type: 'mountType',
    },
    {
      i: 1373,
      checked: false,
      name: 'Sony E',
      type: 'mountType',
    },
    {
      i: 1374,
      checked: false,
      name: 'T-mount',
      type: 'mountType',
    },
    {
      i: 1375,
      checked: false,
      name: 'Резьба M39',
      type: 'mountType',
    },
    {
      i: 1376,
      checked: false,
      name: 'Резьба M42',
      type: 'mountType',
    },
    {
      i: 1377,
      checked: false,
      name: 'Есть',
      type: 'autofocus',
    },
    {
      i: 1378,
      checked: false,
      name: 'Нет',
      type: 'autofocus',
    },
    {
      i: 1379,
      checked: false,
      name: 'Обычные',
      type: 85,
    },
    {
      i: 1380,
      checked: false,
      name: 'Кольцевые',
      type: 85,
    },
    {
      i: 1381,
      checked: false,
      name: 'Двухламповые',
      type: 85,
    },
    {
      i: 1382,
      checked: false,
      name: 'Для подводной съемки',
      type: 85,
    },
    {
      i: 1383,
      checked: false,
      name: 'Есть',
      type: 'swivelHead',
    },
    {
      i: 1384,
      checked: false,
      name: 'Нет',
      type: 'swivelHead',
    },
    {
      i: 1385,
      checked: false,
      name: 'Адаптеры, переходные кольца',
      type: 86,
    },
    {
      i: 1386,
      checked: false,
      name: 'Аккумуляторы, зарядные устройства',
      type: 86,
    },
    {
      i: 1387,
      checked: false,
      name: 'Бленды, насадки на объективы',
      type: 86,
    },
    {
      i: 1388,
      checked: false,
      name: 'Видоискатели, наглазники',
      type: 86,
    },
    {
      i: 1389,
      checked: false,
      name: 'Для подводной съемки',
      type: 86,
    },
    {
      i: 1390,
      checked: false,
      name: 'Защитные пленки, стекла',
      type: 86,
    },
    {
      i: 1391,
      checked: false,
      name: 'Конвертеры для объективов',
      type: 86,
    },
    {
      i: 1392,
      checked: false,
      name: 'Крышки на объективы',
      type: 86,
    },
    {
      i: 1393,
      checked: false,
      name: 'Микрофоны',
      type: 86,
    },
    {
      i: 1394,
      checked: false,
      name: 'Провода, кабели',
      type: 86,
    },
    {
      i: 1395,
      checked: false,
      name: 'Пульты дистанционного управления',
      type: 86,
    },
    {
      i: 1396,
      checked: false,
      name: 'Светофильтры',
      type: 86,
    },
    {
      i: 1397,
      checked: false,
      name: 'Сумки, чехлы, ремни',
      type: 86,
    },
    {
      i: 1398,
      checked: false,
      name: 'Фотопленка',
      type: 86,
    },
    {
      i: 1399,
      checked: false,
      name: 'Триподы',
      type: 87,
    },
    {
      i: 1400,
      checked: false,
      name: 'Моноподы',
      type: 87,
    },
    {
      i: 1401,
      checked: false,
      name: 'Плечевые упоры, обвесы',
      type: 87,
    },
    {
      i: 1402,
      checked: false,
      name: 'Cтабилизаторы, Steadicam',
      type: 87,
    },
    {
      i: 1403,
      checked: false,
      name: 'Лампы, студийный свет',
      type: 88,
    },
    {
      i: 1404,
      checked: false,
      name: 'Отражатели, рефлекторы',
      type: 88,
    },
    {
      i: 1405,
      checked: false,
      name: 'Софтбоксы',
      type: 88,
    },
    {
      i: 1406,
      checked: false,
      name: 'Фоны, хромакеи',
      type: 88,
    },
    {
      i: 1407,
      checked: false,
      name: 'Фотобоксы, лайткубы',
      type: 88,
    },
    {
      i: 1408,
      checked: false,
      name: 'Менее 7"',
      type: 'diagonalR',
    },
    {
      i: 1409,
      checked: false,
      name: 'От 7.1" до 9"',
      type: 'diagonalR',
    },
    {
      i: 1410,
      checked: false,
      name: 'От 9.1" до 12"',
      type: 'diagonalR',
    },
    {
      i: 1411,
      checked: false,
      name: 'От 12.1" до 15"',
      type: 'diagonalR',
    },
    {
      i: 1412,
      checked: false,
      name: '15" и более',
      type: 'diagonalR',
    },
    {
      i: 1413,
      checked: false,
      name: 'Есть',
      type: 'playVid',
    },
    {
      i: 1414,
      checked: false,
      name: 'Нет',
      type: 'playVid',
    },
    {
      i: 1415,
      checked: false,
      name: 'Canon',
      type: 'brandPrint',
    },
    {
      i: 1416,
      checked: false,
      name: 'Epson',
      type: 'brandPrint',
    },
    {
      i: 1417,
      checked: false,
      name: 'HP',
      type: 'brandPrint',
    },
    {
      i: 1418,
      checked: false,
      name: 'Kodak',
      type: 'brandPrint',
    },
    {
      i: 1419,
      checked: false,
      name: 'LG',
      type: 'brandPrint',
    },
    {
      i: 1420,
      checked: false,
      name: 'Sony',
      type: 'brandPrint',
    },
    {
      i: 1421,
      checked: false,
      name: 'Бинокли',
      type: 91,
    },
    {
      i: 1422,
      checked: false,
      name: 'Лупы',
      type: 91,
    },
    {
      i: 1423,
      checked: false,
      name: 'Микроскопы',
      type: 91,
    },
    {
      i: 1424,
      checked: false,
      name: 'Окуляры',
      type: 91,
    },
    {
      i: 1425,
      checked: false,
      name: 'Подзорные трубы',
      type: 91,
    },
    {
      i: 1426,
      checked: false,
      name: 'Приборы ночного видения',
      type: 91,
    },
    {
      i: 1427,
      checked: false,
      name: 'Телескопы',
      type: 91,
    },
    {
      i: 1428,
      checked: false,
      name: 'Ноутбуки',
      type: 92,
    },
    {
      i: 1429,
      checked: false,
      name: 'Нетбуки',
      type: 92,
    },
    {
      i: 1430,
      checked: false,
      name: 'Трансформеры',
      type: 92,
    },
    {
      i: 1431,
      checked: false,
      name: 'Acer',
      type: 'pcBrand',
    },
    {
      i: 1432,
      checked: false,
      name: 'Alienware',
      type: 'pcBrand',
    },
    {
      i: 1433,
      checked: false,
      name: 'Apple',
      type: 'pcBrand',
    },
    {
      i: 1434,
      checked: false,
      name: 'ASUS',
      type: 'pcBrand',
    },
    {
      i: 1435,
      checked: false,
      name: 'DELL',
      type: 'pcBrand',
    },
    {
      i: 1436,
      checked: false,
      name: 'Dexp',
      type: 'pcBrand',
    },
    {
      i: 1437,
      checked: false,
      name: 'DNS',
      type: 'pcBrand',
    },
    {
      i: 1438,
      checked: false,
      name: 'Fujitsu',
      type: 'pcBrand',
    },
    {
      i: 1439,
      checked: false,
      name: 'Haier',
      type: 'pcBrand',
    },
    {
      i: 1440,
      checked: false,
      name: 'HP',
      type: 'pcBrand',
    },
    {
      i: 1441,
      checked: false,
      name: 'Huawei',
      type: 'pcBrand',
    },
    {
      i: 1442,
      checked: false,
      name: 'Lenovo',
      type: 'pcBrand',
    },
    {
      i: 1443,
      checked: false,
      name: 'MSI',
      type: 'pcBrand',
    },
    {
      i: 1444,
      checked: false,
      name: 'Packard Bell',
      type: 'pcBrand',
    },
    {
      i: 1445,
      checked: false,
      name: 'Panasonic',
      type: 'pcBrand',
    },
    {
      i: 1446,
      checked: false,
      name: 'Prestigio',
      type: 'pcBrand',
    },
    {
      i: 1447,
      checked: false,
      name: 'Samsung',
      type: 'pcBrand',
    },
    {
      i: 1448,
      checked: false,
      name: 'Sony',
      type: 'pcBrand',
    },
    {
      i: 1449,
      checked: false,
      name: 'Toshiba',
      type: 'pcBrand',
    },
    {
      i: 1450,
      checked: false,
      name: '12.5" и менее',
      type: 'diagonalNoteBook',
    },
    {
      i: 1451,
      checked: false,
      name: 'от 13" до 14.6"',
      type: 'diagonalNoteBook',
    },
    {
      i: 1452,
      checked: false,
      name: 'от 15" до 16.4"',
      type: 'diagonalNoteBook',
    },
    {
      i: 1453,
      checked: false,
      name: '16.5" и более',
      type: 'diagonalNoteBook',
    },
    {
      i: 1454,
      checked: false,
      name: 'до 1 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1455,
      checked: false,
      name: '1 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1456,
      checked: false,
      name: '2 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1457,
      checked: false,
      name: '3 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1458,
      checked: false,
      name: '4 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1459,
      checked: false,
      name: '6 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1460,
      checked: false,
      name: '8 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1461,
      checked: false,
      name: '12 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1462,
      checked: false,
      name: '16 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1463,
      checked: false,
      name: '24 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1464,
      checked: false,
      name: '32 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1465,
      checked: false,
      name: '64 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1466,
      checked: false,
      name: 'Более 64 ГБ',
      type: 'ozuPc',
    },
    {
      i: 1467,
      checked: false,
      name: '2Тб и больше',
      type: 'ssdPc',
    },
    {
      i: 1468,
      checked: false,
      name: '1Тб - 1,99Тб',
      type: 'ssdPc',
    },
    {
      i: 1469,
      checked: false,
      name: '500Гб - 900Гб',
      type: 'ssdPc',
    },
    {
      i: 1470,
      checked: false,
      name: '250Гб - 499Гб',
      type: 'ssdPc',
    },
    {
      i: 1471,
      checked: false,
      name: 'до 250Гб',
      type: 'ssdPc',
    },
    {
      i: 1472,
      checked: false,
      name: 'Настольные, системные блоки',
      type: 93,
    },
    {
      i: 1473,
      checked: false,
      name: 'Моноблоки',
      type: 93,
    },
    {
      i: 1474,
      checked: false,
      name: 'Мини ПК, неттопы',
      type: 93,
    },
    {
      i: 1475,
      checked: false,
      name: 'Встроенная',
      type: 'videoTy',
    },
    {
      i: 1476,
      checked: false,
      name: 'Дискретная',
      type: 'videoTy',
    },
    {
      i: 1477,
      checked: false,
      name: 'Да',
      type: 'gamePc',
    },
    {
      i: 1478,
      checked: false,
      name: 'Нет',
      type: 'gamePc',
    },
    {
      i: 1479,
      checked: false,
      name: 'Blu-ray',
      type: 'opticalDrive',
    },
    {
      i: 1480,
      checked: false,
      name: 'DVD',
      type: 'opticalDrive',
    },
    {
      i: 1481,
      checked: false,
      name: 'DVD-RW',
      type: 'opticalDrive',
    },
    {
      i: 1482,
      checked: false,
      name: 'DVD/CD-RW',
      type: 'opticalDrive',
    },
    {
      i: 1483,
      checked: false,
      name: 'Отсутствует',
      type: 'opticalDrive',
    },
    {
      i: 1484,
      checked: false,
      name: 'Для дома и офиса',
      type: 94,
    },
    {
      i: 1485,
      checked: false,
      name: 'Игровой',
      type: 94,
    },
    {
      i: 1486,
      checked: false,
      name: 'Профессиональный',
      type: 94,
    },
    {
      i: 1487,
      checked: false,
      name: 'AOC',
      type: 'brandMonitor',
    },
    {
      i: 1488,
      checked: false,
      name: 'BenQ',
      type: 'brandMonitor',
    },
    {
      i: 1489,
      checked: false,
      name: 'Iiyama',
      type: 'brandMonitor',
    },
    {
      i: 1490,
      checked: false,
      name: 'NEC',
      type: 'brandMonitor',
    },
    {
      i: 1491,
      checked: false,
      name: 'Philips',
      type: 'brandMonitor',
    },
    {
      i: 1492,
      checked: false,
      name: 'Viewsonic',
      type: 'brandMonitor',
    },
    {
      i: 1493,
      checked: false,
      name: 'Менее 14"',
      type: 'diagonalMonitor',
    },
    {
      i: 1494,
      checked: false,
      name: 'От 14" до 14.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1495,
      checked: false,
      name: 'От 15" до 15.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1496,
      checked: false,
      name: 'От 16" до 16.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1497,
      checked: false,
      name: 'От 17" до 17.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1498,
      checked: false,
      name: 'От 18" до 18.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1499,
      checked: false,
      name: 'От 19" до 19.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1500,
      checked: false,
      name: 'От 20" до 20.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1501,
      checked: false,
      name: 'От 21" до 21.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1502,
      checked: false,
      name: 'От 22" до 22.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1503,
      checked: false,
      name: 'От 23" до 23.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1504,
      checked: false,
      name: 'От 24" до 24.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1505,
      checked: false,
      name: 'От 25" до 25.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1506,
      checked: false,
      name: 'От 26" до 26.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1507,
      checked: false,
      name: 'От 27" до 27.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1508,
      checked: false,
      name: 'От 28" до 28.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1509,
      checked: false,
      name: 'От 29" до 29.9"',
      type: 'diagonalMonitor',
    },
    {
      i: 1510,
      checked: false,
      name: '30" и более',
      type: 'diagonalMonitor',
    },
    {
      i: 1511,
      checked: false,
      name: '1 шт',
      type: 'hdmiMonitor',
    },
    {
      i: 1512,
      checked: false,
      name: '2 шт',
      type: 'hdmiMonitor',
    },
    {
      i: 1513,
      checked: false,
      name: '4 шт',
      type: 'hdmiMonitor',
    },
    {
      i: 1514,
      checked: false,
      name: 'Есть',
      type: 'speakersMonitor',
    },
    {
      i: 1515,
      checked: false,
      name: 'Нет',
      type: 'speakersMonitor',
    },
    {
      i: 1516,
      checked: false,
      name: 'Есть',
      type: 'usbMonitor',
    },
    {
      i: 1517,
      checked: false,
      name: 'Нет',
      type: 'usbMonitor',
    },
    {
      i: 1518,
      checked: false,
      name: 'Клавиатуры',
      type: 95,
    },
    {
      i: 1519,
      checked: false,
      name: 'Мыши',
      type: 95,
    },
    {
      i: 1520,
      checked: false,
      name: 'A4Tech',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1521,
      checked: false,
      name: 'Apple',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1522,
      checked: false,
      name: 'ASUS',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1523,
      checked: false,
      name: 'Defender',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1524,
      checked: false,
      name: 'Genius',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1525,
      checked: false,
      name: 'HP',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1526,
      checked: false,
      name: 'HyperX',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1527,
      checked: false,
      name: 'Logitech',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1528,
      checked: false,
      name: 'Microsoft',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1529,
      checked: false,
      name: 'Oklick',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1530,
      checked: false,
      name: 'Razer',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1531,
      checked: false,
      name: 'SteelSeries',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1532,
      checked: false,
      name: 'Sven',
      type: 'brandKeyboardsAndMouse',
    },
    {
      i: 1533,
      checked: false,
      name: 'Проводные',
      type: 'conTypeKeyboardsAndMouse',
    },
    {
      i: 1534,
      checked: false,
      name: 'Беспроводные',
      type: 'conTypeKeyboardsAndMouse',
    },
    {
      i: 1535,
      checked: false,
      name: 'Принтеры',
      type: 96,
    },
    {
      i: 1536,
      checked: false,
      name: '3D принтеры',
      type: 96,
    },
    {
      i: 1537,
      checked: false,
      name: 'Сканеры',
      type: 96,
    },
    {
      i: 1538,
      checked: false,
      name: 'Плоттеры',
      type: 96,
    },
    {
      i: 1539,
      checked: false,
      name: 'Копиры',
      type: 96,
    },
    {
      i: 1540,
      checked: false,
      name: 'МФУ',
      type: 96,
    },
    {
      i: 1541,
      checked: false,
      name: 'Шредеры, уничтожители бумаг',
      type: 96,
    },
    {
      i: 1542,
      checked: false,
      name: 'Факсы',
      type: 96,
    },
    {
      i: 1543,
      checked: false,
      name: 'Ламинаторы',
      type: 96,
    },
    {
      i: 1544,
      checked: false,
      name: 'Переплетчики, брошюровщики',
      type: 96,
    },
    {
      i: 1545,
      checked: false,
      name: 'Картриджи, чернила, тонеры',
      type: 96,
    },
    {
      i: 1546,
      checked: false,
      name: 'Бумага для печати',
      type: 96,
    },
    {
      i: 1547,
      checked: false,
      name: 'Аксессуары для оргтехники',
      type: 96,
    },
    {
      i: 1548,
      checked: false,
      name: 'ADSL модемы',
      type: 97,
    },
    {
      i: 1549,
      checked: false,
      name: 'USB, 3G модемы',
      type: 97,
    },
    {
      i: 1550,
      checked: false,
      name: 'Роутеры, маршрутизаторы',
      type: 97,
    },
    {
      i: 1551,
      checked: false,
      name: 'Точки доступа',
      type: 97,
    },
    {
      i: 1552,
      checked: false,
      name: 'Усилители сигнала, антенны',
      type: 97,
    },
    {
      i: 1553,
      checked: false,
      name: 'Коммутаторы',
      type: 97,
    },
    {
      i: 1554,
      checked: false,
      name: 'Серверы',
      type: 97,
    },
    {
      i: 1555,
      checked: false,
      name: 'Принт-серверы',
      type: 97,
    },
    {
      i: 1556,
      checked: false,
      name: 'Сетевые карты',
      type: 97,
    },
    {
      i: 1557,
      checked: false,
      name: 'Сетевые хранилища (NAS)',
      type: 97,
    },
    {
      i: 1558,
      checked: false,
      name: 'Сетевые кабели, разъемы',
      type: 97,
    },
    {
      i: 1559,
      checked: false,
      name: 'Трансиверы, конвертеры',
      type: 97,
    },
    {
      i: 1560,
      checked: false,
      name: 'Wi-Fi, bluetooth адаптеры',
      type: 97,
    },
    {
      i: 1561,
      checked: false,
      name: 'VoIP-оборудование',
      type: 97,
    },
    {
      i: 1562,
      checked: false,
      name: 'Веб-камеры',
      type: 98,
    },
    {
      i: 1563,
      checked: false,
      name: 'Графические планшеты',
      type: 98,
    },
    {
      i: 1564,
      checked: false,
      name: 'Очки виртуальной реальности',
      type: 98,
    },
    {
      i: 1565,
      checked: false,
      name: 'Компьютерные колонки',
      type: 98,
    },
    {
      i: 1566,
      checked: false,
      name: 'Компьютерные гарнитуры',
      type: 98,
    },
    {
      i: 1567,
      checked: false,
      name: 'Компьютерные микрофоны',
      type: 98,
    },
    {
      i: 1568,
      checked: false,
      name: 'Карты памяти',
      type: 99,
    },
    {
      i: 1569,
      checked: false,
      name: 'USB флэш-карты',
      type: 99,
    },
    {
      i: 1570,
      checked: false,
      name: 'Внешние жесткие диски',
      type: 99,
    },
    {
      i: 1571,
      checked: false,
      name: 'Картридеры',
      type: 99,
    },
    {
      i: 1572,
      checked: false,
      name: 'Диски, дискеты',
      type: 99,
    },
    {
      i: 1573,
      checked: false,
      name: 'Антивирусы',
      type: 100,
    },
    {
      i: 1574,
      checked: false,
      name: 'Восстановление данных',
      type: 100,
    },
    {
      i: 1575,
      checked: false,
      name: 'Графика, дизайн',
      type: 100,
    },
    {
      i: 1576,
      checked: false,
      name: 'Обработка аудио, видео',
      type: 100,
    },
    {
      i: 1577,
      checked: false,
      name: 'Обучение, образование',
      type: 100,
    },
    {
      i: 1578,
      checked: false,
      name: 'Операционные системы',
      type: 100,
    },
    {
      i: 1579,
      checked: false,
      name: 'Офисные программы',
      type: 100,
    },
    {
      i: 1580,
      checked: false,
      name: 'Управление предприятием',
      type: 100,
    },
    {
      i: 1581,
      checked: false,
      name: 'Утилиты, драйверы',
      type: 100,
    },
    {
      i: 1582,
      checked: false,
      name: 'Геймпады',
      type: 101,
    },
    {
      i: 1583,
      checked: false,
      name: 'Рули',
      type: 101,
    },
    {
      i: 1584,
      checked: false,
      name: 'Джойстики, штурвалы',
      type: 101,
    },
    {
      i: 1585,
      checked: false,
      name: 'CD, DVD, Blu-ray приводы',
      type: 102,
    },
    {
      i: 1586,
      checked: false,
      name: 'Аккумуляторы',
      type: 102,
    },
    {
      i: 1587,
      checked: false,
      name: 'Блоки питания',
      type: 102,
    },
    {
      i: 1588,
      checked: false,
      name: 'Видеокарты',
      type: 102,
    },
    {
      i: 1589,
      checked: false,
      name: 'Жесткие диски',
      type: 102,
    },
    {
      i: 1590,
      checked: false,
      name: 'Звуковые карты',
      type: 102,
    },
    {
      i: 1591,
      checked: false,
      name: 'Клавиатуры для ноутбуков',
      type: 102,
    },
    {
      i: 1592,
      checked: false,
      name: 'Контроллеры',
      type: 102,
    },
    {
      i: 1593,
      checked: false,
      name: 'Корпуса',
      type: 102,
    },
    {
      i: 1594,
      checked: false,
      name: 'Кулеры, системы охлаждения',
      type: 102,
    },
    {
      i: 1595,
      checked: false,
      name: 'Материнские платы',
      type: 102,
    },
    {
      i: 1596,
      checked: false,
      name: 'Матрицы, экраны',
      type: 102,
    },
    {
      i: 1597,
      checked: false,
      name: 'Оперативная память',
      type: 102,
    },
    {
      i: 1598,
      checked: false,
      name: 'Процессоры',
      type: 102,
    },
    {
      i: 1599,
      checked: false,
      name: 'TV-тюнеры, видеозахват',
      type: 102,
    },
    {
      i: 1600,
      checked: false,
      name: 'Шлейфы',
      type: 102,
    },
    {
      i: 1601,
      checked: false,
      name: 'Для компьютеров',
      type: 'devicePc',
    },
    {
      i: 1602,
      checked: false,
      name: 'Для ноутбуков',
      type: 'devicePc',
    },
    {
      i: 1603,
      checked: false,
      name: 'USB-концентраторы, хабы',
      type: 103,
    },
    {
      i: 1604,
      checked: false,
      name: 'USB-гаджеты',
      type: 103,
    },
    {
      i: 1605,
      checked: false,
      name: 'Кабели, разъемы, переходники',
      type: 103,
    },
    {
      i: 1606,
      checked: false,
      name: 'Коврики для мышей',
      type: 103,
    },
    {
      i: 1607,
      checked: false,
      name: 'Подставки, кронштейны',
      type: 103,
    },
    {
      i: 1608,
      checked: false,
      name: 'Сумки, чехлы для ноутбуков',
      type: 103,
    },
    {
      i: 1609,
      checked: false,
      name: 'OLED',
      type: 104,
    },
    {
      i: 1610,
      checked: false,
      name: 'ЖК, LED',
      type: 104,
    },
    {
      i: 1611,
      checked: false,
      name: 'Плазменные панели',
      type: 104,
    },
    {
      i: 1612,
      checked: false,
      name: 'ЭЛТ, кинескопные',
      type: 104,
    },
    {
      i: 1613,
      checked: false,
      name: 'AMCV',
      type: 'tvBrand',
    },
    {
      i: 1614,
      checked: false,
      name: 'Aiwa',
      type: 'tvBrand',
    },
    {
      i: 1615,
      checked: false,
      name: 'Akai',
      type: 'tvBrand',
    },
    {
      i: 1616,
      checked: false,
      name: 'Akira',
      type: 'tvBrand',
    },
    {
      i: 1617,
      checked: false,
      name: 'BBK',
      type: 'tvBrand',
    },
    {
      i: 1618,
      checked: false,
      name: 'Daewoo',
      type: 'tvBrand',
    },
    {
      i: 1619,
      checked: false,
      name: 'Erisson',
      type: 'tvBrand',
    },
    {
      i: 1620,
      checked: false,
      name: 'Funai',
      type: 'tvBrand',
    },
    {
      i: 1621,
      checked: false,
      name: 'Fusion',
      type: 'tvBrand',
    },
    {
      i: 1622,
      checked: false,
      name: 'Goldstar',
      type: 'tvBrand',
    },
    {
      i: 1623,
      checked: false,
      name: 'Grundig',
      type: 'tvBrand',
    },
    {
      i: 1624,
      checked: false,
      name: 'Hisense',
      type: 'tvBrand',
    },
    {
      i: 1625,
      checked: false,
      name: 'Hitachi',
      type: 'tvBrand',
    },
    {
      i: 1626,
      checked: false,
      name: 'Horizont',
      type: 'tvBrand',
    },
    {
      i: 1627,
      checked: false,
      name: 'Hyundai',
      type: 'tvBrand',
    },
    {
      i: 1628,
      checked: false,
      name: 'JVC',
      type: 'tvBrand',
    },
    {
      i: 1629,
      checked: false,
      name: 'LG',
      type: 'tvBrand',
    },
    {
      i: 1630,
      checked: false,
      name: 'Loewe',
      type: 'tvBrand',
    },
    {
      i: 1631,
      checked: false,
      name: 'Mystery',
      type: 'tvBrand',
    },
    {
      i: 1632,
      checked: false,
      name: 'Orion',
      type: 'tvBrand',
    },
    {
      i: 1633,
      checked: false,
      name: 'Panasonic',
      type: 'tvBrand',
    },
    {
      i: 1634,
      checked: false,
      name: 'Philips',
      type: 'tvBrand',
    },
    {
      i: 1635,
      checked: false,
      name: 'Rolsen',
      type: 'tvBrand',
    },
    {
      i: 1636,
      checked: false,
      name: 'Rubin',
      type: 'tvBrand',
    },
    {
      i: 1637,
      checked: false,
      name: 'Samsung',
      type: 'tvBrand',
    },
    {
      i: 1638,
      checked: false,
      name: 'Sharp',
      type: 'tvBrand',
    },
    {
      i: 1639,
      checked: false,
      name: 'Shivaki',
      type: 'tvBrand',
    },
    {
      i: 1640,
      checked: false,
      name: 'Sony',
      type: 'tvBrand',
    },
    {
      i: 1641,
      checked: false,
      name: 'Supra',
      type: 'tvBrand',
    },
    {
      i: 1642,
      checked: false,
      name: 'TCL',
      type: 'tvBrand',
    },
    {
      i: 1643,
      checked: false,
      name: 'Telefunken',
      type: 'tvBrand',
    },
    {
      i: 1644,
      checked: false,
      name: 'Thomson',
      type: 'tvBrand',
    },
    {
      i: 1645,
      checked: false,
      name: 'Toshiba',
      type: 'tvBrand',
    },
    {
      i: 1646,
      checked: false,
      name: 'Витязь',
      type: 'tvBrand',
    },
    {
      i: 1647,
      checked: false,
      name: 'Другая',
      type: 'tvBrand',
    },
    {
      i: 1648,
      checked: false,
      name: 'Сameron',
      type: 'tvBrand',
    },
    {
      i: 1649,
      checked: false,
      name: 'До 15"',
      type: 'diagonalTv',
    },
    {
      i: 1650,
      checked: false,
      name: 'От 15" до 19.9"',
      type: 'diagonalTv',
    },
    {
      i: 1651,
      checked: false,
      name: 'От 20" до 24.9"',
      type: 'diagonalTv',
    },
    {
      i: 1652,
      checked: false,
      name: 'От 25" до 29.9"',
      type: 'diagonalTv',
    },
    {
      i: 1653,
      checked: false,
      name: 'От 30" до 34.9"',
      type: 'diagonalTv',
    },
    {
      i: 1654,
      checked: false,
      name: 'От 35" до 39.9"',
      type: 'diagonalTv',
    },
    {
      i: 1655,
      checked: false,
      name: 'От 40" до 44.9"',
      type: 'diagonalTv',
    },
    {
      i: 1656,
      checked: false,
      name: 'От 45" до 49.9"',
      type: 'diagonalTv',
    },
    {
      i: 1657,
      checked: false,
      name: 'От 50" до 54.9"',
      type: 'diagonalTv',
    },
    {
      i: 1658,
      checked: false,
      name: 'От 55" до 59.9"',
      type: 'diagonalTv',
    },
    {
      i: 1659,
      checked: false,
      name: '60" и более',
      type: 'diagonalTv',
    },
    {
      i: 1660,
      checked: false,
      name: 'Full HD',
      type: 'sizeDisplay',
    },
    {
      i: 1661,
      checked: false,
      name: 'HD-Ready',
      type: 'sizeDisplay',
    },
    {
      i: 1662,
      checked: false,
      name: 'Ultra HD',
      type: 'sizeDisplay',
    },
    {
      i: 1663,
      checked: false,
      name: 'Портативные',
      type: 105,
    },
    {
      i: 1664,
      checked: false,
      name: 'Стационарные',
      type: 105,
    },
    {
      i: 1665,
      checked: false,
      name: '3840x2160',
      type: 'sizeProjectors',
    },
    {
      i: 1666,
      checked: false,
      name: '2716x1528',
      type: 'sizeProjectors',
    },
    {
      i: 1667,
      checked: false,
      name: '1920х1200',
      type: 'sizeProjectors',
    },
    {
      i: 1668,
      checked: false,
      name: '1920х1080',
      type: 'sizeProjectors',
    },
    {
      i: 1669,
      checked: false,
      name: '1280x720',
      type: 'sizeProjectors',
    },
    {
      i: 1670,
      checked: false,
      name: '1280x800',
      type: 'sizeProjectors',
    },
    {
      i: 1671,
      checked: false,
      name: '1440x900',
      type: 'sizeProjectors',
    },
    {
      i: 1672,
      checked: false,
      name: '1024x768',
      type: 'sizeProjectors',
    },
    {
      i: 1673,
      checked: false,
      name: '800x600',
      type: 'sizeProjectors',
    },
    {
      i: 1674,
      checked: false,
      name: '854х480',
      type: 'sizeProjectors',
    },
    {
      i: 1675,
      checked: false,
      name: 'Встраиваемые колонки',
      type: 106,
    },
    {
      i: 1676,
      checked: false,
      name: 'Звуковые панели, саундбары',
      type: 106,
    },
    {
      i: 1677,
      checked: false,
      name: 'Комплекты акустики',
      type: 106,
    },
    {
      i: 1678,
      checked: false,
      name: 'Напольные колонки',
      type: 106,
    },
    {
      i: 1679,
      checked: false,
      name: 'Полочные, подвесные колонки',
      type: 106,
    },
    {
      i: 1680,
      checked: false,
      name: 'Портативные колонки',
      type: 106,
    },
    {
      i: 1681,
      checked: false,
      name: 'Сабвуферы',
      type: 106,
    },
    {
      i: 1682,
      checked: false,
      name: 'Умные колонки',
      type: 106,
    },
    {
      i: 1683,
      checked: false,
      name: 'Центральные каналы',
      type: 106,
    },
    {
      i: 1684,
      checked: false,
      name: 'Attitude',
      type: 'audioBrand',
    },
    {
      i: 1685,
      checked: false,
      name: 'Audio Pro',
      type: 'audioBrand',
    },
    {
      i: 1686,
      checked: false,
      name: 'Audiovector',
      type: 'audioBrand',
    },
    {
      i: 1687,
      checked: false,
      name: 'BBK',
      type: 'audioBrand',
    },
    {
      i: 1688,
      checked: false,
      name: 'Behringer',
      type: 'audioBrand',
    },
    {
      i: 1689,
      checked: false,
      name: 'Bose',
      type: 'audioBrand',
    },
    {
      i: 1690,
      checked: false,
      name: 'Bowers & Wilkins',
      type: 'audioBrand',
    },
    {
      i: 1691,
      checked: false,
      name: 'Cambridge Audio',
      type: 'audioBrand',
    },
    {
      i: 1692,
      checked: false,
      name: 'Canton',
      type: 'audioBrand',
    },
    {
      i: 1693,
      checked: false,
      name: 'Dali',
      type: 'audioBrand',
    },
    {
      i: 1694,
      checked: false,
      name: 'Denon',
      type: 'audioBrand',
    },
    {
      i: 1695,
      checked: false,
      name: 'Elac',
      type: 'audioBrand',
    },
    {
      i: 1696,
      checked: false,
      name: 'Focal',
      type: 'audioBrand',
    },
    {
      i: 1697,
      checked: false,
      name: 'Gheco',
      type: 'audioBrand',
    },
    {
      i: 1698,
      checked: false,
      name: 'Harman / Kardon',
      type: 'audioBrand',
    },
    {
      i: 1699,
      checked: false,
      name: 'JBL',
      type: 'audioBrand',
    },
    {
      i: 1700,
      checked: false,
      name: 'Jamo',
      type: 'audioBrand',
    },
    {
      i: 1701,
      checked: false,
      name: 'KEF',
      type: 'audioBrand',
    },
    {
      i: 1702,
      checked: false,
      name: 'LG',
      type: 'audioBrand',
    },
    {
      i: 1703,
      checked: false,
      name: 'Monitor Audio',
      type: 'audioBrand',
    },
    {
      i: 1704,
      checked: false,
      name: 'Mystery',
      type: 'audioBrand',
    },
    {
      i: 1705,
      checked: false,
      name: 'Onkyo',
      type: 'audioBrand',
    },
    {
      i: 1706,
      checked: false,
      name: 'Philips',
      type: 'audioBrand',
    },
    {
      i: 1707,
      checked: false,
      name: 'Pioneer',
      type: 'audioBrand',
    },
    {
      i: 1708,
      checked: false,
      name: 'RBH',
      type: 'audioBrand',
    },
    {
      i: 1709,
      checked: false,
      name: 'REL',
      type: 'audioBrand',
    },
    {
      i: 1710,
      checked: false,
      name: 'Reference Audio',
      type: 'audioBrand',
    },
    {
      i: 1711,
      checked: false,
      name: 'Samsung',
      type: 'audioBrand',
    },
    {
      i: 1712,
      checked: false,
      name: 'Sharp',
      type: 'audioBrand',
    },
    {
      i: 1713,
      checked: false,
      name: 'Sonos',
      type: 'audioBrand',
    },
    {
      i: 1714,
      checked: false,
      name: 'Sony',
      type: 'audioBrand',
    },
    {
      i: 1715,
      checked: false,
      name: 'Sven',
      type: 'audioBrand',
    },
    {
      i: 1716,
      checked: false,
      name: 'Tannoy',
      type: 'audioBrand',
    },
    {
      i: 1717,
      checked: false,
      name: 'Telefunken',
      type: 'audioBrand',
    },
    {
      i: 1718,
      checked: false,
      name: 'Ultimate',
      type: 'audioBrand',
    },
    {
      i: 1719,
      checked: false,
      name: 'Vector',
      type: 'audioBrand',
    },
    {
      i: 1720,
      checked: false,
      name: 'Yamaha',
      type: 'audioBrand',
    },
    {
      i: 1721,
      checked: false,
      name: 'Капсула',
      type: 'audioBrand',
    },
    {
      i: 1722,
      checked: false,
      name: 'Радиотехника',
      type: 'audioBrand',
    },
    {
      i: 1723,
      checked: false,
      name: 'Яндекс.Станция',
      type: 'audioBrand',
    },
    {
      i: 1724,
      checked: false,
      name: '2.1',
      type: 'acousticType',
    },
    {
      i: 1725,
      checked: false,
      name: '4.2',
      type: 'acousticType',
    },
    {
      i: 1726,
      checked: false,
      name: '5.1',
      type: 'acousticType',
    },
    {
      i: 1727,
      checked: false,
      name: '7.1',
      type: 'acousticType',
    },
    {
      i: 1728,
      checked: false,
      name: '9.1',
      type: 'acousticType',
    },
    {
      i: 1729,
      checked: false,
      name: 'Медиаплееры',
      type: 108,
    },
    {
      i: 1730,
      checked: false,
      name: 'DVD плееры',
      type: 108,
    },
    {
      i: 1731,
      checked: false,
      name: 'Blu-Ray плееры',
      type: 108,
    },
    {
      i: 1732,
      checked: false,
      name: 'VHS, видеомагнитофоны',
      type: 108,
    },
    {
      i: 1733,
      checked: false,
      name: 'Музыкальные центры',
      type: 109,
    },
    {
      i: 1734,
      checked: false,
      name: 'Магнитолы, магнитофоны',
      type: 109,
    },
    {
      i: 1735,
      checked: false,
      name: 'Радиоприемники',
      type: 109,
    },
    {
      i: 1736,
      checked: false,
      name: 'CD-проигрыватели',
      type: 109,
    },
    {
      i: 1737,
      checked: false,
      name: 'Проигрыватели виниловых дисков',
      type: 109,
    },
    {
      i: 1738,
      checked: false,
      name: 'MP3, цифровые плееры',
      type: 110,
    },
    {
      i: 1739,
      checked: false,
      name: 'Диктофоны',
      type: 110,
    },
    {
      i: 1740,
      checked: false,
      name: 'CD-плееры',
      type: 110,
    },
    {
      i: 1741,
      checked: false,
      name: 'Кассетные плееры',
      type: 110,
    },
    {
      i: 1742,
      checked: false,
      name: 'E-Ink',
      type: 'displayType',
    },
    {
      i: 1743,
      checked: false,
      name: 'LCD, TFT',
      type: 'displayType',
    },
    {
      i: 1744,
      checked: false,
      name: 'Цифровое ТВ, ТВ-ресиверы',
      type: 112,
    },
    {
      i: 1745,
      checked: false,
      name: 'Спутниковое ТВ',
      type: 112,
    },
    {
      i: 1746,
      checked: false,
      name: 'Антенны',
      type: 112,
    },
    {
      i: 1747,
      checked: false,
      name: 'Кабельное ТВ',
      type: 112,
    },
    {
      i: 1748,
      checked: false,
      name: 'BBK',
      type: 'digitalTVBrand',
    },
    {
      i: 1749,
      checked: false,
      name: 'Cadena',
      type: 'digitalTVBrand',
    },
    {
      i: 1750,
      checked: false,
      name: 'D-color',
      type: 'digitalTVBrand',
    },
    {
      i: 1751,
      checked: false,
      name: 'Galaxy innovations',
      type: 'digitalTVBrand',
    },
    {
      i: 1752,
      checked: false,
      name: 'General satellite',
      type: 'digitalTVBrand',
    },
    {
      i: 1753,
      checked: false,
      name: 'Harper',
      type: 'digitalTVBrand',
    },
    {
      i: 1754,
      checked: false,
      name: 'OnLime',
      type: 'digitalTVBrand',
    },
    {
      i: 1755,
      checked: false,
      name: 'Openbox',
      type: 'digitalTVBrand',
    },
    {
      i: 1756,
      checked: false,
      name: 'Oriel',
      type: 'digitalTVBrand',
    },
    {
      i: 1757,
      checked: false,
      name: 'Rolsen',
      type: 'digitalTVBrand',
    },
    {
      i: 1758,
      checked: false,
      name: 'Selenga',
      type: 'digitalTVBrand',
    },
    {
      i: 1759,
      checked: false,
      name: 'Skyway',
      type: 'digitalTVBrand',
    },
    {
      i: 1760,
      checked: false,
      name: 'Supra',
      type: 'digitalTVBrand',
    },
    {
      i: 1761,
      checked: false,
      name: 'Telefunken',
      type: 'digitalTVBrand',
    },
    {
      i: 1762,
      checked: false,
      name: 'Tesler',
      type: 'digitalTVBrand',
    },
    {
      i: 1763,
      checked: false,
      name: 'World vision',
      type: 'digitalTVBrand',
    },
    {
      i: 1764,
      checked: false,
      name: 'МТС',
      type: 'digitalTVBrand',
    },
    {
      i: 1765,
      checked: false,
      name: 'НТВ-плюс',
      type: 'digitalTVBrand',
    },
    {
      i: 1766,
      checked: false,
      name: 'Ростелеком',
      type: 'digitalTVBrand',
    },
    {
      i: 1767,
      checked: false,
      name: 'Телекарта',
      type: 'digitalTVBrand',
    },
    {
      i: 1768,
      checked: false,
      name: 'Триколор',
      type: 'digitalTVBrand',
    },
    {
      i: 1769,
      checked: false,
      name: 'Усилители',
      type: 113,
    },
    {
      i: 1770,
      checked: false,
      name: 'Ресиверы',
      type: 113,
    },
    {
      i: 1771,
      checked: false,
      name: 'AV-процессоры',
      type: 113,
    },
    {
      i: 1772,
      checked: false,
      name: 'Behringer',
      type: 'receiversBrand',
    },
    {
      i: 1773,
      checked: false,
      name: 'Cambridge Audio',
      type: 'receiversBrand',
    },
    {
      i: 1774,
      checked: false,
      name: 'Denon',
      type: 'receiversBrand',
    },
    {
      i: 1775,
      checked: false,
      name: 'Fiio',
      type: 'receiversBrand',
    },
    {
      i: 1776,
      checked: false,
      name: 'Harman / Kardon',
      type: 'receiversBrand',
    },
    {
      i: 1777,
      checked: false,
      name: 'Kenwood',
      type: 'receiversBrand',
    },
    {
      i: 1778,
      checked: false,
      name: 'Marantz',
      type: 'receiversBrand',
    },
    {
      i: 1779,
      checked: false,
      name: 'NAD',
      type: 'receiversBrand',
    },
    {
      i: 1780,
      checked: false,
      name: 'Onkyo',
      type: 'receiversBrand',
    },
    {
      i: 1781,
      checked: false,
      name: 'Pioneer',
      type: 'receiversBrand',
    },
    {
      i: 1782,
      checked: false,
      name: 'Rotel',
      type: 'receiversBrand',
    },
    {
      i: 1783,
      checked: false,
      name: 'Sony',
      type: 'receiversBrand',
    },
    {
      i: 1784,
      checked: false,
      name: 'Technics',
      type: 'receiversBrand',
    },
    {
      i: 1785,
      checked: false,
      name: 'Yamaha',
      type: 'receiversBrand',
    },
    {
      i: 1786,
      checked: false,
      name: 'Вкладыши',
      type: 114,
    },
    {
      i: 1787,
      checked: false,
      name: 'Вставные (затычки)',
      type: 114,
    },
    {
      i: 1788,
      checked: false,
      name: 'Накладные, полноразмерные',
      type: 114,
    },
    {
      i: 1789,
      checked: false,
      name: 'Игровые наушники',
      type: 114,
    },
    {
      i: 1790,
      checked: false,
      name: 'AKG',
      type: 'brandHeadphones',
    },
    {
      i: 1791,
      checked: false,
      name: 'Anker',
      type: 'brandHeadphones',
    },
    {
      i: 1792,
      checked: false,
      name: 'Apple',
      type: 'brandHeadphones',
    },
    {
      i: 1793,
      checked: false,
      name: 'Audio-Technica',
      type: 'brandHeadphones',
    },
    {
      i: 1794,
      checked: false,
      name: 'Beats',
      type: 'brandHeadphones',
    },
    {
      i: 1795,
      checked: false,
      name: 'Bose',
      type: 'brandHeadphones',
    },
    {
      i: 1796,
      checked: false,
      name: 'Defender',
      type: 'brandHeadphones',
    },
    {
      i: 1797,
      checked: false,
      name: 'Huawei',
      type: 'brandHeadphones',
    },
    {
      i: 1798,
      checked: false,
      name: 'JBL',
      type: 'brandHeadphones',
    },
    {
      i: 1799,
      checked: false,
      name: 'Meizu',
      type: 'brandHeadphones',
    },
    {
      i: 1800,
      checked: false,
      name: 'Panasonic',
      type: 'brandHeadphones',
    },
    {
      i: 1801,
      checked: false,
      name: 'Philips',
      type: 'brandHeadphones',
    },
    {
      i: 1802,
      checked: false,
      name: 'Pioneer',
      type: 'brandHeadphones',
    },
    {
      i: 1803,
      checked: false,
      name: 'Plantronics',
      type: 'brandHeadphones',
    },
    {
      i: 1804,
      checked: false,
      name: 'Razer',
      type: 'brandHeadphones',
    },
    {
      i: 1805,
      checked: false,
      name: 'Remax',
      type: 'brandHeadphones',
    },
    {
      i: 1806,
      checked: false,
      name: 'Samsung',
      type: 'brandHeadphones',
    },
    {
      i: 1807,
      checked: false,
      name: 'Sennheiser',
      type: 'brandHeadphones',
    },
    {
      i: 1808,
      checked: false,
      name: 'Sony',
      type: 'brandHeadphones',
    },
    {
      i: 1809,
      checked: false,
      name: 'SteelSeries',
      type: 'brandHeadphones',
    },
    {
      i: 1810,
      checked: false,
      name: 'Sven',
      type: 'brandHeadphones',
    },
    {
      i: 1811,
      checked: false,
      name: 'Xiaomi',
      type: 'brandHeadphones',
    },
    {
      i: 1812,
      checked: false,
      name: 'Для вокала',
      type: 115,
    },
    {
      i: 1813,
      checked: false,
      name: 'Петличный',
      type: 115,
    },
    {
      i: 1814,
      checked: false,
      name: 'Студийный',
      type: 115,
    },
    {
      i: 1815,
      checked: false,
      name: 'Универсальный',
      type: 115,
    },
    {
      i: 1816,
      checked: false,
      name: '3D очки',
      type: 116,
    },
    {
      i: 1817,
      checked: false,
      name: 'Батарейки, аккумуляторы',
      type: 116,
    },
    {
      i: 1818,
      checked: false,
      name: 'Кабели, адаптеры',
      type: 116,
    },
    {
      i: 1819,
      checked: false,
      name: 'Кронштейны, подставки',
      type: 116,
    },
    {
      i: 1820,
      checked: false,
      name: 'Пульты дистанционного управления',
      type: 116,
    },
    {
      i: 1821,
      checked: false,
      name: 'Механические',
      type: 117,
    },
    {
      i: 1822,
      checked: false,
      name: 'Электронные',
      type: 117,
    },
    {
      i: 1823,
      checked: false,
      name: 'Напольные',
      type: 'scopeOfApplication',
    },
    {
      i: 1824,
      checked: false,
      name: 'Кухонные',
      type: 'scopeOfApplication',
    },
    {
      i: 1825,
      checked: false,
      name: 'Вытяжки',
      type: 118,
    },
    {
      i: 1826,
      checked: false,
      name: 'Фильтры для вытяжек',
      type: 118,
    },
    {
      i: 1827,
      checked: false,
      name: 'Bosch',
      type: 'brandHoods',
    },
    {
      i: 1828,
      checked: false,
      name: 'Elica',
      type: 'brandHoods',
    },
    {
      i: 1829,
      checked: false,
      name: 'Elikor',
      type: 'brandHoods',
    },
    {
      i: 1830,
      checked: false,
      name: 'Falmec',
      type: 'brandHoods',
    },
    {
      i: 1831,
      checked: false,
      name: 'Gorenje',
      type: 'brandHoods',
    },
    {
      i: 1832,
      checked: false,
      name: 'Hansa',
      type: 'brandHoods',
    },
    {
      i: 1833,
      checked: false,
      name: 'Jetair',
      type: 'brandHoods',
    },
    {
      i: 1834,
      checked: false,
      name: 'Konigin',
      type: 'brandHoods',
    },
    {
      i: 1835,
      checked: false,
      name: 'Korting',
      type: 'brandHoods',
    },
    {
      i: 1836,
      checked: false,
      name: 'Krona',
      type: 'brandHoods',
    },
    {
      i: 1837,
      checked: false,
      name: 'Kuppersberg',
      type: 'brandHoods',
    },
    {
      i: 1838,
      checked: false,
      name: 'Maunfeld',
      type: 'brandHoods',
    },
    {
      i: 1839,
      checked: false,
      name: 'Zigmund & Shtain',
      type: 'brandHoods',
    },
    {
      i: 1840,
      checked: false,
      name: 'Блендеры, измельчители',
      type: 119,
    },
    {
      i: 1841,
      checked: false,
      name: 'Кухонные комбайны',
      type: 119,
    },
    {
      i: 1842,
      checked: false,
      name: 'Ломтерезки',
      type: 119,
    },
    {
      i: 1843,
      checked: false,
      name: 'Миксеры',
      type: 119,
    },
    {
      i: 1844,
      checked: false,
      name: 'Мясорубки',
      type: 119,
    },
    {
      i: 1845,
      checked: false,
      name: 'Вентиляторы',
      type: 120,
    },
    {
      i: 1846,
      checked: false,
      name: 'Ионизаторы',
      type: 120,
    },
    {
      i: 1847,
      checked: false,
      name: 'Метеостанции, термометры',
      type: 120,
    },
    {
      i: 1848,
      checked: false,
      name: 'Мобильные кондиционеры',
      type: 120,
    },
    {
      i: 1849,
      checked: false,
      name: 'Настенные кондиционеры',
      type: 120,
    },
    {
      i: 1850,
      checked: false,
      name: 'Обогревательные приборы',
      type: 120,
    },
    {
      i: 1851,
      checked: false,
      name: 'Очистители, увлажнители воздуха',
      type: 120,
    },
    {
      i: 1852,
      checked: false,
      name: 'Кулеры',
      type: 121,
    },
    {
      i: 1853,
      checked: false,
      name: 'Фильтры',
      type: 121,
    },
    {
      i: 1854,
      checked: false,
      name: 'Плиты',
      type: 122,
    },
    {
      i: 1855,
      checked: false,
      name: 'Варочные панели',
      type: 122,
    },
    {
      i: 1856,
      checked: false,
      name: 'Духовые шкафы',
      type: 122,
    },
    {
      i: 1857,
      checked: false,
      name: 'Автовыключение',
      type: 'safety',
    },
    {
      i: 1858,
      checked: false,
      name: 'Блокировка от детей',
      type: 'safety',
    },
    {
      i: 1859,
      checked: false,
      name: 'Защита от перегрева',
      type: 'safety',
    },
    {
      i: 1860,
      checked: false,
      name: 'Полноразмерные',
      type: 123,
    },
    {
      i: 1861,
      checked: false,
      name: 'Компактные, настольные',
      type: 123,
    },
    {
      i: 1862,
      checked: false,
      name: 'Узкие',
      type: 123,
    },
    {
      i: 1863,
      checked: false,
      name: 'AEG',
      type: 'brandDishwashers',
    },
    {
      i: 1864,
      checked: false,
      name: 'Beko',
      type: 'brandDishwashers',
    },
    {
      i: 1865,
      checked: false,
      name: 'Bosch',
      type: 'brandDishwashers',
    },
    {
      i: 1866,
      checked: false,
      name: 'Candy',
      type: 'brandDishwashers',
    },
    {
      i: 1867,
      checked: false,
      name: 'Electrolux',
      type: 'brandDishwashers',
    },
    {
      i: 1868,
      checked: false,
      name: 'Gorenje',
      type: 'brandDishwashers',
    },
    {
      i: 1869,
      checked: false,
      name: 'Hansa',
      type: 'brandDishwashers',
    },
    {
      i: 1870,
      checked: false,
      name: 'Hotpoint - Ariston',
      type: 'brandDishwashers',
    },
    {
      i: 1871,
      checked: false,
      name: 'Indesit',
      type: 'brandDishwashers',
    },
    {
      i: 1872,
      checked: false,
      name: 'Konigin',
      type: 'brandDishwashers',
    },
    {
      i: 1873,
      checked: false,
      name: 'Samsung',
      type: 'brandDishwashers',
    },
    {
      i: 1874,
      checked: false,
      name: 'Siemens',
      type: 'brandDishwashers',
    },
    {
      i: 1875,
      checked: false,
      name: 'Whirlpool',
      type: 'brandDishwashers',
    },
    {
      i: 1876,
      checked: false,
      name: 'Zanussi',
      type: 'brandDishwashers',
    },
    {
      i: 1877,
      checked: false,
      name: '12 и более комплектов',
      type: 'utensilCapacity',
    },
    {
      i: 1878,
      checked: false,
      name: '9-11 комплектов',
      type: 'utensilCapacity',
    },
    {
      i: 1879,
      checked: false,
      name: 'до 6 комплектов',
      type: 'utensilCapacity',
    },
    {
      i: 1880,
      checked: false,
      name: 'Отдельно стоящие',
      type: 'installationType',
    },
    {
      i: 1881,
      checked: false,
      name: 'Встраиваемые',
      type: 'installationType',
    },
    {
      i: 1882,
      checked: false,
      name: 'Аэрогрили',
      type: 124,
    },
    {
      i: 1883,
      checked: false,
      name: 'Блинницы',
      type: 124,
    },
    {
      i: 1884,
      checked: false,
      name: 'Йогуртницы',
      type: 124,
    },
    {
      i: 1885,
      checked: false,
      name: 'Микроволновые печи',
      type: 124,
    },
    {
      i: 1886,
      checked: false,
      name: 'Мини-печи, ростеры',
      type: 124,
    },
    {
      i: 1887,
      checked: false,
      name: 'Мороженицы',
      type: 124,
    },
    {
      i: 1888,
      checked: false,
      name: 'Мультиварки',
      type: 124,
    },
    {
      i: 1889,
      checked: false,
      name: 'Пароварки',
      type: 124,
    },
    {
      i: 1890,
      checked: false,
      name: 'Сушилки для овощей, фруктов',
      type: 124,
    },
    {
      i: 1891,
      checked: false,
      name: 'Сэндвичницы, вафельницы',
      type: 124,
    },
    {
      i: 1892,
      checked: false,
      name: 'Тостеры',
      type: 124,
    },
    {
      i: 1893,
      checked: false,
      name: 'Фритюрницы',
      type: 124,
    },
    {
      i: 1894,
      checked: false,
      name: 'Хлебопечки',
      type: 124,
    },
    {
      i: 1895,
      checked: false,
      name: 'Электрогрили, шашлычницы',
      type: 124,
    },
    {
      i: 1896,
      checked: false,
      name: 'Электрочайники, термопоты',
      type: 125,
    },
    {
      i: 1897,
      checked: false,
      name: 'Соковыжималки',
      type: 125,
    },
    {
      i: 1898,
      checked: false,
      name: 'Кофеварки, кофемашины',
      type: 125,
    },
    {
      i: 1899,
      checked: false,
      name: 'Кофемолки',
      type: 125,
    },
    {
      i: 1900,
      checked: false,
      name: 'Паровые швабры',
      type: 126,
    },
    {
      i: 1901,
      checked: false,
      name: 'Пароочистители',
      type: 126,
    },
    {
      i: 1902,
      checked: false,
      name: 'Пылесосы',
      type: 126,
    },
    {
      i: 1903,
      checked: false,
      name: 'Роботы-пылесосы',
      type: 126,
    },
    {
      i: 1904,
      checked: false,
      name: 'Bosch',
      type: 'brandСleaner',
    },
    {
      i: 1905,
      checked: false,
      name: 'Dyson',
      type: 'brandСleaner',
    },
    {
      i: 1906,
      checked: false,
      name: 'Karcher',
      type: 'brandСleaner',
    },
    {
      i: 1907,
      checked: false,
      name: 'Konigin',
      type: 'brandСleaner',
    },
    {
      i: 1908,
      checked: false,
      name: 'LG',
      type: 'brandСleaner',
    },
    {
      i: 1909,
      checked: false,
      name: 'Philips',
      type: 'brandСleaner',
    },
    {
      i: 1910,
      checked: false,
      name: 'Polaris',
      type: 'brandСleaner',
    },
    {
      i: 1911,
      checked: false,
      name: 'Samsung',
      type: 'brandСleaner',
    },
    {
      i: 1912,
      checked: false,
      name: 'Scarlett',
      type: 'brandСleaner',
    },
    {
      i: 1913,
      checked: false,
      name: 'Supra',
      type: 'brandСleaner',
    },
    {
      i: 1914,
      checked: false,
      name: 'Tefal',
      type: 'brandСleaner',
    },
    {
      i: 1915,
      checked: false,
      name: 'Thomas',
      type: 'brandСleaner',
    },
    {
      i: 1916,
      checked: false,
      name: 'Xiaomi',
      type: 'brandСleaner',
    },
    {
      i: 1917,
      checked: false,
      name: 'Zelmer',
      type: 'brandСleaner',
    },
    {
      i: 1918,
      checked: false,
      name: 'iRobot',
      type: 'brandСleaner',
    },
    {
      i: 1919,
      checked: false,
      name: 'Сухая',
      type: 'typeCleaning',
    },
    {
      i: 1920,
      checked: false,
      name: 'Влажная',
      type: 'typeCleaning',
    },
    {
      i: 1921,
      checked: false,
      name: 'Сухая и влажная',
      type: 'typeCleaning',
    },
    {
      i: 1922,
      checked: false,
      name: 'Отдельно стоящие',
      type: 127,
    },
    {
      i: 1923,
      checked: false,
      name: 'Встраиваемые',
      type: 127,
    },
    {
      i: 1924,
      checked: false,
      name: 'Ardo',
      type: 'brandDishwashers',
    },
    {
      i: 1925,
      checked: false,
      name: 'Asko',
      type: 'brandDishwashers',
    },
    {
      i: 1926,
      checked: false,
      name: 'Atlant',
      type: 'brandDishwashers',
    },
    {
      i: 1927,
      checked: false,
      name: 'Daewoo',
      type: 'brandDishwashers',
    },
    {
      i: 1928,
      checked: false,
      name: 'Haier',
      type: 'brandDishwashers',
    },
    {
      i: 1929,
      checked: false,
      name: 'Miele',
      type: 'brandDishwashers',
    },
    {
      i: 1930,
      checked: false,
      name: 'Vestel',
      type: 'brandDishwashers',
    },
    {
      i: 1931,
      checked: false,
      name: 'Другая',
      type: 'brandDishwashers',
    },
    {
      i: 1932,
      checked: false,
      name: 'до 4 кг',
      type: 'kg',
    },
    {
      i: 1933,
      checked: false,
      name: 'до 5 кг',
      type: 'kg',
    },
    {
      i: 1934,
      checked: false,
      name: 'до 6 кг',
      type: 'kg',
    },
    {
      i: 1935,
      checked: false,
      name: 'до 7 кг',
      type: 'kg',
    },
    {
      i: 1936,
      checked: false,
      name: 'до 700 об/мин',
      type: 'spin',
    },
    {
      i: 1937,
      checked: false,
      name: '700-800 об/мин',
      type: 'spin',
    },
    {
      i: 1938,
      checked: false,
      name: '800-900 об/мин',
      type: 'spin',
    },
    {
      i: 1939,
      checked: false,
      name: '900-1100 об/мин',
      type: 'spin',
    },
    {
      i: 1940,
      checked: false,
      name: '1100-1200 об/мин',
      type: 'spin',
    },
    {
      i: 1941,
      checked: false,
      name: 'от 1200 об/мин',
      type: 'spin',
    },
    {
      i: 1942,
      checked: false,
      name: 'Вертикальная',
      type: 'loadType',
    },
    {
      i: 1943,
      checked: false,
      name: 'Фронтальная',
      type: 'loadType',
    },
    {
      i: 1944,
      checked: false,
      name: 'Менее 50',
      type: 'height',
    },
    {
      i: 1945,
      checked: false,
      name: '50,1 - 70',
      type: 'height',
    },
    {
      i: 1946,
      checked: false,
      name: '70,1 - 80',
      type: 'height',
    },
    {
      i: 1947,
      checked: false,
      name: '80,1 - 85',
      type: 'height',
    },
    {
      i: 1948,
      checked: false,
      name: '85,1 - 90',
      type: 'height',
    },
    {
      i: 1949,
      checked: false,
      name: '90,1 и более',
      type: 'height',
    },
    {
      i: 1950,
      checked: false,
      name: 'Менее 45',
      type: 'depth',
    },
    {
      i: 1951,
      checked: false,
      name: '45,1 - 50',
      type: 'depth',
    },
    {
      i: 1952,
      checked: false,
      name: '50,1 - 55',
      type: 'depth',
    },
    {
      i: 1953,
      checked: false,
      name: '55,1 - 60',
      type: 'depth',
    },
    {
      i: 1954,
      checked: false,
      name: '60,1 - 65',
      type: 'depth',
    },
    {
      i: 1955,
      checked: false,
      name: '65,1 и более',
      type: 'depth',
    },
    {
      i: 1956,
      checked: false,
      name: 'Менее 45',
      type: 'width',
    },
    {
      i: 1957,
      checked: false,
      name: '45,1 - 55',
      type: 'width',
    },
    {
      i: 1958,
      checked: false,
      name: '55,1 - 60',
      type: 'width',
    },
    {
      i: 1959,
      checked: false,
      name: '60,1 - 70',
      type: 'width',
    },
    {
      i: 1960,
      checked: false,
      name: '70,1 - 75',
      type: 'width',
    },
    {
      i: 1961,
      checked: false,
      name: '75,1 и более',
      type: 'width',
    },
    {
      i: 1962,
      checked: false,
      name: 'Утюги',
      type: 128,
    },
    {
      i: 1963,
      checked: false,
      name: 'Отпариватели',
      type: 128,
    },
    {
      i: 1964,
      checked: false,
      name: 'Гладильные доски',
      type: 128,
    },
    {
      i: 1965,
      checked: false,
      name: 'Сушилки для белья',
      type: 128,
    },
    {
      i: 1966,
      checked: false,
      name: 'Сушилки для обуви',
      type: 128,
    },
    {
      i: 1967,
      checked: false,
      name: 'Холодильники',
      type: 129,
    },
    {
      i: 1968,
      checked: false,
      name: 'Морозильники',
      type: 129,
    },
    {
      i: 1969,
      checked: false,
      name: 'Вышивальные машины',
      type: 130,
    },
    {
      i: 1970,
      checked: false,
      name: 'Вязальные машины',
      type: 130,
    },
    {
      i: 1971,
      checked: false,
      name: 'Оверлоки',
      type: 130,
    },
    {
      i: 1972,
      checked: false,
      name: 'Швейные машины',
      type: 130,
    },
    {
      i: 1973,
      checked: false,
      name: 'Аксессуары и запчасти',
      type: 130,
    },
    {
      i: 1974,
      checked: false,
      name: 'AstraLux',
      type: 'brandSewing',
    },
    {
      i: 1975,
      checked: false,
      name: 'Bernina',
      type: 'brandSewing',
    },
    {
      i: 1976,
      checked: false,
      name: 'Brother',
      type: 'brandSewing',
    },
    {
      i: 1977,
      checked: false,
      name: 'Elna',
      type: 'brandSewing',
    },
    {
      i: 1978,
      checked: false,
      name: 'Jaguar',
      type: 'brandSewing',
    },
    {
      i: 1979,
      checked: false,
      name: 'Janome',
      type: 'brandSewing',
    },
    {
      i: 1980,
      checked: false,
      name: 'Juki',
      type: 'brandSewing',
    },
    {
      i: 1981,
      checked: false,
      name: 'Merrylock',
      type: 'brandSewing',
    },
    {
      i: 1982,
      checked: false,
      name: 'Singer',
      type: 'brandSewing',
    },
    {
      i: 1983,
      checked: false,
      name: 'Toyota',
      type: 'brandSewing',
    },
    {
      i: 1984,
      checked: false,
      name: 'Zoje',
      type: 'brandSewing',
    },
    {
      i: 1985,
      checked: false,
      name: 'Тула',
      type: 'brandSewing',
    },
    {
      i: 1986,
      checked: false,
      name: 'Чайка',
      type: 'brandSewing',
    },
    {
      i: 1987,
      checked: false,
      name: 'Для ухода за бытовой техникой',
      type: 131,
    },
    {
      i: 1988,
      checked: false,
      name: 'Освежители воздуха',
      type: 131,
    },
    {
      i: 1989,
      checked: false,
      name: 'Средства для стирки',
      type: 131,
    },
    {
      i: 1990,
      checked: false,
      name: 'Чистящие и моющие средства',
      type: 131,
    },
    {
      i: 1991,
      checked: false,
      name: 'Диваны',
      type: 132,
    },
    {
      i: 1992,
      checked: false,
      name: 'Компьютерные кресла',
      type: 132,
    },
    {
      i: 1993,
      checked: false,
      name: 'Кресла',
      type: 132,
    },
    {
      i: 1994,
      checked: false,
      name: 'Кресла-мешки',
      type: 132,
    },
    {
      i: 1995,
      checked: false,
      name: 'Кровати',
      type: 133,
    },
    {
      i: 1996,
      checked: false,
      name: 'Матрасы',
      type: 133,
    },
    {
      i: 1997,
      checked: false,
      name: 'Раскладушки',
      type: 133,
    },
    {
      i: 1998,
      checked: false,
      name: 'Бра',
      type: 135,
    },
    {
      i: 1999,
      checked: false,
      name: 'Лампочки',
      type: 135,
    },
    {
      i: 2000,
      checked: false,
      name: 'Лампы',
      type: 135,
    },
    {
      i: 2001,
      checked: false,
      name: 'Люстры',
      type: 135,
    },
    {
      i: 2002,
      checked: false,
      name: 'Ночники',
      type: 135,
    },
    {
      i: 2003,
      checked: false,
      name: 'Прожекторы',
      type: 135,
    },
    {
      i: 2004,
      checked: false,
      name: 'Светодиодные ленты',
      type: 135,
    },
    {
      i: 2005,
      checked: false,
      name: 'Торшеры',
      type: 135,
    },
    {
      i: 2006,
      checked: false,
      name: 'Вазы',
      type: 136,
    },
    {
      i: 2007,
      checked: false,
      name: 'Зеркала',
      type: 136,
    },
    {
      i: 2008,
      checked: false,
      name: 'Искусственные растения',
      type: 136,
    },
    {
      i: 2009,
      checked: false,
      name: 'Картины и постеры',
      type: 136,
    },
    {
      i: 2010,
      checked: false,
      name: 'Ключницы',
      type: 136,
    },
    {
      i: 2011,
      checked: false,
      name: 'Наклейки',
      type: 136,
    },
    {
      i: 2012,
      checked: false,
      name: 'Свечи и подсвечники',
      type: 136,
    },
    {
      i: 2013,
      checked: false,
      name: 'Статуэтки и фигурки',
      type: 136,
    },
    {
      i: 2014,
      checked: false,
      name: 'Фотоальбомы и рамки',
      type: 136,
    },
    {
      i: 2015,
      checked: false,
      name: 'Часы',
      type: 136,
    },
    {
      i: 2016,
      checked: false,
      name: 'Ширмы',
      type: 136,
    },
    {
      i: 2017,
      checked: false,
      name: 'Шкатулки',
      type: 136,
    },
    {
      i: 2018,
      checked: false,
      name: 'Домофоны',
      type: 137,
    },
    {
      i: 2019,
      checked: false,
      name: 'Замки',
      type: 137,
    },
    {
      i: 2020,
      checked: false,
      name: 'Сейфы',
      type: 137,
    },
    {
      i: 2021,
      checked: false,
      name: 'Сигнализации',
      type: 137,
    },
    {
      i: 2022,
      checked: false,
      name: 'В ванную',
      type: 138,
    },
    {
      i: 2023,
      checked: false,
      name: 'Под обувь',
      type: 138,
    },
    {
      i: 2024,
      checked: false,
      name: 'Под телевизор',
      type: 138,
    },
    {
      i: 2025,
      checked: false,
      name: 'Прикроватные',
      type: 138,
    },
    {
      i: 2026,
      checked: false,
      name: 'Банки',
      type: 139,
    },
    {
      i: 2027,
      checked: false,
      name: 'Блюда и салатники',
      type: 139,
    },
    {
      i: 2028,
      checked: false,
      name: 'Бокалы',
      type: 139,
    },
    {
      i: 2029,
      checked: false,
      name: 'Кастрюли и ковши',
      type: 139,
    },
    {
      i: 2030,
      checked: false,
      name: 'Кружки',
      type: 139,
    },
    {
      i: 2031,
      checked: false,
      name: 'Кувшины',
      type: 139,
    },
    {
      i: 2032,
      checked: false,
      name: 'Кухонные аксессуары',
      type: 139,
    },
    {
      i: 2033,
      checked: false,
      name: 'Ложки и вилки',
      type: 139,
    },
    {
      i: 2034,
      checked: false,
      name: 'Ножи',
      type: 139,
    },
    {
      i: 2035,
      checked: false,
      name: 'Подносы',
      type: 139,
    },
    {
      i: 2036,
      checked: false,
      name: 'Рюмки',
      type: 139,
    },
    {
      i: 2037,
      checked: false,
      name: 'Сервизы',
      type: 139,
    },
    {
      i: 2038,
      checked: false,
      name: 'Сковородки',
      type: 139,
    },
    {
      i: 2039,
      checked: false,
      name: 'Стаканы',
      type: 139,
    },
    {
      i: 2040,
      checked: false,
      name: 'Тарелки',
      type: 139,
    },
    {
      i: 2041,
      checked: false,
      name: 'Термосы и термокружки',
      type: 139,
    },
    {
      i: 2042,
      checked: false,
      name: 'Чайники',
      type: 139,
    },
    {
      i: 2043,
      checked: false,
      name: 'Аксессуары для рассады',
      type: 140,
    },
    {
      i: 2044,
      checked: false,
      name: 'Букеты',
      type: 140,
    },
    {
      i: 2045,
      checked: false,
      name: 'Декоративные растения',
      type: 140,
    },
    {
      i: 2046,
      checked: false,
      name: 'Картофель, лук, грибы, чеснок',
      type: 140,
    },
    {
      i: 2047,
      checked: false,
      name: 'Комнатные растения',
      type: 140,
    },
    {
      i: 2048,
      checked: false,
      name: 'Плодовые растения',
      type: 140,
    },
    {
      i: 2049,
      checked: false,
      name: 'Рассада',
      type: 140,
    },
    {
      i: 2050,
      checked: false,
      name: 'Семена и саженцы',
      type: 140,
    },
    {
      i: 2051,
      checked: false,
      name: 'Аксессуары для полива',
      type: 141,
    },
    {
      i: 2052,
      checked: false,
      name: 'Бассейны',
      type: 141,
    },
    {
      i: 2053,
      checked: false,
      name: 'Биотуалеты',
      type: 141,
    },
    {
      i: 2054,
      checked: false,
      name: 'Души и умывальники',
      type: 141,
    },
    {
      i: 2055,
      checked: false,
      name: 'Отпугиватели животных',
      type: 141,
    },
    {
      i: 2056,
      checked: false,
      name: 'Парники и теплицы',
      type: 141,
    },
    {
      i: 2057,
      checked: false,
      name: 'Садовая техника',
      type: 141,
    },
    {
      i: 2058,
      checked: false,
      name: 'Садовые инструменты',
      type: 141,
    },
    {
      i: 2059,
      checked: false,
      name: 'Садовый декор',
      type: 141,
    },
    {
      i: 2060,
      checked: false,
      name: 'Удобрения и грунты',
      type: 141,
    },
    {
      i: 2061,
      checked: false,
      name: 'Гамаки',
      type: 142,
    },
    {
      i: 2062,
      checked: false,
      name: 'Зонты от солнца',
      type: 142,
    },
    {
      i: 2063,
      checked: false,
      name: 'Качели',
      type: 142,
    },
    {
      i: 2064,
      checked: false,
      name: 'Лежаки и шезлонги',
      type: 142,
    },
    {
      i: 2065,
      checked: false,
      name: 'Подвесные кресла',
      type: 142,
    },
    {
      i: 2066,
      checked: false,
      name: 'Скамейки',
      type: 142,
    },
    {
      i: 2067,
      checked: false,
      name: 'Шатры и беседки',
      type: 142,
    },
    {
      i: 2068,
      checked: false,
      name: 'Банкетки и скамьи',
      type: 143,
    },
    {
      i: 2069,
      checked: false,
      name: 'Журнальные столы',
      type: 143,
    },
    {
      i: 2070,
      checked: false,
      name: 'Компьютерные столы',
      type: 143,
    },
    {
      i: 2071,
      checked: false,
      name: 'Обеденные столы',
      type: 143,
    },
    {
      i: 2072,
      checked: false,
      name: 'Письменные столы',
      type: 143,
    },
    {
      i: 2073,
      checked: false,
      name: 'Пуфики',
      type: 143,
    },
    {
      i: 2074,
      checked: false,
      name: 'Столы',
      type: 143,
    },
    {
      i: 2075,
      checked: false,
      name: 'Стулья',
      type: 143,
    },
    {
      i: 2076,
      checked: false,
      name: 'Табуреты',
      type: 143,
    },
    {
      i: 2077,
      checked: false,
      name: 'Туалетные столики',
      type: 143,
    },
    {
      i: 2078,
      checked: false,
      name: 'Ковровые дорожки',
      type: 144,
    },
    {
      i: 2079,
      checked: false,
      name: 'Ковры',
      type: 144,
    },
    {
      i: 2080,
      checked: false,
      name: 'Наматрасники',
      type: 144,
    },
    {
      i: 2081,
      checked: false,
      name: 'Одеяла',
      type: 144,
    },
    {
      i: 2082,
      checked: false,
      name: 'Пледы',
      type: 144,
    },
    {
      i: 2083,
      checked: false,
      name: 'Подушки',
      type: 144,
    },
    {
      i: 2084,
      checked: false,
      name: 'Полотенца',
      type: 144,
    },
    {
      i: 2085,
      checked: false,
      name: 'Постельное белье',
      type: 144,
    },
    {
      i: 2086,
      checked: false,
      name: 'Салфетки',
      type: 144,
    },
    {
      i: 2087,
      checked: false,
      name: 'Скатерти',
      type: 144,
    },
    {
      i: 2088,
      checked: false,
      name: 'Шторы и гардины',
      type: 144,
    },
    {
      i: 2089,
      checked: false,
      name: 'Гардеробы',
      type: 145,
    },
    {
      i: 2090,
      checked: false,
      name: 'Комоды',
      type: 145,
    },
    {
      i: 2091,
      checked: false,
      name: 'Полки',
      type: 145,
    },
    {
      i: 2092,
      checked: false,
      name: 'Стеллажи',
      type: 145,
    },
    {
      i: 2093,
      checked: false,
      name: 'Шкафы',
      type: 145,
    },
    {
      i: 2094,
      checked: false,
      name: 'Входные',
      type: 147,
    },
    {
      i: 2095,
      checked: false,
      name: 'Дверные коробки',
      type: 147,
    },
    {
      i: 2096,
      checked: false,
      name: 'Дверные ручки',
      type: 147,
    },
    {
      i: 2097,
      checked: false,
      name: 'Межкомнатные',
      type: 147,
    },
    {
      i: 2098,
      checked: false,
      name: 'Амперметры',
      type: 148,
    },
    {
      i: 2099,
      checked: false,
      name: 'Анемометры',
      type: 148,
    },
    {
      i: 2100,
      checked: false,
      name: 'Ареометры',
      type: 148,
    },
    {
      i: 2101,
      checked: false,
      name: 'Вакуумные рамки',
      type: 148,
    },
    {
      i: 2102,
      checked: false,
      name: 'Виброметры',
      type: 148,
    },
    {
      i: 2103,
      checked: false,
      name: 'Видеоэндоскопы',
      type: 148,
    },
    {
      i: 2104,
      checked: false,
      name: 'Вольтметры',
      type: 148,
    },
    {
      i: 2105,
      checked: false,
      name: 'Высотомеры',
      type: 148,
    },
    {
      i: 2106,
      checked: false,
      name: 'Газоанализаторы',
      type: 148,
    },
    {
      i: 2107,
      checked: false,
      name: 'Гигрометры',
      type: 148,
    },
    {
      i: 2108,
      checked: false,
      name: 'Глубиномеры',
      type: 148,
    },
    {
      i: 2109,
      checked: false,
      name: 'Дальномеры',
      type: 148,
    },
    {
      i: 2110,
      checked: false,
      name: 'Детекторы проводки',
      type: 148,
    },
    {
      i: 2111,
      checked: false,
      name: 'Динамометры',
      type: 148,
    },
    {
      i: 2112,
      checked: false,
      name: 'Дозиметры',
      type: 148,
    },
    {
      i: 2113,
      checked: false,
      name: 'Калибраторы',
      type: 148,
    },
    {
      i: 2114,
      checked: false,
      name: 'Курвиметры',
      type: 148,
    },
    {
      i: 2115,
      checked: false,
      name: 'Люксметры',
      type: 148,
    },
    {
      i: 2116,
      checked: false,
      name: 'Манометры',
      type: 148,
    },
    {
      i: 2117,
      checked: false,
      name: 'Мерные ленты',
      type: 148,
    },
    {
      i: 2118,
      checked: false,
      name: 'Микрометры',
      type: 148,
    },
    {
      i: 2119,
      checked: false,
      name: 'Мультиметры',
      type: 148,
    },
    {
      i: 2120,
      checked: false,
      name: 'Нивелиры',
      type: 148,
    },
    {
      i: 2121,
      checked: false,
      name: 'Нутромеры',
      type: 148,
    },
    {
      i: 2122,
      checked: false,
      name: 'Омметры',
      type: 148,
    },
    {
      i: 2123,
      checked: false,
      name: 'Пирометры',
      type: 148,
    },
    {
      i: 2124,
      checked: false,
      name: 'Разметочные циркули',
      type: 148,
    },
    {
      i: 2125,
      checked: false,
      name: 'Резьбомеры',
      type: 148,
    },
    {
      i: 2126,
      checked: false,
      name: 'Рефрактометры',
      type: 148,
    },
    {
      i: 2127,
      checked: false,
      name: 'Рулетки',
      type: 148,
    },
    {
      i: 2128,
      checked: false,
      name: 'Склерометры',
      type: 148,
    },
    {
      i: 2129,
      checked: false,
      name: 'Стетоскопы',
      type: 148,
    },
    {
      i: 2130,
      checked: false,
      name: 'Стробоскопы',
      type: 148,
    },
    {
      i: 2131,
      checked: false,
      name: 'Строительные линейки',
      type: 148,
    },
    {
      i: 2132,
      checked: false,
      name: 'Строительные уровни',
      type: 148,
    },
    {
      i: 2133,
      checked: false,
      name: 'Счетчики электроэнергии',
      type: 148,
    },
    {
      i: 2134,
      checked: false,
      name: 'Тахеометры',
      type: 148,
    },
    {
      i: 2135,
      checked: false,
      name: 'Тахометры',
      type: 148,
    },
    {
      i: 2136,
      checked: false,
      name: 'Твердомеры',
      type: 148,
    },
    {
      i: 2137,
      checked: false,
      name: 'Теодолиты',
      type: 148,
    },
    {
      i: 2138,
      checked: false,
      name: 'Тепловизоры',
      type: 148,
    },
    {
      i: 2139,
      checked: false,
      name: 'Тестеры напряжения',
      type: 148,
    },
    {
      i: 2140,
      checked: false,
      name: 'Токовые клещи',
      type: 148,
    },
    {
      i: 2141,
      checked: false,
      name: 'Толщиномеры',
      type: 148,
    },
    {
      i: 2142,
      checked: false,
      name: 'Топливомеры',
      type: 148,
    },
    {
      i: 2143,
      checked: false,
      name: 'Трассоискатели',
      type: 148,
    },
    {
      i: 2144,
      checked: false,
      name: 'Трубки Пито',
      type: 148,
    },
    {
      i: 2145,
      checked: false,
      name: 'Угломеры',
      type: 148,
    },
    {
      i: 2146,
      checked: false,
      name: 'Цифровые анализаторы батарей',
      type: 148,
    },
    {
      i: 2147,
      checked: false,
      name: 'Частотомеры',
      type: 148,
    },
    {
      i: 2148,
      checked: false,
      name: 'Штангенрейсмасы',
      type: 148,
    },
    {
      i: 2149,
      checked: false,
      name: 'Штангенциркули',
      type: 148,
    },
    {
      i: 2150,
      checked: false,
      name: 'Штанги для невелиров и уровней',
      type: 148,
    },
    {
      i: 2151,
      checked: false,
      name: 'Штативы для нивелиров и уровней',
      type: 148,
    },
    {
      i: 2152,
      checked: false,
      name: 'Шумомеры',
      type: 148,
    },
    {
      i: 2153,
      checked: false,
      name: 'Другое',
      type: 148,
    },
    {
      i: 2154,
      checked: false,
      name: 'Вентиляция',
      type: 150,
    },
    {
      i: 2155,
      checked: false,
      name: 'Газовые баллоны',
      type: 150,
    },
    {
      i: 2156,
      checked: false,
      name: 'Камины и печи',
      type: 150,
    },
    {
      i: 2157,
      checked: false,
      name: 'Отопительные котлы',
      type: 150,
    },
    {
      i: 2158,
      checked: false,
      name: 'Радиаторы',
      type: 150,
    },
    {
      i: 2159,
      checked: false,
      name: 'Теплый пол',
      type: 150,
    },
    {
      i: 2160,
      checked: false,
      name: 'Грильято',
      type: 151,
    },
    {
      i: 2161,
      checked: false,
      name: 'Комплектующие',
      type: 151,
    },
    {
      i: 2162,
      checked: false,
      name: 'Натяжные',
      type: 151,
    },
    {
      i: 2163,
      checked: false,
      name: 'Плиточные',
      type: 151,
    },
    {
      i: 2164,
      checked: false,
      name: 'Реечные',
      type: 151,
    },
    {
      i: 2165,
      checked: false,
      name: 'Баллонные ключи',
      type: 152,
    },
    {
      i: 2166,
      checked: false,
      name: 'Болторезы',
      type: 152,
    },
    {
      i: 2167,
      checked: false,
      name: 'Валики и кисти',
      type: 152,
    },
    {
      i: 2168,
      checked: false,
      name: 'Воротки',
      type: 152,
    },
    {
      i: 2169,
      checked: false,
      name: 'Выколотки',
      type: 152,
    },
    {
      i: 2170,
      checked: false,
      name: 'Вышки туры',
      type: 152,
    },
    {
      i: 2171,
      checked: false,
      name: 'Газовые горелки',
      type: 152,
    },
    {
      i: 2172,
      checked: false,
      name: 'Гвоздодеры',
      type: 152,
    },
    {
      i: 2173,
      checked: false,
      name: 'Динамометрические ключи',
      type: 152,
    },
    {
      i: 2174,
      checked: false,
      name: 'Длинногубцы',
      type: 152,
    },
    {
      i: 2175,
      checked: false,
      name: 'Дыроколы',
      type: 152,
    },
    {
      i: 2176,
      checked: false,
      name: 'Заклепочники',
      type: 152,
    },
    {
      i: 2177,
      checked: false,
      name: 'Зубила',
      type: 152,
    },
    {
      i: 2178,
      checked: false,
      name: 'Кабелерезы',
      type: 152,
    },
    {
      i: 2179,
      checked: false,
      name: 'Кирки',
      type: 152,
    },
    {
      i: 2180,
      checked: false,
      name: 'Кисти',
      type: 152,
    },
    {
      i: 2181,
      checked: false,
      name: 'Киянки',
      type: 152,
    },
    {
      i: 2182,
      checked: false,
      name: 'Клещи',
      type: 152,
    },
    {
      i: 2183,
      checked: false,
      name: 'Клещи и бокорезы',
      type: 152,
    },
    {
      i: 2184,
      checked: false,
      name: 'Ключи',
      type: 152,
    },
    {
      i: 2185,
      checked: false,
      name: 'Коловороты',
      type: 152,
    },
    {
      i: 2186,
      checked: false,
      name: 'Комбинированные ключи',
      type: 152,
    },
    {
      i: 2187,
      checked: false,
      name: 'Краскопульты ручные',
      type: 152,
    },
    {
      i: 2188,
      checked: false,
      name: 'Кримперы',
      type: 152,
    },
    {
      i: 2189,
      checked: false,
      name: 'Круглогубцы',
      type: 152,
    },
    {
      i: 2190,
      checked: false,
      name: 'Кувалды',
      type: 152,
    },
    {
      i: 2191,
      checked: false,
      name: 'Кусачки (бокорезы)',
      type: 152,
    },
    {
      i: 2192,
      checked: false,
      name: 'Ледорубы',
      type: 152,
    },
    {
      i: 2193,
      checked: false,
      name: 'Лестницы',
      type: 152,
    },
    {
      i: 2194,
      checked: false,
      name: 'Ломы',
      type: 152,
    },
    {
      i: 2195,
      checked: false,
      name: 'Мастерки, кельмы',
      type: 152,
    },
    {
      i: 2196,
      checked: false,
      name: 'Молотки',
      type: 152,
    },
    {
      i: 2197,
      checked: false,
      name: 'Молотки и кувалды',
      type: 152,
    },
    {
      i: 2198,
      checked: false,
      name: 'Монтировки',
      type: 152,
    },
    {
      i: 2199,
      checked: false,
      name: 'Наборы гаечных ключей',
      type: 152,
    },
    {
      i: 2200,
      checked: false,
      name: 'Наборы кистей',
      type: 152,
    },
    {
      i: 2201,
      checked: false,
      name: 'Наборы клейм',
      type: 152,
    },
    {
      i: 2202,
      checked: false,
      name: 'Наборы надфилей',
      type: 152,
    },
    {
      i: 2203,
      checked: false,
      name: 'Наборы напильников',
      type: 152,
    },
    {
      i: 2204,
      checked: false,
      name: 'Наборы отверток',
      type: 152,
    },
    {
      i: 2205,
      checked: false,
      name: 'Наборы стамесок',
      type: 152,
    },
    {
      i: 2206,
      checked: false,
      name: 'Наборы торцевых головок',
      type: 152,
    },
    {
      i: 2207,
      checked: false,
      name: 'Накидные ключи',
      type: 152,
    },
    {
      i: 2208,
      checked: false,
      name: 'Наковальни',
      type: 152,
    },
    {
      i: 2209,
      checked: false,
      name: 'Напильники',
      type: 152,
    },
    {
      i: 2210,
      checked: false,
      name: 'Ножи',
      type: 152,
    },
    {
      i: 2211,
      checked: false,
      name: 'Ножницы',
      type: 152,
    },
    {
      i: 2212,
      checked: false,
      name: 'Ножовки',
      type: 152,
    },
    {
      i: 2213,
      checked: false,
      name: 'Отвертки',
      type: 152,
    },
    {
      i: 2214,
      checked: false,
      name: 'Пассатижи и плоскогубцы',
      type: 152,
    },
    {
      i: 2215,
      checked: false,
      name: 'Паяльники',
      type: 152,
    },
    {
      i: 2216,
      checked: false,
      name: 'Паяльные лампы',
      type: 152,
    },
    {
      i: 2217,
      checked: false,
      name: 'Паяльные станции',
      type: 152,
    },
    {
      i: 2218,
      checked: false,
      name: 'Пилы',
      type: 152,
    },
    {
      i: 2219,
      checked: false,
      name: 'Пинцеты',
      type: 152,
    },
    {
      i: 2220,
      checked: false,
      name: 'Плиткорезы',
      type: 152,
    },
    {
      i: 2221,
      checked: false,
      name: 'Плоскогубцы и пассатижи',
      type: 152,
    },
    {
      i: 2222,
      checked: false,
      name: 'Правила',
      type: 152,
    },
    {
      i: 2223,
      checked: false,
      name: 'Разводные ключи',
      type: 152,
    },
    {
      i: 2224,
      checked: false,
      name: 'Рожковые ключи',
      type: 152,
    },
    {
      i: 2225,
      checked: false,
      name: 'Рубанки',
      type: 152,
    },
    {
      i: 2226,
      checked: false,
      name: 'Ручные зажимы',
      type: 152,
    },
    {
      i: 2227,
      checked: false,
      name: 'Свечные ключи',
      type: 152,
    },
    {
      i: 2228,
      checked: false,
      name: 'Скребки',
      type: 152,
    },
    {
      i: 2229,
      checked: false,
      name: 'Стамески',
      type: 152,
    },
    {
      i: 2230,
      checked: false,
      name: 'Стеклорезы',
      type: 152,
    },
    {
      i: 2231,
      checked: false,
      name: 'Степлеры ручные',
      type: 152,
    },
    {
      i: 2232,
      checked: false,
      name: 'Стремянки',
      type: 152,
    },
    {
      i: 2233,
      checked: false,
      name: 'Стрипперы',
      type: 152,
    },
    {
      i: 2234,
      checked: false,
      name: 'Струбцины',
      type: 152,
    },
    {
      i: 2235,
      checked: false,
      name: 'Ступенчатые ключи',
      type: 152,
    },
    {
      i: 2236,
      checked: false,
      name: 'Стусла',
      type: 152,
    },
    {
      i: 2237,
      checked: false,
      name: 'Терки',
      type: 152,
    },
    {
      i: 2238,
      checked: false,
      name: 'Тиски',
      type: 152,
    },
    {
      i: 2239,
      checked: false,
      name: 'Тонкогубцы',
      type: 152,
    },
    {
      i: 2240,
      checked: false,
      name: 'Топоры',
      type: 152,
    },
    {
      i: 2241,
      checked: false,
      name: 'Торцевые головки',
      type: 152,
    },
    {
      i: 2242,
      checked: false,
      name: 'Торцевые ключи',
      type: 152,
    },
    {
      i: 2243,
      checked: false,
      name: 'Трещотки',
      type: 152,
    },
    {
      i: 2244,
      checked: false,
      name: 'Трубные ключи',
      type: 152,
    },
    {
      i: 2245,
      checked: false,
      name: 'Ударные клейма',
      type: 152,
    },
    {
      i: 2246,
      checked: false,
      name: 'Универсальные ключи',
      type: 152,
    },
    {
      i: 2247,
      checked: false,
      name: 'Утконосы',
      type: 152,
    },
    {
      i: 2248,
      checked: false,
      name: 'Цикли',
      type: 152,
    },
    {
      i: 2249,
      checked: false,
      name: 'Четырехгранные ключи',
      type: 152,
    },
    {
      i: 2250,
      checked: false,
      name: 'Шарнирные ключи',
      type: 152,
    },
    {
      i: 2251,
      checked: false,
      name: 'Шестигранные ключи',
      type: 152,
    },
    {
      i: 2252,
      checked: false,
      name: 'Шпатели',
      type: 152,
    },
    {
      i: 2253,
      checked: false,
      name: 'Щипцы',
      type: 152,
    },
    {
      i: 2254,
      checked: false,
      name: 'Ванны',
      type: 153,
    },
    {
      i: 2255,
      checked: false,
      name: 'Водонагреватели',
      type: 153,
    },
    {
      i: 2256,
      checked: false,
      name: 'Водоснабжение',
      type: 153,
    },
    {
      i: 2257,
      checked: false,
      name: 'Душевые кабины',
      type: 153,
    },
    {
      i: 2258,
      checked: false,
      name: 'Души',
      type: 153,
    },
    {
      i: 2259,
      checked: false,
      name: 'Инсталляции',
      type: 153,
    },
    {
      i: 2260,
      checked: false,
      name: 'Канализация',
      type: 153,
    },
    {
      i: 2261,
      checked: false,
      name: 'Комплектующие для ванны',
      type: 153,
    },
    {
      i: 2262,
      checked: false,
      name: 'Мойки',
      type: 153,
    },
    {
      i: 2263,
      checked: false,
      name: 'Насосы',
      type: 153,
    },
    {
      i: 2264,
      checked: false,
      name: 'Полотенцесушители',
      type: 153,
    },
    {
      i: 2265,
      checked: false,
      name: 'Раковины, умывальники',
      type: 153,
    },
    {
      i: 2266,
      checked: false,
      name: 'Сливы, сифоны, трапы',
      type: 153,
    },
    {
      i: 2267,
      checked: false,
      name: 'Смесители',
      type: 153,
    },
    {
      i: 2268,
      checked: false,
      name: 'Сушилки для рук',
      type: 153,
    },
    {
      i: 2269,
      checked: false,
      name: 'Счетчики воды',
      type: 153,
    },
    {
      i: 2270,
      checked: false,
      name: 'Трубы и фитинги',
      type: 153,
    },
    {
      i: 2271,
      checked: false,
      name: 'Унитазы и биде',
      type: 153,
    },
    {
      i: 2272,
      checked: false,
      name: 'Фильтры и краны для воды',
      type: 153,
    },
    {
      i: 2273,
      checked: false,
      name: 'Блоки',
      type: 154,
    },
    {
      i: 2274,
      checked: false,
      name: 'Бытовки',
      type: 154,
    },
    {
      i: 2275,
      checked: false,
      name: 'Гипсокартон',
      type: 154,
    },
    {
      i: 2276,
      checked: false,
      name: 'Изоляция',
      type: 154,
    },
    {
      i: 2277,
      checked: false,
      name: 'Кирпичи и блоки',
      type: 154,
    },
    {
      i: 2278,
      checked: false,
      name: 'Кровля',
      type: 154,
    },
    {
      i: 2279,
      checked: false,
      name: 'Лакокрасочные материалы',
      type: 154,
    },
    {
      i: 2280,
      checked: false,
      name: 'Металлопрокат',
      type: 154,
    },
    {
      i: 2281,
      checked: false,
      name: 'Напольные покрытия',
      type: 154,
    },
    {
      i: 2282,
      checked: false,
      name: 'Обои',
      type: 154,
    },
    {
      i: 2283,
      checked: false,
      name: 'Пиломатериалы',
      type: 154,
    },
    {
      i: 2284,
      checked: false,
      name: 'Плитка',
      type: 154,
    },
    {
      i: 2285,
      checked: false,
      name: 'Сайдинг и панели',
      type: 154,
    },
    {
      i: 2286,
      checked: false,
      name: 'Сетки',
      type: 154,
    },
    {
      i: 2287,
      checked: false,
      name: 'Сухие смеси',
      type: 154,
    },
    {
      i: 2288,
      checked: false,
      name: 'Сыпучие материалы',
      type: 154,
    },
    {
      i: 2289,
      checked: false,
      name: 'Аккумуляторные батареи',
      type: 155,
    },
    {
      i: 2290,
      checked: false,
      name: 'Выключатели и переключатели',
      type: 155,
    },
    {
      i: 2291,
      checked: false,
      name: 'Источники бесперебойного питания',
      type: 155,
    },
    {
      i: 2292,
      checked: false,
      name: 'Предохранители',
      type: 155,
    },
    {
      i: 2293,
      checked: false,
      name: 'Провода и кабели',
      type: 155,
    },
    {
      i: 2294,
      checked: false,
      name: 'Розетки',
      type: 155,
    },
    {
      i: 2295,
      checked: false,
      name: 'Стабилизаторы напряжения',
      type: 155,
    },
    {
      i: 2296,
      checked: false,
      name: 'Трансформаторы',
      type: 155,
    },
    {
      i: 2297,
      checked: false,
      name: 'Удлинители и переходники',
      type: 155,
    },
    {
      i: 2298,
      checked: false,
      name: 'Щиты и шкафы',
      type: 155,
    },
    {
      i: 2299,
      checked: false,
      name: 'Электрогенераторы',
      type: 155,
    },
    {
      i: 2300,
      checked: false,
      name: 'Электросчетчики',
      type: 155,
    },
    {
      i: 2301,
      checked: false,
      name: 'Аккумуляторные отвертки',
      type: 156,
    },
    {
      i: 2302,
      checked: false,
      name: 'Бензогенераторы',
      type: 156,
    },
    {
      i: 2303,
      checked: false,
      name: 'Бетономешалки',
      type: 156,
    },
    {
      i: 2304,
      checked: false,
      name: 'Бороздоделы',
      type: 156,
    },
    {
      i: 2305,
      checked: false,
      name: 'Виброплиты',
      type: 156,
    },
    {
      i: 2306,
      checked: false,
      name: 'Гайковерты',
      type: 156,
    },
    {
      i: 2307,
      checked: false,
      name: 'Глубинные вибраторы',
      type: 156,
    },
    {
      i: 2308,
      checked: false,
      name: 'Граверы',
      type: 156,
    },
    {
      i: 2309,
      checked: false,
      name: 'Дрели и шуруповерты',
      type: 156,
    },
    {
      i: 2310,
      checked: false,
      name: 'Заклепочники',
      type: 156,
    },
    {
      i: 2311,
      checked: false,
      name: 'Клеевые пистолеты',
      type: 156,
    },
    {
      i: 2312,
      checked: false,
      name: 'Краскопульты и аэрографы',
      type: 156,
    },
    {
      i: 2313,
      checked: false,
      name: 'Лобзики',
      type: 156,
    },
    {
      i: 2314,
      checked: false,
      name: 'Наборы электроинструментов',
      type: 156,
    },
    {
      i: 2315,
      checked: false,
      name: 'Отбойники',
      type: 156,
    },
    {
      i: 2316,
      checked: false,
      name: 'Перфораторы',
      type: 156,
    },
    {
      i: 2317,
      checked: false,
      name: 'Плазморезы',
      type: 156,
    },
    {
      i: 2318,
      checked: false,
      name: 'Реноваторы',
      type: 156,
    },
    {
      i: 2319,
      checked: false,
      name: 'Рубанки',
      type: 156,
    },
    {
      i: 2320,
      checked: false,
      name: 'Сварочные аппараты',
      type: 156,
    },
    {
      i: 2321,
      checked: false,
      name: 'Степлеры',
      type: 156,
    },
    {
      i: 2322,
      checked: false,
      name: 'Строительные миксеры',
      type: 156,
    },
    {
      i: 2323,
      checked: false,
      name: 'Строительные пылесосы',
      type: 156,
    },
    {
      i: 2324,
      checked: false,
      name: 'Строительные фены',
      type: 156,
    },
    {
      i: 2325,
      checked: false,
      name: 'Тельферы',
      type: 156,
    },
    {
      i: 2326,
      checked: false,
      name: 'УШМ (болгарки)',
      type: 156,
    },
    {
      i: 2327,
      checked: false,
      name: 'Фрезеры',
      type: 156,
    },
    {
      i: 2328,
      checked: false,
      name: 'Шлифовальные машины',
      type: 156,
    },
    {
      i: 2329,
      checked: false,
      name: 'Штроборезы',
      type: 156,
    },
    {
      i: 2330,
      checked: false,
      name: 'Электрические ножницы',
      type: 156,
    },
    {
      i: 2331,
      checked: false,
      name: 'Электро- и бензопилы',
      type: 156,
    },
    {
      i: 2332,
      checked: false,
      name: 'Бальзам для губ',
      type: 159,
    },
    {
      i: 2333,
      checked: false,
      name: 'Блеск для губ',
      type: 159,
    },
    {
      i: 2334,
      checked: false,
      name: 'Зеркало косметическое',
      type: 159,
    },
    {
      i: 2335,
      checked: false,
      name: 'Кисти, спонжи',
      type: 159,
    },
    {
      i: 2336,
      checked: false,
      name: 'Контуры для губ',
      type: 159,
    },
    {
      i: 2337,
      checked: false,
      name: 'Корректоры, хайлайтеры',
      type: 159,
    },
    {
      i: 2338,
      checked: false,
      name: 'Накладные ресницы',
      type: 159,
    },
    {
      i: 2339,
      checked: false,
      name: 'Помады',
      type: 159,
    },
    {
      i: 2340,
      checked: false,
      name: 'Пудра',
      type: 159,
    },
    {
      i: 2341,
      checked: false,
      name: 'Румяна',
      type: 159,
    },
    {
      i: 2342,
      checked: false,
      name: 'Средства для бровей',
      type: 159,
    },
    {
      i: 2343,
      checked: false,
      name: 'Тени',
      type: 159,
    },
    {
      i: 2344,
      checked: false,
      name: 'Тон, основа',
      type: 159,
    },
    {
      i: 2345,
      checked: false,
      name: 'Тушь, подводки',
      type: 159,
    },
    {
      i: 2346,
      checked: false,
      name: 'Щипцы для ресниц',
      type: 159,
    },
    {
      i: 2347,
      checked: false,
      name: 'Для снятия лака',
      type: 159,
    },
    {
      i: 2348,
      checked: false,
      name: 'Инструменты, наборы',
      type: 159,
    },
    {
      i: 2349,
      checked: false,
      name: 'Лаки, гели',
      type: 159,
    },
    {
      i: 2350,
      checked: false,
      name: 'Лампы для сушки',
      type: 159,
    },
    {
      i: 2351,
      checked: false,
      name: 'Накладные ногти',
      type: 159,
    },
    {
      i: 2352,
      checked: false,
      name: 'Украшения, дизайн',
      type: 159,
    },
    {
      i: 2353,
      checked: false,
      name: 'Бандажи, бинты',
      type: 160,
    },
    {
      i: 2354,
      checked: false,
      name: 'Глюкометры',
      type: 160,
    },
    {
      i: 2355,
      checked: false,
      name: 'Инвалидные коляски',
      type: 160,
    },
    {
      i: 2356,
      checked: false,
      name: 'Ингаляторы',
      type: 160,
    },
    {
      i: 2357,
      checked: false,
      name: 'Контактные линзы',
      type: 160,
    },
    {
      i: 2358,
      checked: false,
      name: 'Корсеты, ортезы',
      type: 160,
    },
    {
      i: 2359,
      checked: false,
      name: 'Костыли, трости',
      type: 160,
    },
    {
      i: 2360,
      checked: false,
      name: 'Массажеры',
      type: 160,
    },
    {
      i: 2361,
      checked: false,
      name: 'Миостимуляторы',
      type: 160,
    },
    {
      i: 2362,
      checked: false,
      name: 'Ортопедические стельки',
      type: 160,
    },
    {
      i: 2363,
      checked: false,
      name: 'Очки',
      type: 160,
    },
    {
      i: 2364,
      checked: false,
      name: 'Пластыри',
      type: 160,
    },
    {
      i: 2365,
      checked: false,
      name: 'Слуховые аппараты',
      type: 160,
    },
    {
      i: 2366,
      checked: false,
      name: 'Термометры',
      type: 160,
    },
    {
      i: 2367,
      checked: false,
      name: 'Тонометры',
      type: 160,
    },
    {
      i: 2368,
      checked: false,
      name: 'Духи',
      type: 161,
    },
    {
      i: 2369,
      checked: false,
      name: 'Пробники',
      type: 161,
    },
    {
      i: 2370,
      checked: false,
      name: 'Туалетная вода',
      type: 161,
    },
    {
      i: 2371,
      checked: false,
      name: 'Женская',
      type: 'sex',
    },
    {
      i: 2372,
      checked: false,
      name: 'Мужская',
      type: 'sex',
    },
    {
      i: 2373,
      checked: false,
      name: 'Унисекс',
      type: 'sex',
    },
    {
      i: 2374,
      checked: false,
      name: 'Бритвенные станки',
      type: 162,
    },
    {
      i: 2375,
      checked: false,
      name: 'Машинки для стрижки',
      type: 162,
    },
    {
      i: 2376,
      checked: false,
      name: 'Ножницы',
      type: 162,
    },
    {
      i: 2377,
      checked: false,
      name: 'Средства для бритья',
      type: 162,
    },
    {
      i: 2378,
      checked: false,
      name: 'Средства для эпиляции',
      type: 162,
    },
    {
      i: 2379,
      checked: false,
      name: 'Электробритвы',
      type: 162,
    },
    {
      i: 2380,
      checked: false,
      name: 'Эпиляторы',
      type: 162,
    },
    {
      i: 2381,
      checked: false,
      name: 'Аксессуары для волос',
      type: 163,
    },
    {
      i: 2382,
      checked: false,
      name: 'Кондиционеры, бальзамы',
      type: 163,
    },
    {
      i: 2383,
      checked: false,
      name: 'Маски, сыворотки',
      type: 163,
    },
    {
      i: 2384,
      checked: false,
      name: 'Масла',
      type: 163,
    },
    {
      i: 2385,
      checked: false,
      name: 'Средства для окрашивания',
      type: 163,
    },
    {
      i: 2386,
      checked: false,
      name: 'Шампуни',
      type: 163,
    },
    {
      i: 2387,
      checked: false,
      name: 'Гели',
      type: 164,
    },
    {
      i: 2388,
      checked: false,
      name: 'Кремы, бальзамы',
      type: 164,
    },
    {
      i: 2389,
      checked: false,
      name: 'Маски, сыворотки',
      type: 164,
    },
    {
      i: 2390,
      checked: false,
      name: 'Масла',
      type: 164,
    },
    {
      i: 2391,
      checked: false,
      name: 'Молочко',
      type: 164,
    },
    {
      i: 2392,
      checked: false,
      name: 'Пенки, эмульсии',
      type: 164,
    },
    {
      i: 2393,
      checked: false,
      name: 'Скрабы, пилинги',
      type: 164,
    },
    {
      i: 2394,
      checked: false,
      name: 'Тоники, лосьоны',
      type: 164,
    },
    {
      i: 2395,
      checked: false,
      name: 'Веки, вокруг глаз',
      type: 'applicationArea',
    },
    {
      i: 2396,
      checked: false,
      name: 'Губы',
      type: 'applicationArea',
    },
    {
      i: 2397,
      checked: false,
      name: 'Лицо',
      type: 'applicationArea',
    },
    {
      i: 2398,
      checked: false,
      name: 'Ноги',
      type: 'applicationArea',
    },
    {
      i: 2399,
      checked: false,
      name: 'Руки',
      type: 'applicationArea',
    },
    {
      i: 2400,
      checked: false,
      name: 'Тело',
      type: 'applicationArea',
    },
    {
      i: 2401,
      checked: false,
      name: 'Шея и зона декольте',
      type: 'applicationArea',
    },
    {
      i: 2402,
      checked: false,
      name: 'Бигуди',
      type: 165,
    },
    {
      i: 2403,
      checked: false,
      name: 'Волосы, шиньоны',
      type: 165,
    },
    {
      i: 2404,
      checked: false,
      name: 'Выпрямители',
      type: 165,
    },
    {
      i: 2405,
      checked: false,
      name: 'Парики',
      type: 165,
    },
    {
      i: 2406,
      checked: false,
      name: 'Расчески',
      type: 165,
    },
    {
      i: 2407,
      checked: false,
      name: 'Средства для укладки',
      type: 165,
    },
    {
      i: 2408,
      checked: false,
      name: 'Стайлеры',
      type: 165,
    },
    {
      i: 2409,
      checked: false,
      name: 'Фены',
      type: 165,
    },
    {
      i: 2410,
      checked: false,
      name: 'Щипцы',
      type: 165,
    },
    {
      i: 2411,
      checked: false,
      name: 'Временные татуировки',
      type: 166,
    },
    {
      i: 2412,
      checked: false,
      name: 'Для татуажа',
      type: 166,
    },
    {
      i: 2413,
      checked: false,
      name: 'Для татуировок',
      type: 166,
    },
    {
      i: 2414,
      checked: false,
      name: 'Солярии',
      type: 167,
    },
    {
      i: 2415,
      checked: false,
      name: 'Автозагары',
      type: 167,
    },
    {
      i: 2416,
      checked: false,
      name: 'Для защиты от солнца',
      type: 167,
    },
    {
      i: 2417,
      checked: false,
      name: 'Для усиления загара',
      type: 167,
    },
    {
      i: 2418,
      checked: false,
      name: 'Средства после загара',
      type: 167,
    },
    {
      i: 2419,
      checked: false,
      name: 'Гели для душа',
      type: 168,
    },
    {
      i: 2420,
      checked: false,
      name: 'Дезодоранты',
      type: 168,
    },
    {
      i: 2421,
      checked: false,
      name: 'Для интимной гигиены',
      type: 168,
    },
    {
      i: 2422,
      checked: false,
      name: 'Зубная паста',
      type: 168,
    },
    {
      i: 2423,
      checked: false,
      name: 'Зубные щетки',
      type: 168,
    },
    {
      i: 2424,
      checked: false,
      name: 'Ирригаторы',
      type: 168,
    },
    {
      i: 2425,
      checked: false,
      name: 'Мочалки',
      type: 168,
    },
    {
      i: 2426,
      checked: false,
      name: 'Мыло',
      type: 168,
    },
    {
      i: 2427,
      checked: false,
      name: 'Отбеливание зубов',
      type: 168,
    },
    {
      i: 2428,
      checked: false,
      name: 'Пена и соль для ванной',
      type: 168,
    },
    {
      i: 2429,
      checked: false,
      name: 'Подгузники для взрослых',
      type: 168,
    },
    {
      i: 2430,
      checked: false,
      name: 'Прокладки, тампоны',
      type: 168,
    },
    {
      i: 2431,
      checked: false,
      name: 'Защита бедра',
      type: 170,
    },
    {
      i: 2432,
      checked: false,
      name: 'Защита груди',
      type: 170,
    },
    {
      i: 2433,
      checked: false,
      name: 'Защита запястий',
      type: 170,
    },
    {
      i: 2434,
      checked: false,
      name: 'Защита паха',
      type: 170,
    },
    {
      i: 2435,
      checked: false,
      name: 'Защита предплечья',
      type: 170,
    },
    {
      i: 2436,
      checked: false,
      name: 'Защита спины',
      type: 170,
    },
    {
      i: 2437,
      checked: false,
      name: 'Защита стопы',
      type: 170,
    },
    {
      i: 2438,
      checked: false,
      name: 'Капы',
      type: 170,
    },
    {
      i: 2439,
      checked: false,
      name: 'Комплекты защиты',
      type: 170,
    },
    {
      i: 2440,
      checked: false,
      name: 'Маски, очки',
      type: 170,
    },
    {
      i: 2441,
      checked: false,
      name: 'Наколенники',
      type: 170,
    },
    {
      i: 2442,
      checked: false,
      name: 'Налокотники',
      type: 170,
    },
    {
      i: 2443,
      checked: false,
      name: 'Шлемы',
      type: 170,
    },
    {
      i: 2444,
      checked: false,
      name: 'Бокс, единоборства',
      type: 'typeSport',
    },
    {
      i: 2445,
      checked: false,
      name: 'Велоспорт',
      type: 'typeSport',
    },
    {
      i: 2446,
      checked: false,
      name: 'Волейбол',
      type: 'typeSport',
    },
    {
      i: 2447,
      checked: false,
      name: 'Горные лыжи',
      type: 'typeSport',
    },
    {
      i: 2448,
      checked: false,
      name: 'Пейнтбол, страйкбол',
      type: 'typeSport',
    },
    {
      i: 2449,
      checked: false,
      name: 'Регби, гандбол',
      type: 'typeSport',
    },
    {
      i: 2450,
      checked: false,
      name: 'Ролики, самокаты, скейтборды',
      type: 'typeSport',
    },
    {
      i: 2451,
      checked: false,
      name: 'Сноубординг',
      type: 'typeSport',
    },
    {
      i: 2452,
      checked: false,
      name: 'Футбол',
      type: 'typeSport',
    },
    {
      i: 2453,
      checked: false,
      name: 'Хоккей',
      type: 'typeSport',
    },
    {
      i: 2454,
      checked: false,
      name: 'Трехколесные',
      type: 171,
    },
    {
      i: 2455,
      checked: false,
      name: 'Городские, дорожные',
      type: 171,
    },
    {
      i: 2456,
      checked: false,
      name: 'Горные, MTB',
      type: 171,
    },
    {
      i: 2457,
      checked: false,
      name: 'Шоссейные',
      type: 171,
    },
    {
      i: 2458,
      checked: false,
      name: 'BMX, трюковые',
      type: 171,
    },
    {
      i: 2459,
      checked: false,
      name: 'Фэт-байки',
      type: 171,
    },
    {
      i: 2460,
      checked: false,
      name: 'Круизеры',
      type: 171,
    },
    {
      i: 2461,
      checked: false,
      name: 'Велокресла',
      type: 171,
    },
    {
      i: 2462,
      checked: false,
      name: 'Аксессуары и запчасти',
      type: 171,
    },
    {
      i: 2463,
      checked: false,
      name: 'XS (158-165 см)',
      type: 'frame',
    },
    {
      i: 2464,
      checked: false,
      name: 'S (164-173 см)',
      type: 'frame',
    },
    {
      i: 2465,
      checked: false,
      name: 'M (168-180 см)',
      type: 'frame',
    },
    {
      i: 2466,
      checked: false,
      name: 'L (178-190 см)',
      type: 'frame',
    },
    {
      i: 2467,
      checked: false,
      name: 'XL (185-197 см)',
      type: 'frame',
    },
    {
      i: 2468,
      checked: false,
      name: 'XXL (193-205 см)',
      type: 'frame',
    },
    {
      i: 2469,
      checked: false,
      name: '11" (80-135 см)',
      type: 'frame',
    },
    {
      i: 2470,
      checked: false,
      name: '12" (95-120 см)',
      type: 'frame',
    },
    {
      i: 2471,
      checked: false,
      name: '13" (130-145 см)',
      type: 'frame',
    },
    {
      i: 2472,
      checked: false,
      name: '14" (135-155 см)',
      type: 'frame',
    },
    {
      i: 2473,
      checked: false,
      name: '15" (145-160 см)',
      type: 'frame',
    },
    {
      i: 2474,
      checked: false,
      name: '16" (150-165 см)',
      type: 'frame',
    },
    {
      i: 2475,
      checked: false,
      name: '17" (156-170 см)',
      type: 'frame',
    },
    {
      i: 2476,
      checked: false,
      name: '17.5" (161-171 см)',
      type: 'frame',
    },
    {
      i: 2477,
      checked: false,
      name: '18" (167-178 см)',
      type: 'frame',
    },
    {
      i: 2478,
      checked: false,
      name: '18.5" (170-179 см)',
      type: 'frame',
    },
    {
      i: 2479,
      checked: false,
      name: '19" (172-180 см)',
      type: 'frame',
    },
    {
      i: 2480,
      checked: false,
      name: '19.5" (174-187 см)',
      type: 'frame',
    },
    {
      i: 2481,
      checked: false,
      name: '20" (178-185 см)',
      type: 'frame',
    },
    {
      i: 2482,
      checked: false,
      name: '21" (180-190 см)',
      type: 'frame',
    },
    {
      i: 2483,
      checked: false,
      name: '22" (185-195 см)',
      type: 'frame',
    },
    {
      i: 2484,
      checked: false,
      name: '23" (190-200 см)',
      type: 'frame',
    },
    {
      i: 2485,
      checked: false,
      name: '24" (195-210 см)',
      type: 'frame',
    },
    {
      i: 2486,
      checked: false,
      name: 'Роликовые коньки',
      type: 172,
    },
    {
      i: 2487,
      checked: false,
      name: 'Скейтборды',
      type: 172,
    },
    {
      i: 2488,
      checked: false,
      name: 'Лонгборды',
      type: 172,
    },
    {
      i: 2489,
      checked: false,
      name: 'Самокаты',
      type: 173,
    },
    {
      i: 2490,
      checked: false,
      name: 'Гироскутеры',
      type: 173,
    },
    {
      i: 2491,
      checked: false,
      name: 'Беговелы',
      type: 173,
    },
    {
      i: 2492,
      checked: false,
      name: 'Бильярдные кии',
      type: 174,
    },
    {
      i: 2493,
      checked: false,
      name: 'Бильярдные столы',
      type: 174,
    },
    {
      i: 2494,
      checked: false,
      name: 'Шары для бильярда',
      type: 174,
    },
    {
      i: 2495,
      checked: false,
      name: 'Шары для боулинга',
      type: 174,
    },
    {
      i: 2496,
      checked: false,
      name: 'Серфинг, водные лыжи',
      type: 175,
    },
    {
      i: 2497,
      checked: false,
      name: 'Бассейны',
      type: 175,
    },
    {
      i: 2498,
      checked: false,
      name: 'Гидрокостюмы, дайвинг',
      type: 175,
    },
    {
      i: 2499,
      checked: false,
      name: 'Ласты',
      type: 175,
    },
    {
      i: 2500,
      checked: false,
      name: 'Маски, трубки',
      type: 175,
    },
    {
      i: 2501,
      checked: false,
      name: 'Надувные круги, матрасы',
      type: 175,
    },
    {
      i: 2502,
      checked: false,
      name: 'Очки для плавания',
      type: 175,
    },
    {
      i: 2503,
      checked: false,
      name: 'Спасательные жилеты',
      type: 175,
    },
    {
      i: 2504,
      checked: false,
      name: 'Шапочки для плавания',
      type: 175,
    },
    {
      i: 2505,
      checked: false,
      name: 'Перчатки',
      type: 176,
    },
    {
      i: 2506,
      checked: false,
      name: 'Лапы, макивары',
      type: 176,
    },
    {
      i: 2507,
      checked: false,
      name: 'Груши, мешки, манекены',
      type: 176,
    },
    {
      i: 2508,
      checked: false,
      name: 'Кимоно, одежда',
      type: 176,
    },
    {
      i: 2509,
      checked: false,
      name: 'Обувь для единоборств',
      type: 176,
    },
    {
      i: 2510,
      checked: false,
      name: 'Беговые лыжи',
      type: 177,
    },
    {
      i: 2511,
      checked: false,
      name: 'Горные лыжи',
      type: 177,
    },
    {
      i: 2512,
      checked: false,
      name: 'Сноубординг',
      type: 177,
    },
    {
      i: 2513,
      checked: false,
      name: 'Фигурное катание',
      type: 177,
    },
    {
      i: 2514,
      checked: false,
      name: 'Хоккей',
      type: 177,
    },
    {
      i: 2515,
      checked: false,
      name: 'Санки, снегокаты',
      type: 177,
    },
    {
      i: 2516,
      checked: false,
      name: 'Баскетбол',
      type: 178,
    },
    {
      i: 2517,
      checked: false,
      name: 'Бейсбол',
      type: 178,
    },
    {
      i: 2518,
      checked: false,
      name: 'Водное поло',
      type: 178,
    },
    {
      i: 2519,
      checked: false,
      name: 'Волейбол',
      type: 178,
    },
    {
      i: 2520,
      checked: false,
      name: 'Гандбол',
      type: 178,
    },
    {
      i: 2521,
      checked: false,
      name: 'Гольф',
      type: 178,
    },
    {
      i: 2522,
      checked: false,
      name: 'Регби',
      type: 178,
    },
    {
      i: 2523,
      checked: false,
      name: 'Футбол',
      type: 178,
    },
    {
      i: 2524,
      checked: false,
      name: 'Хоккей на траве',
      type: 178,
    },
    {
      i: 2525,
      checked: false,
      name: 'Аксессуары для оружия',
      type: 179,
    },
    {
      i: 2526,
      checked: false,
      name: 'Катушки',
      type: 179,
    },
    {
      i: 2527,
      checked: false,
      name: 'Крючки, грузила, поплавки',
      type: 179,
    },
    {
      i: 2528,
      checked: false,
      name: 'Ледобуры',
      type: 179,
    },
    {
      i: 2529,
      checked: false,
      name: 'Леска, поводки',
      type: 179,
    },
    {
      i: 2530,
      checked: false,
      name: 'Маскировка',
      type: 179,
    },
    {
      i: 2531,
      checked: false,
      name: 'Подводная охота',
      type: 179,
    },
    {
      i: 2532,
      checked: false,
      name: 'Приманки, воблеры',
      type: 179,
    },
    {
      i: 2533,
      checked: false,
      name: 'Садки, подсачеки',
      type: 179,
    },
    {
      i: 2534,
      checked: false,
      name: 'Удилища',
      type: 179,
    },
    {
      i: 2535,
      checked: false,
      name: 'Удочки, спиннинги',
      type: 179,
    },
    {
      i: 2536,
      checked: false,
      name: 'Эхолоты',
      type: 179,
    },
    {
      i: 2537,
      checked: false,
      name: 'Альпинизм, скалолазание',
      type: 180,
    },
    {
      i: 2538,
      checked: false,
      name: 'Газовые горелки',
      type: 180,
    },
    {
      i: 2539,
      checked: false,
      name: 'Генераторы, обогреватели',
      type: 180,
    },
    {
      i: 2540,
      checked: false,
      name: 'Грили, барбекю, коптильни',
      type: 180,
    },
    {
      i: 2541,
      checked: false,
      name: 'Компасы',
      type: 180,
    },
    {
      i: 2542,
      checked: false,
      name: 'Мангалы, шампуры',
      type: 180,
    },
    {
      i: 2543,
      checked: false,
      name: 'Металлоискатели',
      type: 180,
    },
    {
      i: 2544,
      checked: false,
      name: 'Палатки, походная мебель',
      type: 180,
    },
    {
      i: 2545,
      checked: false,
      name: 'Палки для треккинга',
      type: 180,
    },
    {
      i: 2546,
      checked: false,
      name: 'Спальные мешки, коврики',
      type: 180,
    },
    {
      i: 2547,
      checked: false,
      name: 'Термосумки',
      type: 180,
    },
    {
      i: 2548,
      checked: false,
      name: 'Туристическая посуда',
      type: 180,
    },
    {
      i: 2549,
      checked: false,
      name: 'Туристические рюкзаки',
      type: 180,
    },
    {
      i: 2550,
      checked: false,
      name: 'Угли, средства для розжига',
      type: 180,
    },
    {
      i: 2551,
      checked: false,
      name: 'Фонари, лампы',
      type: 180,
    },
    {
      i: 2552,
      checked: false,
      name: 'Большой теннис',
      type: 181,
    },
    {
      i: 2553,
      checked: false,
      name: 'Настольный теннис',
      type: 181,
    },
    {
      i: 2554,
      checked: false,
      name: 'Бадминтон',
      type: 181,
    },
    {
      i: 2555,
      checked: false,
      name: 'Дартс',
      type: 181,
    },
    {
      i: 2556,
      checked: false,
      name: 'Батуты',
      type: 182,
    },
    {
      i: 2557,
      checked: false,
      name: 'Беговые дорожки',
      type: 182,
    },
    {
      i: 2558,
      checked: false,
      name: 'Велотренажеры',
      type: 182,
    },
    {
      i: 2559,
      checked: false,
      name: 'Виброплатформы',
      type: 182,
    },
    {
      i: 2560,
      checked: false,
      name: 'Гантели',
      type: 182,
    },
    {
      i: 2561,
      checked: false,
      name: 'Гимнастические мячи',
      type: 182,
    },
    {
      i: 2562,
      checked: false,
      name: 'Гири',
      type: 182,
    },
    {
      i: 2563,
      checked: false,
      name: 'Гребные тренажеры',
      type: 182,
    },
    {
      i: 2564,
      checked: false,
      name: 'Джамперы',
      type: 182,
    },
    {
      i: 2565,
      checked: false,
      name: 'Коврики, товары для йоги',
      type: 182,
    },
    {
      i: 2566,
      checked: false,
      name: 'Обручи',
      type: 182,
    },
    {
      i: 2567,
      checked: false,
      name: 'Силовые тренажеры',
      type: 182,
    },
    {
      i: 2568,
      checked: false,
      name: 'Скакалки',
      type: 182,
    },
    {
      i: 2569,
      checked: false,
      name: 'Спортивные комплексы',
      type: 182,
    },
    {
      i: 2570,
      checked: false,
      name: 'Степперы',
      type: 182,
    },
    {
      i: 2571,
      checked: false,
      name: 'Утяжелители',
      type: 182,
    },
    {
      i: 2572,
      checked: false,
      name: 'Штанги, блины',
      type: 182,
    },
    {
      i: 2573,
      checked: false,
      name: 'Эллиптические тренажеры',
      type: 182,
    },
    {
      i: 2574,
      checked: false,
      name: 'Эспандеры',
      type: 182,
    },
    {
      i: 2575,
      checked: false,
      name: 'Аминокислоты',
      type: 183,
    },
    {
      i: 2576,
      checked: false,
      name: 'Витамины, минералы',
      type: 183,
    },
    {
      i: 2577,
      checked: false,
      name: 'Гейнеры',
      type: 183,
    },
    {
      i: 2578,
      checked: false,
      name: 'Жиросжигатели',
      type: 183,
    },
    {
      i: 2579,
      checked: false,
      name: 'Креатин',
      type: 183,
    },
    {
      i: 2580,
      checked: false,
      name: 'Препараты для восстановления',
      type: 183,
    },
    {
      i: 2581,
      checked: false,
      name: 'Протеины',
      type: 183,
    },
    {
      i: 2582,
      checked: false,
      name: 'Энергетики, изотоники',
      type: 183,
    },
    {
      i: 2583,
      checked: false,
      name: 'Шейкеры, бутылки',
      type: 183,
    },
    {
      i: 2584,
      checked: false,
      name: 'Кино',
      type: 185,
    },
    {
      i: 2585,
      checked: false,
      name: 'Концерты, выставки',
      type: 185,
    },
    {
      i: 2586,
      checked: false,
      name: 'Путешествия',
      type: 185,
    },
    {
      i: 2587,
      checked: false,
      name: 'Спорт',
      type: 185,
    },
    {
      i: 2588,
      checked: false,
      name: 'Театр, опера, балет',
      type: 185,
    },
    {
      i: 2589,
      checked: false,
      name: 'Цирк',
      type: 185,
    },
    {
      i: 2590,
      checked: false,
      name: 'Шоу, мюзикл',
      type: 185,
    },
    {
      i: 2591,
      checked: false,
      name: 'Юмор',
      type: 185,
    },
    {
      i: 2592,
      checked: false,
      name: 'DVD',
      type: 186,
    },
    {
      i: 2593,
      checked: false,
      name: 'Blu-Ray',
      type: 186,
    },
    {
      i: 2594,
      checked: false,
      name: 'CD',
      type: 186,
    },
    {
      i: 2595,
      checked: false,
      name: 'Кассеты VHS',
      type: 186,
    },
    {
      i: 2596,
      checked: false,
      name: 'Приставки',
      type: 187,
    },
    {
      i: 2597,
      checked: false,
      name: 'Геймпады',
      type: 187,
    },
    {
      i: 2598,
      checked: false,
      name: 'Датчики движения',
      type: 187,
    },
    {
      i: 2599,
      checked: false,
      name: 'Жесткие диски',
      type: 187,
    },
    {
      i: 2600,
      checked: false,
      name: 'Зарядки, аккумуляторы',
      type: 187,
    },
    {
      i: 2601,
      checked: false,
      name: 'Кабели, адаптеры',
      type: 187,
    },
    {
      i: 2602,
      checked: false,
      name: 'Клавиатуры, мыши',
      type: 187,
    },
    {
      i: 2603,
      checked: false,
      name: 'Подставки',
      type: 187,
    },
    {
      i: 2604,
      checked: false,
      name: 'Аксессуары',
      type: 187,
    },
    {
      i: 2605,
      checked: false,
      name: 'Dendy',
      type: 'platform',
    },
    {
      i: 2606,
      checked: false,
      name: 'Game Boy',
      type: 'platform',
    },
    {
      i: 2607,
      checked: false,
      name: 'Nintendo 3DS',
      type: 'platform',
    },
    {
      i: 2608,
      checked: false,
      name: 'Nintendo DS',
      type: 'platform',
    },
    {
      i: 2609,
      checked: false,
      name: 'Nintendo DS Lite',
      type: 'platform',
    },
    {
      i: 2610,
      checked: false,
      name: 'Nintendo GameCube',
      type: 'platform',
    },
    {
      i: 2611,
      checked: false,
      name: 'Nintendo Switch',
      type: 'platform',
    },
    {
      i: 2612,
      checked: false,
      name: 'Nintendo Wii',
      type: 'platform',
    },
    {
      i: 2613,
      checked: false,
      name: 'Nintendo Wii U',
      type: 'platform',
    },
    {
      i: 2614,
      checked: false,
      name: 'PlayStation 1',
      type: 'platform',
    },
    {
      i: 2615,
      checked: false,
      name: 'PlayStation 2',
      type: 'platform',
    },
    {
      i: 2616,
      checked: false,
      name: 'PlayStation 3',
      type: 'platform',
    },
    {
      i: 2617,
      checked: false,
      name: 'PlayStation 4',
      type: 'platform',
    },
    {
      i: 2618,
      checked: false,
      name: 'PlayStation 4 Pro',
      type: 'platform',
    },
    {
      i: 2619,
      checked: false,
      name: 'PlayStation 5',
      type: 'platform',
    },
    {
      i: 2620,
      checked: false,
      name: 'PlayStation Portable',
      type: 'platform',
    },
    {
      i: 2621,
      checked: false,
      name: 'PlayStation Vita',
      type: 'platform',
    },
    {
      i: 2622,
      checked: false,
      name: 'Sega',
      type: 'platform',
    },
    {
      i: 2623,
      checked: false,
      name: 'Steam Deck',
      type: 'platform',
    },
    {
      i: 2624,
      checked: false,
      name: 'Xbox 360',
      type: 'platform',
    },
    {
      i: 2625,
      checked: false,
      name: 'Xbox One',
      type: 'platform',
    },
    {
      i: 2626,
      checked: false,
      name: 'Xbox Series S',
      type: 'platform',
    },
    {
      i: 2627,
      checked: false,
      name: 'Xbox Series X',
      type: 'platform',
    },
    {
      i: 2628,
      checked: false,
      name: 'PC, компьютерные игры',
      type: 'gameFromPc',
    },
    {
      i: 2629,
      checked: false,
      name: 'Аркады',
      type: 'genre',
    },
    {
      i: 2630,
      checked: false,
      name: 'Гонки',
      type: 'genre',
    },
    {
      i: 2631,
      checked: false,
      name: 'Драки',
      type: 'genre',
    },
    {
      i: 2632,
      checked: false,
      name: 'Квесты, логические',
      type: 'genre',
    },
    {
      i: 2633,
      checked: false,
      name: 'Музыкальные',
      type: 'genre',
    },
    {
      i: 2634,
      checked: false,
      name: 'Приключения',
      type: 'genre',
    },
    {
      i: 2635,
      checked: false,
      name: 'Для детей, развивающие',
      type: 'genre',
    },
    {
      i: 2636,
      checked: false,
      name: 'Ролевые, RPG',
      type: 'genre',
    },
    {
      i: 2637,
      checked: false,
      name: 'Симуляторы',
      type: 'genre',
    },
    {
      i: 2638,
      checked: false,
      name: 'Спортивные',
      type: 'genre',
    },
    {
      i: 2639,
      checked: false,
      name: 'Стратегии',
      type: 'genre',
    },
    {
      i: 2640,
      checked: false,
      name: 'Экономические',
      type: 'genre',
    },
    {
      i: 2641,
      checked: false,
      name: 'Экшен, шутеры',
      type: 'genre',
    },
    {
      i: 2642,
      checked: false,
      name: 'Газеты',
      type: 189,
    },
    {
      i: 2643,
      checked: false,
      name: 'Журналы',
      type: 189,
    },
    {
      i: 2644,
      checked: false,
      name: 'Книги',
      type: 189,
    },
    {
      i: 2645,
      checked: false,
      name: 'Комиксы',
      type: 189,
    },
    {
      i: 2646,
      checked: false,
      name: 'Бизнес-литература',
      type: 'theme',
    },
    {
      i: 2647,
      checked: false,
      name: 'Дом и семья',
      type: 'theme',
    },
    {
      i: 2648,
      checked: false,
      name: 'Искусство, культура',
      type: 'theme',
    },
    {
      i: 2649,
      checked: false,
      name: 'Компьютерная литература',
      type: 'theme',
    },
    {
      i: 2650,
      checked: false,
      name: 'Кулинария, рецепты',
      type: 'theme',
    },
    {
      i: 2651,
      checked: false,
      name: 'Медицина, здоровье',
      type: 'theme',
    },
    {
      i: 2652,
      checked: false,
      name: 'Наука, техника',
      type: 'theme',
    },
    {
      i: 2653,
      checked: false,
      name: 'Поэзия',
      type: 'theme',
    },
    {
      i: 2654,
      checked: false,
      name: 'Природа, животные',
      type: 'theme',
    },
    {
      i: 2655,
      checked: false,
      name: 'Психология',
      type: 'theme',
    },
    {
      i: 2656,
      checked: false,
      name: 'Публицистика',
      type: 'theme',
    },
    {
      i: 2657,
      checked: false,
      name: 'Путешествия, фото',
      type: 'theme',
    },
    {
      i: 2658,
      checked: false,
      name: 'Религия, эзотерика',
      type: 'theme',
    },
    {
      i: 2659,
      checked: false,
      name: 'Сад и огород',
      type: 'theme',
    },
    {
      i: 2660,
      checked: false,
      name: 'Спорт, фитнес',
      type: 'theme',
    },
    {
      i: 2661,
      checked: false,
      name: 'Справочники, энциклопедии',
      type: 'theme',
    },
    {
      i: 2662,
      checked: false,
      name: 'Учебная литература',
      type: 'theme',
    },
    {
      i: 2663,
      checked: false,
      name: 'Хобби, рукоделие, творчество',
      type: 'theme',
    },
    {
      i: 2664,
      checked: false,
      name: 'Художественная литература',
      type: 'theme',
    },
    {
      i: 2665,
      checked: false,
      name: 'Антикварные мебель, посуда',
      type: 190,
    },
    {
      i: 2666,
      checked: false,
      name: 'Банкноты',
      type: 190,
    },
    {
      i: 2667,
      checked: false,
      name: 'Билеты',
      type: 190,
    },
    {
      i: 2668,
      checked: false,
      name: 'Вещи знаменитостей, автографы',
      type: 190,
    },
    {
      i: 2669,
      checked: false,
      name: 'Виниловые пластинки',
      type: 190,
    },
    {
      i: 2670,
      checked: false,
      name: 'Вкладыши, наклейки',
      type: 190,
    },
    {
      i: 2671,
      checked: false,
      name: 'Военные вещи',
      type: 190,
    },
    {
      i: 2672,
      checked: false,
      name: 'Документы',
      type: 190,
    },
    {
      i: 2673,
      checked: false,
      name: 'Жетоны, медали, значки',
      type: 190,
    },
    {
      i: 2674,
      checked: false,
      name: 'Календари',
      type: 190,
    },
    {
      i: 2675,
      checked: false,
      name: 'Киндер-сюрпризы',
      type: 190,
    },
    {
      i: 2676,
      checked: false,
      name: 'Книги, журналы, рукописи',
      type: 190,
    },
    {
      i: 2677,
      checked: false,
      name: 'Конверты, открытки',
      type: 190,
    },
    {
      i: 2678,
      checked: false,
      name: 'Копилки',
      type: 190,
    },
    {
      i: 2679,
      checked: false,
      name: 'Куклы, игрушки',
      type: 190,
    },
    {
      i: 2680,
      checked: false,
      name: 'Магниты',
      type: 190,
    },
    {
      i: 2681,
      checked: false,
      name: 'Марки',
      type: 190,
    },
    {
      i: 2682,
      checked: false,
      name: 'Модели',
      type: 190,
    },
    {
      i: 2683,
      checked: false,
      name: 'Монеты, нумизматика',
      type: 190,
    },
    {
      i: 2684,
      checked: false,
      name: 'Музыкальные инструменты',
      type: 190,
    },
    {
      i: 2685,
      checked: false,
      name: 'Пепельницы, зажигалки',
      type: 190,
    },
    {
      i: 2686,
      checked: false,
      name: 'Пластиковые карточки',
      type: 190,
    },
    {
      i: 2687,
      checked: false,
      name: 'Предметы искусства, картины',
      type: 190,
    },
    {
      i: 2688,
      checked: false,
      name: 'Статуэтки, фигурки',
      type: 190,
    },
    {
      i: 2689,
      checked: false,
      name: 'Украшения, аксессуары',
      type: 190,
    },
    {
      i: 2690,
      checked: false,
      name: 'Фотографии, письма',
      type: 190,
    },
    {
      i: 2691,
      checked: false,
      name: 'Шахматы, игры',
      type: 190,
    },
    {
      i: 2692,
      checked: false,
      name: 'Шкатулки',
      type: 190,
    },
    {
      i: 2693,
      checked: false,
      name: 'Этикетки, бутылки, пробки',
      type: 190,
    },
    {
      i: 2694,
      checked: false,
      name: 'Выжигание',
      type: 191,
    },
    {
      i: 2695,
      checked: false,
      name: 'Вязание',
      type: 191,
    },
    {
      i: 2696,
      checked: false,
      name: 'Лепка, скульптура',
      type: 191,
    },
    {
      i: 2697,
      checked: false,
      name: 'Плетение, макраме',
      type: 191,
    },
    {
      i: 2698,
      checked: false,
      name: 'Поделки из бумаги',
      type: 191,
    },
    {
      i: 2699,
      checked: false,
      name: 'Резьба по дереву',
      type: 191,
    },
    {
      i: 2700,
      checked: false,
      name: 'Рисование, раскраски',
      type: 191,
    },
    {
      i: 2701,
      checked: false,
      name: 'Шитье, вышивание',
      type: 191,
    },
    {
      i: 2702,
      checked: false,
      name: 'CD',
      type: 192,
    },
    {
      i: 2703,
      checked: false,
      name: 'DVD',
      type: 192,
    },
    {
      i: 2704,
      checked: false,
      name: 'Blu-Ray',
      type: 192,
    },
    {
      i: 2705,
      checked: false,
      name: 'Аудиокассеты',
      type: 192,
    },
    {
      i: 2706,
      checked: false,
      name: 'Виниловые пластинки',
      type: 192,
    },
    {
      i: 2707,
      checked: false,
      name: 'Аккордеоны, гармони, баяны',
      type: 193,
    },
    {
      i: 2708,
      checked: false,
      name: 'Акустические гитары',
      type: 193,
    },
    {
      i: 2709,
      checked: false,
      name: 'Бас-гитары',
      type: 193,
    },
    {
      i: 2710,
      checked: false,
      name: 'Электрогитары',
      type: 193,
    },
    {
      i: 2711,
      checked: false,
      name: 'Гитарное усиление',
      type: 193,
    },
    {
      i: 2712,
      checked: false,
      name: 'Духовые инструменты',
      type: 193,
    },
    {
      i: 2713,
      checked: false,
      name: 'Клавишные',
      type: 193,
    },
    {
      i: 2714,
      checked: false,
      name: 'Микшерные пульты',
      type: 193,
    },
    {
      i: 2715,
      checked: false,
      name: 'Народные инструменты',
      type: 193,
    },
    {
      i: 2716,
      checked: false,
      name: 'Педали эффектов',
      type: 193,
    },
    {
      i: 2717,
      checked: false,
      name: 'Скрипки, смычковые',
      type: 193,
    },
    {
      i: 2718,
      checked: false,
      name: 'Ударные инструменты',
      type: 193,
    },
    {
      i: 2719,
      checked: false,
      name: 'Аксессуары',
      type: 193,
    },
    {
      i: 2720,
      checked: false,
      name: 'Бродилки',
      type: 194,
    },
    {
      i: 2721,
      checked: false,
      name: 'Викторины',
      type: 194,
    },
    {
      i: 2722,
      checked: false,
      name: 'Домино, лото',
      type: 194,
    },
    {
      i: 2723,
      checked: false,
      name: 'Карточные',
      type: 194,
    },
    {
      i: 2724,
      checked: false,
      name: 'Морской бой',
      type: 194,
    },
    {
      i: 2725,
      checked: false,
      name: 'Пазлы, головоломки',
      type: 194,
    },
    {
      i: 2726,
      checked: false,
      name: 'Романтические',
      type: 194,
    },
    {
      i: 2727,
      checked: false,
      name: 'Слова, ассоциации',
      type: 194,
    },
    {
      i: 2728,
      checked: false,
      name: 'Футбол, хоккей, бильярд',
      type: 194,
    },
    {
      i: 2729,
      checked: false,
      name: 'Шахматы, шашки, нарды',
      type: 194,
    },
    {
      i: 2730,
      checked: false,
      name: 'Экономические',
      type: 194,
    },
    {
      i: 2731,
      checked: false,
      name: 'За час',
      type: 'priceOf',
    },
    {
      i: 2732,
      checked: false,
      name: 'За услугу',
      type: 'priceOf',
    },
    {
      i: 2733,
      checked: false,
      name: 'За метраж, объем',
      type: 'priceOf',
    },
    {
      i: 2734,
      checked: false,
      name: 'Дистанционно',
      type: 'placeDelivery',
    },
    {
      i: 2735,
      checked: false,
      name: 'В салоне',
      type: 'placeDelivery',
    },
    {
      i: 2736,
      checked: false,
      name: 'Выезд на дом',
      type: 'placeDelivery',
    },
    {
      i: 2737,
      checked: false,
      name: 'Дома у мастера',
      type: 'placeDelivery',
    },
    {
      i: 2738,
      checked: false,
      name: 'Артистические дисциплины',
      type: 'Обучение',
    },
    {
      i: 2739,
      checked: false,
      name: 'Вождение (АКПП)',
      type: 'Обучение',
    },
    {
      i: 2740,
      checked: false,
      name: 'Вождение (МКПП)',
      type: 'Обучение',
    },
    {
      i: 2741,
      checked: false,
      name: 'Вождение квадроцикла',
      type: 'Обучение',
    },
    {
      i: 2742,
      checked: false,
      name: 'Вождение мотоцикла',
      type: 'Обучение',
    },
    {
      i: 2743,
      checked: false,
      name: 'Вождение снегохода',
      type: 'Обучение',
    },
    {
      i: 2744,
      checked: false,
      name: 'Детское развитие',
      type: 'Обучение',
    },
    {
      i: 2745,
      checked: false,
      name: 'Единоборства',
      type: 'Обучение',
    },
    {
      i: 2746,
      checked: false,
      name: 'Игровые виды спорта',
      type: 'Обучение',
    },
    {
      i: 2747,
      checked: false,
      name: 'Индивидуальные виды спорта',
      type: 'Обучение',
    },
    {
      i: 2748,
      checked: false,
      name: 'Лёгкая атлетика',
      type: 'Обучение',
    },
    {
      i: 2749,
      checked: false,
      name: 'Мастер-классы, тренинги',
      type: 'Обучение',
    },
    {
      i: 2750,
      checked: false,
      name: 'Многоборье',
      type: 'Обучение',
    },
    {
      i: 2751,
      checked: false,
      name: 'Обучение автоинструкторов',
      type: 'Обучение',
    },
    {
      i: 2752,
      checked: false,
      name: 'Обучение бухгалтерскому учёту',
      type: 'Обучение',
    },
    {
      i: 2753,
      checked: false,
      name: 'Обучение вождению',
      type: 'Обучение',
    },
    {
      i: 2754,
      checked: false,
      name: 'Обучение игры на гитаре',
      type: 'Обучение',
    },
    {
      i: 2755,
      checked: false,
      name: 'Обучение игры на флейте',
      type: 'Обучение',
    },
    {
      i: 2756,
      checked: false,
      name: 'Обучение языкам',
      type: 'Обучение',
    },
    {
      i: 2757,
      checked: false,
      name: 'Оздоровительные дисциплины',
      type: 'Обучение',
    },
    {
      i: 2758,
      checked: false,
      name: 'ПДД (теория)',
      type: 'Обучение',
    },
    {
      i: 2759,
      checked: false,
      name: 'Подготовка к школе',
      type: 'Обучение',
    },
    {
      i: 2760,
      checked: false,
      name: 'Подготовка к экзаменам',
      type: 'Обучение',
    },
    {
      i: 2761,
      checked: false,
      name: 'Программы вождения',
      type: 'Обучение',
    },
    {
      i: 2762,
      checked: false,
      name: 'Профессиональное обучение',
      type: 'Обучение',
    },
    {
      i: 2763,
      checked: false,
      name: 'Силовые виды спорта',
      type: 'Обучение',
    },
    {
      i: 2764,
      checked: false,
      name: 'Сложнокоординационные виды спорта',
      type: 'Обучение',
    },
    {
      i: 2765,
      checked: false,
      name: 'Технические виды спорта',
      type: 'Обучение',
    },
    {
      i: 2766,
      checked: false,
      name: 'Тренера, другое',
      type: 'Обучение',
    },
    {
      i: 2767,
      checked: false,
      name: 'Уроки музыки, театрального мастерства',
      type: 'Обучение',
    },
    {
      i: 2768,
      checked: false,
      name: 'Уроки рисования, дизайна, рукоделия',
      type: 'Обучение',
    },
    {
      i: 2769,
      checked: false,
      name: 'Услуги логопеда, дефектолога',
      type: 'Обучение',
    },
    {
      i: 2770,
      checked: false,
      name: 'Услуги психолога',
      type: 'Обучение',
    },
    {
      i: 2771,
      checked: false,
      name: 'Услуги репетитора',
      type: 'Обучение',
    },
    {
      i: 2772,
      checked: false,
      name: 'Услуги тренера (фитнес, спорт, танцы)',
      type: 'Обучение',
    },
    {
      i: 2773,
      checked: false,
      name: 'Циклические виды спорта',
      type: 'Обучение',
    },
    {
      i: 2774,
      checked: false,
      name: 'Экстремальное вождение',
      type: 'Обучение',
    },
    {
      i: 2775,
      checked: false,
      name: 'Экстремальные виды спорта',
      type: 'Обучение',
    },
    {
      i: 2776,
      checked: false,
      name: 'Дезинфекция, дезинсекция',
      type: 'Мастер на час',
    },
    {
      i: 2777,
      checked: false,
      name: 'Изготовление ключей, вскрытие замков',
      type: 'Мастер на час',
    },
    {
      i: 2778,
      checked: false,
      name: 'Муж на час',
      type: 'Мастер на час',
    },
    {
      i: 2779,
      checked: false,
      name: 'Сборка и ремонт мебели',
      type: 'Мастер на час',
    },
    {
      i: 2780,
      checked: false,
      name: 'Услуги няни, гувернантки',
      type: 'Мастер на час',
    },
    {
      i: 2781,
      checked: false,
      name: 'Услуги сиделки',
      type: 'Мастер на час',
    },
    {
      i: 2782,
      checked: false,
      name: 'Аренда кабинета',
      type: 'Красота и здоровье',
    },
    {
      i: 2783,
      checked: false,
      name: 'Депиляция, шугаринг',
      type: 'Красота и здоровье',
    },
    {
      i: 2784,
      checked: false,
      name: 'Загар',
      type: 'Красота и здоровье',
    },
    {
      i: 2785,
      checked: false,
      name: 'Ламинирование и ботокс ресниц',
      type: 'Красота и здоровье',
    },
    {
      i: 2786,
      checked: false,
      name: 'Лечение волос',
      type: 'Красота и здоровье',
    },
    {
      i: 2787,
      checked: false,
      name: 'Макияж',
      type: 'Красота и здоровье',
    },
    {
      i: 2788,
      checked: false,
      name: 'Маникюр, педикюр',
      type: 'Красота и здоровье',
    },
    {
      i: 2789,
      checked: false,
      name: 'Массаж',
      type: 'Красота и здоровье',
    },
    {
      i: 2790,
      checked: false,
      name: 'Наращивание волос',
      type: 'Красота и здоровье',
    },
    {
      i: 2791,
      checked: false,
      name: 'Наращивание ресниц, услуги бровиста',
      type: 'Красота и здоровье',
    },
    {
      i: 2792,
      checked: false,
      name: 'Пирсинг',
      type: 'Красота и здоровье',
    },
    {
      i: 2793,
      checked: false,
      name: 'СПА-услуги',
      type: 'Красота и здоровье',
    },
    {
      i: 2794,
      checked: false,
      name: 'Свадебный стилист',
      type: 'Красота и здоровье',
    },
    {
      i: 2795,
      checked: false,
      name: 'Стилисты и имиджмейкеры',
      type: 'Красота и здоровье',
    },
    {
      i: 2796,
      checked: false,
      name: 'Тату',
      type: 'Красота и здоровье',
    },
    {
      i: 2797,
      checked: false,
      name: 'Татуаж',
      type: 'Красота и здоровье',
    },
    {
      i: 2798,
      checked: false,
      name: 'Услуги парикмахера',
      type: 'Красота и здоровье',
    },
    {
      i: 2799,
      checked: false,
      name: 'Аренда авто',
      type: 'Перевозки',
    },
    {
      i: 2800,
      checked: false,
      name: 'Аренда спецтехники',
      type: 'Перевозки',
    },
    {
      i: 2801,
      checked: false,
      name: 'Вывоз мусора',
      type: 'Перевозки',
    },
    {
      i: 2802,
      checked: false,
      name: 'Грузоперевозки',
      type: 'Перевозки',
    },
    {
      i: 2803,
      checked: false,
      name: 'Грузчики',
      type: 'Перевозки',
    },
    {
      i: 2804,
      checked: false,
      name: 'Доставка продуктов',
      type: 'Перевозки',
    },
    {
      i: 2805,
      checked: false,
      name: 'Курьер на авто',
      type: 'Перевозки',
    },
    {
      i: 2806,
      checked: false,
      name: 'Междугородные перевозки',
      type: 'Перевозки',
    },
    {
      i: 2807,
      checked: false,
      name: 'Пассажирские перевозки',
      type: 'Перевозки',
    },
    {
      i: 2808,
      checked: false,
      name: 'Перевозка стройматериалов',
      type: 'Перевозки',
    },
    {
      i: 2809,
      checked: false,
      name: 'Пеший курьер',
      type: 'Перевозки',
    },
    {
      i: 2810,
      checked: false,
      name: 'Услуги водителя',
      type: 'Перевозки',
    },
    {
      i: 2811,
      checked: false,
      name: 'Услуги эвакуатора',
      type: 'Перевозки',
    },
    {
      i: 2812,
      checked: false,
      name: 'Аренда инструмента',
      type: 'Ремонт и строительство',
    },
    {
      i: 2813,
      checked: false,
      name: 'Бетонные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2814,
      checked: false,
      name: 'Бурение',
      type: 'Ремонт и строительство',
    },
    {
      i: 2815,
      checked: false,
      name: 'Газификация',
      type: 'Ремонт и строительство',
    },
    {
      i: 2816,
      checked: false,
      name: 'Дорожное строительство',
      type: 'Ремонт и строительство',
    },
    {
      i: 2817,
      checked: false,
      name: 'Заборы и ограждения',
      type: 'Ремонт и строительство',
    },
    {
      i: 2818,
      checked: false,
      name: 'Кадастровые работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2819,
      checked: false,
      name: 'Кладочные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2820,
      checked: false,
      name: 'Охранные системы и контроль доступа',
      type: 'Ремонт и строительство',
    },
    {
      i: 2821,
      checked: false,
      name: 'Рольставни и секционные ворота',
      type: 'Ремонт и строительство',
    },
    {
      i: 2822,
      checked: false,
      name: 'Умный дом',
      type: 'Ремонт и строительство',
    },
    {
      i: 2823,
      checked: false,
      name: 'Аварийное вскрытие замков',
      type: 'Ремонт и строительство',
    },
    {
      i: 2824,
      checked: false,
      name: 'Благоустройство территории',
      type: 'Ремонт и строительство',
    },
    {
      i: 2825,
      checked: false,
      name: 'Вентиляция и кондиционеры',
      type: 'Ремонт и строительство',
    },
    {
      i: 2826,
      checked: false,
      name: 'Возведение стен и перегородок',
      type: 'Ремонт и строительство',
    },
    {
      i: 2827,
      checked: false,
      name: 'Высотные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2828,
      checked: false,
      name: 'Гипсокартонные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2829,
      checked: false,
      name: 'Декоративно-прикладные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2830,
      checked: false,
      name: 'Демонтаж сооружений и конструкций',
      type: 'Ремонт и строительство',
    },
    {
      i: 2831,
      checked: false,
      name: 'Дизайн интерьера',
      type: 'Ремонт и строительство',
    },
    {
      i: 2832,
      checked: false,
      name: 'Дома и коттеджи «под ключ»',
      type: 'Ремонт и строительство',
    },
    {
      i: 2833,
      checked: false,
      name: 'Инженерные изыскания',
      type: 'Ремонт и строительство',
    },
    {
      i: 2834,
      checked: false,
      name: 'Кровельные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2835,
      checked: false,
      name: 'Кузнечное дело',
      type: 'Ремонт и строительство',
    },
    {
      i: 2836,
      checked: false,
      name: 'Лазерная резка и гравировка',
      type: 'Ремонт и строительство',
    },
    {
      i: 2837,
      checked: false,
      name: 'Малярные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2838,
      checked: false,
      name: 'Мелкий ремонт',
      type: 'Ремонт и строительство',
    },
    {
      i: 2839,
      checked: false,
      name: 'Металлоконструкции',
      type: 'Ремонт и строительство',
    },
    {
      i: 2840,
      checked: false,
      name: 'Монтаж отопления',
      type: 'Ремонт и строительство',
    },
    {
      i: 2841,
      checked: false,
      name: 'Навесы и тенты',
      type: 'Ремонт и строительство',
    },
    {
      i: 2842,
      checked: false,
      name: 'Натяжные потолки',
      type: 'Ремонт и строительство',
    },
    {
      i: 2843,
      checked: false,
      name: 'Остекление, ремонт окон',
      type: 'Ремонт и строительство',
    },
    {
      i: 2844,
      checked: false,
      name: 'Отделка квартир',
      type: 'Ремонт и строительство',
    },
    {
      i: 2845,
      checked: false,
      name: 'Отделочные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2846,
      checked: false,
      name: 'Печи и камины',
      type: 'Ремонт и строительство',
    },
    {
      i: 2847,
      checked: false,
      name: 'Поклейка обоев',
      type: 'Ремонт и строительство',
    },
    {
      i: 2848,
      checked: false,
      name: 'Проектные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2849,
      checked: false,
      name: 'Ремонт',
      type: 'Ремонт и строительство',
    },
    {
      i: 2850,
      checked: false,
      name: 'Ремонт квартир',
      type: 'Ремонт и строительство',
    },
    {
      i: 2851,
      checked: false,
      name: 'Ремонт промышленного оборудования',
      type: 'Ремонт и строительство',
    },
    {
      i: 2852,
      checked: false,
      name: 'Сборка мебели',
      type: 'Ремонт и строительство',
    },
    {
      i: 2853,
      checked: false,
      name: 'Сварочные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2854,
      checked: false,
      name: 'Слесарные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2855,
      checked: false,
      name: 'Стекольные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2856,
      checked: false,
      name: 'Столярные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2857,
      checked: false,
      name: 'Строительные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2858,
      checked: false,
      name: 'Строительство фундаментов',
      type: 'Ремонт и строительство',
    },
    {
      i: 2859,
      checked: false,
      name: 'Токарные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2860,
      checked: false,
      name: 'Укладка плитки',
      type: 'Ремонт и строительство',
    },
    {
      i: 2861,
      checked: false,
      name: 'Услуги плотника',
      type: 'Ремонт и строительство',
    },
    {
      i: 2862,
      checked: false,
      name: 'Услуги разнорабочих',
      type: 'Ремонт и строительство',
    },
    {
      i: 2863,
      checked: false,
      name: 'Услуги сантехника',
      type: 'Ремонт и строительство',
    },
    {
      i: 2864,
      checked: false,
      name: 'Услуги электрика',
      type: 'Ремонт и строительство',
    },
    {
      i: 2865,
      checked: false,
      name: 'Установка и ремонт дверей',
      type: 'Ремонт и строительство',
    },
    {
      i: 2866,
      checked: false,
      name: 'Фасадные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2867,
      checked: false,
      name: 'Штукатурные работы',
      type: 'Ремонт и строительство',
    },
    {
      i: 2868,
      checked: false,
      name: 'IT-аутсорсинг',
      type: 'Компьютерные услуги',
    },
    {
      i: 2869,
      checked: false,
      name: 'Веб-Дизайн, бренд, арт',
      type: 'Компьютерные услуги',
    },
    {
      i: 2870,
      checked: false,
      name: 'Верстальщики',
      type: 'Компьютерные услуги',
    },
    {
      i: 2871,
      checked: false,
      name: 'Геймдизайнер',
      type: 'Компьютерные услуги',
    },
    {
      i: 2872,
      checked: false,
      name: 'Дизайнеры',
      type: 'Компьютерные услуги',
    },
    {
      i: 2873,
      checked: false,
      name: 'Иллюстратор',
      type: 'Компьютерные услуги',
    },
    {
      i: 2874,
      checked: false,
      name: 'Компьютерная анимация, моделирование',
      type: 'Компьютерные услуги',
    },
    {
      i: 2875,
      checked: false,
      name: 'Компьютерная помощь, настройка ПК',
      type: 'Компьютерные услуги',
    },
    {
      i: 2876,
      checked: false,
      name: 'Маркетинг',
      type: 'Компьютерные услуги',
    },
    {
      i: 2877,
      checked: false,
      name: 'Набор текста, ввод данных',
      type: 'Компьютерные услуги',
    },
    {
      i: 2878,
      checked: false,
      name: 'Подключение Интернета',
      type: 'Компьютерные услуги',
    },
    {
      i: 2879,
      checked: false,
      name: 'Поиск и обработка информации',
      type: 'Компьютерные услуги',
    },
    {
      i: 2880,
      checked: false,
      name: 'Презентации, инфографика',
      type: 'Компьютерные услуги',
    },
    {
      i: 2881,
      checked: false,
      name: 'Программисты',
      type: 'Компьютерные услуги',
    },
    {
      i: 2882,
      checked: false,
      name: 'Промышленный дизайн',
      type: 'Компьютерные услуги',
    },
    {
      i: 2883,
      checked: false,
      name: 'Работа с текстами',
      type: 'Компьютерные услуги',
    },
    {
      i: 2884,
      checked: false,
      name: 'Системные администраторы',
      type: 'Компьютерные услуги',
    },
    {
      i: 2885,
      checked: false,
      name: 'Создание и продвижение сайтов',
      type: 'Компьютерные услуги',
    },
    {
      i: 2886,
      checked: false,
      name: 'Упаковка и реклама',
      type: 'Компьютерные услуги',
    },
    {
      i: 2887,
      checked: false,
      name: 'Установка ПО',
      type: 'Компьютерные услуги',
    },
    {
      i: 2888,
      checked: false,
      name: 'Другие IT-услуги',
      type: 'Компьютерные услуги',
    },
    {
      i: 2889,
      checked: false,
      name: 'Адвокаты',
      type: 'Деловые услуги',
    },
    {
      i: 2890,
      checked: false,
      name: 'Байеры',
      type: 'Деловые услуги',
    },
    {
      i: 2891,
      checked: false,
      name: 'Безопасность, охранное предприятие',
      type: 'Деловые услуги',
    },
    {
      i: 2892,
      checked: false,
      name: 'Бизнес-консультирование',
      type: 'Деловые услуги',
    },
    {
      i: 2893,
      checked: false,
      name: 'Бизнес-тренеры',
      type: 'Деловые услуги',
    },
    {
      i: 2894,
      checked: false,
      name: 'Брачные агентства',
      type: 'Деловые услуги',
    },
    {
      i: 2895,
      checked: false,
      name: 'Брокеры',
      type: 'Деловые услуги',
    },
    {
      i: 2896,
      checked: false,
      name: 'Бухгалтерия, финансы',
      type: 'Деловые услуги',
    },
    {
      i: 2897,
      checked: false,
      name: 'Виртуальный помощник',
      type: 'Деловые услуги',
    },
    {
      i: 2898,
      checked: false,
      name: 'Детективы',
      type: 'Деловые услуги',
    },
    {
      i: 2899,
      checked: false,
      name: 'Дикторы',
      type: 'Деловые услуги',
    },
    {
      i: 2900,
      checked: false,
      name: 'Изготовление вывесок, рекламы',
      type: 'Деловые услуги',
    },
    {
      i: 2901,
      checked: false,
      name: 'Изготовление и монтаж рекламных конструкций и вывесок',
      type: 'Деловые услуги',
    },
    {
      i: 2902,
      checked: false,
      name: 'Интервьюеры',
      type: 'Деловые услуги',
    },
    {
      i: 2903,
      checked: false,
      name: 'Кадровики',
      type: 'Деловые услуги',
    },
    {
      i: 2904,
      checked: false,
      name: 'Консьержи',
      type: 'Деловые услуги',
    },
    {
      i: 2905,
      checked: false,
      name: 'Личный помощник',
      type: 'Деловые услуги',
    },
    {
      i: 2906,
      checked: false,
      name: 'Логисты',
      type: 'Деловые услуги',
    },
    {
      i: 2907,
      checked: false,
      name: 'Маркетинг, реклама, PR',
      type: 'Деловые услуги',
    },
    {
      i: 2908,
      checked: false,
      name: 'Обзвон по базе',
      type: 'Деловые услуги',
    },
    {
      i: 2909,
      checked: false,
      name: 'Охрана труда',
      type: 'Деловые услуги',
    },
    {
      i: 2910,
      checked: false,
      name: 'Оценщик',
      type: 'Деловые услуги',
    },
    {
      i: 2911,
      checked: false,
      name: 'Перевод',
      type: 'Деловые услуги',
    },
    {
      i: 2912,
      checked: false,
      name: 'Полиграфия, печать, дизайн',
      type: 'Деловые услуги',
    },
    {
      i: 2913,
      checked: false,
      name: 'Ритуальные услуги',
      type: 'Деловые услуги',
    },
    {
      i: 2914,
      checked: false,
      name: 'Риэлторские услуги',
      type: 'Деловые услуги',
    },
    {
      i: 2915,
      checked: false,
      name: 'Санитарно-эпидемиологические услуги',
      type: 'Деловые услуги',
    },
    {
      i: 2916,
      checked: false,
      name: 'Сельскохозяйственные работы',
      type: 'Деловые услуги',
    },
    {
      i: 2917,
      checked: false,
      name: 'Сортировщики',
      type: 'Деловые услуги',
    },
    {
      i: 2918,
      checked: false,
      name: 'Составление документов, сертификации и пр.',
      type: 'Деловые услуги',
    },
    {
      i: 2919,
      checked: false,
      name: 'Специалисты по тендерам',
      type: 'Деловые услуги',
    },
    {
      i: 2920,
      checked: false,
      name: 'Трейдеры',
      type: 'Деловые услуги',
    },
    {
      i: 2921,
      checked: false,
      name: 'Управляющий',
      type: 'Деловые услуги',
    },
    {
      i: 2922,
      checked: false,
      name: 'Хэдхантеры',
      type: 'Деловые услуги',
    },
    {
      i: 2923,
      checked: false,
      name: 'Юридические услуги',
      type: 'Деловые услуги',
    },
    {
      i: 2924,
      checked: false,
      name: 'Мойка окон, балконов',
      type: 'Уборка',
    },
    {
      i: 2925,
      checked: false,
      name: 'Работы в саду, на участке',
      type: 'Уборка',
    },
    {
      i: 2926,
      checked: false,
      name: 'Уборка',
      type: 'Уборка',
    },
    {
      i: 2927,
      checked: false,
      name: 'Услуги домработницы',
      type: 'Уборка',
    },
    {
      i: 2928,
      checked: false,
      name: 'Химчистка, стирка, глажка',
      type: 'Уборка',
    },
    {
      i: 2929,
      checked: false,
      name: 'Помощь на дорогах',
      type: 'Автоуслуги',
    },
    {
      i: 2930,
      checked: false,
      name: 'Ремонт грузовых авто',
      type: 'Автоуслуги',
    },
    {
      i: 2931,
      checked: false,
      name: 'Ремонт спецтехники',
      type: 'Автоуслуги',
    },
    {
      i: 2932,
      checked: false,
      name: 'Тонировка и автовинил',
      type: 'Автоуслуги',
    },
    {
      i: 2933,
      checked: false,
      name: 'Автостекла и зеркала',
      type: 'Автоуслуги',
    },
    {
      i: 2934,
      checked: false,
      name: 'Автоэлектрика',
      type: 'Автоуслуги',
    },
    {
      i: 2935,
      checked: false,
      name: 'Выхлопная система',
      type: 'Автоуслуги',
    },
    {
      i: 2936,
      checked: false,
      name: 'Детейлинг',
      type: 'Автоуслуги',
    },
    {
      i: 2937,
      checked: false,
      name: 'Диагностика, подбор авто',
      type: 'Автоуслуги',
    },
    {
      i: 2938,
      checked: false,
      name: 'Замена жидкостей',
      type: 'Автоуслуги',
    },
    {
      i: 2939,
      checked: false,
      name: 'Кондиционеры и отопление',
      type: 'Автоуслуги',
    },
    {
      i: 2940,
      checked: false,
      name: 'Кузовные работы',
      type: 'Автоуслуги',
    },
    {
      i: 2941,
      checked: false,
      name: 'Покраска автомобиля',
      type: 'Автоуслуги',
    },
    {
      i: 2942,
      checked: false,
      name: 'Ремонт двигателя',
      type: 'Автоуслуги',
    },
    {
      i: 2943,
      checked: false,
      name: 'Ремонт мототехники',
      type: 'Автоуслуги',
    },
    {
      i: 2944,
      checked: false,
      name: 'Ремонт подвески',
      type: 'Автоуслуги',
    },
    {
      i: 2945,
      checked: false,
      name: 'Ремонт рулевого управления',
      type: 'Автоуслуги',
    },
    {
      i: 2946,
      checked: false,
      name: 'Ремонт топливной системы',
      type: 'Автоуслуги',
    },
    {
      i: 2947,
      checked: false,
      name: 'Ремонт тормозной системы',
      type: 'Автоуслуги',
    },
    {
      i: 2948,
      checked: false,
      name: 'Ремонт трансмиссии',
      type: 'Автоуслуги',
    },
    {
      i: 2949,
      checked: false,
      name: 'Техническое обслуживание',
      type: 'Автоуслуги',
    },
    {
      i: 2950,
      checked: false,
      name: 'Тюнинг и установка доп. оборудования',
      type: 'Автоуслуги',
    },
    {
      i: 2951,
      checked: false,
      name: 'Химчистка, мойка и полировка',
      type: 'Автоуслуги',
    },
    {
      i: 2952,
      checked: false,
      name: 'Шиномонтажные работы',
      type: 'Автоуслуги',
    },
    {
      i: 2953,
      checked: false,
      name: 'Электрооборудование',
      type: 'Автоуслуги',
    },
    {
      i: 2954,
      checked: false,
      name: 'Ремонт велосипедов',
      type: 'Ремонт техники',
    },
    {
      i: 2955,
      checked: false,
      name: 'Ремонт водонагревателей',
      type: 'Ремонт техники',
    },
    {
      i: 2956,
      checked: false,
      name: 'Ремонт газового оборудования',
      type: 'Ремонт техники',
    },
    {
      i: 2957,
      checked: false,
      name: 'Ремонт инструментов',
      type: 'Ремонт техники',
    },
    {
      i: 2958,
      checked: false,
      name: 'Ремонт компьютеров, ноутбуков',
      type: 'Ремонт техники',
    },
    {
      i: 2959,
      checked: false,
      name: 'Ремонт кухонных плит',
      type: 'Ремонт техники',
    },
    {
      i: 2960,
      checked: false,
      name: 'Ремонт медицинского оборудования',
      type: 'Ремонт техники',
    },
    {
      i: 2961,
      checked: false,
      name: 'Ремонт музыкальных инструментов',
      type: 'Ремонт техники',
    },
    {
      i: 2962,
      checked: false,
      name: 'Ремонт оптических приборов',
      type: 'Ремонт техники',
    },
    {
      i: 2963,
      checked: false,
      name: 'Ремонт персонального электротранспорта',
      type: 'Ремонт техники',
    },
    {
      i: 2964,
      checked: false,
      name: 'Ремонт садовой техники',
      type: 'Ремонт техники',
    },
    {
      i: 2965,
      checked: false,
      name: 'Ремонт спортивного инвентаря',
      type: 'Ремонт техники',
    },
    {
      i: 2966,
      checked: false,
      name: 'Ремонт строительного оборудования',
      type: 'Ремонт техники',
    },
    {
      i: 2967,
      checked: false,
      name: 'Ремонт сушильных машин',
      type: 'Ремонт техники',
    },
    {
      i: 2968,
      checked: false,
      name: 'Ремонт телевизоров, аудио, видеотехники',
      type: 'Ремонт техники',
    },
    {
      i: 2969,
      checked: false,
      name: 'Ремонт телефонов, электроники',
      type: 'Ремонт техники',
    },
    {
      i: 2970,
      checked: false,
      name: 'Ремонт торгового оборудования',
      type: 'Ремонт техники',
    },
    {
      i: 2971,
      checked: false,
      name: 'Ремонт утюгов',
      type: 'Ремонт техники',
    },
    {
      i: 2972,
      checked: false,
      name: 'Ремонт фототехники',
      type: 'Ремонт техники',
    },
    {
      i: 2973,
      checked: false,
      name: 'Ремонт часов',
      type: 'Ремонт техники',
    },
    {
      i: 2974,
      checked: false,
      name: 'Ремонт швейных машин',
      type: 'Ремонт техники',
    },
    {
      i: 2975,
      checked: false,
      name: 'Установка кондиционеров',
      type: 'Ремонт техники',
    },
    {
      i: 2976,
      checked: false,
      name: 'Установка систем безопасности',
      type: 'Ремонт техники',
    },
    {
      i: 2977,
      checked: false,
      name: 'Установка, ремонт бытовой техники',
      type: 'Ремонт техники',
    },
    {
      i: 2978,
      checked: false,
      name: 'Ремонт промышленного оборудования',
      type: 'Ремонт техники',
    },
    {
      i: 2979,
      checked: false,
      name: 'Аренда оборудования, аттракционов',
      type: 'Организация праздников',
    },
    {
      i: 2980,
      checked: false,
      name: 'Аренда площадки',
      type: 'Организация праздников',
    },
    {
      i: 2981,
      checked: false,
      name: 'Ведущие, музыканты, артисты',
      type: 'Организация праздников',
    },
    {
      i: 2982,
      checked: false,
      name: 'Диджеи',
      type: 'Организация праздников',
    },
    {
      i: 2983,
      checked: false,
      name: 'Организация мероприятий',
      type: 'Организация праздников',
    },
    {
      i: 2984,
      checked: false,
      name: 'Приготовление еды и кейтеринг',
      type: 'Организация праздников',
    },
    {
      i: 2985,
      checked: false,
      name: 'Прокат костюмов',
      type: 'Организация праздников',
    },
    {
      i: 2986,
      checked: false,
      name: 'Промоутеры, модели',
      type: 'Организация праздников',
    },
    {
      i: 2987,
      checked: false,
      name: 'Туризм и отдых',
      type: 'Организация праздников',
    },
    {
      i: 2988,
      checked: false,
      name: 'Цветы и декор',
      type: 'Организация праздников',
    },
    {
      i: 2989,
      checked: false,
      name: 'Дикторы',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2990,
      checked: false,
      name: 'Запись аудиороликов',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2991,
      checked: false,
      name: 'Запись музыки и песен',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2992,
      checked: false,
      name: 'Оцифровка',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2993,
      checked: false,
      name: 'Постобработка',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2994,
      checked: false,
      name: 'Видеомонтажер',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2995,
      checked: false,
      name: 'Видеооператоры',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2996,
      checked: false,
      name: 'Детские фотографы',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2997,
      checked: false,
      name: 'Печать альбомов и фотокниг',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2998,
      checked: false,
      name: 'Портретная фотосъемка',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 2999,
      checked: false,
      name: 'Предметное фото',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3000,
      checked: false,
      name: 'Рекламная фотосъемка',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3001,
      checked: false,
      name: 'Рекламное видео',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3002,
      checked: false,
      name: 'Ретушь и восстановление фото',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3003,
      checked: false,
      name: 'Свадебные фотографы',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3004,
      checked: false,
      name: 'Студийная фотосъемка',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3005,
      checked: false,
      name: 'Фото архитектуры',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3006,
      checked: false,
      name: 'Фото и видеосъемка, другое',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3007,
      checked: false,
      name: 'Фотосъемка новорожденных',
      type: 'Фото- и видеосъемка',
    },
    {
      i: 3008,
      checked: false,
      name: 'Аппликация',
      type: 'Изготовление на заказ',
    },
    {
      i: 3009,
      checked: false,
      name: 'Багетные работы',
      type: 'Изготовление на заказ',
    },
    {
      i: 3010,
      checked: false,
      name: 'Валяние',
      type: 'Изготовление на заказ',
    },
    {
      i: 3011,
      checked: false,
      name: 'Вязание',
      type: 'Изготовление на заказ',
    },
    {
      i: 3012,
      checked: false,
      name: 'Изготовление и ремонт одежды, обуви, аксессуаров',
      type: 'Изготовление на заказ',
    },
    {
      i: 3013,
      checked: false,
      name: 'Изготовление игрушек',
      type: 'Изготовление на заказ',
    },
    {
      i: 3014,
      checked: false,
      name: 'Изготовление украшений',
      type: 'Изготовление на заказ',
    },
    {
      i: 3015,
      checked: false,
      name: 'Кованые изделия на заказ',
      type: 'Изготовление на заказ',
    },
    {
      i: 3016,
      checked: false,
      name: 'Лепка',
      type: 'Изготовление на заказ',
    },
    {
      i: 3017,
      checked: false,
      name: 'Мебель на заказ',
      type: 'Изготовление на заказ',
    },
    {
      i: 3018,
      checked: false,
      name: 'Музыка, стихи, озвучка на заказ',
      type: 'Изготовление на заказ',
    },
    {
      i: 3019,
      checked: false,
      name: 'Печати и штампы на заказ',
      type: 'Изготовление на заказ',
    },
    {
      i: 3020,
      checked: false,
      name: 'Резьба',
      type: 'Изготовление на заказ',
    },
    {
      i: 3021,
      checked: false,
      name: 'Рисунок, живопись, графика на заказ',
      type: 'Изготовление на заказ',
    },
    {
      i: 3022,
      checked: false,
      name: 'Скрапбукинг',
      type: 'Изготовление на заказ',
    },
    {
      i: 3023,
      checked: false,
      name: 'Сувенирная продукция, полиграфия',
      type: 'Изготовление на заказ',
    },
    {
      i: 3024,
      checked: false,
      name: 'Ювелирные услуги',
      type: 'Изготовление на заказ',
    },
    {
      i: 3025,
      checked: false,
      name: 'Другие услуги на заказ',
      type: 'Изготовление на заказ',
    },
    {
      i: 3026,
      checked: false,
      name: 'Выпечка, торты на заказ',
      type: 'Продукты питания',
    },
    {
      i: 3027,
      checked: false,
      name: 'Продукты питания',
      type: 'Продукты питания',
    },
    {
      i: 3028,
      checked: false,
      name: 'Услуги повара',
      type: 'Продукты питания',
    },
    {
      i: 3029,
      checked: false,
      name: 'Чай, кофе',
      type: 'Продукты питания',
    },
    {
      i: 3030,
      checked: false,
      name: 'Вязка',
      type: 'Уход за животными',
    },
    {
      i: 3031,
      checked: false,
      name: 'Дрессировка и выгул',
      type: 'Уход за животными',
    },
    {
      i: 3032,
      checked: false,
      name: 'Мастер-классы',
      type: 'Уход за животными',
    },
    {
      i: 3033,
      checked: false,
      name: 'Обслуживание аквариума',
      type: 'Уход за животными',
    },
    {
      i: 3034,
      checked: false,
      name: 'Перевозка животных',
      type: 'Уход за животными',
    },
    {
      i: 3035,
      checked: false,
      name: 'Передержка',
      type: 'Уход за животными',
    },
    {
      i: 3036,
      checked: false,
      name: 'Составление родословной',
      type: 'Уход за животными',
    },
    {
      i: 3037,
      checked: false,
      name: 'Стрижка когтей',
      type: 'Уход за животными',
    },
    {
      i: 3038,
      checked: false,
      name: 'Стрижка, уход',
      type: 'Уход за животными',
    },
    {
      i: 3039,
      checked: false,
      name: 'Другие услуги для животных',
      type: 'Уход за животными',
    },
    {
      i: 3040,
      checked: false,
      name: 'Вторичка',
      type: 'setApartmentType',
    },
    {
      i: 3041,
      checked: false,
      name: 'Новостройка',
      type: 'setApartmentType',
    },
    {
      i: 3042,
      checked: false,
      name: 'Панельный',
      type: 'houseType',
    },
    {
      i: 3043,
      checked: false,
      name: 'Кирпичный',
      type: 'houseType',
    },
    {
      i: 3044,
      checked: false,
      name: 'Монолит',
      type: 'houseType',
    },
    {
      i: 3045,
      checked: false,
      name: 'Кирпично-монолитный',
      type: 'houseType',
    },
    {
      i: 3046,
      checked: false,
      name: 'Блочный',
      type: 'houseType',
    },
    {
      i: 3047,
      checked: false,
      name: 'Деревянный',
      type: 'houseType',
    },
    {
      i: 3048,
      checked: false,
      name: 'До 5 мин. пешком',
      type: 'toTheMetro',
    },
    {
      i: 3049,
      checked: false,
      name: '5 - 15 мин. пешком',
      type: 'toTheMetro',
    },
    {
      i: 3050,
      checked: false,
      name: 'Более 15 мин. пешком',
      type: 'toTheMetro',
    },
    {
      i: 3051,
      checked: false,
      name: 'На транспорте',
      type: 'toTheMetro',
    },
    {
      i: 3052,
      checked: false,
      name: 'Метро планируется',
      type: 'toTheMetro',
    },
    {
      i: 3053,
      checked: false,
      name: 'Нет',
      type: 'elevators',
    },
    {
      i: 3054,
      checked: false,
      name: 'Легковой',
      type: 'elevators',
    },
    {
      i: 3055,
      checked: false,
      name: 'Легковой и грузовой',
      type: 'elevators',
    },
    {
      i: 3056,
      checked: false,
      name: '3 и более лифтов',
      type: 'elevators',
    },
    {
      i: 3057,
      checked: false,
      name: 'Детская площадка',
      type: 'infrastructure',
    },
    {
      i: 3058,
      checked: false,
      name: 'Школа',
      type: 'infrastructure',
    },
    {
      i: 3059,
      checked: false,
      name: 'Детский сад',
      type: 'infrastructure',
    },
    {
      i: 3060,
      checked: false,
      name: 'Поликлиника',
      type: 'infrastructure',
    },
    {
      i: 3061,
      checked: false,
      name: 'Магазины',
      type: 'infrastructure',
    },
    {
      i: 3062,
      checked: false,
      name: 'Спорт',
      type: 'infrastructure',
    },
    {
      i: 3063,
      checked: false,
      name: 'Транспорт',
      type: 'infrastructure',
    },
    {
      i: 3064,
      checked: false,
      name: 'Кафе и рестораны',
      type: 'infrastructure',
    },
    {
      i: 3065,
      checked: false,
      name: 'Развлечения',
      type: 'infrastructure',
    },
    {
      i: 3066,
      checked: false,
      name: 'Парки и места для отдыха',
      type: 'infrastructure',
    },
    {
      i: 3067,
      checked: false,
      name: '1 комната',
      type: 'roomsCount',
    },
    {
      i: 3068,
      checked: false,
      name: '2 комнаты',
      type: 'roomsCount',
    },
    {
      i: 3069,
      checked: false,
      name: '3 комнаты',
      type: 'roomsCount',
    },
    {
      i: 3070,
      checked: false,
      name: '4 комнаты',
      type: 'roomsCount',
    },
    {
      i: 3071,
      checked: false,
      name: '5 и более комнат',
      type: 'roomsCount',
    },
    {
      i: 3072,
      checked: false,
      name: 'Апартаменты',
      type: 'roomsCount',
    },
    {
      i: 3073,
      checked: false,
      name: 'Свободная планировка',
      type: 'roomsCount',
    },
    {
      i: 3074,
      checked: false,
      name: 'Студия',
      type: 'roomsCount',
    },
    {
      i: 3075,
      checked: false,
      name: 'Требуется ремонт',
      type: 'repair',
    },
    {
      i: 3076,
      checked: false,
      name: 'Не требуется',
      type: 'repair',
    },
    {
      i: 3077,
      checked: false,
      name: 'Косметический',
      type: 'repair',
    },
    {
      i: 3078,
      checked: false,
      name: 'Евроремонт',
      type: 'repair',
    },
    {
      i: 3079,
      checked: false,
      name: 'Дизайнерский',
      type: 'repair',
    },
    {
      i: 3080,
      checked: false,
      name: 'Капитальный ремонт',
      type: 'repair',
    },
    {
      i: 3081,
      checked: false,
      name: 'Совмещенный',
      type: 'bathrooms',
    },
    {
      i: 3082,
      checked: false,
      name: 'Раздельный',
      type: 'bathrooms',
    },
    {
      i: 3083,
      checked: false,
      name: '2 и более',
      type: 'bathrooms',
    },
    {
      i: 3084,
      checked: false,
      name: 'Нет',
      type: 'balcony',
    },
    {
      i: 3085,
      checked: false,
      name: 'Балкон',
      type: 'balcony',
    },
    {
      i: 3086,
      checked: false,
      name: 'Лоджия',
      type: 'balcony',
    },
    {
      i: 3087,
      checked: false,
      name: 'Несколько балконов',
      type: 'balcony',
    },
    {
      i: 3088,
      checked: false,
      name: 'До 3-х лет',
      type: 'periodOfUseN',
    },
    {
      i: 3089,
      checked: false,
      name: 'От 3 до 5 лет',
      type: 'periodOfUseN',
    },
    {
      i: 3090,
      checked: false,
      name: 'Более 5 лет',
      type: 'periodOfUseN',
    },
    {
      i: 3091,
      checked: false,
      name: 'Собственник',
      type: 'whoPosted',
    },
    {
      i: 3092,
      checked: false,
      name: 'Агент',
      type: 'whoPosted',
    },
    {
      i: 3093,
      checked: false,
      name: 'Дом',
      type: 'houseTypeH',
    },
    {
      i: 3094,
      checked: false,
      name: 'Таунхаус',
      type: 'houseTypeH',
    },
    {
      i: 3095,
      checked: false,
      name: 'Коттедж',
      type: 'houseTypeH',
    },
    {
      i: 3096,
      checked: false,
      name: 'Дача',
      type: 'houseTypeH',
    },
    {
      i: 3097,
      checked: false,
      name: 'Кирпичный',
      type: 'materHouse',
    },
    {
      i: 3098,
      checked: false,
      name: 'Деревянный',
      type: 'materHouse',
    },
    {
      i: 3099,
      checked: false,
      name: 'Щитовой',
      type: 'materHouse',
    },
    {
      i: 3100,
      checked: false,
      name: 'Монолитный',
      type: 'materHouse',
    },
    {
      i: 3101,
      checked: false,
      name: 'Блочный',
      type: 'materHouse',
    },
    {
      i: 3102,
      checked: false,
      name: 'Нет',
      type: 'electricity',
    },
    {
      i: 3103,
      checked: false,
      name: 'Подключено',
      type: 'electricity',
    },
    {
      i: 3104,
      checked: false,
      name: 'Нет',
      type: 'heating',
    },
    {
      i: 3105,
      checked: false,
      name: 'Автономное',
      type: 'heating',
    },
    {
      i: 3106,
      checked: false,
      name: 'Централизованное',
      type: 'heating',
    },
    {
      i: 3107,
      checked: false,
      name: 'Нет',
      type: 'garage',
    },
    {
      i: 3108,
      checked: false,
      name: 'В доме',
      type: 'garage',
    },
    {
      i: 3109,
      checked: false,
      name: 'Отдельно стоящий',
      type: 'garage',
    },
    {
      i: 3110,
      checked: false,
      name: 'Сельхоз (СНТ или ДНП)',
      type: 'plotType',
    },
    {
      i: 3111,
      checked: false,
      name: 'Фермерское хоз-во',
      type: 'plotType',
    },
    {
      i: 3112,
      checked: false,
      name: 'Поселения (ИЖС)',
      type: 'plotType',
    },
    {
      i: 3113,
      checked: false,
      name: 'Земля промназначения',
      type: 'plotType',
    },
    {
      i: 3114,
      checked: false,
      name: 'Инвестпроект',
      type: 'plotType',
    },
    {
      i: 3115,
      checked: false,
      name: 'Без предоплаты',
      type: 'prepayment',
    },
    {
      i: 3116,
      checked: false,
      name: '1 месяц',
      type: 'prepayment',
    },
    {
      i: 3117,
      checked: false,
      name: '2 месяца',
      type: 'prepayment',
    },
    {
      i: 3118,
      checked: false,
      name: '3 месяца',
      type: 'prepayment',
    },
    {
      i: 3119,
      checked: false,
      name: '4 и более месяцев',
      type: 'prepayment',
    },
    {
      i: 3120,
      checked: false,
      name: 'Включены',
      type: 'publicUtilities',
    },
    {
      i: 3121,
      checked: false,
      name: 'Не включены',
      type: 'publicUtilities',
    },
    {
      i: 3122,
      checked: false,
      name: 'Арендный бизнес',
      type: 'typeBuilding',
    },
    {
      i: 3123,
      checked: false,
      name: 'Особняк',
      type: 'typeBuilding',
    },
    {
      i: 3124,
      checked: false,
      name: 'Офисное помещение',
      type: 'typeBuilding',
    },
    {
      i: 3125,
      checked: false,
      name: 'Помещение свободного назначения',
      type: 'typeBuilding',
    },
    {
      i: 3126,
      checked: false,
      name: 'Производство',
      type: 'typeBuilding',
    },
    {
      i: 3127,
      checked: false,
      name: 'Склад',
      type: 'typeBuilding',
    },
    {
      i: 3128,
      checked: false,
      name: 'Торговое помещение',
      type: 'typeBuilding',
    },
    {
      i: 3129,
      checked: false,
      name: 'Другая коммерческая недвижимость',
      type: 'typeBuilding',
    },
    {
      i: 3130,
      checked: false,
      name: 'Аренда',
      type: 'transactionType',
    },
    {
      i: 3131,
      checked: false,
      name: 'Продажа',
      type: 'transactionType',
    },
    {
      i: 3132,
      checked: false,
      name: 'Баня',
      type: 'typeBuildingOther',
    },
    {
      i: 3133,
      checked: false,
      name: 'Сарай',
      type: 'typeBuildingOther',
    },
    {
      i: 3134,
      checked: false,
      name: 'Гараж',
      type: 'typeBuildingOther',
    },
    {
      i: 3135,
      checked: false,
      name: 'Машиноместо',
      type: 'typeBuildingOther',
    },
    {
      i: 3136,
      checked: false,
      name: 'Другое',
      type: 'typeBuildingOther',
    },
    {
      i: 3137,
      checked: false,
      name: 'Акита',
      type: 'breed',
    },
    {
      i: 3138,
      checked: false,
      name: 'Аляскинский маламут',
      type: 'breed',
    },
    {
      i: 3139,
      checked: false,
      name: 'Американский Булли',
      type: 'breed',
    },
    {
      i: 3140,
      checked: false,
      name: 'Американский бульдог',
      type: 'breed',
    },
    {
      i: 3141,
      checked: false,
      name: 'Английский бульдог',
      type: 'breed',
    },
    {
      i: 3142,
      checked: false,
      name: 'Басенджи',
      type: 'breed',
    },
    {
      i: 3143,
      checked: false,
      name: 'Бассет',
      type: 'breed',
    },
    {
      i: 3144,
      checked: false,
      name: 'Бельгийская овчарка',
      type: 'breed',
    },
    {
      i: 3145,
      checked: false,
      name: 'Бельгийский гриффон',
      type: 'breed',
    },
    {
      i: 3146,
      checked: false,
      name: 'Бернский зенненхунд',
      type: 'breed',
    },
    {
      i: 3147,
      checked: false,
      name: 'Бивер',
      type: 'breed',
    },
    {
      i: 3148,
      checked: false,
      name: 'Бигль',
      type: 'breed',
    },
    {
      i: 3149,
      checked: false,
      name: 'Бишон фризе',
      type: 'breed',
    },
    {
      i: 3150,
      checked: false,
      name: 'Бобтейл',
      type: 'breed',
    },
    {
      i: 3151,
      checked: false,
      name: 'Боксер',
      type: 'breed',
    },
    {
      i: 3152,
      checked: false,
      name: 'Болонка',
      type: 'breed',
    },
    {
      i: 3153,
      checked: false,
      name: 'Бостон-терьер',
      type: 'breed',
    },
    {
      i: 3154,
      checked: false,
      name: 'Бриар',
      type: 'breed',
    },
    {
      i: 3155,
      checked: false,
      name: 'Брюссельский гриффон',
      type: 'breed',
    },
    {
      i: 3156,
      checked: false,
      name: 'Бульмастиф',
      type: 'breed',
    },
    {
      i: 3157,
      checked: false,
      name: 'Бультерьер',
      type: 'breed',
    },
    {
      i: 3158,
      checked: false,
      name: 'Бурбуль',
      type: 'breed',
    },
    {
      i: 3159,
      checked: false,
      name: 'Вельш-корги',
      type: 'breed',
    },
    {
      i: 3160,
      checked: false,
      name: 'Вельштерьер',
      type: 'breed',
    },
    {
      i: 3161,
      checked: false,
      name: 'Вест-хайленд-уайт-терьер',
      type: 'breed',
    },
    {
      i: 3162,
      checked: false,
      name: 'Восточноевропейская овчарка',
      type: 'breed',
    },
    {
      i: 3163,
      checked: false,
      name: 'Далматин',
      type: 'breed',
    },
    {
      i: 3164,
      checked: false,
      name: 'Джек Рассел терьер',
      type: 'breed',
    },
    {
      i: 3165,
      checked: false,
      name: 'Доберман',
      type: 'breed',
    },
    {
      i: 3166,
      checked: false,
      name: 'Дог',
      type: 'breed',
    },
    {
      i: 3167,
      checked: false,
      name: 'Ирландский терьер',
      type: 'breed',
    },
    {
      i: 3168,
      checked: false,
      name: 'Йоркширский терьер',
      type: 'breed',
    },
    {
      i: 3169,
      checked: false,
      name: 'Кавказская овчарка',
      type: 'breed',
    },
    {
      i: 3170,
      checked: false,
      name: 'Кане-корсо',
      type: 'breed',
    },
    {
      i: 3171,
      checked: false,
      name: 'Керн-терьер',
      type: 'breed',
    },
    {
      i: 3172,
      checked: false,
      name: 'Китайская хохлатая',
      type: 'breed',
    },
    {
      i: 3173,
      checked: false,
      name: 'Кокер-спаниель',
      type: 'breed',
    },
    {
      i: 3174,
      checked: false,
      name: 'Колли',
      type: 'breed',
    },
    {
      i: 3175,
      checked: false,
      name: 'Ксолоитцкуинтли',
      type: 'breed',
    },
    {
      i: 3176,
      checked: false,
      name: 'Курцхаар',
      type: 'breed',
    },
    {
      i: 3177,
      checked: false,
      name: 'Лабрадор',
      type: 'breed',
    },
    {
      i: 3178,
      checked: false,
      name: 'Лаготто-романьоло',
      type: 'breed',
    },
    {
      i: 3179,
      checked: false,
      name: 'Лайка',
      type: 'breed',
    },
    {
      i: 3180,
      checked: false,
      name: 'Левретка',
      type: 'breed',
    },
    {
      i: 3181,
      checked: false,
      name: 'Леонбергер',
      type: 'breed',
    },
    {
      i: 3182,
      checked: false,
      name: 'Лхаса Апсо',
      type: 'breed',
    },
    {
      i: 3183,
      checked: false,
      name: 'Мастиф',
      type: 'breed',
    },
    {
      i: 3184,
      checked: false,
      name: 'Миттельшнауцер',
      type: 'breed',
    },
    {
      i: 3185,
      checked: false,
      name: 'Мопс',
      type: 'breed',
    },
    {
      i: 3186,
      checked: false,
      name: 'Московская сторожевая',
      type: 'breed',
    },
    {
      i: 3187,
      checked: false,
      name: 'Немецкая овчарка',
      type: 'breed',
    },
    {
      i: 3188,
      checked: false,
      name: 'Норвич-терьер',
      type: 'breed',
    },
    {
      i: 3189,
      checked: false,
      name: 'Ньюфаундленд',
      type: 'breed',
    },
    {
      i: 3190,
      checked: false,
      name: 'Овчарка',
      type: 'breed',
    },
    {
      i: 3191,
      checked: false,
      name: 'Папийон',
      type: 'breed',
    },
    {
      i: 3192,
      checked: false,
      name: 'Пекинес',
      type: 'breed',
    },
    {
      i: 3193,
      checked: false,
      name: 'Петербургская орхидея',
      type: 'breed',
    },
    {
      i: 3194,
      checked: false,
      name: 'Питбуль',
      type: 'breed',
    },
    {
      i: 3195,
      checked: false,
      name: 'Пойнтер',
      type: 'breed',
    },
    {
      i: 3196,
      checked: false,
      name: 'Пти-брабансон',
      type: 'breed',
    },
    {
      i: 3197,
      checked: false,
      name: 'Пудель',
      type: 'breed',
    },
    {
      i: 3198,
      checked: false,
      name: 'Ретривер',
      type: 'breed',
    },
    {
      i: 3199,
      checked: false,
      name: 'Ризеншнауцер',
      type: 'breed',
    },
    {
      i: 3200,
      checked: false,
      name: 'Родезийский риджбек',
      type: 'breed',
    },
    {
      i: 3201,
      checked: false,
      name: 'Ротвейлер',
      type: 'breed',
    },
    {
      i: 3202,
      checked: false,
      name: 'Русская борзая',
      type: 'breed',
    },
    {
      i: 3203,
      checked: false,
      name: 'Русский чёрный терьер',
      type: 'breed',
    },
    {
      i: 3204,
      checked: false,
      name: 'Самоедская собака',
      type: 'breed',
    },
    {
      i: 3205,
      checked: false,
      name: 'Сенбернар',
      type: 'breed',
    },
    {
      i: 3206,
      checked: false,
      name: 'Сеттер',
      type: 'breed',
    },
    {
      i: 3207,
      checked: false,
      name: 'Сибирский хаски',
      type: 'breed',
    },
    {
      i: 3208,
      checked: false,
      name: 'Скотч-терьер',
      type: 'breed',
    },
    {
      i: 3209,
      checked: false,
      name: 'Спаниель',
      type: 'breed',
    },
    {
      i: 3210,
      checked: false,
      name: 'Среднеазиатская овчарка',
      type: 'breed',
    },
    {
      i: 3211,
      checked: false,
      name: 'Стаффордширский терьер',
      type: 'breed',
    },
    {
      i: 3212,
      checked: false,
      name: 'Такса',
      type: 'breed',
    },
    {
      i: 3213,
      checked: false,
      name: 'Той-пудель',
      type: 'breed',
    },
    {
      i: 3214,
      checked: false,
      name: 'Той-терьер',
      type: 'breed',
    },
    {
      i: 3215,
      checked: false,
      name: 'Фландрский бувье',
      type: 'breed',
    },
    {
      i: 3216,
      checked: false,
      name: 'Фокстерьер',
      type: 'breed',
    },
    {
      i: 3217,
      checked: false,
      name: 'Французская овчарка',
      type: 'breed',
    },
    {
      i: 3218,
      checked: false,
      name: 'Французский бульдог',
      type: 'breed',
    },
    {
      i: 3219,
      checked: false,
      name: 'Цвергпинчер',
      type: 'breed',
    },
    {
      i: 3220,
      checked: false,
      name: 'Цвергшнауцер',
      type: 'breed',
    },
    {
      i: 3221,
      checked: false,
      name: 'Чау-чау',
      type: 'breed',
    },
    {
      i: 3222,
      checked: false,
      name: 'Чихуахуа',
      type: 'breed',
    },
    {
      i: 3223,
      checked: false,
      name: 'Шарпей',
      type: 'breed',
    },
    {
      i: 3224,
      checked: false,
      name: 'Швейцарская овчарка',
      type: 'breed',
    },
    {
      i: 3225,
      checked: false,
      name: 'Шелти',
      type: 'breed',
    },
    {
      i: 3226,
      checked: false,
      name: 'Ши-тцу',
      type: 'breed',
    },
    {
      i: 3227,
      checked: false,
      name: 'Шпиц',
      type: 'breed',
    },
    {
      i: 3228,
      checked: false,
      name: 'Эрдельтерьер',
      type: 'breed',
    },
    {
      i: 3229,
      checked: false,
      name: 'Ягдтерьер',
      type: 'breed',
    },
    {
      i: 3230,
      checked: false,
      name: 'Японский хин',
      type: 'breed',
    },
    {
      i: 3231,
      checked: false,
      name: 'Другая',
      type: 'breed',
    },
    {
      i: 3232,
      checked: false,
      name: 'Частное лицо',
      type: 'typeProductAn',
    },
    {
      i: 3233,
      checked: false,
      name: 'Заводчик',
      type: 'typeProductAn',
    },
    {
      i: 3234,
      checked: false,
      name: 'Абиссинская',
      type: 'breedCat',
    },
    {
      i: 3235,
      checked: false,
      name: 'Американская короткошерстная',
      type: 'breedCat',
    },
    {
      i: 3236,
      checked: false,
      name: 'Американский кёрл',
      type: 'breedCat',
    },
    {
      i: 3237,
      checked: false,
      name: 'Балинез',
      type: 'breedCat',
    },
    {
      i: 3238,
      checked: false,
      name: 'Бенгальская',
      type: 'breedCat',
    },
    {
      i: 3239,
      checked: false,
      name: 'Бирманская',
      type: 'breedCat',
    },
    {
      i: 3240,
      checked: false,
      name: 'Британская',
      type: 'breedCat',
    },
    {
      i: 3241,
      checked: false,
      name: 'Бурманская',
      type: 'breedCat',
    },
    {
      i: 3242,
      checked: false,
      name: 'Девон-рекс',
      type: 'breedCat',
    },
    {
      i: 3243,
      checked: false,
      name: 'Донской сфинкс',
      type: 'breedCat',
    },
    {
      i: 3244,
      checked: false,
      name: 'Европейская',
      type: 'breedCat',
    },
    {
      i: 3245,
      checked: false,
      name: 'Канадский сфинкс',
      type: 'breedCat',
    },
    {
      i: 3246,
      checked: false,
      name: 'Корниш-рекс',
      type: 'breedCat',
    },
    {
      i: 3247,
      checked: false,
      name: 'Курильский бобтейл',
      type: 'breedCat',
    },
    {
      i: 3248,
      checked: false,
      name: 'Лаперм',
      type: 'breedCat',
    },
    {
      i: 3249,
      checked: false,
      name: 'Лаперм',
      type: 'breedCat',
    },
    {
      i: 3250,
      checked: false,
      name: 'Манчкин',
      type: 'breedCat',
    },
    {
      i: 3251,
      checked: false,
      name: 'Мейн-кун',
      type: 'breedCat',
    },
    {
      i: 3252,
      checked: false,
      name: 'Меконгский бобтейл',
      type: 'breedCat',
    },
    {
      i: 3253,
      checked: false,
      name: 'Невская маскарадная',
      type: 'breedCat',
    },
    {
      i: 3254,
      checked: false,
      name: 'Норвежская лесная',
      type: 'breedCat',
    },
    {
      i: 3255,
      checked: false,
      name: 'Ориентальная',
      type: 'breedCat',
    },
    {
      i: 3256,
      checked: false,
      name: 'Оцикет',
      type: 'breedCat',
    },
    {
      i: 3257,
      checked: false,
      name: 'Персидская',
      type: 'breedCat',
    },
    {
      i: 3258,
      checked: false,
      name: 'Петерболд',
      type: 'breedCat',
    },
    {
      i: 3259,
      checked: false,
      name: 'Русская голубая',
      type: 'breedCat',
    },
    {
      i: 3260,
      checked: false,
      name: 'Селкирк-рекс',
      type: 'breedCat',
    },
    {
      i: 3261,
      checked: false,
      name: 'Сиамская',
      type: 'breedCat',
    },
    {
      i: 3262,
      checked: false,
      name: 'Сибирская',
      type: 'breedCat',
    },
    {
      i: 3263,
      checked: false,
      name: 'Сингапурская',
      type: 'breedCat',
    },
    {
      i: 3264,
      checked: false,
      name: 'Сомалийская',
      type: 'breedCat',
    },
    {
      i: 3265,
      checked: false,
      name: 'Тайская',
      type: 'breedCat',
    },
    {
      i: 3266,
      checked: false,
      name: 'Тонкинез',
      type: 'breedCat',
    },
    {
      i: 3267,
      checked: false,
      name: 'Турецкая ангора',
      type: 'breedCat',
    },
    {
      i: 3268,
      checked: false,
      name: 'Уральский рекс',
      type: 'breedCat',
    },
    {
      i: 3269,
      checked: false,
      name: 'Шотландская',
      type: 'breedCat',
    },
    {
      i: 3270,
      checked: false,
      name: 'Экзотическая',
      type: 'breedCat',
    },
    {
      i: 3271,
      checked: false,
      name: 'Японский бобтейл',
      type: 'breedCat',
    },
    {
      i: 3272,
      checked: false,
      name: 'Другая',
      type: 'breedCat',
    },
    {
      i: 3273,
      checked: false,
      name: 'Голуби',
      type: 'birds',
    },
    {
      i: 3274,
      checked: false,
      name: 'Певчие',
      type: 'birds',
    },
    {
      i: 3275,
      checked: false,
      name: 'Попугаи',
      type: 'birds',
    },
    {
      i: 3276,
      checked: false,
      name: 'С/х птицы',
      type: 'birds',
    },
    {
      i: 3277,
      checked: false,
      name: 'Кролики',
      type: 'rodents',
    },
    {
      i: 3278,
      checked: false,
      name: 'Морские свинки',
      type: 'rodents',
    },
    {
      i: 3279,
      checked: false,
      name: 'Мыши и крысы',
      type: 'rodents',
    },
    {
      i: 3280,
      checked: false,
      name: 'Хомяки',
      type: 'rodents',
    },
    {
      i: 3281,
      checked: false,
      name: 'Хорьки',
      type: 'rodents',
    },
    {
      i: 3282,
      checked: false,
      name: 'Шиншиллы',
      type: 'rodents',
    },
    {
      i: 3283,
      checked: false,
      name: 'Другой',
      type: 'rodents',
    },
    {
      i: 3284,
      checked: false,
      name: 'Козы',
      type: 'farmAnimals',
    },
    {
      i: 3285,
      checked: false,
      name: 'Коровы и быки',
      type: 'farmAnimals',
    },
    {
      i: 3286,
      checked: false,
      name: 'Овцы и бараны',
      type: 'farmAnimals',
    },
    {
      i: 3287,
      checked: false,
      name: 'Лошади',
      type: 'farmAnimals',
    },
    {
      i: 3288,
      checked: false,
      name: 'Свиньи',
      type: 'farmAnimals',
    },
    {
      i: 3289,
      checked: false,
      name: 'Пауки и насекомые',
      type: 'otherAnimals',
    },
    {
      i: 3290,
      checked: false,
      name: 'Улитки',
      type: 'otherAnimals',
    },
    {
      i: 3291,
      checked: false,
      name: 'Черепахи и рептилии',
      type: 'otherAnimals',
    },
    {
      i: 3292,
      checked: false,
      name: 'Собаки',
      type: 'animals',
    },
    {
      i: 3293,
      checked: false,
      name: 'Кошки',
      type: 'animals',
    },
    {
      i: 3294,
      checked: false,
      name: 'Птицы',
      type: 'animals',
    },
    {
      i: 3295,
      checked: false,
      name: 'Грызуны',
      type: 'animals',
    },
    {
      i: 3296,
      checked: false,
      name: 'Рыбки',
      type: 'animals',
    },
    {
      i: 3297,
      checked: false,
      name: 'Лошади',
      type: 'animals',
    },
    {
      i: 3298,
      checked: false,
      name: 'Рептилии',
      type: 'animals',
    },
    {
      i: 3299,
      checked: false,
      name: 'С/х животные',
      type: 'animals',
    },
    {
      i: 3300,
      checked: false,
      name: 'Будки и вольеры',
      type: 'typeProducts',
    },
    {
      i: 3301,
      checked: false,
      name: 'Витамины и добавки',
      type: 'typeProducts',
    },
    {
      i: 3302,
      checked: false,
      name: 'Игрушки',
      type: 'typeProducts',
    },
    {
      i: 3303,
      checked: false,
      name: 'Инструменты для ухода',
      type: 'typeProducts',
    },
    {
      i: 3304,
      checked: false,
      name: 'Клетки и переноски',
      type: 'typeProducts',
    },
    {
      i: 3305,
      checked: false,
      name: 'Корма',
      type: 'typeProducts',
    },
    {
      i: 3306,
      checked: false,
      name: 'Косметика и гигиена',
      type: 'typeProducts',
    },
    {
      i: 3307,
      checked: false,
      name: 'Лежаки и домики',
      type: 'typeProducts',
    },
    {
      i: 3308,
      checked: false,
      name: 'Миски, кормушки, поилки',
      type: 'typeProducts',
    },
    {
      i: 3309,
      checked: false,
      name: 'Намордники',
      type: 'typeProducts',
    },
    {
      i: 3310,
      checked: false,
      name: 'Одежда и обувь',
      type: 'typeProducts',
    },
    {
      i: 3311,
      checked: false,
      name: 'От блох и клещей',
      type: 'typeProducts',
    },
    {
      i: 3312,
      checked: false,
      name: 'Ошейники и поводки',
      type: 'typeProducts',
    },
    {
      i: 3313,
      checked: false,
      name: 'Туалет и наполнители',
      type: 'typeProducts',
    },
    {
      i: 3314,
      checked: false,
      name: 'Аквариумы',
      type: 'aquarium',
    },
    {
      i: 3315,
      checked: false,
      name: 'Декор и растения',
      type: 'aquarium',
    },
    {
      i: 3316,
      checked: false,
      name: 'Инвентарь для обслуживания',
      type: 'aquarium',
    },
    {
      i: 3317,
      checked: false,
      name: 'Обогрев',
      type: 'aquarium',
    },
    {
      i: 3318,
      checked: false,
      name: 'Освещение',
      type: 'aquarium',
    },
    {
      i: 3319,
      checked: false,
      name: 'Террариумы',
      type: 'aquarium',
    },
    {
      i: 3320,
      checked: false,
      name: 'Фильтры и аэрация',
      type: 'aquarium',
    },
    {
      i: 3321,
      checked: false,
      name: 'AITO',
      type: 'brandCar',
    },
    {
      i: 3322,
      checked: false,
      name: 'Acura',
      type: 'brandCar',
    },
    {
      i: 3323,
      checked: false,
      name: 'Aiways',
      type: 'brandCar',
    },
    {
      i: 3324,
      checked: false,
      name: 'Alfa Romeo',
      type: 'brandCar',
    },
    {
      i: 3325,
      checked: false,
      name: 'Arcfox',
      type: 'brandCar',
    },
    {
      i: 3326,
      checked: false,
      name: 'Arctic Cat',
      type: 'brandCar',
    },
    {
      i: 3327,
      checked: false,
      name: 'Aro',
      type: 'brandCar',
    },
    {
      i: 3328,
      checked: false,
      name: 'Asia',
      type: 'brandCar',
    },
    {
      i: 3329,
      checked: false,
      name: 'Aston Martin',
      type: 'brandCar',
    },
    {
      i: 3330,
      checked: false,
      name: 'Audi',
      type: 'brandCar',
    },
    {
      i: 3331,
      checked: false,
      name: 'Aurus',
      type: 'brandCar',
    },
    {
      i: 3332,
      checked: false,
      name: 'Austin (AMC)',
      type: 'brandCar',
    },
    {
      i: 3333,
      checked: false,
      name: 'Avatr',
      type: 'brandCar',
    },
    {
      i: 3334,
      checked: false,
      name: 'BAIC',
      type: 'brandCar',
    },
    {
      i: 3335,
      checked: false,
      name: 'BAJAJ',
      type: 'brandCar',
    },
    {
      i: 3336,
      checked: false,
      name: 'BAW',
      type: 'brandCar',
    },
    {
      i: 3337,
      checked: false,
      name: 'BMW',
      type: 'brandCar',
    },
    {
      i: 3338,
      checked: false,
      name: 'BRP',
      type: 'brandCar',
    },
    {
      i: 3339,
      checked: false,
      name: 'BYD',
      type: 'brandCar',
    },
    {
      i: 3340,
      checked: false,
      name: 'Baojun',
      type: 'brandCar',
    },
    {
      i: 3341,
      checked: false,
      name: 'Belgee',
      type: 'brandCar',
    },
    {
      i: 3342,
      checked: false,
      name: 'Bentley',
      type: 'brandCar',
    },
    {
      i: 3343,
      checked: false,
      name: 'Brilliance',
      type: 'brandCar',
    },
    {
      i: 3344,
      checked: false,
      name: 'Bugatti',
      type: 'brandCar',
    },
    {
      i: 3345,
      checked: false,
      name: 'Buick',
      type: 'brandCar',
    },
    {
      i: 3346,
      checked: false,
      name: 'CFMOTO',
      type: 'brandCar',
    },
    {
      i: 3347,
      checked: false,
      name: 'Cadillac',
      type: 'brandCar',
    },
    {
      i: 3348,
      checked: false,
      name: 'ChangFeng',
      type: 'brandCar',
    },
    {
      i: 3349,
      checked: false,
      name: 'Changan',
      type: 'brandCar',
    },
    {
      i: 3350,
      checked: false,
      name: 'Changhe',
      type: 'brandCar',
    },
    {
      i: 3351,
      checked: false,
      name: 'Chery',
      type: 'brandCar',
    },
    {
      i: 3352,
      checked: false,
      name: 'Chevrolet',
      type: 'brandCar',
    },
    {
      i: 3353,
      checked: false,
      name: 'Chrysler',
      type: 'brandCar',
    },
    {
      i: 3354,
      checked: false,
      name: 'Citroen',
      type: 'brandCar',
    },
    {
      i: 3355,
      checked: false,
      name: 'Cupra',
      type: 'brandCar',
    },
    {
      i: 3356,
      checked: false,
      name: 'DS',
      type: 'brandCar',
    },
    {
      i: 3357,
      checked: false,
      name: 'Dacia',
      type: 'brandCar',
    },
    {
      i: 3358,
      checked: false,
      name: 'Dadi',
      type: 'brandCar',
    },
    {
      i: 3359,
      checked: false,
      name: 'Daewoo',
      type: 'brandCar',
    },
    {
      i: 3360,
      checked: false,
      name: 'Daihatsu',
      type: 'brandCar',
    },
    {
      i: 3361,
      checked: false,
      name: 'Daimler',
      type: 'brandCar',
    },
    {
      i: 3362,
      checked: false,
      name: 'Datsun',
      type: 'brandCar',
    },
    {
      i: 3363,
      checked: false,
      name: 'Dayun',
      type: 'brandCar',
    },
    {
      i: 3364,
      checked: false,
      name: 'De Tomaso',
      type: 'brandCar',
    },
    {
      i: 3365,
      checked: false,
      name: 'DeLorean',
      type: 'brandCar',
    },
    {
      i: 3366,
      checked: false,
      name: 'Denza',
      type: 'brandCar',
    },
    {
      i: 3367,
      checked: false,
      name: 'Derways',
      type: 'brandCar',
    },
    {
      i: 3368,
      checked: false,
      name: 'Dodge',
      type: 'brandCar',
    },
    {
      i: 3369,
      checked: false,
      name: 'DongFeng',
      type: 'brandCar',
    },
    {
      i: 3370,
      checked: false,
      name: 'Doninvest',
      type: 'brandCar',
    },
    {
      i: 3371,
      checked: false,
      name: 'Ducati',
      type: 'brandCar',
    },
    {
      i: 3372,
      checked: false,
      name: 'EXEED',
      type: 'brandCar',
    },
    {
      i: 3373,
      checked: false,
      name: 'Eagle',
      type: 'brandCar',
    },
    {
      i: 3374,
      checked: false,
      name: 'Enovate',
      type: 'brandCar',
    },
    {
      i: 3375,
      checked: false,
      name: 'Evolute',
      type: 'brandCar',
    },
    {
      i: 3376,
      checked: false,
      name: 'FAW',
      type: 'brandCar',
    },
    {
      i: 3377,
      checked: false,
      name: 'FSO',
      type: 'brandCar',
    },
    {
      i: 3378,
      checked: false,
      name: 'Fang Cheng Bao',
      type: 'brandCar',
    },
    {
      i: 3379,
      checked: false,
      name: 'Ferrari',
      type: 'brandCar',
    },
    {
      i: 3380,
      checked: false,
      name: 'Fiat',
      type: 'brandCar',
    },
    {
      i: 3381,
      checked: false,
      name: 'Fisker',
      type: 'brandCar',
    },
    {
      i: 3382,
      checked: false,
      name: 'Ford',
      type: 'brandCar',
    },
    {
      i: 3383,
      checked: false,
      name: 'Forthing',
      type: 'brandCar',
    },
    {
      i: 3384,
      checked: false,
      name: 'Foton',
      type: 'brandCar',
    },
    {
      i: 3385,
      checked: false,
      name: 'GAC',
      type: 'brandCar',
    },
    {
      i: 3386,
      checked: false,
      name: 'GMC',
      type: 'brandCar',
    },
    {
      i: 3387,
      checked: false,
      name: 'GasGas',
      type: 'brandCar',
    },
    {
      i: 3388,
      checked: false,
      name: 'Geely',
      type: 'brandCar',
    },
    {
      i: 3389,
      checked: false,
      name: 'Genesis',
      type: 'brandCar',
    },
    {
      i: 3390,
      checked: false,
      name: 'Geo',
      type: 'brandCar',
    },
    {
      i: 3391,
      checked: false,
      name: 'Great Wall',
      type: 'brandCar',
    },
    {
      i: 3392,
      checked: false,
      name: 'Hafei',
      type: 'brandCar',
    },
    {
      i: 3393,
      checked: false,
      name: 'Haima',
      type: 'brandCar',
    },
    {
      i: 3394,
      checked: false,
      name: 'Hanteng',
      type: 'brandCar',
    },
    {
      i: 3395,
      checked: false,
      name: 'Harley-Davidson',
      type: 'brandCar',
    },
    {
      i: 3396,
      checked: false,
      name: 'Haval',
      type: 'brandCar',
    },
    {
      i: 3397,
      checked: false,
      name: 'Hawtai',
      type: 'brandCar',
    },
    {
      i: 3398,
      checked: false,
      name: 'HiPhi',
      type: 'brandCar',
    },
    {
      i: 3399,
      checked: false,
      name: 'Holden',
      type: 'brandCar',
    },
    {
      i: 3400,
      checked: false,
      name: 'Honda',
      type: 'brandCar',
    },
    {
      i: 3401,
      checked: false,
      name: 'Hongqi',
      type: 'brandCar',
    },
    {
      i: 3402,
      checked: false,
      name: 'HuangHai',
      type: 'brandCar',
    },
    {
      i: 3403,
      checked: false,
      name: 'Hummer',
      type: 'brandCar',
    },
    {
      i: 3404,
      checked: false,
      name: 'Husaberg',
      type: 'brandCar',
    },
    {
      i: 3405,
      checked: false,
      name: 'Husqvarna',
      type: 'brandCar',
    },
    {
      i: 3406,
      checked: false,
      name: 'Hyosung',
      type: 'brandCar',
    },
    {
      i: 3407,
      checked: false,
      name: 'Hyundai',
      type: 'brandCar',
    },
    {
      i: 3408,
      checked: false,
      name: 'Indian',
      type: 'brandCar',
    },
    {
      i: 3409,
      checked: false,
      name: 'Infiniti',
      type: 'brandCar',
    },
    {
      i: 3410,
      checked: false,
      name: 'Iran Khodro',
      type: 'brandCar',
    },
    {
      i: 3411,
      checked: false,
      name: 'Isuzu',
      type: 'brandCar',
    },
    {
      i: 3412,
      checked: false,
      name: 'JAC',
      type: 'brandCar',
    },
    {
      i: 3413,
      checked: false,
      name: 'JAECOO',
      type: 'brandCar',
    },
    {
      i: 3414,
      checked: false,
      name: 'JMC',
      type: 'brandCar',
    },
    {
      i: 3415,
      checked: false,
      name: 'Jaguar',
      type: 'brandCar',
    },
    {
      i: 3416,
      checked: false,
      name: 'Jawa',
      type: 'brandCar',
    },
    {
      i: 3417,
      checked: false,
      name: 'Jeep',
      type: 'brandCar',
    },
    {
      i: 3418,
      checked: false,
      name: 'Jetour',
      type: 'brandCar',
    },
    {
      i: 3419,
      checked: false,
      name: 'Jetta',
      type: 'brandCar',
    },
    {
      i: 3420,
      checked: false,
      name: 'Jinbei',
      type: 'brandCar',
    },
    {
      i: 3421,
      checked: false,
      name: 'KAYO',
      type: 'brandCar',
    },
    {
      i: 3422,
      checked: false,
      name: 'KTM',
      type: 'brandCar',
    },
    {
      i: 3423,
      checked: false,
      name: 'KYC',
      type: 'brandCar',
    },
    {
      i: 3424,
      checked: false,
      name: 'Kaiyi',
      type: 'brandCar',
    },
    {
      i: 3425,
      checked: false,
      name: 'Kawasaki',
      type: 'brandCar',
    },
    {
      i: 3426,
      checked: false,
      name: 'Kia',
      type: 'brandCar',
    },
    {
      i: 3427,
      checked: false,
      name: 'Koenigsegg',
      type: 'brandCar',
    },
    {
      i: 3428,
      checked: false,
      name: 'Kymco',
      type: 'brandCar',
    },
    {
      i: 3429,
      checked: false,
      name: 'LTI',
      type: 'brandCar',
    },
    {
      i: 3430,
      checked: false,
      name: 'Lamborghini',
      type: 'brandCar',
    },
    {
      i: 3431,
      checked: false,
      name: 'Lancia',
      type: 'brandCar',
    },
    {
      i: 3432,
      checked: false,
      name: 'Land Rover',
      type: 'brandCar',
    },
    {
      i: 3433,
      checked: false,
      name: 'Landwind',
      type: 'brandCar',
    },
    {
      i: 3434,
      checked: false,
      name: 'Leapmotor',
      type: 'brandCar',
    },
    {
      i: 3435,
      checked: false,
      name: 'Lexus',
      type: 'brandCar',
    },
    {
      i: 3436,
      checked: false,
      name: 'LiXiang',
      type: 'brandCar',
    },
    {
      i: 3437,
      checked: false,
      name: 'Lifan',
      type: 'brandCar',
    },
    {
      i: 3438,
      checked: false,
      name: 'Lincoln',
      type: 'brandCar',
    },
    {
      i: 3439,
      checked: false,
      name: 'Livan',
      type: 'brandCar',
    },
    {
      i: 3440,
      checked: false,
      name: 'Lotus',
      type: 'brandCar',
    },
    {
      i: 3441,
      checked: false,
      name: 'Luxeed',
      type: 'brandCar',
    },
    {
      i: 3442,
      checked: false,
      name: 'Luxgen',
      type: 'brandCar',
    },
    {
      i: 3443,
      checked: false,
      name: 'Lynk & Co',
      type: 'brandCar',
    },
    {
      i: 3444,
      checked: false,
      name: 'MG',
      type: 'brandCar',
    },
    {
      i: 3445,
      checked: false,
      name: 'MV Agusta',
      type: 'brandCar',
    },
    {
      i: 3446,
      checked: false,
      name: 'Mahindra',
      type: 'brandCar',
    },
    {
      i: 3447,
      checked: false,
      name: 'Maruti',
      type: 'brandCar',
    },
    {
      i: 3448,
      checked: false,
      name: 'Maserati',
      type: 'brandCar',
    },
    {
      i: 3449,
      checked: false,
      name: 'Maxus',
      type: 'brandCar',
    },
    {
      i: 3450,
      checked: false,
      name: 'Maybach',
      type: 'brandCar',
    },
    {
      i: 3451,
      checked: false,
      name: 'Mazda',
      type: 'brandCar',
    },
    {
      i: 3452,
      checked: false,
      name: 'McLaren',
      type: 'brandCar',
    },
    {
      i: 3453,
      checked: false,
      name: 'Mengshi',
      type: 'brandCar',
    },
    {
      i: 3454,
      checked: false,
      name: 'Mercedes-Benz',
      type: 'brandCar',
    },
    {
      i: 3455,
      checked: false,
      name: 'Mercury',
      type: 'brandCar',
    },
    {
      i: 3456,
      checked: false,
      name: 'Metrocab',
      type: 'brandCar',
    },
    {
      i: 3457,
      checked: false,
      name: 'Mini',
      type: 'brandCar',
    },
    {
      i: 3458,
      checked: false,
      name: 'Mitsubishi',
      type: 'brandCar',
    },
    {
      i: 3459,
      checked: false,
      name: 'Mitsuoka',
      type: 'brandCar',
    },
    {
      i: 3460,
      checked: false,
      name: 'Morgan',
      type: 'brandCar',
    },
    {
      i: 3461,
      checked: false,
      name: 'Moto Guzzi',
      type: 'brandCar',
    },
    {
      i: 3462,
      checked: false,
      name: 'NIO',
      type: 'brandCar',
    },
    {
      i: 3463,
      checked: false,
      name: 'Neta',
      type: 'brandCar',
    },
    {
      i: 3464,
      checked: false,
      name: 'Nexus',
      type: 'brandCar',
    },
    {
      i: 3465,
      checked: false,
      name: 'Nissan',
      type: 'brandCar',
    },
    {
      i: 3466,
      checked: false,
      name: 'OMODA',
      type: 'brandCar',
    },
    {
      i: 3467,
      checked: false,
      name: 'Oldsmobile',
      type: 'brandCar',
    },
    {
      i: 3468,
      checked: false,
      name: 'Opel',
      type: 'brandCar',
    },
    {
      i: 3469,
      checked: false,
      name: 'Ora',
      type: 'brandCar',
    },
    {
      i: 3470,
      checked: false,
      name: 'Pagani',
      type: 'brandCar',
    },
    {
      i: 3471,
      checked: false,
      name: 'Peugeot',
      type: 'brandCar',
    },
    {
      i: 3472,
      checked: false,
      name: 'Piaggio',
      type: 'brandCar',
    },
    {
      i: 3473,
      checked: false,
      name: 'Plymouth',
      type: 'brandCar',
    },
    {
      i: 3474,
      checked: false,
      name: 'Polar Stone (Jishi)',
      type: 'brandCar',
    },
    {
      i: 3475,
      checked: false,
      name: 'Polaris',
      type: 'brandCar',
    },
    {
      i: 3476,
      checked: false,
      name: 'Polestar',
      type: 'brandCar',
    },
    {
      i: 3477,
      checked: false,
      name: 'Pontiac',
      type: 'brandCar',
    },
    {
      i: 3478,
      checked: false,
      name: 'Porsche',
      type: 'brandCar',
    },
    {
      i: 3479,
      checked: false,
      name: 'Proton',
      type: 'brandCar',
    },
    {
      i: 3480,
      checked: false,
      name: 'RAM',
      type: 'brandCar',
    },
    {
      i: 3481,
      checked: false,
      name: 'Ravon',
      type: 'brandCar',
    },
    {
      i: 3482,
      checked: false,
      name: 'Renault',
      type: 'brandCar',
    },
    {
      i: 3483,
      checked: false,
      name: 'Rising Auto',
      type: 'brandCar',
    },
    {
      i: 3484,
      checked: false,
      name: 'Rivian',
      type: 'brandCar',
    },
    {
      i: 3485,
      checked: false,
      name: 'Roewe',
      type: 'brandCar',
    },
    {
      i: 3486,
      checked: false,
      name: 'Rolls-Royce',
      type: 'brandCar',
    },
    {
      i: 3487,
      checked: false,
      name: 'Rover',
      type: 'brandCar',
    },
    {
      i: 3488,
      checked: false,
      name: 'Royal Enfield',
      type: 'brandCar',
    },
    {
      i: 3489,
      checked: false,
      name: 'SEAT',
      type: 'brandCar',
    },
    {
      i: 3490,
      checked: false,
      name: 'SMA',
      type: 'brandCar',
    },
    {
      i: 3491,
      checked: false,
      name: 'SWM',
      type: 'brandCar',
    },
    {
      i: 3492,
      checked: false,
      name: 'SYM',
      type: 'brandCar',
    },
    {
      i: 3493,
      checked: false,
      name: 'Saab',
      type: 'brandCar',
    },
    {
      i: 3494,
      checked: false,
      name: 'Saleen',
      type: 'brandCar',
    },
    {
      i: 3495,
      checked: false,
      name: 'Samsung',
      type: 'brandCar',
    },
    {
      i: 3496,
      checked: false,
      name: 'Santana',
      type: 'brandCar',
    },
    {
      i: 3497,
      checked: false,
      name: 'Saturn',
      type: 'brandCar',
    },
    {
      i: 3498,
      checked: false,
      name: 'Scion',
      type: 'brandCar',
    },
    {
      i: 3499,
      checked: false,
      name: 'Seres',
      type: 'brandCar',
    },
    {
      i: 3500,
      checked: false,
      name: 'Shifeng',
      type: 'brandCar',
    },
    {
      i: 3501,
      checked: false,
      name: 'ShuangHuan',
      type: 'brandCar',
    },
    {
      i: 3502,
      checked: false,
      name: 'Skoda',
      type: 'brandCar',
    },
    {
      i: 3503,
      checked: false,
      name: 'Skywell',
      type: 'brandCar',
    },
    {
      i: 3504,
      checked: false,
      name: 'Smart',
      type: 'brandCar',
    },
    {
      i: 3505,
      checked: false,
      name: 'Solaris',
      type: 'brandCar',
    },
    {
      i: 3506,
      checked: false,
      name: 'Sollers',
      type: 'brandCar',
    },
    {
      i: 3507,
      checked: false,
      name: 'Soueast',
      type: 'brandCar',
    },
    {
      i: 3508,
      checked: false,
      name: 'Spyker',
      type: 'brandCar',
    },
    {
      i: 3509,
      checked: false,
      name: 'SsangYong',
      type: 'brandCar',
    },
    {
      i: 3510,
      checked: false,
      name: 'Subaru',
      type: 'brandCar',
    },
    {
      i: 3511,
      checked: false,
      name: 'Suzuki',
      type: 'brandCar',
    },
    {
      i: 3512,
      checked: false,
      name: 'Talbot',
      type: 'brandCar',
    },
    {
      i: 3513,
      checked: false,
      name: 'Tank',
      type: 'brandCar',
    },
    {
      i: 3514,
      checked: false,
      name: 'Tata',
      type: 'brandCar',
    },
    {
      i: 3515,
      checked: false,
      name: 'Tatra',
      type: 'brandCar',
    },
    {
      i: 3516,
      checked: false,
      name: 'Tesla',
      type: 'brandCar',
    },
    {
      i: 3517,
      checked: false,
      name: 'Tianma',
      type: 'brandCar',
    },
    {
      i: 3518,
      checked: false,
      name: 'Tianye',
      type: 'brandCar',
    },
    {
      i: 3519,
      checked: false,
      name: 'Tofas',
      type: 'brandCar',
    },
    {
      i: 3520,
      checked: false,
      name: 'Toyota',
      type: 'brandCar',
    },
    {
      i: 3521,
      checked: false,
      name: 'Trabant',
      type: 'brandCar',
    },
    {
      i: 3522,
      checked: false,
      name: 'Triumph',
      type: 'brandCar',
    },
    {
      i: 3523,
      checked: false,
      name: 'Trumpchi',
      type: 'brandCar',
    },
    {
      i: 3524,
      checked: false,
      name: 'VGV',
      type: 'brandCar',
    },
    {
      i: 3525,
      checked: false,
      name: 'Vauxhall',
      type: 'brandCar',
    },
    {
      i: 3526,
      checked: false,
      name: 'Venucia',
      type: 'brandCar',
    },
    {
      i: 3527,
      checked: false,
      name: 'Vespa',
      type: 'brandCar',
    },
    {
      i: 3528,
      checked: false,
      name: 'Victory',
      type: 'brandCar',
    },
    {
      i: 3529,
      checked: false,
      name: 'Volkswagen',
      type: 'brandCar',
    },
    {
      i: 3530,
      checked: false,
      name: 'Volvo',
      type: 'brandCar',
    },
    {
      i: 3531,
      checked: false,
      name: 'Vortex',
      type: 'brandCar',
    },
    {
      i: 3532,
      checked: false,
      name: 'Voyah',
      type: 'brandCar',
    },
    {
      i: 3533,
      checked: false,
      name: 'Wartburg',
      type: 'brandCar',
    },
    {
      i: 3534,
      checked: false,
      name: 'Weltmeister',
      type: 'brandCar',
    },
    {
      i: 3535,
      checked: false,
      name: 'Willys',
      type: 'brandCar',
    },
    {
      i: 3536,
      checked: false,
      name: 'Wuling',
      type: 'brandCar',
    },
    {
      i: 3537,
      checked: false,
      name: 'Xcite',
      type: 'brandCar',
    },
    {
      i: 3538,
      checked: false,
      name: 'Xiaomi',
      type: 'brandCar',
    },
    {
      i: 3539,
      checked: false,
      name: 'Xin Kai',
      type: 'brandCar',
    },
    {
      i: 3540,
      checked: false,
      name: 'Yamaha',
      type: 'brandCar',
    },
    {
      i: 3541,
      checked: false,
      name: 'ZX',
      type: 'brandCar',
    },
    {
      i: 3542,
      checked: false,
      name: 'Zeekr',
      type: 'brandCar',
    },
    {
      i: 3543,
      checked: false,
      name: 'Zotye',
      type: 'brandCar',
    },
    {
      i: 3544,
      checked: false,
      name: 'ВАЗ (Lada)',
      type: 'brandCar',
    },
    {
      i: 3545,
      checked: false,
      name: 'ГАЗ',
      type: 'brandCar',
    },
    {
      i: 3546,
      checked: false,
      name: 'ЗАЗ',
      type: 'brandCar',
    },
    {
      i: 3547,
      checked: false,
      name: 'ЗИЛ',
      type: 'brandCar',
    },
    {
      i: 3548,
      checked: false,
      name: 'ИЖ',
      type: 'brandCar',
    },
    {
      i: 3549,
      checked: false,
      name: 'ЛуАЗ',
      type: 'brandCar',
    },
    {
      i: 3550,
      checked: false,
      name: 'Москвич',
      type: 'brandCar',
    },
    {
      i: 3551,
      checked: false,
      name: 'РАФ',
      type: 'brandCar',
    },
    {
      i: 3552,
      checked: false,
      name: 'ТагАЗ',
      type: 'brandCar',
    },
    {
      i: 3553,
      checked: false,
      name: 'УАЗ',
      type: 'brandCar',
    },
    {
      i: 3554,
      checked: false,
      name: 'Прочие авто',
      type: 'brandCar',
    },
    {
      i: 3555,
      checked: false,
      name: 'Левый',
      type: 'rul',
    },
    {
      i: 3556,
      checked: false,
      name: 'Правый',
      type: 'rul',
    },
    {
      i: 3557,
      checked: false,
      name: 'Внедорожник',
      type: 'bodyCar',
    },
    {
      i: 3558,
      checked: false,
      name: 'Универсал',
      type: 'bodyCar',
    },
    {
      i: 3559,
      checked: false,
      name: 'Седан',
      type: 'bodyCar',
    },
    {
      i: 3560,
      checked: false,
      name: 'Хетчбэк',
      type: 'bodyCar',
    },
    {
      i: 3561,
      checked: false,
      name: 'Минивэн',
      type: 'bodyCar',
    },
    {
      i: 3562,
      checked: false,
      name: 'Пикап',
      type: 'bodyCar',
    },
    {
      i: 3563,
      checked: false,
      name: 'Купе',
      type: 'bodyCar',
    },
    {
      i: 3564,
      checked: false,
      name: 'Кабриолет',
      type: 'bodyCar',
    },
    {
      i: 3565,
      checked: false,
      name: 'Лимузин',
      type: 'bodyCar',
    },
    {
      i: 3566,
      checked: false,
      name: 'Бензиновый',
      type: 'engineType',
    },
    {
      i: 3567,
      checked: false,
      name: 'Дизельный',
      type: 'engineType',
    },
    {
      i: 3568,
      checked: false,
      name: 'Газ',
      type: 'engineType',
    },
    {
      i: 3569,
      checked: false,
      name: 'Гибридный',
      type: 'engineType',
    },
    {
      i: 3570,
      checked: false,
      name: 'Электрический',
      type: 'engineType',
    },
    {
      i: 3571,
      checked: false,
      name: 'Механика',
      type: 'transmission',
    },
    {
      i: 3572,
      checked: false,
      name: 'Автомат',
      type: 'transmission',
    },
    {
      i: 3573,
      checked: false,
      name: 'Вариатор',
      type: 'transmission',
    },
    {
      i: 3574,
      checked: false,
      name: 'Робот',
      type: 'transmission',
    },
    {
      i: 3575,
      checked: false,
      name: 'Передний',
      type: 'drive',
    },
    {
      i: 3576,
      checked: false,
      name: 'Задний',
      type: 'drive',
    },
    {
      i: 3577,
      checked: false,
      name: 'Полный',
      type: 'drive',
    },
    {
      i: 3578,
      checked: false,
      name: 'Не битый',
      type: 'stateAuto',
    },
    {
      i: 3579,
      checked: false,
      name: 'Битый',
      type: 'stateAuto',
    },
    {
      i: 3580,
      checked: false,
      name: 'есть',
      type: 'climateControl',
    },
    {
      i: 3581,
      checked: false,
      name: 'Нет',
      type: 'climateControl',
    },
    {
      i: 3582,
      checked: false,
      name: 'многозонный',
      type: 'climateControl',
    },
    {
      i: 3583,
      checked: false,
      name: 'все',
      type: 'windowLifters',
    },
    {
      i: 3584,
      checked: false,
      name: 'передние',
      type: 'windowLifters',
    },
    {
      i: 3585,
      checked: false,
      name: 'всех',
      type: 'heatedSeats',
    },
    {
      i: 3586,
      checked: false,
      name: 'передних',
      type: 'heatedSeats',
    },
    {
      i: 3587,
      checked: false,
      name: 'Нет',
      type: 'heatedSeats',
    },
    {
      i: 3588,
      checked: false,
      name: 'в двух плоскостях',
      type: 'rulR',
    },
    {
      i: 3589,
      checked: false,
      name: 'в одной плоскости',
      type: 'rulR',
    },
    {
      i: 3590,
      checked: false,
      name: 'Нет',
      type: 'rulR',
    },
    {
      i: 3591,
      checked: false,
      name: 'с памятью',
      type: 'seatReg',
    },
    {
      i: 3592,
      checked: false,
      name: 'с электроприводом',
      type: 'seatReg',
    },
    {
      i: 3593,
      checked: false,
      name: 'есть',
      type: 'seatReg',
    },
    {
      i: 3594,
      checked: false,
      name: 'Нет',
      type: 'seatReg',
    },
    {
      i: 3595,
      checked: false,
      name: 'Алькантара',
      type: 'upholstery',
    },
    {
      i: 3596,
      checked: false,
      name: 'Велюр',
      type: 'upholstery',
    },
    {
      i: 3597,
      checked: false,
      name: 'Кожа',
      type: 'upholstery',
    },
    {
      i: 3598,
      checked: false,
      name: 'Комбинированная',
      type: 'upholstery',
    },
    {
      i: 3599,
      checked: false,
      name: 'Ткань',
      type: 'upholstery',
    },
    {
      i: 3600,
      checked: false,
      name: 'есть',
      type: 'luke',
    },
    {
      i: 3601,
      checked: false,
      name: 'Нет',
      type: 'luke',
    },
    {
      i: 3602,
      checked: false,
      name: 'электрический',
      type: 'luke',
    },
    {
      i: 3603,
      checked: false,
      name: 'Гидроусилитель',
      type: 'powerSteering',
    },
    {
      i: 3604,
      checked: false,
      name: 'Отсутствует',
      type: 'powerSteering',
    },
    {
      i: 3605,
      checked: false,
      name: 'Электрогидроусилитель',
      type: 'powerSteering',
    },
    {
      i: 3606,
      checked: false,
      name: 'Электроусилитель',
      type: 'powerSteering',
    },
    {
      i: 3607,
      checked: false,
      name: 'Адаптивные',
      type: 'headlightsT',
    },
    {
      i: 3608,
      checked: false,
      name: 'Биксеноновые',
      type: 'headlightsT',
    },
    {
      i: 3609,
      checked: false,
      name: 'Галогенные',
      type: 'headlightsT',
    },
    {
      i: 3610,
      checked: false,
      name: 'Ксеноновые',
      type: 'headlightsT',
    },
    {
      i: 3611,
      checked: false,
      name: 'Светодиодные',
      type: 'headlightsT',
    },
    {
      i: 3612,
      checked: false,
      name: 'боковые',
      type: 'airbag',
    },
    {
      i: 3613,
      checked: false,
      name: 'водительская',
      type: 'airbag',
    },
    {
      i: 3614,
      checked: false,
      name: 'пассажирские',
      type: 'airbag',
    },
    {
      i: 3615,
      checked: false,
      name: 'шторки',
      type: 'airbag',
    },
    {
      i: 3616,
      checked: false,
      name: 'USB',
      type: 'opticalDriveAuto',
    },
    {
      i: 3617,
      checked: false,
      name: 'Кассетная',
      type: 'opticalDriveAuto',
    },
    {
      i: 3618,
      checked: false,
      name: 'Автобусы',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3619,
      checked: false,
      name: 'Микроавтобусы',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3620,
      checked: false,
      name: 'Автодома',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3621,
      checked: false,
      name: 'Авторефрижераторы',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3622,
      checked: false,
      name: 'Грузовики, шасси',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3623,
      checked: false,
      name: 'Грузовые прицепы',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3624,
      checked: false,
      name: 'Седельные тягачи',
      type: 'Автобусы и грузовики',
    },
    {
      i: 3625,
      checked: false,
      name: 'Гидроциклы',
      type: 'Водный транспорт',
    },
    {
      i: 3626,
      checked: false,
      name: 'Гребные лодки',
      type: 'Водный транспорт',
    },
    {
      i: 3627,
      checked: false,
      name: 'Катера, яхты',
      type: 'Водный транспорт',
    },
    {
      i: 3628,
      checked: false,
      name: 'Моторные лодки',
      type: 'Водный транспорт',
    },
    {
      i: 3629,
      checked: false,
      name: 'Надувные лодки',
      type: 'Водный транспорт',
    },
    {
      i: 3630,
      checked: false,
      name: 'Лодочные моторы, запчасти',
      type: 'Водный транспорт',
    },
    {
      i: 3631,
      checked: false,
      name: 'Дорожные мотоциклы',
      type: 'Мототехника',
    },
    {
      i: 3632,
      checked: false,
      name: 'Спортивные мотоциклы',
      type: 'Мототехника',
    },
    {
      i: 3633,
      checked: false,
      name: 'Кросс, эндуро',
      type: 'Мототехника',
    },
    {
      i: 3634,
      checked: false,
      name: 'Круизеры, чопперы',
      type: 'Мототехника',
    },
    {
      i: 3635,
      checked: false,
      name: 'Кастом-байки',
      type: 'Мототехника',
    },
    {
      i: 3636,
      checked: false,
      name: 'Мопеды, скутеры',
      type: 'Мототехника',
    },
    {
      i: 3637,
      checked: false,
      name: 'Квадроциклы',
      type: 'Мототехника',
    },
    {
      i: 3638,
      checked: false,
      name: 'Багги',
      type: 'Мототехника',
    },
    {
      i: 3639,
      checked: false,
      name: 'Картинг',
      type: 'Мототехника',
    },
    {
      i: 3640,
      checked: false,
      name: 'Снегоходы',
      type: 'Мототехника',
    },
    {
      i: 3641,
      checked: false,
      name: 'Автовышки',
      type: 'Спецтехника',
    },
    {
      i: 3642,
      checked: false,
      name: 'Автокраны',
      type: 'Спецтехника',
    },
    {
      i: 3643,
      checked: false,
      name: 'Бульдозеры',
      type: 'Спецтехника',
    },
    {
      i: 3644,
      checked: false,
      name: 'Вездеходы-амфибии',
      type: 'Спецтехника',
    },
    {
      i: 3645,
      checked: false,
      name: 'Коммунальная техника',
      type: 'Спецтехника',
    },
    {
      i: 3646,
      checked: false,
      name: 'Погрузчики',
      type: 'Спецтехника',
    },
    {
      i: 3647,
      checked: false,
      name: 'Строительная техника',
      type: 'Спецтехника',
    },
    {
      i: 3648,
      checked: false,
      name: 'Техника для лесозаготовки',
      type: 'Спецтехника',
    },
    {
      i: 3649,
      checked: false,
      name: 'Тракторы, сельхозтехника',
      type: 'Спецтехника',
    },
    {
      i: 3650,
      checked: false,
      name: 'Экскаваторы',
      type: 'Спецтехника',
    },
    {
      i: 3651,
      checked: false,
      name: 'Авто в разборе',
      type: 'Запчасти',
    },
    {
      i: 3652,
      checked: false,
      name: 'Автосвет, оптика',
      type: 'Запчасти',
    },
    {
      i: 3653,
      checked: false,
      name: 'Безопасность',
      type: 'Запчасти',
    },
    {
      i: 3654,
      checked: false,
      name: 'Впускная система',
      type: 'Запчасти',
    },
    {
      i: 3655,
      checked: false,
      name: 'Выхлопная система',
      type: 'Запчасти',
    },
    {
      i: 3656,
      checked: false,
      name: 'Двигатель, ГРМ, турбина',
      type: 'Запчасти',
    },
    {
      i: 3657,
      checked: false,
      name: 'Кузовные запчасти',
      type: 'Запчасти',
    },
    {
      i: 3658,
      checked: false,
      name: 'Подвеска',
      type: 'Запчасти',
    },
    {
      i: 3659,
      checked: false,
      name: 'Расходники',
      type: 'Запчасти',
    },
    {
      i: 3660,
      checked: false,
      name: 'Рулевое управление',
      type: 'Запчасти',
    },
    {
      i: 3661,
      checked: false,
      name: 'Салон, интерьер',
      type: 'Запчасти',
    },
    {
      i: 3662,
      checked: false,
      name: 'Система зажигания',
      type: 'Запчасти',
    },
    {
      i: 3663,
      checked: false,
      name: 'Система очистки',
      type: 'Запчасти',
    },
    {
      i: 3664,
      checked: false,
      name: 'Системы охлаждения, обогрева',
      type: 'Запчасти',
    },
    {
      i: 3665,
      checked: false,
      name: 'Стекла',
      type: 'Запчасти',
    },
    {
      i: 3666,
      checked: false,
      name: 'Топливная система',
      type: 'Запчасти',
    },
    {
      i: 3667,
      checked: false,
      name: 'Тормозная система',
      type: 'Запчасти',
    },
    {
      i: 3668,
      checked: false,
      name: 'Трансмиссия, привод',
      type: 'Запчасти',
    },
    {
      i: 3669,
      checked: false,
      name: 'Электрооборудование',
      type: 'Запчасти',
    },
    {
      i: 3670,
      checked: false,
      name: 'Для автомобилей',
      type: 'typeAuto',
    },
    {
      i: 3671,
      checked: false,
      name: 'Для мототехники',
      type: 'typeAuto',
    },
    {
      i: 3672,
      checked: false,
      name: 'Для спецтехники',
      type: 'typeAuto',
    },
    {
      i: 3673,
      checked: false,
      name: 'Для грузовиков и автобусов',
      type: 'typeAuto',
    },
    {
      i: 3674,
      checked: false,
      name: 'Шины',
      type: 'Шины и диски',
    },
    {
      i: 3675,
      checked: false,
      name: 'Диски',
      type: 'Шины и диски',
    },
    {
      i: 3676,
      checked: false,
      name: 'Колёса в сборе',
      type: 'Шины и диски',
    },
    {
      i: 3677,
      checked: false,
      name: 'Колпаки',
      type: 'Шины и диски',
    },
    {
      i: 3678,
      checked: false,
      name: 'Комплектующие',
      type: 'Шины и диски',
    },
    {
      i: 3679,
      checked: false,
      name: '12 и менее',
      type: 'diameter',
    },
    {
      i: 3680,
      checked: false,
      name: '13',
      type: 'diameter',
    },
    {
      i: 3681,
      checked: false,
      name: '14',
      type: 'diameter',
    },
    {
      i: 3682,
      checked: false,
      name: '15',
      type: 'diameter',
    },
    {
      i: 3683,
      checked: false,
      name: '16',
      type: 'diameter',
    },
    {
      i: 3684,
      checked: false,
      name: '17',
      type: 'diameter',
    },
    {
      i: 3685,
      checked: false,
      name: '18',
      type: 'diameter',
    },
    {
      i: 3686,
      checked: false,
      name: '19',
      type: 'diameter',
    },
    {
      i: 3687,
      checked: false,
      name: '20',
      type: 'diameter',
    },
    {
      i: 3688,
      checked: false,
      name: '21',
      type: 'diameter',
    },
    {
      i: 3689,
      checked: false,
      name: '22',
      type: 'diameter',
    },
    {
      i: 3690,
      checked: false,
      name: '23',
      type: 'diameter',
    },
    {
      i: 3691,
      checked: false,
      name: '15.5',
      type: 'diameter',
    },
    {
      i: 3692,
      checked: false,
      name: '16.5',
      type: 'diameter',
    },
    {
      i: 3693,
      checked: false,
      name: '17.5',
      type: 'diameter',
    },
    {
      i: 3694,
      checked: false,
      name: '19.5',
      type: 'diameter',
    },
    {
      i: 3695,
      checked: false,
      name: '22.5',
      type: 'diameter',
    },
    {
      i: 3696,
      checked: false,
      name: '24 и более',
      type: 'diameter',
    },
    {
      i: 3697,
      checked: false,
      name: '',
      type: 'Моторные масла',
    },
    {
      i: 3698,
      checked: false,
      name: '',
      type: 'Трансмиссионные масла',
    },
    {
      i: 3699,
      checked: false,
      name: '',
      type: 'Гидравлические масла',
    },
    {
      i: 3700,
      checked: false,
      name: '',
      type: 'Автошампуни',
    },
    {
      i: 3701,
      checked: false,
      name: '',
      type: 'Автоэмали, краски, лаки',
    },
    {
      i: 3702,
      checked: false,
      name: '',
      type: 'Герметики, клеи',
    },
    {
      i: 3703,
      checked: false,
      name: '',
      type: 'Антифризы, тосолы',
    },
    {
      i: 3704,
      checked: false,
      name: '',
      type: 'Антидождь',
    },
    {
      i: 3705,
      checked: false,
      name: '',
      type: 'Жидкости гидравлические',
    },
    {
      i: 3706,
      checked: false,
      name: '',
      type: 'Жидкости омывателя стекла',
    },
    {
      i: 3707,
      checked: false,
      name: '',
      type: 'Жидкости тормозные',
    },
    {
      i: 3708,
      checked: false,
      name: '',
      type: 'Полироли',
    },
    {
      i: 3709,
      checked: false,
      name: '',
      type: 'Присадки, промывки',
    },
    {
      i: 3710,
      checked: false,
      name: '',
      type: 'Смазки',
    },
    {
      i: 3711,
      checked: false,
      name: '',
      type: 'Средства для очистки',
    },
    {
      i: 3712,
      checked: false,
      name: 'GPS-навигаторы',
      type: 'Автоэлектроника и GPS',
    },
    {
      i: 3713,
      checked: false,
      name: 'GPS-трекеры',
      type: 'Автоэлектроника и GPS',
    },
    {
      i: 3714,
      checked: false,
      name: 'Видеорегистраторы',
      type: 'Автоэлектроника и GPS',
    },
    {
      i: 3715,
      checked: false,
      name: 'Камеры заднего вида',
      type: 'Автоэлектроника и GPS',
    },
    {
      i: 3716,
      checked: false,
      name: 'Парктроники',
      type: 'Автоэлектроника и GPS',
    },
    {
      i: 3717,
      checked: false,
      name: 'Радар-детекторы',
      type: 'Автоэлектроника и GPS',
    },
    {
      i: 3718,
      checked: false,
      name: 'Аксессуары интерьера',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3719,
      checked: false,
      name: 'Аксессуары экстерьера',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3720,
      checked: false,
      name: 'Брелоки, атрибутика',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3721,
      checked: false,
      name: 'Держатели, подставки, зарядки',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3722,
      checked: false,
      name: 'Дефлекторы, ветровики',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3723,
      checked: false,
      name: 'Диагностическое оборудование',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3724,
      checked: false,
      name: 'Домкраты',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3725,
      checked: false,
      name: 'Канистры',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3726,
      checked: false,
      name: 'Коврики',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3727,
      checked: false,
      name: 'Наборы автомобилиста',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3728,
      checked: false,
      name: 'Наборы инструментов',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3729,
      checked: false,
      name: 'Насосы, компрессоры',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3730,
      checked: false,
      name: 'Наклейки, накладки',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3731,
      checked: false,
      name: 'Оплетки, чехлы на руль',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3732,
      checked: false,
      name: 'Пленки для авто',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3733,
      checked: false,
      name: 'Подсветка и переключатели',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3734,
      checked: false,
      name: 'Пуско-зарядные устройства',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3735,
      checked: false,
      name: 'Пылесосы',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3736,
      checked: false,
      name: 'Ручки КПП',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3737,
      checked: false,
      name: 'Сумки, органайзеры',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3738,
      checked: false,
      name: 'Тенты для автомобиля',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3739,
      checked: false,
      name: 'Тросы, лебедки',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3740,
      checked: false,
      name: 'Уход за авто',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3741,
      checked: false,
      name: 'Чехлы для сидений',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3742,
      checked: false,
      name: 'Шторки, козырьки',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3743,
      checked: false,
      name: 'Щетки стеклоочистителя',
      type: 'Аксессуары и инструменты',
    },
    {
      i: 3744,
      checked: false,
      name: 'CD, DVD-чейнджеры',
      type: 'Аудио и видео',
    },
    {
      i: 3745,
      checked: false,
      name: 'FM-трансмиттеры',
      type: 'Аудио и видео',
    },
    {
      i: 3746,
      checked: false,
      name: 'Автоакустика, колонки',
      type: 'Аудио и видео',
    },
    {
      i: 3747,
      checked: false,
      name: 'Автомагнитолы',
      type: 'Аудио и видео',
    },
    {
      i: 3748,
      checked: false,
      name: 'Автомобильные рации',
      type: 'Аудио и видео',
    },
    {
      i: 3749,
      checked: false,
      name: 'Акустические полки, короба',
      type: 'Аудио и видео',
    },
    {
      i: 3750,
      checked: false,
      name: 'Кабели, переходники',
      type: 'Аудио и видео',
    },
    {
      i: 3751,
      checked: false,
      name: 'Усилители, сабвуферы',
      type: 'Аудио и видео',
    },
    {
      i: 3752,
      checked: false,
      name: 'Телевизоры, TV-тюнеры',
      type: 'Аудио и видео',
    },
    {
      i: 3753,
      checked: false,
      name: 'Переходные рамки',
      type: 'Аудио и видео',
    },
    {
      i: 3754,
      checked: false,
      name: 'Автосигнализации',
      type: 'Противоугонные устройства',
    },
    {
      i: 3755,
      checked: false,
      name: 'Иммобилайзеры',
      type: 'Противоугонные устройства',
    },
    {
      i: 3756,
      checked: false,
      name: 'Механические блокираторы',
      type: 'Противоугонные устройства',
    },
    {
      i: 3757,
      checked: false,
      name: 'Спутниковые системы',
      type: 'Противоугонные устройства',
    },
    {
      i: 3758,
      checked: false,
      name: 'Багажники, рейлинги',
      type: 'Багажные системы и прицепы',
    },
    {
      i: 3759,
      checked: false,
      name: 'Боксы на крышу',
      type: 'Багажные системы и прицепы',
    },
    {
      i: 3760,
      checked: false,
      name: 'Крепления для багажа',
      type: 'Багажные системы и прицепы',
    },
    {
      i: 3761,
      checked: false,
      name: 'Прицепы',
      type: 'Багажные системы и прицепы',
    },
    {
      i: 3762,
      checked: false,
      name: 'Фаркопы',
      type: 'Багажные системы и прицепы',
    },
    {
      i: 3763,
      checked: false,
      name: 'Шлемы',
      type: 'Мотоэкипировка',
    },
    {
      i: 3764,
      checked: false,
      name: 'Очки, маски',
      type: 'Мотоэкипировка',
    },
    {
      i: 3765,
      checked: false,
      name: 'Мотозащита',
      type: 'Мотоэкипировка',
    },
    {
      i: 3766,
      checked: false,
      name: 'Куртки',
      type: 'Мотоэкипировка',
    },
    {
      i: 3767,
      checked: false,
      name: 'Обувь',
      type: 'Мотоэкипировка',
    },
    {
      i: 3768,
      checked: false,
      name: 'Перчатки',
      type: 'Мотоэкипировка',
    },
    {
      i: 3769,
      checked: false,
      name: 'Штаны и джинсы',
      type: 'Мотоэкипировка',
    },
    {
      i: 3770,
      checked: false,
      name: 'Комбинезон',
      type: 'Мотоэкипировка',
    },
    {
      i: 3771,
      checked: false,
      name: 'Аксессуары',
      type: 'Мотоэкипировка',
    },
    {
      i: 3772,
      checked: false,
      name: 'Без опыта работы',
      type: 'requiredExperience',
    },
    {
      i: 3773,
      checked: false,
      name: 'Меньше года',
      type: 'requiredExperience',
    },
    {
      i: 3774,
      checked: false,
      name: 'от 1 до 3 лет',
      type: 'requiredExperience',
    },
    {
      i: 3775,
      checked: false,
      name: 'от 3 до 5 лет',
      type: 'requiredExperience',
    },
    {
      i: 3776,
      checked: false,
      name: 'Больше 5 лет',
      type: 'requiredExperience',
    },
    {
      i: 3777,
      checked: false,
      name: 'Полный день',
      type: 'workingHours',
    },
    {
      i: 3778,
      checked: false,
      name: 'Сменный график',
      type: 'workingHours',
    },
    {
      i: 3779,
      checked: false,
      name: 'Гибкий график',
      type: 'workingHours',
    },
    {
      i: 3780,
      checked: false,
      name: 'Удаленная работа',
      type: 'workingHours',
    },
    {
      i: 3781,
      checked: false,
      name: 'Вахтовый метод',
      type: 'workingHours',
    },
    {
      i: 3782,
      checked: false,
      name: 'Не полный день',
      type: 'workingHours',
    },
    {
      i: 3783,
      checked: false,
      name: 'Полная занятость',
      type: 'employmentType',
    },
    {
      i: 3784,
      checked: false,
      name: 'Частичная занятость',
      type: 'employmentType',
    },
    {
      i: 3785,
      checked: false,
      name: 'Проектная/временная работа',
      type: 'employmentType',
    },
    {
      i: 3786,
      checked: false,
      name: 'Волонтёрство',
      type: 'employmentType',
    },
    {
      i: 3787,
      checked: false,
      name: 'Стажировка',
      type: 'employmentType',
    },
    {
      i: 3788,
      checked: false,
      name: 'На точке',
      type: 'natureWork',
    },
    {
      i: 3789,
      checked: false,
      name: 'В офисе',
      type: 'natureWork',
    },
    {
      i: 3790,
      checked: false,
      name: 'Разъездная',
      type: 'natureWork',
    },
    {
      i: 3791,
      checked: false,
      name: 'Срочно требуется',
      type: 'otherTerms',
    },
    {
      i: 3792,
      checked: false,
      name: 'Можно до 18 лет',
      type: 'otherTerms',
    },
    {
      i: 3793,
      checked: false,
      name: 'Необходима мед. книжка',
      type: 'otherTerms',
    },
    {
      i: 3794,
      checked: false,
      name: 'Автожестянщик',
      type: 'professionType',
    },
    {
      i: 3795,
      checked: false,
      name: 'Автожестянщик-автомаляр',
      type: 'professionType',
    },
    {
      i: 3796,
      checked: false,
      name: 'Автокрановщик',
      type: 'professionType',
    },
    {
      i: 3797,
      checked: false,
      name: 'Автомаляр',
      type: 'professionType',
    },
    {
      i: 3798,
      checked: false,
      name: 'Автомаляр-жестянщик',
      type: 'professionType',
    },
    {
      i: 3799,
      checked: false,
      name: 'Автомаляр-подготовщик',
      type: 'professionType',
    },
    {
      i: 3800,
      checked: false,
      name: 'Автоматчик',
      type: 'professionType',
    },
    {
      i: 3801,
      checked: false,
      name: 'Автомеханик',
      type: 'professionType',
    },
    {
      i: 3802,
      checked: false,
      name: 'Автомеханик-автослесарь',
      type: 'professionType',
    },
    {
      i: 3803,
      checked: false,
      name: 'Автомойщик',
      type: 'professionType',
    },
    {
      i: 3804,
      checked: false,
      name: 'Автоняня',
      type: 'professionType',
    },
    {
      i: 3805,
      checked: false,
      name: 'Авторазборщик',
      type: 'professionType',
    },
    {
      i: 3806,
      checked: false,
      name: 'Автослесарь',
      type: 'professionType',
    },
    {
      i: 3807,
      checked: false,
      name: 'Автослесарь грузовых автомобилей',
      type: 'professionType',
    },
    {
      i: 3808,
      checked: false,
      name: 'Автослесарь-шиномонтажник',
      type: 'professionType',
    },
    {
      i: 3809,
      checked: false,
      name: 'Автотехник-трасолог',
      type: 'professionType',
    },
    {
      i: 3810,
      checked: false,
      name: 'Автоэлектрик',
      type: 'professionType',
    },
    {
      i: 3811,
      checked: false,
      name: 'Автоэлектрик-диагност',
      type: 'professionType',
    },
    {
      i: 3812,
      checked: false,
      name: 'Агрегатчик',
      type: 'professionType',
    },
    {
      i: 3813,
      checked: false,
      name: 'Администратор автомойки',
      type: 'professionType',
    },
    {
      i: 3814,
      checked: false,
      name: 'Антикоррозийщик',
      type: 'professionType',
    },
    {
      i: 3815,
      checked: false,
      name: 'Бульдозерист',
      type: 'professionType',
    },
    {
      i: 3816,
      checked: false,
      name: 'Водитель',
      type: 'professionType',
    },
    {
      i: 3817,
      checked: false,
      name: 'Водитель КДМ',
      type: 'professionType',
    },
    {
      i: 3818,
      checked: false,
      name: 'Водитель автобетоносмесителя',
      type: 'professionType',
    },
    {
      i: 3819,
      checked: false,
      name: 'Водитель автобуса',
      type: 'professionType',
    },
    {
      i: 3820,
      checked: false,
      name: 'Водитель автопогрузчика',
      type: 'professionType',
    },
    {
      i: 3821,
      checked: false,
      name: 'Водитель ассенизатора',
      type: 'professionType',
    },
    {
      i: 3822,
      checked: false,
      name: 'Водитель бензовоза',
      type: 'professionType',
    },
    {
      i: 3823,
      checked: false,
      name: 'Водитель высотного погрузчика',
      type: 'professionType',
    },
    {
      i: 3824,
      checked: false,
      name: 'Водитель грузового автомобиля',
      type: 'professionType',
    },
    {
      i: 3825,
      checked: false,
      name: 'Водитель дизельного погрузчика',
      type: 'professionType',
    },
    {
      i: 3826,
      checked: false,
      name: 'Водитель карьерного самосвала',
      type: 'professionType',
    },
    {
      i: 3827,
      checked: false,
      name: 'Водитель категории B',
      type: 'professionType',
    },
    {
      i: 3828,
      checked: false,
      name: 'Водитель категории C',
      type: 'professionType',
    },
    {
      i: 3829,
      checked: false,
      name: 'Водитель категории CE',
      type: 'professionType',
    },
    {
      i: 3830,
      checked: false,
      name: 'Водитель категории D',
      type: 'professionType',
    },
    {
      i: 3831,
      checked: false,
      name: 'Водитель категории E',
      type: 'professionType',
    },
    {
      i: 3832,
      checked: false,
      name: 'Водитель лесовоза',
      type: 'professionType',
    },
    {
      i: 3833,
      checked: false,
      name: 'Водитель ломовоза',
      type: 'professionType',
    },
    {
      i: 3834,
      checked: false,
      name: 'Водитель манипулятора',
      type: 'professionType',
    },
    {
      i: 3835,
      checked: false,
      name: 'Водитель мусоровоза',
      type: 'professionType',
    },
    {
      i: 3836,
      checked: false,
      name: 'Водитель панелевоза',
      type: 'professionType',
    },
    {
      i: 3837,
      checked: false,
      name: 'Водитель погрузчика',
      type: 'professionType',
    },
    {
      i: 3838,
      checked: false,
      name: 'Водитель пожарного автомобиля',
      type: 'professionType',
    },
    {
      i: 3839,
      checked: false,
      name: 'Водитель ричтрака',
      type: 'professionType',
    },
    {
      i: 3840,
      checked: false,
      name: 'Водитель ручного штабелера',
      type: 'professionType',
    },
    {
      i: 3841,
      checked: false,
      name: 'Водитель самосвала',
      type: 'professionType',
    },
    {
      i: 3842,
      checked: false,
      name: 'Водитель самоходных механизмов',
      type: 'professionType',
    },
    {
      i: 3843,
      checked: false,
      name: 'Водитель складской техники',
      type: 'professionType',
    },
    {
      i: 3844,
      checked: false,
      name: 'Водитель специального транспорта',
      type: 'professionType',
    },
    {
      i: 3845,
      checked: false,
      name: 'Водитель такси',
      type: 'professionType',
    },
    {
      i: 3846,
      checked: false,
      name: 'Водитель технической помощи',
      type: 'professionType',
    },
    {
      i: 3847,
      checked: false,
      name: 'Водитель трамвая',
      type: 'professionType',
    },
    {
      i: 3848,
      checked: false,
      name: 'Водитель троллейбуса',
      type: 'professionType',
    },
    {
      i: 3849,
      checked: false,
      name: 'Водитель тягача',
      type: 'professionType',
    },
    {
      i: 3850,
      checked: false,
      name: 'Водитель фуры',
      type: 'professionType',
    },
    {
      i: 3851,
      checked: false,
      name: 'Водитель штабелера',
      type: 'professionType',
    },
    {
      i: 3852,
      checked: false,
      name: 'Водитель эвакуатора',
      type: 'professionType',
    },
    {
      i: 3853,
      checked: false,
      name: 'Водитель экскаватора',
      type: 'professionType',
    },
    {
      i: 3854,
      checked: false,
      name: 'Водитель электропогрузчика',
      type: 'professionType',
    },
    {
      i: 3855,
      checked: false,
      name: 'Водитель электророхли',
      type: 'professionType',
    },
    {
      i: 3856,
      checked: false,
      name: 'Водитель электротележки',
      type: 'professionType',
    },
    {
      i: 3857,
      checked: false,
      name: 'Водитель электроштабелера',
      type: 'professionType',
    },
    {
      i: 3858,
      checked: false,
      name: 'Водитель-грузчик',
      type: 'professionType',
    },
    {
      i: 3859,
      checked: false,
      name: 'Водитель-загонщик',
      type: 'professionType',
    },
    {
      i: 3860,
      checked: false,
      name: 'Водитель-испытатель',
      type: 'professionType',
    },
    {
      i: 3861,
      checked: false,
      name: 'Водитель-комплектовщик',
      type: 'professionType',
    },
    {
      i: 3862,
      checked: false,
      name: 'Водитель-курьер',
      type: 'professionType',
    },
    {
      i: 3863,
      checked: false,
      name: 'Водитель-курьер в интернет-магазин',
      type: 'professionType',
    },
    {
      i: 3864,
      checked: false,
      name: 'Водитель-монтажник',
      type: 'professionType',
    },
    {
      i: 3865,
      checked: false,
      name: 'Водитель-оператор автобетононасоса',
      type: 'professionType',
    },
    {
      i: 3866,
      checked: false,
      name: 'Водитель-оператор парогенератора',
      type: 'professionType',
    },
    {
      i: 3867,
      checked: false,
      name: 'Водитель-охранник',
      type: 'professionType',
    },
    {
      i: 3868,
      checked: false,
      name: 'Водитель-перегонщик',
      type: 'professionType',
    },
    {
      i: 3869,
      checked: false,
      name: 'Водитель-слесарь',
      type: 'professionType',
    },
    {
      i: 3870,
      checked: false,
      name: 'Водитель-согласователь',
      type: 'professionType',
    },
    {
      i: 3871,
      checked: false,
      name: 'Водитель-универсал',
      type: 'professionType',
    },
    {
      i: 3872,
      checked: false,
      name: 'Водитель-установщик аккумуляторов на авто',
      type: 'professionType',
    },
    {
      i: 3873,
      checked: false,
      name: 'Водитель-фискарист',
      type: 'professionType',
    },
    {
      i: 3874,
      checked: false,
      name: 'Водитель-химик',
      type: 'professionType',
    },
    {
      i: 3875,
      checked: false,
      name: 'Водитель-экспедитор',
      type: 'professionType',
    },
    {
      i: 3876,
      checked: false,
      name: 'Водитель-экспедитор категории В',
      type: 'professionType',
    },
    {
      i: 3877,
      checked: false,
      name: 'Водитель-экспедитор категории Е',
      type: 'professionType',
    },
    {
      i: 3878,
      checked: false,
      name: 'Водитель-экспедитор категории С',
      type: 'professionType',
    },
    {
      i: 3879,
      checked: false,
      name: 'Водитель-экспедитор по доставке заказов',
      type: 'professionType',
    },
    {
      i: 3880,
      checked: false,
      name: 'Грейдерист',
      type: 'professionType',
    },
    {
      i: 3881,
      checked: false,
      name: 'Дезактиваторщик',
      type: 'professionType',
    },
    {
      i: 3882,
      checked: false,
      name: 'Детейлер',
      type: 'professionType',
    },
    {
      i: 3883,
      checked: false,
      name: 'Диагност',
      type: 'professionType',
    },
    {
      i: 3884,
      checked: false,
      name: 'Дизелист',
      type: 'professionType',
    },
    {
      i: 3885,
      checked: false,
      name: 'Заправщик',
      type: 'professionType',
    },
    {
      i: 3886,
      checked: false,
      name: 'Заправщик АЗС',
      type: 'professionType',
    },
    {
      i: 3887,
      checked: false,
      name: 'Инженер сервисной зоны',
      type: 'professionType',
    },
    {
      i: 3888,
      checked: false,
      name: 'Инструктор по безопасности дорожного движения',
      type: 'professionType',
    },
    {
      i: 3889,
      checked: false,
      name: 'Карщик',
      type: 'professionType',
    },
    {
      i: 3890,
      checked: false,
      name: 'Кладовщик-водитель погрузчика',
      type: 'professionType',
    },
    {
      i: 3891,
      checked: false,
      name: 'Колесмен',
      type: 'professionType',
    },
    {
      i: 3892,
      checked: false,
      name: 'Комбайнер',
      type: 'professionType',
    },
    {
      i: 3893,
      checked: false,
      name: 'Кузовщик',
      type: 'professionType',
    },
    {
      i: 3894,
      checked: false,
      name: 'Личный водитель',
      type: 'professionType',
    },
    {
      i: 3895,
      checked: false,
      name: 'Мастер СТО',
      type: 'professionType',
    },
    {
      i: 3896,
      checked: false,
      name: 'Мастер кузовного цеха',
      type: 'professionType',
    },
    {
      i: 3897,
      checked: false,
      name: 'Мастер по пошиву автомобильных ковриков',
      type: 'professionType',
    },
    {
      i: 3898,
      checked: false,
      name: 'Мастер по ремонту а/м панелей',
      type: 'professionType',
    },
    {
      i: 3899,
      checked: false,
      name: 'Мастер по ремонту бензоинструмента',
      type: 'professionType',
    },
    {
      i: 3900,
      checked: false,
      name: 'Мастер по ремонту турбин',
      type: 'professionType',
    },
    {
      i: 3901,
      checked: false,
      name: 'Мастер по ремонту электромобилей',
      type: 'professionType',
    },
    {
      i: 3902,
      checked: false,
      name: 'Мастер по сход-развалу',
      type: 'professionType',
    },
    {
      i: 3903,
      checked: false,
      name: 'Мастер-консультант кузовного цеха',
      type: 'professionType',
    },
    {
      i: 3904,
      checked: false,
      name: 'Мастер-приемщик',
      type: 'professionType',
    },
    {
      i: 3905,
      checked: false,
      name: 'Машинист',
      type: 'professionType',
    },
    {
      i: 3906,
      checked: false,
      name: 'Машинист ЖДСМ',
      type: 'professionType',
    },
    {
      i: 3907,
      checked: false,
      name: 'Машинист КМУ',
      type: 'professionType',
    },
    {
      i: 3908,
      checked: false,
      name: 'Машинист ППДУ',
      type: 'professionType',
    },
    {
      i: 3909,
      checked: false,
      name: 'Машинист РМУ',
      type: 'professionType',
    },
    {
      i: 3910,
      checked: false,
      name: 'Машинист РУМ',
      type: 'professionType',
    },
    {
      i: 3911,
      checked: false,
      name: 'Машинист автовышки и автогидроподъемника',
      type: 'professionType',
    },
    {
      i: 3912,
      checked: false,
      name: 'Машинист автогрейдера',
      type: 'professionType',
    },
    {
      i: 3913,
      checked: false,
      name: 'Машинист автокомпрессора',
      type: 'professionType',
    },
    {
      i: 3914,
      checked: false,
      name: 'Машинист автомотрисы',
      type: 'professionType',
    },
    {
      i: 3915,
      checked: false,
      name: 'Машинист асфальтоукладчика',
      type: 'professionType',
    },
    {
      i: 3916,
      checked: false,
      name: 'Машинист башенного крана',
      type: 'professionType',
    },
    {
      i: 3917,
      checked: false,
      name: 'Машинист бетононасоса',
      type: 'professionType',
    },
    {
      i: 3918,
      checked: false,
      name: 'Машинист буровой установки',
      type: 'professionType',
    },
    {
      i: 3919,
      checked: false,
      name: 'Машинист вездехода',
      type: 'professionType',
    },
    {
      i: 3920,
      checked: false,
      name: 'Машинист горных выемочных машин',
      type: 'professionType',
    },
    {
      i: 3921,
      checked: false,
      name: 'Машинист гранулятора',
      type: 'professionType',
    },
    {
      i: 3922,
      checked: false,
      name: 'Машинист грейдера',
      type: 'professionType',
    },
    {
      i: 3923,
      checked: false,
      name: 'Машинист гусеничного экскаватора',
      type: 'professionType',
    },
    {
      i: 3924,
      checked: false,
      name: 'Машинист дизельной электростанции',
      type: 'professionType',
    },
    {
      i: 3925,
      checked: false,
      name: 'Машинист дорожной фрезы',
      type: 'professionType',
    },
    {
      i: 3926,
      checked: false,
      name: 'Машинист ж/д строительных машин',
      type: 'professionType',
    },
    {
      i: 3927,
      checked: false,
      name: 'Машинист земснаряда',
      type: 'professionType',
    },
    {
      i: 3928,
      checked: false,
      name: 'Машинист катка',
      type: 'professionType',
    },
    {
      i: 3929,
      checked: false,
      name: 'Машинист козлового крана',
      type: 'professionType',
    },
    {
      i: 3930,
      checked: false,
      name: 'Машинист конвейера',
      type: 'professionType',
    },
    {
      i: 3931,
      checked: false,
      name: 'Машинист копра',
      type: 'professionType',
    },
    {
      i: 3932,
      checked: false,
      name: 'Машинист крана',
      type: 'professionType',
    },
    {
      i: 3933,
      checked: false,
      name: 'Машинист крана на ж/д ходу',
      type: 'professionType',
    },
    {
      i: 3934,
      checked: false,
      name: 'Машинист крана-трубоукладчика',
      type: 'professionType',
    },
    {
      i: 3935,
      checked: false,
      name: 'Машинист лесозаготовительной машины',
      type: 'professionType',
    },
    {
      i: 3936,
      checked: false,
      name: 'Машинист листоподборочного оборудования',
      type: 'professionType',
    },
    {
      i: 3937,
      checked: false,
      name: 'Машинист мини-погрузчика',
      type: 'professionType',
    },
    {
      i: 3938,
      checked: false,
      name: 'Машинист мостового крана',
      type: 'professionType',
    },
    {
      i: 3939,
      checked: false,
      name: 'Машинист насосной станции',
      type: 'professionType',
    },
    {
      i: 3940,
      checked: false,
      name: 'Машинист насосных установок',
      type: 'professionType',
    },
    {
      i: 3941,
      checked: false,
      name: 'Машинист погрузочно-доставочной машины',
      type: 'professionType',
    },
    {
      i: 3942,
      checked: false,
      name: 'Машинист подземных самоходных машин',
      type: 'professionType',
    },
    {
      i: 3943,
      checked: false,
      name: 'Машинист подъемника',
      type: 'professionType',
    },
    {
      i: 3944,
      checked: false,
      name: 'Машинист промывочного агрегата',
      type: 'professionType',
    },
    {
      i: 3945,
      checked: false,
      name: 'Машинист ресайклера',
      type: 'professionType',
    },
    {
      i: 3946,
      checked: false,
      name: 'Машинист рубительной машины',
      type: 'professionType',
    },
    {
      i: 3947,
      checked: false,
      name: 'Машинист строительной техники',
      type: 'professionType',
    },
    {
      i: 3948,
      checked: false,
      name: 'Машинист тепловоза',
      type: 'professionType',
    },
    {
      i: 3949,
      checked: false,
      name: 'Машинист фальцевальных машин',
      type: 'professionType',
    },
    {
      i: 3950,
      checked: false,
      name: 'Машинист фальцесклеечного оборудования',
      type: 'professionType',
    },
    {
      i: 3951,
      checked: false,
      name: 'Машинист фронтального погрузчика',
      type: 'professionType',
    },
    {
      i: 3952,
      checked: false,
      name: 'Машинист харвестера',
      type: 'professionType',
    },
    {
      i: 3953,
      checked: false,
      name: 'Машинист холодильных установок',
      type: 'professionType',
    },
    {
      i: 3954,
      checked: false,
      name: 'Машинист шахтного электровоза',
      type: 'professionType',
    },
    {
      i: 3955,
      checked: false,
      name: 'Машинист шнекоротора',
      type: 'professionType',
    },
    {
      i: 3956,
      checked: false,
      name: 'Машинист экскаватора',
      type: 'professionType',
    },
    {
      i: 3957,
      checked: false,
      name: 'Машинист экскаватора-погрузчика',
      type: 'professionType',
    },
    {
      i: 3958,
      checked: false,
      name: 'Машинист экструдера',
      type: 'professionType',
    },
    {
      i: 3959,
      checked: false,
      name: 'Машинист электропоезда',
      type: 'professionType',
    },
    {
      i: 3960,
      checked: false,
      name: 'Машинист ямобура',
      type: 'professionType',
    },
    {
      i: 3961,
      checked: false,
      name: 'Машинист-обходчик',
      type: 'professionType',
    },
    {
      i: 3962,
      checked: false,
      name: 'Машинист-таблетировщик',
      type: 'professionType',
    },
    {
      i: 3963,
      checked: false,
      name: 'Менеджер по ж/д перевозкам',
      type: 'professionType',
    },
    {
      i: 3964,
      checked: false,
      name: 'Менеджер по комиссии и выкупу',
      type: 'professionType',
    },
    {
      i: 3965,
      checked: false,
      name: 'Менеджер по оценке автомобилей',
      type: 'professionType',
    },
    {
      i: 3966,
      checked: false,
      name: 'Менеджер по подбору запчастей',
      type: 'professionType',
    },
    {
      i: 3967,
      checked: false,
      name: 'Менеджер по привлечению водителей',
      type: 'professionType',
    },
    {
      i: 3968,
      checked: false,
      name: 'Менеджер по продажам автозапчастей',
      type: 'professionType',
    },
    {
      i: 3969,
      checked: false,
      name: 'Менеджер-планировщик',
      type: 'professionType',
    },
    {
      i: 3970,
      checked: false,
      name: 'Механик',
      type: 'professionType',
    },
    {
      i: 3971,
      checked: false,
      name: 'Механик по выпуску автотранспорта',
      type: 'professionType',
    },
    {
      i: 3972,
      checked: false,
      name: 'Механик по выпуску транспорта',
      type: 'professionType',
    },
    {
      i: 3973,
      checked: false,
      name: 'Механик по выпуску транспортных средств',
      type: 'professionType',
    },
    {
      i: 3974,
      checked: false,
      name: 'Механик рефрижераторных установок',
      type: 'professionType',
    },
    {
      i: 3975,
      checked: false,
      name: 'Механик-моторист',
      type: 'professionType',
    },
    {
      i: 3976,
      checked: false,
      name: 'Механик-наладчик',
      type: 'professionType',
    },
    {
      i: 3977,
      checked: false,
      name: 'Мотомеханик',
      type: 'professionType',
    },
    {
      i: 3978,
      checked: false,
      name: 'Моторист',
      type: 'professionType',
    },
    {
      i: 3979,
      checked: false,
      name: 'Наполнитель баллонов',
      type: 'professionType',
    },
    {
      i: 3980,
      checked: false,
      name: 'Начальник автоколонны',
      type: 'professionType',
    },
    {
      i: 3981,
      checked: false,
      name: 'Оператор - кассир АЗК',
      type: 'professionType',
    },
    {
      i: 3982,
      checked: false,
      name: 'Оператор АЗС',
      type: 'professionType',
    },
    {
      i: 3983,
      checked: false,
      name: 'Оператор автовышки',
      type: 'professionType',
    },
    {
      i: 3984,
      checked: false,
      name: 'Оператор асфальтного катка',
      type: 'professionType',
    },
    {
      i: 3985,
      checked: false,
      name: 'Оператор бетононасоса',
      type: 'professionType',
    },
    {
      i: 3986,
      checked: false,
      name: 'Оператор весового контроля',
      type: 'professionType',
    },
    {
      i: 3987,
      checked: false,
      name: 'Оператор грохота',
      type: 'professionType',
    },
    {
      i: 3988,
      checked: false,
      name: 'Оператор лесозаготовительной техники',
      type: 'professionType',
    },
    {
      i: 3989,
      checked: false,
      name: 'Оператор мачтового подъемника',
      type: 'professionType',
    },
    {
      i: 3990,
      checked: false,
      name: 'Оператор передвижного кинотеатра',
      type: 'professionType',
    },
    {
      i: 3991,
      checked: false,
      name: 'Оператор-заправщик',
      type: 'professionType',
    },
    {
      i: 3992,
      checked: false,
      name: 'Оператор-кассир АЗС',
      type: 'professionType',
    },
    {
      i: 3993,
      checked: false,
      name: 'Осмотрщик-ремонтник',
      type: 'professionType',
    },
    {
      i: 3994,
      checked: false,
      name: 'Офисный водитель',
      type: 'professionType',
    },
    {
      i: 3995,
      checked: false,
      name: 'Оценщик',
      type: 'professionType',
    },
    {
      i: 3996,
      checked: false,
      name: 'Перегонщик автомобилей',
      type: 'professionType',
    },
    {
      i: 3997,
      checked: false,
      name: 'Погрузист',
      type: 'professionType',
    },
    {
      i: 3998,
      checked: false,
      name: 'Подготовщик',
      type: 'professionType',
    },
    {
      i: 3999,
      checked: false,
      name: 'Подготовщик к покраске',
      type: 'professionType',
    },
    {
      i: 4000,
      checked: false,
      name: 'Полировщик',
      type: 'professionType',
    },
    {
      i: 4001,
      checked: false,
      name: 'Полировщик турбинных лопаток',
      type: 'professionType',
    },
    {
      i: 4002,
      checked: false,
      name: 'Помощник автомаляра',
      type: 'professionType',
    },
    {
      i: 4003,
      checked: false,
      name: 'Помощник автомеханика',
      type: 'professionType',
    },
    {
      i: 4004,
      checked: false,
      name: 'Помощник водителя',
      type: 'professionType',
    },
    {
      i: 4005,
      checked: false,
      name: 'Помощник водителя-экспедитора',
      type: 'professionType',
    },
    {
      i: 4006,
      checked: false,
      name: 'Помощник заправщика',
      type: 'professionType',
    },
    {
      i: 4007,
      checked: false,
      name: 'Помощник оценщика',
      type: 'professionType',
    },
    {
      i: 4008,
      checked: false,
      name: 'Помощник шиномонтажника',
      type: 'professionType',
    },
    {
      i: 4009,
      checked: false,
      name: 'Продавец-консультант автомобилей с пробегом',
      type: 'professionType',
    },
    {
      i: 4010,
      checked: false,
      name: 'Продавец-консультант коммерческих автомобилей',
      type: 'professionType',
    },
    {
      i: 4011,
      checked: false,
      name: 'Рихтовщик',
      type: 'professionType',
    },
    {
      i: 4012,
      checked: false,
      name: 'Роторщик',
      type: 'professionType',
    },
    {
      i: 4013,
      checked: false,
      name: 'Слесарь-механик',
      type: 'professionType',
    },
    {
      i: 4014,
      checked: false,
      name: 'Слесарь-моторист',
      type: 'professionType',
    },
    {
      i: 4015,
      checked: false,
      name: 'Слесарь-шиномонтажник',
      type: 'professionType',
    },
    {
      i: 4016,
      checked: false,
      name: 'Специалист отдела запасных частей',
      type: 'professionType',
    },
    {
      i: 4017,
      checked: false,
      name: 'Специалист по запчастям',
      type: 'professionType',
    },
    {
      i: 4018,
      checked: false,
      name: 'Специалист по оценке автомобилей с пробегом',
      type: 'professionType',
    },
    {
      i: 4019,
      checked: false,
      name: 'Специалист по ремонту узлов двигателей',
      type: 'professionType',
    },
    {
      i: 4020,
      checked: false,
      name: 'Специалист по сельхозтехнике',
      type: 'professionType',
    },
    {
      i: 4021,
      checked: false,
      name: 'Специалист по сельхозтехнике и оборудованию',
      type: 'professionType',
    },
    {
      i: 4022,
      checked: false,
      name: 'Специалист по уходу за автомобилем',
      type: 'professionType',
    },
    {
      i: 4023,
      checked: false,
      name: 'Старший автомойщик',
      type: 'professionType',
    },
    {
      i: 4024,
      checked: false,
      name: 'Старший автослесарь',
      type: 'professionType',
    },
    {
      i: 4025,
      checked: false,
      name: 'Старший продавец-консультант автомобилей с пробегом',
      type: 'professionType',
    },
    {
      i: 4026,
      checked: false,
      name: 'Старший продавец-консультант отдела продаж автомобилей',
      type: 'professionType',
    },
    {
      i: 4027,
      checked: false,
      name: 'Супервайзер водителей',
      type: 'professionType',
    },
    {
      i: 4028,
      checked: false,
      name: 'Съемщик колес',
      type: 'professionType',
    },
    {
      i: 4029,
      checked: false,
      name: 'Техник-водитель',
      type: 'professionType',
    },
    {
      i: 4030,
      checked: false,
      name: 'Тонировщик',
      type: 'professionType',
    },
    {
      i: 4031,
      checked: false,
      name: 'Топливщик',
      type: 'professionType',
    },
    {
      i: 4032,
      checked: false,
      name: 'Тракторист',
      type: 'professionType',
    },
    {
      i: 4033,
      checked: false,
      name: 'Транспортировщик',
      type: 'professionType',
    },
    {
      i: 4034,
      checked: false,
      name: 'Установщик ГБО',
      type: 'professionType',
    },
    {
      i: 4035,
      checked: false,
      name: 'Установщик автостекол',
      type: 'professionType',
    },
    {
      i: 4036,
      checked: false,
      name: 'Установщик авточехлов',
      type: 'professionType',
    },
    {
      i: 4037,
      checked: false,
      name: 'Установщик сигнализаций',
      type: 'professionType',
    },
    {
      i: 4038,
      checked: false,
      name: 'Ученик автомеханика',
      type: 'professionType',
    },
    {
      i: 4039,
      checked: false,
      name: 'Ученик автомеханика в пункт замены масла',
      type: 'professionType',
    },
    {
      i: 4040,
      checked: false,
      name: 'Ученик автослесаря',
      type: 'professionType',
    },
    {
      i: 4041,
      checked: false,
      name: 'Ученик тонировщика',
      type: 'professionType',
    },
    {
      i: 4042,
      checked: false,
      name: 'Шиномонтажник',
      type: 'professionType',
    },
    {
      i: 4043,
      checked: false,
      name: 'Эксперт',
      type: 'professionType',
    },
    {
      i: 4044,
      checked: false,
      name: 'Эксперт по автокредитованию',
      type: 'professionType',
    },
    {
      i: 4045,
      checked: false,
      name: 'Эксперт по осмотру тс',
      type: 'professionType',
    },
    {
      i: 4046,
      checked: false,
      name: 'Эксперт по трейд-ин',
      type: 'professionType',
    },
    {
      i: 4047,
      checked: false,
      name: 'Эксперт-дефектовщик',
      type: 'professionType',
    },
    {
      i: 4048,
      checked: false,
      name: 'Электрик',
      type: 'professionType',
    },
    {
      i: 4049,
      checked: false,
      name: 'Электромеханик',
      type: 'professionType',
    },
    {
      i: 4050,
      checked: false,
      name: 'Электрослесарь',
      type: 'professionType',
    },
    {
      i: 4051,
      checked: false,
      name: 'Боец',
      type: 'professionType',
    },
    {
      i: 4052,
      checked: false,
      name: 'Вахтёр',
      type: 'professionType',
    },
    {
      i: 4053,
      checked: false,
      name: 'Ведущий специалист по промышленной безопасности и охране труда',
      type: 'professionType',
    },
    {
      i: 4054,
      checked: false,
      name: 'Видео-контролер',
      type: 'professionType',
    },
    {
      i: 4055,
      checked: false,
      name: 'Водитель-охранник',
      type: 'professionType',
    },
    {
      i: 4056,
      checked: false,
      name: 'Военнослужащий',
      type: 'professionType',
    },
    {
      i: 4057,
      checked: false,
      name: 'Газоспасатель',
      type: 'professionType',
    },
    {
      i: 4058,
      checked: false,
      name: 'Дежурный',
      type: 'professionType',
    },
    {
      i: 4059,
      checked: false,
      name: 'Дежурный по железнодорожной станции',
      type: 'professionType',
    },
    {
      i: 4060,
      checked: false,
      name: 'Дежурный стрелочного поста',
      type: 'professionType',
    },
    {
      i: 4061,
      checked: false,
      name: 'Директор частного охранного предприятия',
      type: 'professionType',
    },
    {
      i: 4062,
      checked: false,
      name: 'Заместитель начальника опп',
      type: 'professionType',
    },
    {
      i: 4063,
      checked: false,
      name: 'Инспектор бюро пропусков',
      type: 'professionType',
    },
    {
      i: 4064,
      checked: false,
      name: 'Инспектор отдела организации и контроля высадки пассажиров',
      type: 'professionType',
    },
    {
      i: 4065,
      checked: false,
      name: 'Инспектор отдела энергетического надзора',
      type: 'professionType',
    },
    {
      i: 4066,
      checked: false,
      name: 'Инспектор по досмотру',
      type: 'professionType',
    },
    {
      i: 4067,
      checked: false,
      name: 'Инспектор систем безопасности',
      type: 'professionType',
    },
    {
      i: 4068,
      checked: false,
      name: 'Инспектор транспортной безопасности',
      type: 'professionType',
    },
    {
      i: 4069,
      checked: false,
      name: 'Инструктор по противопожарной безопасности',
      type: 'professionType',
    },
    {
      i: 4070,
      checked: false,
      name: 'Комендант',
      type: 'professionType',
    },
    {
      i: 4071,
      checked: false,
      name: 'Контролер ДПП',
      type: 'professionType',
    },
    {
      i: 4072,
      checked: false,
      name: 'Контролер газовой службы',
      type: 'professionType',
    },
    {
      i: 4073,
      checked: false,
      name: 'Контролер кпп',
      type: 'professionType',
    },
    {
      i: 4074,
      checked: false,
      name: 'Контролер службы безопасности',
      type: 'professionType',
    },
    {
      i: 4075,
      checked: false,
      name: 'Контролер-охранник',
      type: 'professionType',
    },
    {
      i: 4076,
      checked: false,
      name: 'Контролёр парковки',
      type: 'professionType',
    },
    {
      i: 4077,
      checked: false,
      name: 'Менеджер ВЭД',
      type: 'professionType',
    },
    {
      i: 4078,
      checked: false,
      name: 'Менеджер видеомониторинга',
      type: 'professionType',
    },
    {
      i: 4079,
      checked: false,
      name: 'Менеджер по безопасности и качеству',
      type: 'professionType',
    },
    {
      i: 4080,
      checked: false,
      name: 'Младший инспектор отдела безопасности',
      type: 'professionType',
    },
    {
      i: 4081,
      checked: false,
      name: 'Наблюдатель',
      type: 'professionType',
    },
    {
      i: 4082,
      checked: false,
      name: 'Начальник СЭБ',
      type: 'professionType',
    },
    {
      i: 4083,
      checked: false,
      name: 'Начальник охраны',
      type: 'professionType',
    },
    {
      i: 4084,
      checked: false,
      name: 'Начальник смены',
      type: 'professionType',
    },
    {
      i: 4085,
      checked: false,
      name: 'Ночной охранник',
      type: 'professionType',
    },
    {
      i: 4086,
      checked: false,
      name: 'Ночной сторож',
      type: 'professionType',
    },
    {
      i: 4087,
      checked: false,
      name: 'Оперативный дежурный',
      type: 'professionType',
    },
    {
      i: 4088,
      checked: false,
      name: 'Оператор ПНЦ',
      type: 'professionType',
    },
    {
      i: 4089,
      checked: false,
      name: 'Оператор ПЦО',
      type: 'professionType',
    },
    {
      i: 4090,
      checked: false,
      name: 'Оператор СВН',
      type: 'professionType',
    },
    {
      i: 4091,
      checked: false,
      name: 'Оператор видеонаблюдения',
      type: 'professionType',
    },
    {
      i: 4092,
      checked: false,
      name: 'Оператор группы режима и надзора',
      type: 'professionType',
    },
    {
      i: 4093,
      checked: false,
      name: 'Оператор отдела охраны',
      type: 'professionType',
    },
    {
      i: 4094,
      checked: false,
      name: 'Охранник',
      type: 'professionType',
    },
    {
      i: 4095,
      checked: false,
      name: 'Охранник-администратор',
      type: 'professionType',
    },
    {
      i: 4096,
      checked: false,
      name: 'Охранник-водитель',
      type: 'professionType',
    },
    {
      i: 4097,
      checked: false,
      name: 'Охранник-грузчик',
      type: 'professionType',
    },
    {
      i: 4098,
      checked: false,
      name: 'Охранник-кинолог',
      type: 'professionType',
    },
    {
      i: 4099,
      checked: false,
      name: 'Парковщик',
      type: 'professionType',
    },
    {
      i: 4100,
      checked: false,
      name: 'Помощник инспектора системы безопастности',
      type: 'professionType',
    },
    {
      i: 4101,
      checked: false,
      name: 'Помощник ревизора',
      type: 'professionType',
    },
    {
      i: 4102,
      checked: false,
      name: 'Ревизор',
      type: 'professionType',
    },
    {
      i: 4103,
      checked: false,
      name: 'Руководитель охраны труда',
      type: 'professionType',
    },
    {
      i: 4104,
      checked: false,
      name: 'Руководитель службы безопасности',
      type: 'professionType',
    },
    {
      i: 4105,
      checked: false,
      name: 'Смотритель',
      type: 'professionType',
    },
    {
      i: 4106,
      checked: false,
      name: 'Сотрудник ОПВС',
      type: 'professionType',
    },
    {
      i: 4107,
      checked: false,
      name: 'Сотрудник СВК',
      type: 'professionType',
    },
    {
      i: 4108,
      checked: false,
      name: 'Сотрудник группы быстрого реагирования',
      type: 'professionType',
    },
    {
      i: 4109,
      checked: false,
      name: 'Сотрудник отдела специального учета',
      type: 'professionType',
    },
    {
      i: 4110,
      checked: false,
      name: 'Сотрудник службы безопасности',
      type: 'professionType',
    },
    {
      i: 4111,
      checked: false,
      name: 'Сотрудник транспортной безопасности',
      type: 'professionType',
    },
    {
      i: 4112,
      checked: false,
      name: 'Спасатель',
      type: 'professionType',
    },
    {
      i: 4113,
      checked: false,
      name: 'Специалист отдела контроля склада',
      type: 'professionType',
    },
    {
      i: 4114,
      checked: false,
      name: 'Специалист отдела режима и надзора',
      type: 'professionType',
    },
    {
      i: 4115,
      checked: false,
      name: 'Специалист по антитеррористической безопасности',
      type: 'professionType',
    },
    {
      i: 4116,
      checked: false,
      name: 'Специалист по безопасности',
      type: 'professionType',
    },
    {
      i: 4117,
      checked: false,
      name: 'Специалист по безопасности дорожного движения',
      type: 'professionType',
    },
    {
      i: 4118,
      checked: false,
      name: 'Специалист по взысканию',
      type: 'professionType',
    },
    {
      i: 4119,
      checked: false,
      name: 'Специалист по защите информации',
      type: 'professionType',
    },
    {
      i: 4120,
      checked: false,
      name: 'Специалист по информационной безопасности',
      type: 'professionType',
    },
    {
      i: 4121,
      checked: false,
      name: 'Специалист по контрольно-ревизионной работе',
      type: 'professionType',
    },
    {
      i: 4122,
      checked: false,
      name: 'Специалист по лицензионно-разрешительной работе',
      type: 'professionType',
    },
    {
      i: 4123,
      checked: false,
      name: 'Специалист по охране труда',
      type: 'professionType',
    },
    {
      i: 4124,
      checked: false,
      name: 'Специалист по охране труда и пожарной безопасности',
      type: 'professionType',
    },
    {
      i: 4125,
      checked: false,
      name: 'Старший контролер',
      type: 'professionType',
    },
    {
      i: 4126,
      checked: false,
      name: 'Старший охранник',
      type: 'professionType',
    },
    {
      i: 4127,
      checked: false,
      name: 'Старший смены',
      type: 'professionType',
    },
    {
      i: 4128,
      checked: false,
      name: 'Сторож',
      type: 'professionType',
    },
    {
      i: 4129,
      checked: false,
      name: 'Сторож-охранник',
      type: 'professionType',
    },
    {
      i: 4130,
      checked: false,
      name: 'Телохранитель',
      type: 'professionType',
    },
    {
      i: 4131,
      checked: false,
      name: 'Техник пожарно-технического оборудования',
      type: 'professionType',
    },
    {
      i: 4132,
      checked: false,
      name: 'Участковый',
      type: 'professionType',
    },
    {
      i: 4133,
      checked: false,
      name: 'Хронометрист',
      type: 'professionType',
    },
    {
      i: 4134,
      checked: false,
      name: 'Эксперт промышленной безопасности',
      type: 'professionType',
    },
    {
      i: 4135,
      checked: false,
      name: 'Газонокосильщик',
      type: 'professionType',
    },
    {
      i: 4136,
      checked: false,
      name: 'Гладильщик/гладильщица',
      type: 'professionType',
    },
    {
      i: 4137,
      checked: false,
      name: 'Горничная',
      type: 'professionType',
    },
    {
      i: 4138,
      checked: false,
      name: 'Ковромойщик',
      type: 'professionType',
    },
    {
      i: 4139,
      checked: false,
      name: 'Косарь травы',
      type: 'professionType',
    },
    {
      i: 4140,
      checked: false,
      name: 'Мастер по химчистке',
      type: 'professionType',
    },
    {
      i: 4141,
      checked: false,
      name: 'Менеджер отдела клининга',
      type: 'professionType',
    },
    {
      i: 4142,
      checked: false,
      name: 'Менеджер по клинингу',
      type: 'professionType',
    },
    {
      i: 4143,
      checked: false,
      name: 'Мобильный уборщик',
      type: 'professionType',
    },
    {
      i: 4144,
      checked: false,
      name: 'Мойщик окон',
      type: 'professionType',
    },
    {
      i: 4145,
      checked: false,
      name: 'Мойщик/мойщица',
      type: 'professionType',
    },
    {
      i: 4146,
      checked: false,
      name: 'Начальник АХО',
      type: 'professionType',
    },
    {
      i: 4147,
      checked: false,
      name: 'Ночной уборщик/уборщица',
      type: 'professionType',
    },
    {
      i: 4148,
      checked: false,
      name: 'Оператор мобильной бригады',
      type: 'professionType',
    },
    {
      i: 4149,
      checked: false,
      name: 'Оператор моечной машины',
      type: 'professionType',
    },
    {
      i: 4150,
      checked: false,
      name: 'Оператор по стирке белья',
      type: 'professionType',
    },
    {
      i: 4151,
      checked: false,
      name: 'Оператор прачечной',
      type: 'professionType',
    },
    {
      i: 4152,
      checked: false,
      name: 'Оператор стиральных машин',
      type: 'professionType',
    },
    {
      i: 4153,
      checked: false,
      name: 'Оператор уборки',
      type: 'professionType',
    },
    {
      i: 4154,
      checked: false,
      name: 'Оператор чистоты',
      type: 'professionType',
    },
    {
      i: 4155,
      checked: false,
      name: 'Операционист-чистильщик',
      type: 'professionType',
    },
    {
      i: 4156,
      checked: false,
      name: 'Отпарщик',
      type: 'professionType',
    },
    {
      i: 4157,
      checked: false,
      name: 'Прачка',
      type: 'professionType',
    },
    {
      i: 4158,
      checked: false,
      name: 'Приемщик химчистки',
      type: 'professionType',
    },
    {
      i: 4159,
      checked: false,
      name: 'Пятновыводчик',
      type: 'professionType',
    },
    {
      i: 4160,
      checked: false,
      name: 'Санитар',
      type: 'professionType',
    },
    {
      i: 4161,
      checked: false,
      name: 'Сестра-хозяйка',
      type: 'professionType',
    },
    {
      i: 4162,
      checked: false,
      name: 'Сотрудник химчистки',
      type: 'professionType',
    },
    {
      i: 4163,
      checked: false,
      name: 'Специалист АХО',
      type: 'professionType',
    },
    {
      i: 4164,
      checked: false,
      name: 'Специалист по клинингу',
      type: 'professionType',
    },
    {
      i: 4165,
      checked: false,
      name: 'Специалист по обслуживанию бассейнов',
      type: 'professionType',
    },
    {
      i: 4166,
      checked: false,
      name: 'Специалист по стирке ковров',
      type: 'professionType',
    },
    {
      i: 4167,
      checked: false,
      name: 'Старшая горничная',
      type: 'professionType',
    },
    {
      i: 4168,
      checked: false,
      name: 'Старший уборщик',
      type: 'professionType',
    },
    {
      i: 4169,
      checked: false,
      name: 'Уборщик производственных помещений',
      type: 'professionType',
    },
    {
      i: 4170,
      checked: false,
      name: 'Уборщик складских помещений',
      type: 'professionType',
    },
    {
      i: 4171,
      checked: false,
      name: 'Уборщик-водитель',
      type: 'professionType',
    },
    {
      i: 4172,
      checked: false,
      name: 'Уборщик-грузчик',
      type: 'professionType',
    },
    {
      i: 4173,
      checked: false,
      name: 'Уборщик-котломойщик',
      type: 'professionType',
    },
    {
      i: 4174,
      checked: false,
      name: 'Уборщик-посудомойщик',
      type: 'professionType',
    },
    {
      i: 4175,
      checked: false,
      name: 'Уборщик/уборщица',
      type: 'professionType',
    },
    {
      i: 4176,
      checked: false,
      name: 'Project manager',
      type: 'professionType',
    },
    {
      i: 4177,
      checked: false,
      name: 'Ведущий менеджер по сопровождению',
      type: 'professionType',
    },
    {
      i: 4178,
      checked: false,
      name: 'Генеральный директор',
      type: 'professionType',
    },
    {
      i: 4179,
      checked: false,
      name: 'Директор',
      type: 'professionType',
    },
    {
      i: 4180,
      checked: false,
      name: 'Директор В2В',
      type: 'professionType',
    },
    {
      i: 4181,
      checked: false,
      name: 'Директор магазина',
      type: 'professionType',
    },
    {
      i: 4182,
      checked: false,
      name: 'Директор по развитию',
      type: 'professionType',
    },
    {
      i: 4183,
      checked: false,
      name: 'Директор производства',
      type: 'professionType',
    },
    {
      i: 4184,
      checked: false,
      name: 'Директор ресторана',
      type: 'professionType',
    },
    {
      i: 4185,
      checked: false,
      name: 'Зам.руководителя коммерческого отдела',
      type: 'professionType',
    },
    {
      i: 4186,
      checked: false,
      name: 'Заместитель администратора',
      type: 'professionType',
    },
    {
      i: 4187,
      checked: false,
      name: 'Заместитель генерального директора',
      type: 'professionType',
    },
    {
      i: 4188,
      checked: false,
      name: 'Заместитель директора',
      type: 'professionType',
    },
    {
      i: 4189,
      checked: false,
      name: 'Заместитель директора магазина',
      type: 'professionType',
    },
    {
      i: 4190,
      checked: false,
      name: 'Заместитель коммерческого директора по продажам',
      type: 'professionType',
    },
    {
      i: 4191,
      checked: false,
      name: 'Заместитель конструктора',
      type: 'professionType',
    },
    {
      i: 4192,
      checked: false,
      name: 'Заместитель начальника отделения почтовой связи',
      type: 'professionType',
    },
    {
      i: 4193,
      checked: false,
      name: 'Заместитель руководителя отдела продаж недвижимости',
      type: 'professionType',
    },
    {
      i: 4194,
      checked: false,
      name: 'Заместитель руководителя проекта',
      type: 'professionType',
    },
    {
      i: 4195,
      checked: false,
      name: 'Коммерческий директор',
      type: 'professionType',
    },
    {
      i: 4196,
      checked: false,
      name: 'Корпоративный менеджер',
      type: 'professionType',
    },
    {
      i: 4197,
      checked: false,
      name: 'Начальник',
      type: 'professionType',
    },
    {
      i: 4198,
      checked: false,
      name: 'Начальник департамента первичного учета',
      type: 'professionType',
    },
    {
      i: 4199,
      checked: false,
      name: 'Начальник отдела',
      type: 'professionType',
    },
    {
      i: 4200,
      checked: false,
      name: 'Начальник отдела кадров',
      type: 'professionType',
    },
    {
      i: 4201,
      checked: false,
      name: 'Начальник отделения почтовой связи',
      type: 'professionType',
    },
    {
      i: 4202,
      checked: false,
      name: 'Начальник сантехнического участка',
      type: 'professionType',
    },
    {
      i: 4203,
      checked: false,
      name: 'Начальник сектора развития инфраструктуры',
      type: 'professionType',
    },
    {
      i: 4204,
      checked: false,
      name: 'Операционный менеджер',
      type: 'professionType',
    },
    {
      i: 4205,
      checked: false,
      name: 'Региональный директор',
      type: 'professionType',
    },
    {
      i: 4206,
      checked: false,
      name: 'Региональный технический представитель',
      type: 'professionType',
    },
    {
      i: 4207,
      checked: false,
      name: 'Руководитель',
      type: 'professionType',
    },
    {
      i: 4208,
      checked: false,
      name: 'Руководитель call-центра',
      type: 'professionType',
    },
    {
      i: 4209,
      checked: false,
      name: 'Руководитель cpa платформы',
      type: 'professionType',
    },
    {
      i: 4210,
      checked: false,
      name: 'Руководитель digital-проектов',
      type: 'professionType',
    },
    {
      i: 4211,
      checked: false,
      name: 'Руководитель ЛАРН',
      type: 'professionType',
    },
    {
      i: 4212,
      checked: false,
      name: 'Руководитель СТО',
      type: 'professionType',
    },
    {
      i: 4213,
      checked: false,
      name: 'Руководитель агентской группы',
      type: 'professionType',
    },
    {
      i: 4214,
      checked: false,
      name: 'Руководитель группы',
      type: 'professionType',
    },
    {
      i: 4215,
      checked: false,
      name: 'Руководитель группы веб-разработки',
      type: 'professionType',
    },
    {
      i: 4216,
      checked: false,
      name: 'Руководитель группы поддержки информационных систем',
      type: 'professionType',
    },
    {
      i: 4217,
      checked: false,
      name: 'Руководитель диспетчерской службы',
      type: 'professionType',
    },
    {
      i: 4218,
      checked: false,
      name: 'Руководитель команды',
      type: 'professionType',
    },
    {
      i: 4219,
      checked: false,
      name: 'Руководитель коммерческого отдела',
      type: 'professionType',
    },
    {
      i: 4220,
      checked: false,
      name: 'Руководитель материально-технического обеспечения',
      type: 'professionType',
    },
    {
      i: 4221,
      checked: false,
      name: 'Руководитель отдела аналитики и сопровождения',
      type: 'professionType',
    },
    {
      i: 4222,
      checked: false,
      name: 'Руководитель отдела запасных частей',
      type: 'professionType',
    },
    {
      i: 4223,
      checked: false,
      name: 'Руководитель отдела кузовного ремонта',
      type: 'professionType',
    },
    {
      i: 4224,
      checked: false,
      name: 'Руководитель отдела логистики',
      type: 'professionType',
    },
    {
      i: 4225,
      checked: false,
      name: 'Руководитель отдела маркетинга',
      type: 'professionType',
    },
    {
      i: 4226,
      checked: false,
      name: 'Руководитель отдела персонала',
      type: 'professionType',
    },
    {
      i: 4227,
      checked: false,
      name: 'Руководитель отдела по продвижению youtubе-каналов/продюсер youtube-канала',
      type: 'professionType',
    },
    {
      i: 4228,
      checked: false,
      name: 'Руководитель отдела приемки',
      type: 'professionType',
    },
    {
      i: 4229,
      checked: false,
      name: 'Руководитель отдела продаж',
      type: 'professionType',
    },
    {
      i: 4230,
      checked: false,
      name: 'Руководитель отдела продаж загородной недвижимости',
      type: 'professionType',
    },
    {
      i: 4231,
      checked: false,
      name: 'Руководитель отдела продаж недвижимости',
      type: 'professionType',
    },
    {
      i: 4232,
      checked: false,
      name: 'Руководитель отдела разработки',
      type: 'professionType',
    },
    {
      i: 4233,
      checked: false,
      name: 'Руководитель отдела рекламы',
      type: 'professionType',
    },
    {
      i: 4234,
      checked: false,
      name: 'Руководитель отдела сертификации',
      type: 'professionType',
    },
    {
      i: 4235,
      checked: false,
      name: 'Руководитель отдела фигурных изделий',
      type: 'professionType',
    },
    {
      i: 4236,
      checked: false,
      name: 'Руководитель офиса продаж',
      type: 'professionType',
    },
    {
      i: 4237,
      checked: false,
      name: 'Руководитель охраны труда',
      type: 'professionType',
    },
    {
      i: 4238,
      checked: false,
      name: 'Руководитель проекта',
      type: 'professionType',
    },
    {
      i: 4239,
      checked: false,
      name: 'Руководитель розничной сети',
      type: 'professionType',
    },
    {
      i: 4240,
      checked: false,
      name: 'Руководитель сектора',
      type: 'professionType',
    },
    {
      i: 4241,
      checked: false,
      name: 'Руководитель сервисного отдела',
      type: 'professionType',
    },
    {
      i: 4242,
      checked: false,
      name: 'Руководитель службы безопасности',
      type: 'professionType',
    },
    {
      i: 4243,
      checked: false,
      name: 'Руководитель филиала',
      type: 'professionType',
    },
    {
      i: 4244,
      checked: false,
      name: 'Библиотекарь',
      type: 'professionType',
    },
    {
      i: 4245,
      checked: false,
      name: 'Бригадир пути',
      type: 'professionType',
    },
    {
      i: 4246,
      checked: false,
      name: 'Ведущий специалист отдела капитального строительства',
      type: 'professionType',
    },
    {
      i: 4247,
      checked: false,
      name: 'Взрывник',
      type: 'professionType',
    },
    {
      i: 4248,
      checked: false,
      name: 'Водитель автобуса',
      type: 'professionType',
    },
    {
      i: 4249,
      checked: false,
      name: 'Водитель пожарного автомобиля',
      type: 'professionType',
    },
    {
      i: 4250,
      checked: false,
      name: 'Водитель трамвая',
      type: 'professionType',
    },
    {
      i: 4251,
      checked: false,
      name: 'Водитель троллейбуса',
      type: 'professionType',
    },
    {
      i: 4252,
      checked: false,
      name: 'Водитель-химик',
      type: 'professionType',
    },
    {
      i: 4253,
      checked: false,
      name: 'Военнослужащий',
      type: 'professionType',
    },
    {
      i: 4254,
      checked: false,
      name: 'Глава администрации',
      type: 'professionType',
    },
    {
      i: 4255,
      checked: false,
      name: 'Гранатометчик',
      type: 'professionType',
    },
    {
      i: 4256,
      checked: false,
      name: 'Диспетчер по регистрации пассажиров',
      type: 'professionType',
    },
    {
      i: 4257,
      checked: false,
      name: 'Дознаватель',
      type: 'professionType',
    },
    {
      i: 4258,
      checked: false,
      name: 'Заместитель главного энергетика',
      type: 'professionType',
    },
    {
      i: 4259,
      checked: false,
      name: 'Заместитель начальника',
      type: 'professionType',
    },
    {
      i: 4260,
      checked: false,
      name: 'Земельный контролер',
      type: 'professionType',
    },
    {
      i: 4261,
      checked: false,
      name: 'Инспектор',
      type: 'professionType',
    },
    {
      i: 4262,
      checked: false,
      name: 'Инспектор ДПС',
      type: 'professionType',
    },
    {
      i: 4263,
      checked: false,
      name: 'Инспектор отделения дорожного надзора',
      type: 'professionType',
    },
    {
      i: 4264,
      checked: false,
      name: 'Инспектор службы перронного контроля',
      type: 'professionType',
    },
    {
      i: 4265,
      checked: false,
      name: 'Инструктор-кинолог',
      type: 'professionType',
    },
    {
      i: 4266,
      checked: false,
      name: 'Испытатель',
      type: 'professionType',
    },
    {
      i: 4267,
      checked: false,
      name: 'Кинолог',
      type: 'professionType',
    },
    {
      i: 4268,
      checked: false,
      name: 'Командир отделения',
      type: 'professionType',
    },
    {
      i: 4269,
      checked: false,
      name: 'Консультант по работе с пенсионным фондом России',
      type: 'professionType',
    },
    {
      i: 4270,
      checked: false,
      name: 'Корреспондент',
      type: 'professionType',
    },
    {
      i: 4271,
      checked: false,
      name: 'Криминалист',
      type: 'professionType',
    },
    {
      i: 4272,
      checked: false,
      name: 'Курсант',
      type: 'professionType',
    },
    {
      i: 4273,
      checked: false,
      name: 'Маневровый диспетчер',
      type: 'professionType',
    },
    {
      i: 4274,
      checked: false,
      name: 'Мастер МПС',
      type: 'professionType',
    },
    {
      i: 4275,
      checked: false,
      name: 'Мастер по ремонту тепловозов',
      type: 'professionType',
    },
    {
      i: 4276,
      checked: false,
      name: 'Мастер службы линий электропередачи',
      type: 'professionType',
    },
    {
      i: 4277,
      checked: false,
      name: 'Мастер участка теплоснабжения',
      type: 'professionType',
    },
    {
      i: 4278,
      checked: false,
      name: 'Машинист-обходчик',
      type: 'professionType',
    },
    {
      i: 4279,
      checked: false,
      name: 'Младший инспектор',
      type: 'professionType',
    },
    {
      i: 4280,
      checked: false,
      name: 'Младший судебный пристав',
      type: 'professionType',
    },
    {
      i: 4281,
      checked: false,
      name: 'Монтер пути',
      type: 'professionType',
    },
    {
      i: 4282,
      checked: false,
      name: 'Наводчик',
      type: 'professionType',
    },
    {
      i: 4283,
      checked: false,
      name: 'Налоговый инспектор',
      type: 'professionType',
    },
    {
      i: 4284,
      checked: false,
      name: 'Налоговый консультант',
      type: 'professionType',
    },
    {
      i: 4285,
      checked: false,
      name: 'Нарядчик',
      type: 'professionType',
    },
    {
      i: 4286,
      checked: false,
      name: 'Нарядчик поездных бригад',
      type: 'professionType',
    },
    {
      i: 4287,
      checked: false,
      name: 'Начальник автоколонны',
      type: 'professionType',
    },
    {
      i: 4288,
      checked: false,
      name: 'Начальник кадастрового отдела',
      type: 'professionType',
    },
    {
      i: 4289,
      checked: false,
      name: 'Начальник караула',
      type: 'professionType',
    },
    {
      i: 4290,
      checked: false,
      name: 'Начальник отделения почтовой связи',
      type: 'professionType',
    },
    {
      i: 4291,
      checked: false,
      name: 'Начальник по земельным отношениям',
      type: 'professionType',
    },
    {
      i: 4292,
      checked: false,
      name: 'Начальник связи',
      type: 'professionType',
    },
    {
      i: 4293,
      checked: false,
      name: 'Номер расчёта',
      type: 'professionType',
    },
    {
      i: 4294,
      checked: false,
      name: 'Оператор по путевым измерениям',
      type: 'professionType',
    },
    {
      i: 4295,
      checked: false,
      name: 'Оперуполномоченный',
      type: 'professionType',
    },
    {
      i: 4296,
      checked: false,
      name: 'Осмотрщик вагонов',
      type: 'professionType',
    },
    {
      i: 4297,
      checked: false,
      name: 'Осмотрщик-ремонтник вагонов',
      type: 'professionType',
    },
    {
      i: 4298,
      checked: false,
      name: 'Паспортист',
      type: 'professionType',
    },
    {
      i: 4299,
      checked: false,
      name: 'Переписчик',
      type: 'professionType',
    },
    {
      i: 4300,
      checked: false,
      name: 'Перронный контролер',
      type: 'professionType',
    },
    {
      i: 4301,
      checked: false,
      name: 'Пограничник',
      type: 'professionType',
    },
    {
      i: 4302,
      checked: false,
      name: 'Поездной электромеханик',
      type: 'professionType',
    },
    {
      i: 4303,
      checked: false,
      name: 'Пожарный',
      type: 'professionType',
    },
    {
      i: 4304,
      checked: false,
      name: 'Полицейский',
      type: 'professionType',
    },
    {
      i: 4305,
      checked: false,
      name: 'Полицейский-водитель',
      type: 'professionType',
    },
    {
      i: 4306,
      checked: false,
      name: 'Помощник арбитражного управляющего',
      type: 'professionType',
    },
    {
      i: 4307,
      checked: false,
      name: 'Помощник машиниста',
      type: 'professionType',
    },
    {
      i: 4308,
      checked: false,
      name: 'Помощник машиниста электропоезда',
      type: 'professionType',
    },
    {
      i: 4309,
      checked: false,
      name: 'Помощник начальника по внешнеэкономическим связям',
      type: 'professionType',
    },
    {
      i: 4310,
      checked: false,
      name: 'Помощник судьи',
      type: 'professionType',
    },
    {
      i: 4311,
      checked: false,
      name: 'Почтальон',
      type: 'professionType',
    },
    {
      i: 4312,
      checked: false,
      name: 'Проводник пассажирского вагона',
      type: 'professionType',
    },
    {
      i: 4313,
      checked: false,
      name: 'Психолог-инспектор',
      type: 'professionType',
    },
    {
      i: 4314,
      checked: false,
      name: 'Пулеметчик',
      type: 'professionType',
    },
    {
      i: 4315,
      checked: false,
      name: 'Работник зоопарка',
      type: 'professionType',
    },
    {
      i: 4316,
      checked: false,
      name: 'Радиотелеграфист',
      type: 'professionType',
    },
    {
      i: 4317,
      checked: false,
      name: 'Радиотелефонист',
      type: 'professionType',
    },
    {
      i: 4318,
      checked: false,
      name: 'Разведчик',
      type: 'professionType',
    },
    {
      i: 4319,
      checked: false,
      name: 'Разработчик ксодд',
      type: 'professionType',
    },
    {
      i: 4320,
      checked: false,
      name: 'Регулировщик',
      type: 'professionType',
    },
    {
      i: 4321,
      checked: false,
      name: 'Референт',
      type: 'professionType',
    },
    {
      i: 4322,
      checked: false,
      name: 'Рулевой-сигнальщик',
      type: 'professionType',
    },
    {
      i: 4323,
      checked: false,
      name: 'Санитарный инспектор',
      type: 'professionType',
    },
    {
      i: 4324,
      checked: false,
      name: 'Сапёр',
      type: 'professionType',
    },
    {
      i: 4325,
      checked: false,
      name: 'Секретарь судебного заседания',
      type: 'professionType',
    },
    {
      i: 4326,
      checked: false,
      name: 'Сигналист',
      type: 'professionType',
    },
    {
      i: 4327,
      checked: false,
      name: 'Следователь',
      type: 'professionType',
    },
    {
      i: 4328,
      checked: false,
      name: 'Снайпер',
      type: 'professionType',
    },
    {
      i: 4329,
      checked: false,
      name: 'Солдат',
      type: 'professionType',
    },
    {
      i: 4330,
      checked: false,
      name: 'Составитель поездов',
      type: 'professionType',
    },
    {
      i: 4331,
      checked: false,
      name: 'Сотрудник УИС',
      type: 'professionType',
    },
    {
      i: 4332,
      checked: false,
      name: 'Сотрудник отдела специального учета',
      type: 'professionType',
    },
    {
      i: 4333,
      checked: false,
      name: 'Сотрудник паспортного контроля',
      type: 'professionType',
    },
    {
      i: 4334,
      checked: false,
      name: 'Специалист МФЦ',
      type: 'professionType',
    },
    {
      i: 4335,
      checked: false,
      name: 'Специалист отдела ОМС',
      type: 'professionType',
    },
    {
      i: 4336,
      checked: false,
      name: 'Специалист отдела РСП',
      type: 'professionType',
    },
    {
      i: 4337,
      checked: false,
      name: 'Специалист отдела государственного земельного надзора',
      type: 'professionType',
    },
    {
      i: 4338,
      checked: false,
      name: 'Специалист по ГИС ЖКХ',
      type: 'professionType',
    },
    {
      i: 4339,
      checked: false,
      name: 'Специалист по ПОД/ФТ',
      type: 'professionType',
    },
    {
      i: 4340,
      checked: false,
      name: 'Специалист по аренде вагонов',
      type: 'professionType',
    },
    {
      i: 4341,
      checked: false,
      name: 'Специалист по безопасности дорожного движения',
      type: 'professionType',
    },
    {
      i: 4342,
      checked: false,
      name: 'Специалист по взаимодействию с государственными органами',
      type: 'professionType',
    },
    {
      i: 4343,
      checked: false,
      name: 'Специалист по воинскому учету',
      type: 'professionType',
    },
    {
      i: 4344,
      checked: false,
      name: 'Специалист по государственным закупкам',
      type: 'professionType',
    },
    {
      i: 4345,
      checked: false,
      name: 'Специалист по документам',
      type: 'professionType',
    },
    {
      i: 4346,
      checked: false,
      name: 'Специалист по исполнительному производству',
      type: 'professionType',
    },
    {
      i: 4347,
      checked: false,
      name: 'Специалист по международной деятельности',
      type: 'professionType',
    },
    {
      i: 4348,
      checked: false,
      name: 'Специалист по миграционному учету',
      type: 'professionType',
    },
    {
      i: 4349,
      checked: false,
      name: 'Специалист по мобилизационной подготовке и го',
      type: 'professionType',
    },
    {
      i: 4350,
      checked: false,
      name: 'Специалист по налогам',
      type: 'professionType',
    },
    {
      i: 4351,
      checked: false,
      name: 'Специалист по налогообложению',
      type: 'professionType',
    },
    {
      i: 4352,
      checked: false,
      name: 'Специалист по неразрушающему контролю',
      type: 'professionType',
    },
    {
      i: 4353,
      checked: false,
      name: 'Специалист по обслуживанию маломобильных пассажиров',
      type: 'professionType',
    },
    {
      i: 4354,
      checked: false,
      name: 'Специалист по охране труда и пожарной безопасности',
      type: 'professionType',
    },
    {
      i: 4355,
      checked: false,
      name: 'Специалист по работе с исполнительным производством',
      type: 'professionType',
    },
    {
      i: 4356,
      checked: false,
      name: 'Специалист по работе с населением',
      type: 'professionType',
    },
    {
      i: 4357,
      checked: false,
      name: 'Специалист по социальной работе',
      type: 'professionType',
    },
    {
      i: 4358,
      checked: false,
      name: 'Специалист по управлению мкд',
      type: 'professionType',
    },
    {
      i: 4359,
      checked: false,
      name: 'Специалист рхбз',
      type: 'professionType',
    },
    {
      i: 4360,
      checked: false,
      name: 'Старший инспектор',
      type: 'professionType',
    },
    {
      i: 4361,
      checked: false,
      name: 'Старший радиотелефонист',
      type: 'professionType',
    },
    {
      i: 4362,
      checked: false,
      name: 'Стволовой',
      type: 'professionType',
    },
    {
      i: 4363,
      checked: false,
      name: 'Стрелок',
      type: 'professionType',
    },
    {
      i: 4364,
      checked: false,
      name: 'Судебный представитель',
      type: 'professionType',
    },
    {
      i: 4365,
      checked: false,
      name: 'Судебный пристав',
      type: 'professionType',
    },
    {
      i: 4366,
      checked: false,
      name: 'Судья',
      type: 'professionType',
    },
    {
      i: 4367,
      checked: false,
      name: 'Таможенный инспектор',
      type: 'professionType',
    },
    {
      i: 4368,
      checked: false,
      name: 'Телеграфист',
      type: 'professionType',
    },
    {
      i: 4369,
      checked: false,
      name: 'Технический эксперт',
      type: 'professionType',
    },
    {
      i: 4370,
      checked: false,
      name: 'Учётчик',
      type: 'professionType',
    },
    {
      i: 4371,
      checked: false,
      name: 'Член попечительского совета',
      type: 'professionType',
    },
    {
      i: 4372,
      checked: false,
      name: 'Эксперт по сертификации',
      type: 'professionType',
    },
    {
      i: 4373,
      checked: false,
      name: 'Эксперт-расчётчик',
      type: 'professionType',
    },
    {
      i: 4374,
      checked: false,
      name: 'Автоняня',
      type: 'professionType',
    },
    {
      i: 4375,
      checked: false,
      name: 'Выгульщик собак',
      type: 'professionType',
    },
    {
      i: 4376,
      checked: false,
      name: 'Домработница',
      type: 'professionType',
    },
    {
      i: 4377,
      checked: false,
      name: 'Котоняня',
      type: 'professionType',
    },
    {
      i: 4378,
      checked: false,
      name: 'Личный водитель',
      type: 'professionType',
    },
    {
      i: 4379,
      checked: false,
      name: 'Няня',
      type: 'professionType',
    },
    {
      i: 4380,
      checked: false,
      name: 'Помощник по хозяйству',
      type: 'professionType',
    },
    {
      i: 4381,
      checked: false,
      name: 'Сиделка',
      type: 'professionType',
    },
    {
      i: 4382,
      checked: false,
      name: 'Специалист по клинингу',
      type: 'professionType',
    },
    {
      i: 4383,
      checked: false,
      name: 'Уборщик/уборщица',
      type: 'professionType',
    },
    {
      i: 4384,
      checked: false,
      name: 'Хаус-мастер',
      type: 'professionType',
    },
    {
      i: 4385,
      checked: false,
      name: 'Хаусмен',
      type: 'professionType',
    },
    {
      i: 4386,
      checked: false,
      name: 'Агент фотографа',
      type: 'professionType',
    },
    {
      i: 4387,
      checked: false,
      name: 'Ведущий специалист по телекоммуникациям',
      type: 'professionType',
    },
    {
      i: 4388,
      checked: false,
      name: 'Видео-монтажер',
      type: 'professionType',
    },
    {
      i: 4389,
      checked: false,
      name: 'Видео-оператор',
      type: 'professionType',
    },
    {
      i: 4390,
      checked: false,
      name: 'Главный редактор',
      type: 'professionType',
    },
    {
      i: 4391,
      checked: false,
      name: 'Журналист',
      type: 'professionType',
    },
    {
      i: 4392,
      checked: false,
      name: 'Интервьюер',
      type: 'professionType',
    },
    {
      i: 4393,
      checked: false,
      name: 'Каллиграф',
      type: 'professionType',
    },
    {
      i: 4394,
      checked: false,
      name: 'Копирайтер',
      type: 'professionType',
    },
    {
      i: 4395,
      checked: false,
      name: 'Копировальщик',
      type: 'professionType',
    },
    {
      i: 4396,
      checked: false,
      name: 'Корректор',
      type: 'professionType',
    },
    {
      i: 4397,
      checked: false,
      name: 'Корреспондент',
      type: 'professionType',
    },
    {
      i: 4398,
      checked: false,
      name: 'Макетчик',
      type: 'professionType',
    },
    {
      i: 4399,
      checked: false,
      name: 'Макетчик по рекламе',
      type: 'professionType',
    },
    {
      i: 4400,
      checked: false,
      name: 'Макетчик-моделист',
      type: 'professionType',
    },
    {
      i: 4401,
      checked: false,
      name: 'Менеджер-корреспондент',
      type: 'professionType',
    },
    {
      i: 4402,
      checked: false,
      name: 'Переплетчик',
      type: 'professionType',
    },
    {
      i: 4403,
      checked: false,
      name: 'Печатник',
      type: 'professionType',
    },
    {
      i: 4404,
      checked: false,
      name: 'Печатник-шелкограф',
      type: 'professionType',
    },
    {
      i: 4405,
      checked: false,
      name: 'Полиграфист',
      type: 'professionType',
    },
    {
      i: 4406,
      checked: false,
      name: 'Помощник фотографа',
      type: 'professionType',
    },
    {
      i: 4407,
      checked: false,
      name: 'Постпечатник',
      type: 'professionType',
    },
    {
      i: 4408,
      checked: false,
      name: 'Принт-менеджер',
      type: 'professionType',
    },
    {
      i: 4409,
      checked: false,
      name: 'Работник типографии',
      type: 'professionType',
    },
    {
      i: 4410,
      checked: false,
      name: 'Редактор',
      type: 'professionType',
    },
    {
      i: 4411,
      checked: false,
      name: 'Рерайтер',
      type: 'professionType',
    },
    {
      i: 4412,
      checked: false,
      name: 'Специалист в интернет-издательство',
      type: 'professionType',
    },
    {
      i: 4413,
      checked: false,
      name: 'Специалист в сфере телекоммуникаций',
      type: 'professionType',
    },
    {
      i: 4414,
      checked: false,
      name: 'Специалист по препресс-подготовке',
      type: 'professionType',
    },
    {
      i: 4415,
      checked: false,
      name: 'Специалист постпечатной обработки',
      type: 'professionType',
    },
    {
      i: 4416,
      checked: false,
      name: 'Типограф',
      type: 'professionType',
    },
    {
      i: 4417,
      checked: false,
      name: 'Трафаретчик',
      type: 'professionType',
    },
    {
      i: 4418,
      checked: false,
      name: 'Ученик печатника шелкографии',
      type: 'professionType',
    },
    {
      i: 4419,
      checked: false,
      name: 'Фотограф',
      type: 'professionType',
    },
    {
      i: 4420,
      checked: false,
      name: 'Шелкограф',
      type: 'professionType',
    },
    {
      i: 4421,
      checked: false,
      name: '2d художник',
      type: 'professionType',
    },
    {
      i: 4422,
      checked: false,
      name: '3d-аниматор',
      type: 'professionType',
    },
    {
      i: 4423,
      checked: false,
      name: '3d-дизайнер',
      type: 'professionType',
    },
    {
      i: 4424,
      checked: false,
      name: '3d-моделлер',
      type: 'professionType',
    },
    {
      i: 4425,
      checked: false,
      name: 'Android-разработчик',
      type: 'professionType',
    },
    {
      i: 4426,
      checked: false,
      name: 'Backend-разработчик',
      type: 'professionType',
    },
    {
      i: 4427,
      checked: false,
      name: 'Bim-менеджер',
      type: 'professionType',
    },
    {
      i: 4428,
      checked: false,
      name: 'Bitrix-разработчик',
      type: 'professionType',
    },
    {
      i: 4429,
      checked: false,
      name: 'CRM-специалист',
      type: 'professionType',
    },
    {
      i: 4430,
      checked: false,
      name: 'Devops специалист',
      type: 'professionType',
    },
    {
      i: 4431,
      checked: false,
      name: 'Frontend-разработчик',
      type: 'professionType',
    },
    {
      i: 4432,
      checked: false,
      name: 'Full-stack web-разработчик',
      type: 'professionType',
    },
    {
      i: 4433,
      checked: false,
      name: 'Full-stack разработчик',
      type: 'professionType',
    },
    {
      i: 4434,
      checked: false,
      name: 'Golang-разработчик',
      type: 'professionType',
    },
    {
      i: 4435,
      checked: false,
      name: 'IT-специалист',
      type: 'professionType',
    },
    {
      i: 4436,
      checked: false,
      name: 'Ios-разработчик',
      type: 'professionType',
    },
    {
      i: 4437,
      checked: false,
      name: 'Java-программист',
      type: 'professionType',
    },
    {
      i: 4438,
      checked: false,
      name: 'Junior data scientist',
      type: 'professionType',
    },
    {
      i: 4439,
      checked: false,
      name: 'PPC-специалист',
      type: 'professionType',
    },
    {
      i: 4440,
      checked: false,
      name: 'Php-разработчик',
      type: 'professionType',
    },
    {
      i: 4441,
      checked: false,
      name: 'Python-разработчик',
      type: 'professionType',
    },
    {
      i: 4442,
      checked: false,
      name: 'QA-специалист',
      type: 'professionType',
    },
    {
      i: 4443,
      checked: false,
      name: 'RND-инженер',
      type: 'professionType',
    },
    {
      i: 4444,
      checked: false,
      name: 'React-разработчик',
      type: 'professionType',
    },
    {
      i: 4445,
      checked: false,
      name: 'UX-исследователь цифровых сервисов',
      type: 'professionType',
    },
    {
      i: 4446,
      checked: false,
      name: 'Unity-разработчик',
      type: 'professionType',
    },
    {
      i: 4447,
      checked: false,
      name: 'UХ/UI дизайнер',
      type: 'professionType',
    },
    {
      i: 4448,
      checked: false,
      name: 'Авитолог',
      type: 'professionType',
    },
    {
      i: 4449,
      checked: false,
      name: 'Администратор 1C',
      type: 'professionType',
    },
    {
      i: 4450,
      checked: false,
      name: 'Администратор aveva',
      type: 'professionType',
    },
    {
      i: 4451,
      checked: false,
      name: 'Администратор linux',
      type: 'professionType',
    },
    {
      i: 4452,
      checked: false,
      name: 'Администратор баз данных',
      type: 'professionType',
    },
    {
      i: 4453,
      checked: false,
      name: 'Администратор по электронной торговле',
      type: 'professionType',
    },
    {
      i: 4454,
      checked: false,
      name: 'Администратор/программист 1С',
      type: 'professionType',
    },
    {
      i: 4455,
      checked: false,
      name: 'Аналитик данных',
      type: 'professionType',
    },
    {
      i: 4456,
      checked: false,
      name: 'Аналитик проектов внедрения 1C',
      type: 'professionType',
    },
    {
      i: 4457,
      checked: false,
      name: 'Аналитик-тестировщик',
      type: 'professionType',
    },
    {
      i: 4458,
      checked: false,
      name: 'Архитектор SharePoint',
      type: 'professionType',
    },
    {
      i: 4459,
      checked: false,
      name: 'Ассистент отдела разработки',
      type: 'professionType',
    },
    {
      i: 4460,
      checked: false,
      name: 'Веб программист-стажер',
      type: 'professionType',
    },
    {
      i: 4461,
      checked: false,
      name: 'Веб-аналитик',
      type: 'professionType',
    },
    {
      i: 4462,
      checked: false,
      name: 'Веб-дизайнер',
      type: 'professionType',
    },
    {
      i: 4463,
      checked: false,
      name: 'Веб-мастер',
      type: 'professionType',
    },
    {
      i: 4464,
      checked: false,
      name: 'Веб-разработчик',
      type: 'professionType',
    },
    {
      i: 4465,
      checked: false,
      name: 'Ведущий разработчик',
      type: 'professionType',
    },
    {
      i: 4466,
      checked: false,
      name: 'Ведущий специалист по сопровождению информационных систем',
      type: 'professionType',
    },
    {
      i: 4467,
      checked: false,
      name: 'Ведущий специалист по технической апробации',
      type: 'professionType',
    },
    {
      i: 4468,
      checked: false,
      name: 'Ведущий специалист пользовательского тестирования',
      type: 'professionType',
    },
    {
      i: 4469,
      checked: false,
      name: 'Верстальщик',
      type: 'professionType',
    },
    {
      i: 4470,
      checked: false,
      name: 'Визуализатор',
      type: 'professionType',
    },
    {
      i: 4471,
      checked: false,
      name: 'Геймдизайнер',
      type: 'professionType',
    },
    {
      i: 4472,
      checked: false,
      name: 'Геймдизайнер по балансу',
      type: 'professionType',
    },
    {
      i: 4473,
      checked: false,
      name: 'Геймдизайнер по монетизации',
      type: 'professionType',
    },
    {
      i: 4474,
      checked: false,
      name: 'Генеральный директор',
      type: 'professionType',
    },
    {
      i: 4475,
      checked: false,
      name: 'Главный специалист центра информационного обеспечения',
      type: 'professionType',
    },
    {
      i: 4476,
      checked: false,
      name: 'Графический дизайнер',
      type: 'professionType',
    },
    {
      i: 4477,
      checked: false,
      name: 'Директор В2В',
      type: 'professionType',
    },
    {
      i: 4478,
      checked: false,
      name: 'Игровой аналитик',
      type: 'professionType',
    },
    {
      i: 4479,
      checked: false,
      name: 'Инженер сервисной зоны',
      type: 'professionType',
    },
    {
      i: 4480,
      checked: false,
      name: 'Интегратор',
      type: 'professionType',
    },
    {
      i: 4481,
      checked: false,
      name: 'Интернет-сотрудник',
      type: 'professionType',
    },
    {
      i: 4482,
      checked: false,
      name: 'Исполнительный директор',
      type: 'professionType',
    },
    {
      i: 4483,
      checked: false,
      name: 'Консультант CRM',
      type: 'professionType',
    },
    {
      i: 4484,
      checked: false,
      name: 'Консультант по интернет-коммуникациям',
      type: 'professionType',
    },
    {
      i: 4485,
      checked: false,
      name: 'Контекстолог',
      type: 'professionType',
    },
    {
      i: 4486,
      checked: false,
      name: 'Контент менеджер',
      type: 'professionType',
    },
    {
      i: 4487,
      checked: false,
      name: 'Контент-менеджер',
      type: 'professionType',
    },
    {
      i: 4488,
      checked: false,
      name: 'Лид-дизайнер 2d',
      type: 'professionType',
    },
    {
      i: 4489,
      checked: false,
      name: 'Мастер ПО',
      type: 'professionType',
    },
    {
      i: 4490,
      checked: false,
      name: 'Медиабайер',
      type: 'professionType',
    },
    {
      i: 4491,
      checked: false,
      name: 'Менеджер IT-проектов',
      type: 'professionType',
    },
    {
      i: 4492,
      checked: false,
      name: 'Менеджер sap',
      type: 'professionType',
    },
    {
      i: 4493,
      checked: false,
      name: 'Менеджер отдела качества',
      type: 'professionType',
    },
    {
      i: 4494,
      checked: false,
      name: 'Менеджер по бережливому производству',
      type: 'professionType',
    },
    {
      i: 4495,
      checked: false,
      name: 'Менеджер продукта',
      type: 'professionType',
    },
    {
      i: 4496,
      checked: false,
      name: 'Менеджер рыболовного онлайн проекта',
      type: 'professionType',
    },
    {
      i: 4497,
      checked: false,
      name: 'Младший менеджер продукта',
      type: 'professionType',
    },
    {
      i: 4498,
      checked: false,
      name: 'Младший менеджер проектов',
      type: 'professionType',
    },
    {
      i: 4499,
      checked: false,
      name: 'Младший специалист amoCRM',
      type: 'professionType',
    },
    {
      i: 4500,
      checked: false,
      name: 'Модератор',
      type: 'professionType',
    },
    {
      i: 4501,
      checked: false,
      name: 'Моушн-дизайнер',
      type: 'professionType',
    },
    {
      i: 4502,
      checked: false,
      name: 'Оператор ПК',
      type: 'professionType',
    },
    {
      i: 4503,
      checked: false,
      name: 'Оператор ЭВМ',
      type: 'professionType',
    },
    {
      i: 4504,
      checked: false,
      name: 'Оператор базы данных',
      type: 'professionType',
    },
    {
      i: 4505,
      checked: false,
      name: 'Оператор дистанционной обработки данных',
      type: 'professionType',
    },
    {
      i: 4506,
      checked: false,
      name: 'Оператор службы поддержки',
      type: 'professionType',
    },
    {
      i: 4507,
      checked: false,
      name: 'Оператор электронных заявок',
      type: 'professionType',
    },
    {
      i: 4508,
      checked: false,
      name: 'Операционный директор',
      type: 'professionType',
    },
    {
      i: 4509,
      checked: false,
      name: 'Помощник SMM-специалиста',
      type: 'professionType',
    },
    {
      i: 4510,
      checked: false,
      name: 'Помощник программиста',
      type: 'professionType',
    },
    {
      i: 4511,
      checked: false,
      name: 'Программист',
      type: 'professionType',
    },
    {
      i: 4512,
      checked: false,
      name: 'Программист 1С',
      type: 'professionType',
    },
    {
      i: 4513,
      checked: false,
      name: 'Программист C++',
      type: 'professionType',
    },
    {
      i: 4514,
      checked: false,
      name: 'Программист php',
      type: 'professionType',
    },
    {
      i: 4515,
      checked: false,
      name: 'Программист микроконтроллеров',
      type: 'professionType',
    },
    {
      i: 4516,
      checked: false,
      name: 'Продакт-менеджер',
      type: 'professionType',
    },
    {
      i: 4517,
      checked: false,
      name: 'Продуктовый дизайнер',
      type: 'professionType',
    },
    {
      i: 4518,
      checked: false,
      name: 'Разработчик',
      type: 'professionType',
    },
    {
      i: 4519,
      checked: false,
      name: 'Разработчик C#',
      type: 'professionType',
    },
    {
      i: 4520,
      checked: false,
      name: 'Редактор соцсетей',
      type: 'professionType',
    },
    {
      i: 4521,
      checked: false,
      name: 'Руководитель группы веб-разработки',
      type: 'professionType',
    },
    {
      i: 4522,
      checked: false,
      name: 'Руководитель группы поддержки информационных систем',
      type: 'professionType',
    },
    {
      i: 4523,
      checked: false,
      name: 'Руководитель отдела разработки',
      type: 'professionType',
    },
    {
      i: 4524,
      checked: false,
      name: 'Сборщик онлайн-заказов',
      type: 'professionType',
    },
    {
      i: 4525,
      checked: false,
      name: 'Сетевой инженер',
      type: 'professionType',
    },
    {
      i: 4526,
      checked: false,
      name: 'Системный администратор',
      type: 'professionType',
    },
    {
      i: 4527,
      checked: false,
      name: 'Специалист баз данных',
      type: 'professionType',
    },
    {
      i: 4528,
      checked: false,
      name: 'Специалист в службу поддержки поисковых сервисов',
      type: 'professionType',
    },
    {
      i: 4529,
      checked: false,
      name: 'Специалист в технический отдел',
      type: 'professionType',
    },
    {
      i: 4530,
      checked: false,
      name: 'Специалист второй линии технической поддержки',
      type: 'professionType',
    },
    {
      i: 4531,
      checked: false,
      name: 'Специалист интернет-магазина',
      type: 'professionType',
    },
    {
      i: 4532,
      checked: false,
      name: 'Специалист информационного обслуживания',
      type: 'professionType',
    },
    {
      i: 4533,
      checked: false,
      name: 'Специалист информационного центра',
      type: 'professionType',
    },
    {
      i: 4534,
      checked: false,
      name: 'Специалист первой линии поддержки',
      type: 'professionType',
    },
    {
      i: 4535,
      checked: false,
      name: 'Специалист по автоматизации процессов',
      type: 'professionType',
    },
    {
      i: 4536,
      checked: false,
      name: 'Специалист по безопасности компьютерных систем и сетей',
      type: 'professionType',
    },
    {
      i: 4537,
      checked: false,
      name: 'Специалист по внедрению ERP-систем',
      type: 'professionType',
    },
    {
      i: 4538,
      checked: false,
      name: 'Специалист по внедрению amoCRM',
      type: 'professionType',
    },
    {
      i: 4539,
      checked: false,
      name: 'Специалист по защите информации',
      type: 'professionType',
    },
    {
      i: 4540,
      checked: false,
      name: 'Специалист по интернет статистике',
      type: 'professionType',
    },
    {
      i: 4541,
      checked: false,
      name: 'Специалист по информационным технологиям',
      type: 'professionType',
    },
    {
      i: 4542,
      checked: false,
      name: 'Специалист по обработке онлайн-заявок',
      type: 'professionType',
    },
    {
      i: 4543,
      checked: false,
      name: 'Специалист по обработке чатов',
      type: 'professionType',
    },
    {
      i: 4544,
      checked: false,
      name: 'Специалист по поддержке',
      type: 'professionType',
    },
    {
      i: 4545,
      checked: false,
      name: 'Специалист по техническому обслуживанию',
      type: 'professionType',
    },
    {
      i: 4546,
      checked: false,
      name: 'Специалист по техподдержке',
      type: 'professionType',
    },
    {
      i: 4547,
      checked: false,
      name: 'Специалист по хоккейной статистике',
      type: 'professionType',
    },
    {
      i: 4548,
      checked: false,
      name: 'Специалист службы поддержки пользователей',
      type: 'professionType',
    },
    {
      i: 4549,
      checked: false,
      name: 'Специалист технической поддержки',
      type: 'professionType',
    },
    {
      i: 4550,
      checked: false,
      name: 'Стажер в технический отдел',
      type: 'professionType',
    },
    {
      i: 4551,
      checked: false,
      name: 'Старший аналитик',
      type: 'professionType',
    },
    {
      i: 4552,
      checked: false,
      name: 'Старший программист',
      type: 'professionType',
    },
    {
      i: 4553,
      checked: false,
      name: 'Тестировщик ПО',
      type: 'professionType',
    },
    {
      i: 4554,
      checked: false,
      name: 'Тестировщик компьютерных игр',
      type: 'professionType',
    },
    {
      i: 4555,
      checked: false,
      name: 'Тестировщик мобильных игр',
      type: 'professionType',
    },
    {
      i: 4556,
      checked: false,
      name: 'Тестировщик мобильных приложений',
      type: 'professionType',
    },
    {
      i: 4557,
      checked: false,
      name: 'Технический директор',
      type: 'professionType',
    },
    {
      i: 4558,
      checked: false,
      name: 'GO-GO',
      type: 'professionType',
    },
    {
      i: 4559,
      checked: false,
      name: 'Автор',
      type: 'professionType',
    },
    {
      i: 4560,
      checked: false,
      name: 'Агент фотографа',
      type: 'professionType',
    },
    {
      i: 4561,
      checked: false,
      name: 'Аккомпаниатор',
      type: 'professionType',
    },
    {
      i: 4562,
      checked: false,
      name: 'Актер',
      type: 'professionType',
    },
    {
      i: 4563,
      checked: false,
      name: 'Актер',
      type: 'professionType',
    },
    {
      i: 4564,
      checked: false,
      name: 'Аниматор',
      type: 'professionType',
    },
    {
      i: 4565,
      checked: false,
      name: 'Аниматор-воспитатель',
      type: 'professionType',
    },
    {
      i: 4566,
      checked: false,
      name: 'Аниматор-экскурсовод',
      type: 'professionType',
    },
    {
      i: 4567,
      checked: false,
      name: 'Аранжировщик',
      type: 'professionType',
    },
    {
      i: 4568,
      checked: false,
      name: 'Арт-агент',
      type: 'professionType',
    },
    {
      i: 4569,
      checked: false,
      name: 'Арт-директор',
      type: 'professionType',
    },
    {
      i: 4570,
      checked: false,
      name: 'Артист',
      type: 'professionType',
    },
    {
      i: 4571,
      checked: false,
      name: 'Архитектор',
      type: 'professionType',
    },
    {
      i: 4572,
      checked: false,
      name: 'Архитектор-дизайнер',
      type: 'professionType',
    },
    {
      i: 4573,
      checked: false,
      name: 'Архитектор-проектировщик',
      type: 'professionType',
    },
    {
      i: 4574,
      checked: false,
      name: 'Аэродизайнер',
      type: 'professionType',
    },
    {
      i: 4575,
      checked: false,
      name: 'Балерина',
      type: 'professionType',
    },
    {
      i: 4576,
      checked: false,
      name: 'Бас-гитарист',
      type: 'professionType',
    },
    {
      i: 4577,
      checked: false,
      name: 'Баянист',
      type: 'professionType',
    },
    {
      i: 4578,
      checked: false,
      name: 'Битмейкер',
      type: 'professionType',
    },
    {
      i: 4579,
      checked: false,
      name: 'Блогер',
      type: 'professionType',
    },
    {
      i: 4580,
      checked: false,
      name: 'Бутафор',
      type: 'professionType',
    },
    {
      i: 4581,
      checked: false,
      name: 'Бэк-вокалист',
      type: 'professionType',
    },
    {
      i: 4582,
      checked: false,
      name: 'Ведущий',
      type: 'professionType',
    },
    {
      i: 4583,
      checked: false,
      name: 'Видео-монтажер',
      type: 'professionType',
    },
    {
      i: 4584,
      checked: false,
      name: 'Видео-оператор',
      type: 'professionType',
    },
    {
      i: 4585,
      checked: false,
      name: 'Видеограф',
      type: 'professionType',
    },
    {
      i: 4586,
      checked: false,
      name: 'Витражист',
      type: 'professionType',
    },
    {
      i: 4587,
      checked: false,
      name: 'Волонтёр',
      type: 'professionType',
    },
    {
      i: 4588,
      checked: false,
      name: 'Восковщик',
      type: 'professionType',
    },
    {
      i: 4589,
      checked: false,
      name: 'Гардеробщик',
      type: 'professionType',
    },
    {
      i: 4590,
      checked: false,
      name: 'Гарпунщик',
      type: 'professionType',
    },
    {
      i: 4591,
      checked: false,
      name: 'Гитарист',
      type: 'professionType',
    },
    {
      i: 4592,
      checked: false,
      name: 'Гитарный мастер',
      type: 'professionType',
    },
    {
      i: 4593,
      checked: false,
      name: 'Главный архитектор',
      type: 'professionType',
    },
    {
      i: 4594,
      checked: false,
      name: 'Главный хранитель музейных предметов',
      type: 'professionType',
    },
    {
      i: 4595,
      checked: false,
      name: 'Гравировщик',
      type: 'professionType',
    },
    {
      i: 4596,
      checked: false,
      name: 'Гримёр',
      type: 'professionType',
    },
    {
      i: 4597,
      checked: false,
      name: 'Декоратор',
      type: 'professionType',
    },
    {
      i: 4598,
      checked: false,
      name: 'Декоратор верхней одежды',
      type: 'professionType',
    },
    {
      i: 4599,
      checked: false,
      name: 'Диджей',
      type: 'professionType',
    },
    {
      i: 4600,
      checked: false,
      name: 'Дизайнер',
      type: 'professionType',
    },
    {
      i: 4601,
      checked: false,
      name: 'Дизайнер интерьера',
      type: 'professionType',
    },
    {
      i: 4602,
      checked: false,
      name: 'Дизайнер штор',
      type: 'professionType',
    },
    {
      i: 4603,
      checked: false,
      name: 'Дизайнер-верстальщик',
      type: 'professionType',
    },
    {
      i: 4604,
      checked: false,
      name: 'Дизайнер-визуализатор',
      type: 'professionType',
    },
    {
      i: 4605,
      checked: false,
      name: 'Дизайнер-замерщик корпусной мебели',
      type: 'professionType',
    },
    {
      i: 4606,
      checked: false,
      name: 'Дизайнер-конструктор',
      type: 'professionType',
    },
    {
      i: 4607,
      checked: false,
      name: 'Дизайнер-консультант',
      type: 'professionType',
    },
    {
      i: 4608,
      checked: false,
      name: 'Дизайнер-менеджер',
      type: 'professionType',
    },
    {
      i: 4609,
      checked: false,
      name: 'Дизайнер-моделлер',
      type: 'professionType',
    },
    {
      i: 4610,
      checked: false,
      name: 'Дизайнер-продавец',
      type: 'professionType',
    },
    {
      i: 4611,
      checked: false,
      name: 'Дизайнер-фотограф',
      type: 'professionType',
    },
    {
      i: 4612,
      checked: false,
      name: 'Диктор',
      type: 'professionType',
    },
    {
      i: 4613,
      checked: false,
      name: 'Драпировщик',
      type: 'professionType',
    },
    {
      i: 4614,
      checked: false,
      name: 'Дрессировщик',
      type: 'professionType',
    },
    {
      i: 4615,
      checked: false,
      name: 'Другое',
      type: 'professionType',
    },
    {
      i: 4616,
      checked: false,
      name: 'Звукооператор',
      type: 'professionType',
    },
    {
      i: 4617,
      checked: false,
      name: 'Звукорежиссер',
      type: 'professionType',
    },
    {
      i: 4618,
      checked: false,
      name: 'Изготовитель художественных изделий',
      type: 'professionType',
    },
    {
      i: 4619,
      checked: false,
      name: 'Иллюстратор',
      type: 'professionType',
    },
    {
      i: 4620,
      checked: false,
      name: 'Импресарио',
      type: 'professionType',
    },
    {
      i: 4621,
      checked: false,
      name: 'Инструктор аэротрубы',
      type: 'professionType',
    },
    {
      i: 4622,
      checked: false,
      name: 'Инструктор батутного зала',
      type: 'professionType',
    },
    {
      i: 4623,
      checked: false,
      name: 'Инструктор детского лабиринта',
      type: 'professionType',
    },
    {
      i: 4624,
      checked: false,
      name: 'Искусствовед',
      type: 'professionType',
    },
    {
      i: 4625,
      checked: false,
      name: 'Каллиграф',
      type: 'professionType',
    },
    {
      i: 4626,
      checked: false,
      name: 'Кальянщик',
      type: 'professionType',
    },
    {
      i: 4627,
      checked: false,
      name: 'Капельдинер',
      type: 'professionType',
    },
    {
      i: 4628,
      checked: false,
      name: 'Караокер',
      type: 'professionType',
    },
    {
      i: 4629,
      checked: false,
      name: 'Киномеханик',
      type: 'professionType',
    },
    {
      i: 4630,
      checked: false,
      name: 'Клавишник',
      type: 'professionType',
    },
    {
      i: 4631,
      checked: false,
      name: 'Комментатор',
      type: 'professionType',
    },
    {
      i: 4632,
      checked: false,
      name: 'Композитор',
      type: 'professionType',
    },
    {
      i: 4633,
      checked: false,
      name: 'Конструктор одежды',
      type: 'professionType',
    },
    {
      i: 4634,
      checked: false,
      name: 'Контролер билетов',
      type: 'professionType',
    },
    {
      i: 4635,
      checked: false,
      name: 'Контуровщик',
      type: 'professionType',
    },
    {
      i: 4636,
      checked: false,
      name: 'Концертмейстер',
      type: 'professionType',
    },
    {
      i: 4637,
      checked: false,
      name: 'Концертный директор',
      type: 'professionType',
    },
    {
      i: 4638,
      checked: false,
      name: 'Концертный менеджер',
      type: 'professionType',
    },
    {
      i: 4639,
      checked: false,
      name: 'Костюмер',
      type: 'professionType',
    },
    {
      i: 4640,
      checked: false,
      name: 'Креативный менеджер',
      type: 'professionType',
    },
    {
      i: 4641,
      checked: false,
      name: 'Крупье',
      type: 'professionType',
    },
    {
      i: 4642,
      checked: false,
      name: 'Ландшафтный дизайнер',
      type: 'professionType',
    },
    {
      i: 4643,
      checked: false,
      name: 'Лепщик',
      type: 'professionType',
    },
    {
      i: 4644,
      checked: false,
      name: 'Маркёр',
      type: 'professionType',
    },
    {
      i: 4645,
      checked: false,
      name: 'Маршал',
      type: 'professionType',
    },
    {
      i: 4646,
      checked: false,
      name: 'Массовка',
      type: 'professionType',
    },
    {
      i: 4647,
      checked: false,
      name: 'Мастер по изготовлению бижутерии',
      type: 'professionType',
    },
    {
      i: 4648,
      checked: false,
      name: 'Мастер по изготовлению картин',
      type: 'professionType',
    },
    {
      i: 4649,
      checked: false,
      name: 'Мастер по рукоделию',
      type: 'professionType',
    },
    {
      i: 4650,
      checked: false,
      name: 'Менеджер игрового направления',
      type: 'professionType',
    },
    {
      i: 4651,
      checked: false,
      name: 'Менеджер игровой зоны',
      type: 'professionType',
    },
    {
      i: 4652,
      checked: false,
      name: 'Менеджер катка',
      type: 'professionType',
    },
    {
      i: 4653,
      checked: false,
      name: 'Менеджер кинотеатра',
      type: 'professionType',
    },
    {
      i: 4654,
      checked: false,
      name: 'Методолог',
      type: 'professionType',
    },
    {
      i: 4655,
      checked: false,
      name: 'Модель',
      type: 'professionType',
    },
    {
      i: 4656,
      checked: false,
      name: 'Модель-менеджер',
      type: 'professionType',
    },
    {
      i: 4657,
      checked: false,
      name: 'Модельер',
      type: 'professionType',
    },
    {
      i: 4658,
      checked: false,
      name: 'Модельер-конструктор',
      type: 'professionType',
    },
    {
      i: 4659,
      checked: false,
      name: 'Монтировщик сцены',
      type: 'professionType',
    },
    {
      i: 4660,
      checked: false,
      name: 'Музыкальный менеджер',
      type: 'professionType',
    },
    {
      i: 4661,
      checked: false,
      name: 'Музыкант',
      type: 'professionType',
    },
    {
      i: 4662,
      checked: false,
      name: 'Мультипликатор',
      type: 'professionType',
    },
    {
      i: 4663,
      checked: false,
      name: 'Оператор ЦПМ',
      type: 'professionType',
    },
    {
      i: 4664,
      checked: false,
      name: 'Оператор дисковой линии лесопиления',
      type: 'professionType',
    },
    {
      i: 4665,
      checked: false,
      name: 'Оператор канатных дорог',
      type: 'professionType',
    },
    {
      i: 4666,
      checked: false,
      name: 'Оператор по подземному ремонту скважин',
      type: 'professionType',
    },
    {
      i: 4667,
      checked: false,
      name: 'Осветитель',
      type: 'professionType',
    },
    {
      i: 4668,
      checked: false,
      name: 'Оформитель',
      type: 'professionType',
    },
    {
      i: 4669,
      checked: false,
      name: 'Певец',
      type: 'professionType',
    },
    {
      i: 4670,
      checked: false,
      name: 'Певчий',
      type: 'professionType',
    },
    {
      i: 4671,
      checked: false,
      name: 'Пианист',
      type: 'professionType',
    },
    {
      i: 4672,
      checked: false,
      name: 'Помощник архитектора',
      type: 'professionType',
    },
    {
      i: 4673,
      checked: false,
      name: 'Помощник декоратора',
      type: 'professionType',
    },
    {
      i: 4674,
      checked: false,
      name: 'Помощник дизайнера интерьера',
      type: 'professionType',
    },
    {
      i: 4675,
      checked: false,
      name: 'Помощник звукорежиссера',
      type: 'professionType',
    },
    {
      i: 4676,
      checked: false,
      name: 'Помощник печатника',
      type: 'professionType',
    },
    {
      i: 4677,
      checked: false,
      name: 'Помощник сценариста',
      type: 'professionType',
    },
    {
      i: 4678,
      checked: false,
      name: 'Продюсер',
      type: 'professionType',
    },
    {
      i: 4679,
      checked: false,
      name: 'Промоутер-фотограф',
      type: 'professionType',
    },
    {
      i: 4680,
      checked: false,
      name: 'Работник в фотосалон',
      type: 'professionType',
    },
    {
      i: 4681,
      checked: false,
      name: 'Работник игровой зоны',
      type: 'professionType',
    },
    {
      i: 4682,
      checked: false,
      name: 'Рабочий по уходу за цветами',
      type: 'professionType',
    },
    {
      i: 4683,
      checked: false,
      name: 'Радиооператор',
      type: 'professionType',
    },
    {
      i: 4684,
      checked: false,
      name: 'Разъездной фотограф',
      type: 'professionType',
    },
    {
      i: 4685,
      checked: false,
      name: 'Редактор',
      type: 'professionType',
    },
    {
      i: 4686,
      checked: false,
      name: 'Режиссёр',
      type: 'professionType',
    },
    {
      i: 4687,
      checked: false,
      name: 'Режиссёр-культорганизатор',
      type: 'professionType',
    },
    {
      i: 4688,
      checked: false,
      name: 'Реквизитор',
      type: 'professionType',
    },
    {
      i: 4689,
      checked: false,
      name: 'Реставратор',
      type: 'professionType',
    },
    {
      i: 4690,
      checked: false,
      name: 'Ретушер',
      type: 'professionType',
    },
    {
      i: 4691,
      checked: false,
      name: 'Светооператор',
      type: 'professionType',
    },
    {
      i: 4692,
      checked: false,
      name: 'Светотехник',
      type: 'professionType',
    },
    {
      i: 4693,
      checked: false,
      name: 'Скульптор',
      type: 'professionType',
    },
    {
      i: 4694,
      checked: false,
      name: 'Сопровождение партнеров',
      type: 'professionType',
    },
    {
      i: 4695,
      checked: false,
      name: 'Сотрудник в парк развлечений',
      type: 'professionType',
    },
    {
      i: 4696,
      checked: false,
      name: 'Сотрудник детской площадки',
      type: 'professionType',
    },
    {
      i: 4697,
      checked: false,
      name: 'Сотрудник кинотеатра',
      type: 'professionType',
    },
    {
      i: 4698,
      checked: false,
      name: 'Специалист',
      type: 'professionType',
    },
    {
      i: 4699,
      checked: false,
      name: 'Старший дизайнер-консультант',
      type: 'professionType',
    },
    {
      i: 4700,
      checked: false,
      name: 'Старший контролер билетов',
      type: 'professionType',
    },
    {
      i: 4701,
      checked: false,
      name: 'Стеклодув',
      type: 'professionType',
    },
    {
      i: 4702,
      checked: false,
      name: 'Стример',
      type: 'professionType',
    },
    {
      i: 4703,
      checked: false,
      name: 'Сценарист',
      type: 'professionType',
    },
    {
      i: 4704,
      checked: false,
      name: 'Танцовщица',
      type: 'professionType',
    },
    {
      i: 4705,
      checked: false,
      name: 'Танцор',
      type: 'professionType',
    },
    {
      i: 4706,
      checked: false,
      name: 'Технический дизайнер',
      type: 'professionType',
    },
    {
      i: 4707,
      checked: false,
      name: 'Технический писатель',
      type: 'professionType',
    },
    {
      i: 4708,
      checked: false,
      name: 'Фандрайзер',
      type: 'professionType',
    },
    {
      i: 4709,
      checked: false,
      name: 'Фотограф',
      type: 'professionType',
    },
    {
      i: 4710,
      checked: false,
      name: 'Фотомодель',
      type: 'professionType',
    },
    {
      i: 4711,
      checked: false,
      name: 'Хормейстер',
      type: 'professionType',
    },
    {
      i: 4712,
      checked: false,
      name: 'Художественный руководитель',
      type: 'professionType',
    },
    {
      i: 4713,
      checked: false,
      name: 'Художник',
      type: 'professionType',
    },
    {
      i: 4714,
      checked: false,
      name: 'Художник по свету',
      type: 'professionType',
    },
    {
      i: 4715,
      checked: false,
      name: 'Художник-аэрографист',
      type: 'professionType',
    },
    {
      i: 4716,
      checked: false,
      name: 'Художник-декоратор',
      type: 'professionType',
    },
    {
      i: 4717,
      checked: false,
      name: 'Швея',
      type: 'professionType',
    },
    {
      i: 4718,
      checked: false,
      name: 'Администратор (директор магазина)',
      type: 'professionType',
    },
    {
      i: 4719,
      checked: false,
      name: 'Администратор магазина',
      type: 'professionType',
    },
    {
      i: 4720,
      checked: false,
      name: 'Администратор сервисного центра',
      type: 'professionType',
    },
    {
      i: 4721,
      checked: false,
      name: 'Администратор торгового зала',
      type: 'professionType',
    },
    {
      i: 4722,
      checked: false,
      name: 'Администратор-кассир',
      type: 'professionType',
    },
    {
      i: 4723,
      checked: false,
      name: 'Ассистент зоны КСО',
      type: 'professionType',
    },
    {
      i: 4724,
      checked: false,
      name: 'Ассистент зоны касс самообслуживания',
      type: 'professionType',
    },
    {
      i: 4725,
      checked: false,
      name: 'Ассистент категорийного менеджера',
      type: 'professionType',
    },
    {
      i: 4726,
      checked: false,
      name: 'Бригадир-кассир',
      type: 'professionType',
    },
    {
      i: 4727,
      checked: false,
      name: 'Ведущий специалист по закупкам',
      type: 'professionType',
    },
    {
      i: 4728,
      checked: false,
      name: 'Ведущий товаровед',
      type: 'professionType',
    },
    {
      i: 4729,
      checked: false,
      name: 'Визитный мерчендайзер',
      type: 'professionType',
    },
    {
      i: 4730,
      checked: false,
      name: 'Визуальный мерчендайзер',
      type: 'professionType',
    },
    {
      i: 4731,
      checked: false,
      name: 'Гастрономист',
      type: 'professionType',
    },
    {
      i: 4732,
      checked: false,
      name: 'Грузчик-разнорабочий',
      type: 'professionType',
    },
    {
      i: 4733,
      checked: false,
      name: 'Грузчик-упаковщик',
      type: 'professionType',
    },
    {
      i: 4734,
      checked: false,
      name: 'Дегустатор',
      type: 'professionType',
    },
    {
      i: 4735,
      checked: false,
      name: 'Дежурный кассир',
      type: 'professionType',
    },
    {
      i: 4736,
      checked: false,
      name: 'Директор магазина',
      type: 'professionType',
    },
    {
      i: 4737,
      checked: false,
      name: 'Заведующий магазином',
      type: 'professionType',
    },
    {
      i: 4738,
      checked: false,
      name: 'Заведующий универсама',
      type: 'professionType',
    },
    {
      i: 4739,
      checked: false,
      name: 'Закупщик',
      type: 'professionType',
    },
    {
      i: 4740,
      checked: false,
      name: 'Заместитель директора магазина',
      type: 'professionType',
    },
    {
      i: 4741,
      checked: false,
      name: 'Заместитель директора магазина (ночные смены)',
      type: 'professionType',
    },
    {
      i: 4742,
      checked: false,
      name: 'Заместитель начальника секции',
      type: 'professionType',
    },
    {
      i: 4743,
      checked: false,
      name: 'Заместитель руководителя отдела',
      type: 'professionType',
    },
    {
      i: 4744,
      checked: false,
      name: 'Заместитель управляющего магазином',
      type: 'professionType',
    },
    {
      i: 4745,
      checked: false,
      name: 'Инженер сервисной зоны',
      type: 'professionType',
    },
    {
      i: 4746,
      checked: false,
      name: 'Инспектор отдела по обеспечению бизнеса',
      type: 'professionType',
    },
    {
      i: 4747,
      checked: false,
      name: 'Кавист',
      type: 'professionType',
    },
    {
      i: 4748,
      checked: false,
      name: 'Кассир',
      type: 'professionType',
    },
    {
      i: 4749,
      checked: false,
      name: 'Кассир (ночные смены)',
      type: 'professionType',
    },
    {
      i: 4750,
      checked: false,
      name: 'Кассир ресторана быстрого обслуживания',
      type: 'professionType',
    },
    {
      i: 4751,
      checked: false,
      name: 'Кассир торгового зала',
      type: 'professionType',
    },
    {
      i: 4752,
      checked: false,
      name: 'Кассир-комплектовщик',
      type: 'professionType',
    },
    {
      i: 4753,
      checked: false,
      name: 'Кассир-консультант',
      type: 'professionType',
    },
    {
      i: 4754,
      checked: false,
      name: 'Кассир-контролер',
      type: 'professionType',
    },
    {
      i: 4755,
      checked: false,
      name: 'Кассир-операционист',
      type: 'professionType',
    },
    {
      i: 4756,
      checked: false,
      name: 'Кассир-продавец',
      type: 'professionType',
    },
    {
      i: 4757,
      checked: false,
      name: 'Кассир-сборщик',
      type: 'professionType',
    },
    {
      i: 4758,
      checked: false,
      name: 'Кассир-сканировщик',
      type: 'professionType',
    },
    {
      i: 4759,
      checked: false,
      name: 'Кассир-универсал',
      type: 'professionType',
    },
    {
      i: 4760,
      checked: false,
      name: 'Категорийный менеджер',
      type: 'professionType',
    },
    {
      i: 4761,
      checked: false,
      name: 'Кладовщик',
      type: 'professionType',
    },
    {
      i: 4762,
      checked: false,
      name: 'Кладовщик-грузчик',
      type: 'professionType',
    },
    {
      i: 4763,
      checked: false,
      name: 'Кладовщик-комплектовщик',
      type: 'professionType',
    },
    {
      i: 4764,
      checked: false,
      name: 'Кладовщик-отборщик',
      type: 'professionType',
    },
    {
      i: 4765,
      checked: false,
      name: 'Консультант',
      type: 'professionType',
    },
    {
      i: 4766,
      checked: false,
      name: 'Консультант интернет-магазина',
      type: 'professionType',
    },
    {
      i: 4767,
      checked: false,
      name: 'Консультант по алкогольным напиткам',
      type: 'professionType',
    },
    {
      i: 4768,
      checked: false,
      name: 'Консультант торгового зала',
      type: 'professionType',
    },
    {
      i: 4769,
      checked: false,
      name: 'Консультант-кассир',
      type: 'professionType',
    },
    {
      i: 4770,
      checked: false,
      name: 'Контролер склада',
      type: 'professionType',
    },
    {
      i: 4771,
      checked: false,
      name: 'Контролер торгового зала',
      type: 'professionType',
    },
    {
      i: 4772,
      checked: false,
      name: 'Контролёр обеспечения сервиса',
      type: 'professionType',
    },
    {
      i: 4773,
      checked: false,
      name: 'Коренщик',
      type: 'professionType',
    },
    {
      i: 4774,
      checked: false,
      name: 'Менеджер интернет-магазина',
      type: 'professionType',
    },
    {
      i: 4775,
      checked: false,
      name: 'Менеджер по инвентаризации',
      type: 'professionType',
    },
    {
      i: 4776,
      checked: false,
      name: 'Менеджер по кассовым операциям',
      type: 'professionType',
    },
    {
      i: 4777,
      checked: false,
      name: 'Менеджер по обработке заказов',
      type: 'professionType',
    },
    {
      i: 4778,
      checked: false,
      name: 'Менеджер расчетно-кассового узла',
      type: 'professionType',
    },
    {
      i: 4779,
      checked: false,
      name: 'Менеджер торгового зала',
      type: 'professionType',
    },
    {
      i: 4780,
      checked: false,
      name: 'Менеджер торгового сектора',
      type: 'professionType',
    },
    {
      i: 4781,
      checked: false,
      name: 'Менеджер-кассир',
      type: 'professionType',
    },
    {
      i: 4782,
      checked: false,
      name: 'Менеджер-товаровед',
      type: 'professionType',
    },
    {
      i: 4783,
      checked: false,
      name: 'Мерчендайзер',
      type: 'professionType',
    },
    {
      i: 4784,
      checked: false,
      name: 'Мерчендайзер-грузчик',
      type: 'professionType',
    },
    {
      i: 4785,
      checked: false,
      name: 'Мерчендайзер-декоратор',
      type: 'professionType',
    },
    {
      i: 4786,
      checked: false,
      name: 'Мерчендайзер-консультант',
      type: 'professionType',
    },
    {
      i: 4787,
      checked: false,
      name: 'Мобильный кассир',
      type: 'professionType',
    },
    {
      i: 4788,
      checked: false,
      name: 'Мобильный продавец',
      type: 'professionType',
    },
    {
      i: 4789,
      checked: false,
      name: 'Мобильный продавец-кассир',
      type: 'professionType',
    },
    {
      i: 4790,
      checked: false,
      name: 'Мобильный продавец-консультант',
      type: 'professionType',
    },
    {
      i: 4791,
      checked: false,
      name: 'Мобильный работник торгового зала',
      type: 'professionType',
    },
    {
      i: 4792,
      checked: false,
      name: 'Мороженщик-кассир',
      type: 'professionType',
    },
    {
      i: 4793,
      checked: false,
      name: 'Мучник',
      type: 'professionType',
    },
    {
      i: 4794,
      checked: false,
      name: 'Мясник',
      type: 'professionType',
    },
    {
      i: 4795,
      checked: false,
      name: 'Начальник пекарни',
      type: 'professionType',
    },
    {
      i: 4796,
      checked: false,
      name: 'Ночной кассир',
      type: 'professionType',
    },
    {
      i: 4797,
      checked: false,
      name: 'Ночной продавец',
      type: 'professionType',
    },
    {
      i: 4798,
      checked: false,
      name: 'Ночной работник торгового зала',
      type: 'professionType',
    },
    {
      i: 4799,
      checked: false,
      name: 'Ночной старший продавец-кассир',
      type: 'professionType',
    },
    {
      i: 4800,
      checked: false,
      name: 'Ночной товаровед',
      type: 'professionType',
    },
    {
      i: 4801,
      checked: false,
      name: 'Ночной товаровед-приемщик',
      type: 'professionType',
    },
    {
      i: 4802,
      checked: false,
      name: 'Оператор интернет-магазина',
      type: 'professionType',
    },
    {
      i: 4803,
      checked: false,
      name: 'Оператор склада',
      type: 'professionType',
    },
    {
      i: 4804,
      checked: false,
      name: 'Оператор-кассир',
      type: 'professionType',
    },
    {
      i: 4805,
      checked: false,
      name: 'Оптик-консультант',
      type: 'professionType',
    },
    {
      i: 4806,
      checked: false,
      name: 'Отборщик-грузчик',
      type: 'professionType',
    },
    {
      i: 4807,
      checked: false,
      name: 'Повар производства',
      type: 'professionType',
    },
    {
      i: 4808,
      checked: false,
      name: 'Повар-продавец',
      type: 'professionType',
    },
    {
      i: 4809,
      checked: false,
      name: 'Подсобный рабочий',
      type: 'professionType',
    },
    {
      i: 4810,
      checked: false,
      name: 'Помощник в супермаркет',
      type: 'professionType',
    },
    {
      i: 4811,
      checked: false,
      name: 'Помощник кассира',
      type: 'professionType',
    },
    {
      i: 4812,
      checked: false,
      name: 'Помощник менеджера по снабжению',
      type: 'professionType',
    },
    {
      i: 4813,
      checked: false,
      name: 'Помощник товароведа',
      type: 'professionType',
    },
    {
      i: 4814,
      checked: false,
      name: 'Приемосдатчик',
      type: 'professionType',
    },
    {
      i: 4815,
      checked: false,
      name: 'Приемосдатчик-ревизор',
      type: 'professionType',
    },
    {
      i: 4816,
      checked: false,
      name: 'Приемщик склада',
      type: 'professionType',
    },
    {
      i: 4817,
      checked: false,
      name: 'Приемщик товара',
      type: 'professionType',
    },
    {
      i: 4818,
      checked: false,
      name: 'Приемщик/приемщица',
      type: 'professionType',
    },
    {
      i: 4819,
      checked: false,
      name: 'Проверяющий торгового зала',
      type: 'professionType',
    },
    {
      i: 4820,
      checked: false,
      name: 'Продавец',
      type: 'professionType',
    },
    {
      i: 4821,
      checked: false,
      name: 'Продавец зала',
      type: 'professionType',
    },
    {
      i: 4822,
      checked: false,
      name: 'Продавец линии раздачи',
      type: 'professionType',
    },
    {
      i: 4823,
      checked: false,
      name: 'Продавец мяса',
      type: 'professionType',
    },
    {
      i: 4824,
      checked: false,
      name: 'Продавец прилавка',
      type: 'professionType',
    },
    {
      i: 4825,
      checked: false,
      name: 'Продавец сервисной зоны',
      type: 'professionType',
    },
    {
      i: 4826,
      checked: false,
      name: 'Продавец-аквариумист',
      type: 'professionType',
    },
    {
      i: 4827,
      checked: false,
      name: 'Продавец-грузчик',
      type: 'professionType',
    },
    {
      i: 4828,
      checked: false,
      name: 'Продавец-кассир',
      type: 'professionType',
    },
    {
      i: 4829,
      checked: false,
      name: 'Продавец-кассир (ночные смены)',
      type: 'professionType',
    },
    {
      i: 4830,
      checked: false,
      name: 'Продавец-комплектовщик',
      type: 'professionType',
    },
    {
      i: 4831,
      checked: false,
      name: 'Продавец-консультант',
      type: 'professionType',
    },
    {
      i: 4832,
      checked: false,
      name: 'Продавец-консультант (ночные смены)',
      type: 'professionType',
    },
    {
      i: 4833,
      checked: false,
      name: 'Продавец-консультант коммерческих автомобилей',
      type: 'professionType',
    },
    {
      i: 4834,
      checked: false,
      name: 'Продавец-логист',
      type: 'professionType',
    },
    {
      i: 4835,
      checked: false,
      name: 'Продавец-мерчендайзер',
      type: 'professionType',
    },
    {
      i: 4836,
      checked: false,
      name: 'Продавец-оператор',
      type: 'professionType',
    },
    {
      i: 4837,
      checked: false,
      name: 'Продавец-пекарь',
      type: 'professionType',
    },
    {
      i: 4838,
      checked: false,
      name: 'Продавец-универсал',
      type: 'professionType',
    },
    {
      i: 4839,
      checked: false,
      name: 'Продавец-флорист',
      type: 'professionType',
    },
    {
      i: 4840,
      checked: false,
      name: 'Работник склада',
      type: 'professionType',
    },
    {
      i: 4841,
      checked: false,
      name: 'Работник торгового зала',
      type: 'professionType',
    },
    {
      i: 4842,
      checked: false,
      name: 'Рубщик мяса',
      type: 'professionType',
    },
    {
      i: 4843,
      checked: false,
      name: 'Руководитель розничной сети',
      type: 'professionType',
    },
    {
      i: 4844,
      checked: false,
      name: 'Руководитель сектора касс',
      type: 'professionType',
    },
    {
      i: 4845,
      checked: false,
      name: 'Руководитель секции',
      type: 'professionType',
    },
    {
      i: 4846,
      checked: false,
      name: 'Сборщик-комплектовщик',
      type: 'professionType',
    },
    {
      i: 4847,
      checked: false,
      name: 'Слотчик',
      type: 'professionType',
    },
    {
      i: 4848,
      checked: false,
      name: 'Снабженец',
      type: 'professionType',
    },
    {
      i: 4849,
      checked: false,
      name: 'Сомелье',
      type: 'professionType',
    },
    {
      i: 4850,
      checked: false,
      name: 'Составитель мясных наборов',
      type: 'professionType',
    },
    {
      i: 4851,
      checked: false,
      name: 'Составитель фарша',
      type: 'professionType',
    },
    {
      i: 4852,
      checked: false,
      name: 'Сотрудник зала',
      type: 'professionType',
    },
    {
      i: 4853,
      checked: false,
      name: 'Сотрудник магазина',
      type: 'professionType',
    },
    {
      i: 4854,
      checked: false,
      name: 'Сотрудник молочного отдела',
      type: 'professionType',
    },
    {
      i: 4855,
      checked: false,
      name: 'Сотрудник на инвентаризацию',
      type: 'professionType',
    },
    {
      i: 4856,
      checked: false,
      name: 'Сотрудник подразделения товарооборота',
      type: 'professionType',
    },
    {
      i: 4857,
      checked: false,
      name: 'Специалист ОКЗИЦ',
      type: 'professionType',
    },
    {
      i: 4858,
      checked: false,
      name: 'Специалист ОПП',
      type: 'professionType',
    },
    {
      i: 4859,
      checked: false,
      name: 'Специалист магазина',
      type: 'professionType',
    },
    {
      i: 4860,
      checked: false,
      name: 'Специалист отдела заказа товара',
      type: 'professionType',
    },
    {
      i: 4861,
      checked: false,
      name: 'Специалист отдела контроля заказов и цен',
      type: 'professionType',
    },
    {
      i: 4862,
      checked: false,
      name: 'Специалист по МТО',
      type: 'professionType',
    },
    {
      i: 4863,
      checked: false,
      name: 'Специалист по административным вопросам',
      type: 'professionType',
    },
    {
      i: 4864,
      checked: false,
      name: 'Специалист по доставке товара',
      type: 'professionType',
    },
    {
      i: 4865,
      checked: false,
      name: 'Специалист по заказу и приемке товара',
      type: 'professionType',
    },
    {
      i: 4866,
      checked: false,
      name: 'Специалист по мониторингу цен',
      type: 'professionType',
    },
    {
      i: 4867,
      checked: false,
      name: 'Специалист по обслуживанию торговых точек',
      type: 'professionType',
    },
    {
      i: 4868,
      checked: false,
      name: 'Специалист по приемке и отгрузке',
      type: 'professionType',
    },
    {
      i: 4869,
      checked: false,
      name: 'Специалист по приему товара',
      type: 'professionType',
    },
    {
      i: 4870,
      checked: false,
      name: 'Специалист по работе с несоответствиями',
      type: 'professionType',
    },
    {
      i: 4871,
      checked: false,
      name: 'Специалист по разделке рыбы',
      type: 'professionType',
    },
    {
      i: 4872,
      checked: false,
      name: 'Специалист по размещению товара',
      type: 'professionType',
    },
    {
      i: 4873,
      checked: false,
      name: 'Специалист по товародвижению',
      type: 'professionType',
    },
    {
      i: 4874,
      checked: false,
      name: 'Специалист по учету товара',
      type: 'professionType',
    },
    {
      i: 4875,
      checked: false,
      name: 'Специалист склада и выкладки товара',
      type: 'professionType',
    },
    {
      i: 4876,
      checked: false,
      name: 'Стабилизатор МХА',
      type: 'professionType',
    },
    {
      i: 4877,
      checked: false,
      name: 'Старший кассир',
      type: 'professionType',
    },
    {
      i: 4878,
      checked: false,
      name: 'Старший кассир (ночные смены)',
      type: 'professionType',
    },
    {
      i: 4879,
      checked: false,
      name: 'Старший кассир-администратор',
      type: 'professionType',
    },
    {
      i: 4880,
      checked: false,
      name: 'Старший кассир-операционист',
      type: 'professionType',
    },
    {
      i: 4881,
      checked: false,
      name: 'Старший консультант',
      type: 'professionType',
    },
    {
      i: 4882,
      checked: false,
      name: 'Старший контролер-кассир',
      type: 'professionType',
    },
    {
      i: 4883,
      checked: false,
      name: 'Старший менеджер склада',
      type: 'professionType',
    },
    {
      i: 4884,
      checked: false,
      name: 'Старший мерчендайзер',
      type: 'professionType',
    },
    {
      i: 4885,
      checked: false,
      name: 'Старший повар',
      type: 'professionType',
    },
    {
      i: 4886,
      checked: false,
      name: 'Старший повар производства',
      type: 'professionType',
    },
    {
      i: 4887,
      checked: false,
      name: 'Старший приемосдатчик',
      type: 'professionType',
    },
    {
      i: 4888,
      checked: false,
      name: 'Старший продавец',
      type: 'professionType',
    },
    {
      i: 4889,
      checked: false,
      name: 'Старший продавец-кассир',
      type: 'professionType',
    },
    {
      i: 4890,
      checked: false,
      name: 'Старший продавец-консультант',
      type: 'professionType',
    },
    {
      i: 4891,
      checked: false,
      name: 'Старший смены',
      type: 'professionType',
    },
    {
      i: 4892,
      checked: false,
      name: 'Тайный покупатель',
      type: 'professionType',
    },
    {
      i: 4893,
      checked: false,
      name: 'Территориальный продавец',
      type: 'professionType',
    },
    {
      i: 4894,
      checked: false,
      name: 'Товаровед',
      type: 'professionType',
    },
    {
      i: 4895,
      checked: false,
      name: 'Товаровед-оценщик',
      type: 'professionType',
    },
    {
      i: 4896,
      checked: false,
      name: 'Товаровед-приемщик',
      type: 'professionType',
    },
    {
      i: 4897,
      checked: false,
      name: 'Торговый агент',
      type: 'professionType',
    },
    {
      i: 4898,
      checked: false,
      name: 'Торговый представитель',
      type: 'professionType',
    },
    {
      i: 4899,
      checked: false,
      name: 'Уборщик-грузчик',
      type: 'professionType',
    },
    {
      i: 4900,
      checked: false,
      name: 'Упаковщик-комплектовщик',
      type: 'professionType',
    },
    {
      i: 4901,
      checked: false,
      name: 'Упаковщик-фасовщик',
      type: 'professionType',
    },
    {
      i: 4902,
      checked: false,
      name: 'Упаковщик/упаковщица',
      type: 'professionType',
    },
    {
      i: 4903,
      checked: false,
      name: 'Ученик продавца-консультанта',
      type: 'professionType',
    },
    {
      i: 4904,
      checked: false,
      name: 'Эксперт',
      type: 'professionType',
    },
    {
      i: 4905,
      checked: false,
      name: 'Affiliate-менеджер',
      type: 'professionType',
    },
    {
      i: 4906,
      checked: false,
      name: 'Event-менеджер',
      type: 'professionType',
    },
    {
      i: 4907,
      checked: false,
      name: 'Media buyer',
      type: 'professionType',
    },
    {
      i: 4908,
      checked: false,
      name: 'ORM-специалист',
      type: 'professionType',
    },
    {
      i: 4909,
      checked: false,
      name: 'PPC-специалист',
      type: 'professionType',
    },
    {
      i: 4910,
      checked: false,
      name: 'PR менеджер',
      type: 'professionType',
    },
    {
      i: 4911,
      checked: false,
      name: 'SMM-специалист',
      type: 'professionType',
    },
    {
      i: 4912,
      checked: false,
      name: 'Seo-специалист',
      type: 'professionType',
    },
    {
      i: 4913,
      checked: false,
      name: 'Агитатор',
      type: 'professionType',
    },
    {
      i: 4914,
      checked: false,
      name: 'Арбитражник',
      type: 'professionType',
    },
    {
      i: 4915,
      checked: false,
      name: 'Ассистент менеджера по рекламе',
      type: 'professionType',
    },
    {
      i: 4916,
      checked: false,
      name: 'Ассистент по маркетингу',
      type: 'professionType',
    },
    {
      i: 4917,
      checked: false,
      name: 'Ассистент по работе с маркетплейсами',
      type: 'professionType',
    },
    {
      i: 4918,
      checked: false,
      name: 'Бренд-дизайнер',
      type: 'professionType',
    },
    {
      i: 4919,
      checked: false,
      name: 'Бренд-менеджер',
      type: 'professionType',
    },
    {
      i: 4920,
      checked: false,
      name: 'Ведущий маркетолог',
      type: 'professionType',
    },
    {
      i: 4921,
      checked: false,
      name: 'Директолог',
      type: 'professionType',
    },
    {
      i: 4922,
      checked: false,
      name: 'Директор по маркетингу',
      type: 'professionType',
    },
    {
      i: 4923,
      checked: false,
      name: 'Интернет-маркетолог',
      type: 'professionType',
    },
    {
      i: 4924,
      checked: false,
      name: 'Консультант-промоутер',
      type: 'professionType',
    },
    {
      i: 4925,
      checked: false,
      name: 'Контент-менеджер',
      type: 'professionType',
    },
    {
      i: 4926,
      checked: false,
      name: 'Креатор',
      type: 'professionType',
    },
    {
      i: 4927,
      checked: false,
      name: 'Курьер-промоутер',
      type: 'professionType',
    },
    {
      i: 4928,
      checked: false,
      name: 'Маркетолог',
      type: 'professionType',
    },
    {
      i: 4929,
      checked: false,
      name: 'Маркетолог-аналитик',
      type: 'professionType',
    },
    {
      i: 4930,
      checked: false,
      name: 'Матрицор',
      type: 'professionType',
    },
    {
      i: 4931,
      checked: false,
      name: 'Менеджер отдела маркетинга',
      type: 'professionType',
    },
    {
      i: 4932,
      checked: false,
      name: 'Менеджер по интернет-маркетингу',
      type: 'professionType',
    },
    {
      i: 4933,
      checked: false,
      name: 'Менеджер по наружной рекламе',
      type: 'professionType',
    },
    {
      i: 4934,
      checked: false,
      name: 'Менеджер по рекламе',
      type: 'professionType',
    },
    {
      i: 4935,
      checked: false,
      name: 'Менеджер по рекламной монетизации',
      type: 'professionType',
    },
    {
      i: 4936,
      checked: false,
      name: 'Менеджер по торговому маркетингу',
      type: 'professionType',
    },
    {
      i: 4937,
      checked: false,
      name: 'Менеджер проектов',
      type: 'professionType',
    },
    {
      i: 4938,
      checked: false,
      name: 'Младший менеджер проектов',
      type: 'professionType',
    },
    {
      i: 4939,
      checked: false,
      name: 'Младший специалист по контекстной рекламе',
      type: 'professionType',
    },
    {
      i: 4940,
      checked: false,
      name: 'Младший специалист по таргетированной рекламе',
      type: 'professionType',
    },
    {
      i: 4941,
      checked: false,
      name: 'Оператор телемаркетинга',
      type: 'professionType',
    },
    {
      i: 4942,
      checked: false,
      name: 'Помощник маркетолога',
      type: 'professionType',
    },
    {
      i: 4943,
      checked: false,
      name: 'Помощник проект-менеджера',
      type: 'professionType',
    },
    {
      i: 4944,
      checked: false,
      name: 'Помощник специалиста по контекстной рекламе',
      type: 'professionType',
    },
    {
      i: 4945,
      checked: false,
      name: 'Помощник таргетолога',
      type: 'professionType',
    },
    {
      i: 4946,
      checked: false,
      name: 'Пресс-секретарь',
      type: 'professionType',
    },
    {
      i: 4947,
      checked: false,
      name: 'Проект-менеджер',
      type: 'professionType',
    },
    {
      i: 4948,
      checked: false,
      name: 'Промоутер',
      type: 'professionType',
    },
    {
      i: 4949,
      checked: false,
      name: 'Промоутер-менеджер',
      type: 'professionType',
    },
    {
      i: 4950,
      checked: false,
      name: 'Реклама',
      type: 'professionType',
    },
    {
      i: 4951,
      checked: false,
      name: 'Рекламный агент',
      type: 'professionType',
    },
    {
      i: 4952,
      checked: false,
      name: 'Руководитель отдела маркетинга',
      type: 'professionType',
    },
    {
      i: 4953,
      checked: false,
      name: 'Руководитель отдела рекламы',
      type: 'professionType',
    },
    {
      i: 4954,
      checked: false,
      name: 'Сборщик рекламных конструкций',
      type: 'professionType',
    },
    {
      i: 4955,
      checked: false,
      name: 'Скаут',
      type: 'professionType',
    },
    {
      i: 4956,
      checked: false,
      name: 'Специалист наружной рекламы',
      type: 'professionType',
    },
    {
      i: 4957,
      checked: false,
      name: 'Специалист отдела телемаркетинга',
      type: 'professionType',
    },
    {
      i: 4958,
      checked: false,
      name: 'Специалист по автоматизации контекстной рекламы',
      type: 'professionType',
    },
    {
      i: 4959,
      checked: false,
      name: 'Специалист по коммуникациям',
      type: 'professionType',
    },
    {
      i: 4960,
      checked: false,
      name: 'Специалист по контекстной рекламе',
      type: 'professionType',
    },
    {
      i: 4961,
      checked: false,
      name: 'Специалист по маркетинговым исследованиям',
      type: 'professionType',
    },
    {
      i: 4962,
      checked: false,
      name: 'Специалист по продвижению',
      type: 'professionType',
    },
    {
      i: 4963,
      checked: false,
      name: 'Специалист по развитию интернет-проектов',
      type: 'professionType',
    },
    {
      i: 4964,
      checked: false,
      name: 'Специалист по рекламе',
      type: 'professionType',
    },
    {
      i: 4965,
      checked: false,
      name: 'Специалист по связям с общественностью',
      type: 'professionType',
    },
    {
      i: 4966,
      checked: false,
      name: 'Специалист по сопровождению проектов',
      type: 'professionType',
    },
    {
      i: 4967,
      checked: false,
      name: 'Специалист по социальным коммуникациям',
      type: 'professionType',
    },
    {
      i: 4968,
      checked: false,
      name: 'Стажер в отдел маркетинга',
      type: 'professionType',
    },
    {
      i: 4969,
      checked: false,
      name: 'Старший специалист по социальным коммуникациям',
      type: 'professionType',
    },
    {
      i: 4970,
      checked: false,
      name: 'Таргетолог',
      type: 'professionType',
    },
    {
      i: 4971,
      checked: false,
      name: 'Телемаркетолог',
      type: 'professionType',
    },
    {
      i: 4972,
      checked: false,
      name: 'Территориальный представитель по торговому маркетингу',
      type: 'professionType',
    },
    {
      i: 4973,
      checked: false,
      name: 'Торговый представитель',
      type: 'professionType',
    },
    {
      i: 4974,
      checked: false,
      name: 'Трафик-менеджер',
      type: 'professionType',
    },
    {
      i: 4975,
      checked: false,
      name: 'Трейд-маркетолог',
      type: 'professionType',
    },
    {
      i: 4976,
      checked: false,
      name: 'Акупунктурщик',
      type: 'professionType',
    },
    {
      i: 4977,
      checked: false,
      name: 'Акушер-гинеколог',
      type: 'professionType',
    },
    {
      i: 4978,
      checked: false,
      name: 'Акушерка',
      type: 'professionType',
    },
    {
      i: 4979,
      checked: false,
      name: 'Аллерголог',
      type: 'professionType',
    },
    {
      i: 4980,
      checked: false,
      name: 'Андролог',
      type: 'professionType',
    },
    {
      i: 4981,
      checked: false,
      name: 'Анестезиолог',
      type: 'professionType',
    },
    {
      i: 4982,
      checked: false,
      name: 'Ассистент ветеринара',
      type: 'professionType',
    },
    {
      i: 4983,
      checked: false,
      name: 'Ассистент врача',
      type: 'professionType',
    },
    {
      i: 4984,
      checked: false,
      name: 'Ассистент стоматолога',
      type: 'professionType',
    },
    {
      i: 4985,
      checked: false,
      name: 'Бактериолог',
      type: 'professionType',
    },
    {
      i: 4986,
      checked: false,
      name: 'Биостатистик',
      type: 'professionType',
    },
    {
      i: 4987,
      checked: false,
      name: 'Ветеринарно-санитарный врач',
      type: 'professionType',
    },
    {
      i: 4988,
      checked: false,
      name: 'Ветеринарный врач',
      type: 'professionType',
    },
    {
      i: 4989,
      checked: false,
      name: 'Ветеринарный консультант',
      type: 'professionType',
    },
    {
      i: 4990,
      checked: false,
      name: 'Врач',
      type: 'professionType',
    },
    {
      i: 4991,
      checked: false,
      name: 'Врач МРТ',
      type: 'professionType',
    },
    {
      i: 4992,
      checked: false,
      name: 'Врач УЗИ',
      type: 'professionType',
    },
    {
      i: 4993,
      checked: false,
      name: 'Врач клинической лабораторной диагностики',
      type: 'professionType',
    },
    {
      i: 4994,
      checked: false,
      name: 'Врач медицинской профилактики',
      type: 'professionType',
    },
    {
      i: 4995,
      checked: false,
      name: 'Врач невролог-вертебролог',
      type: 'professionType',
    },
    {
      i: 4996,
      checked: false,
      name: 'Врач общей практики',
      type: 'professionType',
    },
    {
      i: 4997,
      checked: false,
      name: 'Врач скорой помощи',
      type: 'professionType',
    },
    {
      i: 4998,
      checked: false,
      name: 'Врач-гематолог',
      type: 'professionType',
    },
    {
      i: 4999,
      checked: false,
      name: 'Врач-гигиенист',
      type: 'professionType',
    },
    {
      i: 5000,
      checked: false,
      name: 'Врач-невропатолог',
      type: 'professionType',
    },
    {
      i: 5001,
      checked: false,
      name: 'Врач-неонатолог',
      type: 'professionType',
    },
    {
      i: 5002,
      checked: false,
      name: 'Врач-нефролог',
      type: 'professionType',
    },
    {
      i: 5003,
      checked: false,
      name: 'Врач-офтальмолог',
      type: 'professionType',
    },
    {
      i: 5004,
      checked: false,
      name: 'Врач-педиатр',
      type: 'professionType',
    },
    {
      i: 5005,
      checked: false,
      name: 'Врач-проктолог',
      type: 'professionType',
    },
    {
      i: 5006,
      checked: false,
      name: 'Врач-ревматолог',
      type: 'professionType',
    },
    {
      i: 5007,
      checked: false,
      name: 'Врач-статистик',
      type: 'professionType',
    },
    {
      i: 5008,
      checked: false,
      name: 'Врач-терапевт',
      type: 'professionType',
    },
    {
      i: 5009,
      checked: false,
      name: 'Врач-трансфузиолог',
      type: 'professionType',
    },
    {
      i: 5010,
      checked: false,
      name: 'Гастроэнтеролог',
      type: 'professionType',
    },
    {
      i: 5011,
      checked: false,
      name: 'Генетик',
      type: 'professionType',
    },
    {
      i: 5012,
      checked: false,
      name: 'Гинеколог',
      type: 'professionType',
    },
    {
      i: 5013,
      checked: false,
      name: 'Гирудотерапевт',
      type: 'professionType',
    },
    {
      i: 5014,
      checked: false,
      name: 'Главная медсестра',
      type: 'professionType',
    },
    {
      i: 5015,
      checked: false,
      name: 'Главный ветеринарный врач',
      type: 'professionType',
    },
    {
      i: 5016,
      checked: false,
      name: 'Главный врач',
      type: 'professionType',
    },
    {
      i: 5017,
      checked: false,
      name: 'Главный специалист медицинского управления',
      type: 'professionType',
    },
    {
      i: 5018,
      checked: false,
      name: 'Гомеопат',
      type: 'professionType',
    },
    {
      i: 5019,
      checked: false,
      name: 'Дерматовенеролог',
      type: 'professionType',
    },
    {
      i: 5020,
      checked: false,
      name: 'Дерматолог',
      type: 'professionType',
    },
    {
      i: 5021,
      checked: false,
      name: 'Дерматолог-косметолог',
      type: 'professionType',
    },
    {
      i: 5022,
      checked: false,
      name: 'Детский кардиолог',
      type: 'professionType',
    },
    {
      i: 5023,
      checked: false,
      name: 'Детский психолог',
      type: 'professionType',
    },
    {
      i: 5024,
      checked: false,
      name: 'Детский стоматолог',
      type: 'professionType',
    },
    {
      i: 5025,
      checked: false,
      name: 'Дефектолог',
      type: 'professionType',
    },
    {
      i: 5026,
      checked: false,
      name: 'Диетолог',
      type: 'professionType',
    },
    {
      i: 5027,
      checked: false,
      name: 'Дозиметрист',
      type: 'professionType',
    },
    {
      i: 5028,
      checked: false,
      name: 'Донор ооцитов',
      type: 'professionType',
    },
    {
      i: 5029,
      checked: false,
      name: 'Заведующий аптекой',
      type: 'professionType',
    },
    {
      i: 5030,
      checked: false,
      name: 'Заведующий инфекционным отделением',
      type: 'professionType',
    },
    {
      i: 5031,
      checked: false,
      name: 'Заведующий лабораторией',
      type: 'professionType',
    },
    {
      i: 5032,
      checked: false,
      name: 'Заведующий терапевтическим отделением',
      type: 'professionType',
    },
    {
      i: 5033,
      checked: false,
      name: 'Заместитель главного врача',
      type: 'professionType',
    },
    {
      i: 5034,
      checked: false,
      name: 'Зубной врач',
      type: 'professionType',
    },
    {
      i: 5035,
      checked: false,
      name: 'Зубной техник',
      type: 'professionType',
    },
    {
      i: 5036,
      checked: false,
      name: 'Инструктор ЛФК',
      type: 'professionType',
    },
    {
      i: 5037,
      checked: false,
      name: 'Инфекционист',
      type: 'professionType',
    },
    {
      i: 5038,
      checked: false,
      name: 'Кардиолог',
      type: 'professionType',
    },
    {
      i: 5039,
      checked: false,
      name: 'Колопроктолог',
      type: 'professionType',
    },
    {
      i: 5040,
      checked: false,
      name: 'Консультант по химической зависимости',
      type: 'professionType',
    },
    {
      i: 5041,
      checked: false,
      name: 'Косметолог',
      type: 'professionType',
    },
    {
      i: 5042,
      checked: false,
      name: 'Косметолог-массажист',
      type: 'professionType',
    },
    {
      i: 5043,
      checked: false,
      name: 'Косметолог-эстетист',
      type: 'professionType',
    },
    {
      i: 5044,
      checked: false,
      name: 'Лаборант КДЛ',
      type: 'professionType',
    },
    {
      i: 5045,
      checked: false,
      name: 'Мануальный терапевт',
      type: 'professionType',
    },
    {
      i: 5046,
      checked: false,
      name: 'Массажист',
      type: 'professionType',
    },
    {
      i: 5047,
      checked: false,
      name: 'Мастер по отбеливанию зубов',
      type: 'professionType',
    },
    {
      i: 5048,
      checked: false,
      name: 'Мастер-оптик',
      type: 'professionType',
    },
    {
      i: 5049,
      checked: false,
      name: 'Медицинский дезинфектор',
      type: 'professionType',
    },
    {
      i: 5050,
      checked: false,
      name: 'Медицинский консультант',
      type: 'professionType',
    },
    {
      i: 5051,
      checked: false,
      name: 'Медицинский лабораторный техник',
      type: 'professionType',
    },
    {
      i: 5052,
      checked: false,
      name: 'Медицинский представитель',
      type: 'professionType',
    },
    {
      i: 5053,
      checked: false,
      name: 'Медицинский работник',
      type: 'professionType',
    },
    {
      i: 5054,
      checked: false,
      name: 'Медицинский регистратор',
      type: 'professionType',
    },
    {
      i: 5055,
      checked: false,
      name: 'Медсестра',
      type: 'professionType',
    },
    {
      i: 5056,
      checked: false,
      name: 'Медсестра в стоматологию',
      type: 'professionType',
    },
    {
      i: 5057,
      checked: false,
      name: 'Медсестра-администратор',
      type: 'professionType',
    },
    {
      i: 5058,
      checked: false,
      name: 'Медстатист',
      type: 'professionType',
    },
    {
      i: 5059,
      checked: false,
      name: 'Миколог',
      type: 'professionType',
    },
    {
      i: 5060,
      checked: false,
      name: 'Младшая медицинская сестра',
      type: 'professionType',
    },
    {
      i: 5061,
      checked: false,
      name: 'Нарколог',
      type: 'professionType',
    },
    {
      i: 5062,
      checked: false,
      name: 'Начальник медико-психологической службы',
      type: 'professionType',
    },
    {
      i: 5063,
      checked: false,
      name: 'Невролог',
      type: 'professionType',
    },
    {
      i: 5064,
      checked: false,
      name: 'Нейропсихолог',
      type: 'professionType',
    },
    {
      i: 5065,
      checked: false,
      name: 'Онколог',
      type: 'professionType',
    },
    {
      i: 5066,
      checked: false,
      name: 'Онколог-химиотерапевт',
      type: 'professionType',
    },
    {
      i: 5067,
      checked: false,
      name: 'Операционная медсестра',
      type: 'professionType',
    },
    {
      i: 5068,
      checked: false,
      name: 'Оптометрист',
      type: 'professionType',
    },
    {
      i: 5069,
      checked: false,
      name: 'Ортодонт',
      type: 'professionType',
    },
    {
      i: 5070,
      checked: false,
      name: 'Ортопед',
      type: 'professionType',
    },
    {
      i: 5071,
      checked: false,
      name: 'Остеопат',
      type: 'professionType',
    },
    {
      i: 5072,
      checked: false,
      name: 'Отоларинголог',
      type: 'professionType',
    },
    {
      i: 5073,
      checked: false,
      name: 'Патологоанатом',
      type: 'professionType',
    },
    {
      i: 5074,
      checked: false,
      name: 'Педиатр',
      type: 'professionType',
    },
    {
      i: 5075,
      checked: false,
      name: 'Пластический хирург',
      type: 'professionType',
    },
    {
      i: 5076,
      checked: false,
      name: 'Подолог',
      type: 'professionType',
    },
    {
      i: 5077,
      checked: false,
      name: 'Помощник зубного техника',
      type: 'professionType',
    },
    {
      i: 5078,
      checked: false,
      name: 'Помощник косметолога',
      type: 'professionType',
    },
    {
      i: 5079,
      checked: false,
      name: 'Помощник фармацевта',
      type: 'professionType',
    },
    {
      i: 5080,
      checked: false,
      name: 'Предрейсовая медсестра',
      type: 'professionType',
    },
    {
      i: 5081,
      checked: false,
      name: 'Представитель лекарственного производства',
      type: 'professionType',
    },
    {
      i: 5082,
      checked: false,
      name: 'Провизор',
      type: 'professionType',
    },
    {
      i: 5083,
      checked: false,
      name: 'Провизор-аналитик',
      type: 'professionType',
    },
    {
      i: 5084,
      checked: false,
      name: 'Проктолог',
      type: 'professionType',
    },
    {
      i: 5085,
      checked: false,
      name: 'Протезист',
      type: 'professionType',
    },
    {
      i: 5086,
      checked: false,
      name: 'Профпатолог',
      type: 'professionType',
    },
    {
      i: 5087,
      checked: false,
      name: 'Процедурная медсестра',
      type: 'professionType',
    },
    {
      i: 5088,
      checked: false,
      name: 'Психиатр',
      type: 'professionType',
    },
    {
      i: 5089,
      checked: false,
      name: 'Психолог',
      type: 'professionType',
    },
    {
      i: 5090,
      checked: false,
      name: 'Психолог-кадровик',
      type: 'professionType',
    },
    {
      i: 5091,
      checked: false,
      name: 'Психотерапевт',
      type: 'professionType',
    },
    {
      i: 5092,
      checked: false,
      name: 'Пульмонолог',
      type: 'professionType',
    },
    {
      i: 5093,
      checked: false,
      name: 'Работник ЦСО',
      type: 'professionType',
    },
    {
      i: 5094,
      checked: false,
      name: 'Расстановщик',
      type: 'professionType',
    },
    {
      i: 5095,
      checked: false,
      name: 'Реабилитолог',
      type: 'professionType',
    },
    {
      i: 5096,
      checked: false,
      name: 'Реаниматолог',
      type: 'professionType',
    },
    {
      i: 5097,
      checked: false,
      name: 'Регистратор медицинских изделий',
      type: 'professionType',
    },
    {
      i: 5098,
      checked: false,
      name: 'Рентгенолаборант',
      type: 'professionType',
    },
    {
      i: 5099,
      checked: false,
      name: 'Рентгенолог',
      type: 'professionType',
    },
    {
      i: 5100,
      checked: false,
      name: 'Рефлексотерапевт',
      type: 'professionType',
    },
    {
      i: 5101,
      checked: false,
      name: 'Санитар',
      type: 'professionType',
    },
    {
      i: 5102,
      checked: false,
      name: 'Санитарный врач',
      type: 'professionType',
    },
    {
      i: 5103,
      checked: false,
      name: 'Спасатель',
      type: 'professionType',
    },
    {
      i: 5104,
      checked: false,
      name: 'Специалист ОФД',
      type: 'professionType',
    },
    {
      i: 5105,
      checked: false,
      name: 'Специалист по ветеринарному контролю',
      type: 'professionType',
    },
    {
      i: 5106,
      checked: false,
      name: 'Специалист по кинезиотейпированию',
      type: 'professionType',
    },
    {
      i: 5107,
      checked: false,
      name: 'Специалист по клиническим исследованиям',
      type: 'professionType',
    },
    {
      i: 5108,
      checked: false,
      name: 'Специалист по медицинскому обслуживанию',
      type: 'professionType',
    },
    {
      i: 5109,
      checked: false,
      name: 'Старшая медсестра',
      type: 'professionType',
    },
    {
      i: 5110,
      checked: false,
      name: 'Стоматолог',
      type: 'professionType',
    },
    {
      i: 5111,
      checked: false,
      name: 'Стоматолог-ортопед',
      type: 'professionType',
    },
    {
      i: 5112,
      checked: false,
      name: 'Стоматолог-пародонтолог',
      type: 'professionType',
    },
    {
      i: 5113,
      checked: false,
      name: 'Стоматолог-терапевт',
      type: 'professionType',
    },
    {
      i: 5114,
      checked: false,
      name: 'Судебно-психологический эксперт',
      type: 'professionType',
    },
    {
      i: 5115,
      checked: false,
      name: 'Сурдолог',
      type: 'professionType',
    },
    {
      i: 5116,
      checked: false,
      name: 'Травматолог',
      type: 'professionType',
    },
    {
      i: 5117,
      checked: false,
      name: 'Тренер',
      type: 'professionType',
    },
    {
      i: 5118,
      checked: false,
      name: 'Трихолог',
      type: 'professionType',
    },
    {
      i: 5119,
      checked: false,
      name: 'Уролог',
      type: 'professionType',
    },
    {
      i: 5120,
      checked: false,
      name: 'Фармаколог',
      type: 'professionType',
    },
    {
      i: 5121,
      checked: false,
      name: 'Фармацевт',
      type: 'professionType',
    },
    {
      i: 5122,
      checked: false,
      name: 'Фармацевт-провизор',
      type: 'professionType',
    },
    {
      i: 5123,
      checked: false,
      name: 'Фельдшер',
      type: 'professionType',
    },
    {
      i: 5124,
      checked: false,
      name: 'Фельдшер скорой медицинской помощи',
      type: 'professionType',
    },
    {
      i: 5125,
      checked: false,
      name: 'Фельдшер-лаборант',
      type: 'professionType',
    },
    {
      i: 5126,
      checked: false,
      name: 'Физиотерапевт',
      type: 'professionType',
    },
    {
      i: 5127,
      checked: false,
      name: 'Флеболог',
      type: 'professionType',
    },
    {
      i: 5128,
      checked: false,
      name: 'Фтизиатр',
      type: 'professionType',
    },
    {
      i: 5129,
      checked: false,
      name: 'Хирург',
      type: 'professionType',
    },
    {
      i: 5130,
      checked: false,
      name: 'Хирург-имплантолог',
      type: 'professionType',
    },
    {
      i: 5131,
      checked: false,
      name: 'Хирург-онколог',
      type: 'professionType',
    },
    {
      i: 5132,
      checked: false,
      name: 'Эксперт по специальной оценке условий труда',
      type: 'professionType',
    },
    {
      i: 5133,
      checked: false,
      name: 'Эксперт-фоноскопист',
      type: 'professionType',
    },
    {
      i: 5134,
      checked: false,
      name: 'Эмбриолог',
      type: 'professionType',
    },
    {
      i: 5135,
      checked: false,
      name: 'Эндокринолог',
      type: 'professionType',
    },
    {
      i: 5136,
      checked: false,
      name: 'Эндоскопист',
      type: 'professionType',
    },
    {
      i: 5137,
      checked: false,
      name: 'Эпидемиолог',
      type: 'professionType',
    },
  ],
}

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_FILTERS: {
      const filtersAll = state.filtersAll.map((item) => {
        if (item.i === action.payload) {
          return {
            ...item,
            checked: !item.checked,
          }
        }
        return item
      })
      return { ...state, filtersAll: filtersAll }
    }
    case ActionTypes.TOGGLE_FILTERS_RESET: {
      const filtersAll = state.filtersAll.map((item) => {
        if (item.checked) {
          return {
            ...item,
            checked: !item.checked,
          }
        }
        return item
      })
      return { ...state, filtersAll: filtersAll }
    }
    default:
      return state
  }
}
export default filtersReducer
