import * as ActionTypes from '../constants/filters.constants'

export const toggleFilters = (values) => ({
  type: ActionTypes.TOGGLE_FILTERS,
  payload: values,
})

export const toggleFiltersReset = (values) => ({
  type: ActionTypes.TOGGLE_FILTERS_RESET,
  payload: values,
})
