import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenWardrobeDrugoe() {
  const category = useSelector((state) => state.app.category)
  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskaya-odezhda'}
      subCategoryRoute={'drugoe'}
      category={category}
      categoryId={1}
      columnsId={3}
      subCategoryId={49}
      title={'Верхняя одежда'}
      subTitle={'Детский гардероб'}
    />
  )
}
export default ChildrenWardrobeDrugoe
