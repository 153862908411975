import React from 'react'
import ProductFilter from '../ProductFilter'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import * as actions from '../../actions'
import { useDispatch } from 'react-redux'

function PageContainer({
  categoryRoute,
  columnRoute,
  subCategoryRoute,
  children,
  brands,
  isLinks = false,
  category,
  categoryId,
  columnsId,
  subCategoryId,
}) {
  const dispatch = useDispatch()
  return (
    <main className={styles.main}>
      <div>для рекламного блока</div>
      {isLinks && (
        <div
          className={styles.titleLinks}
          onClick={() => {
            dispatch(actions.toggleFiltersReset())
          }}
        >
          <Link to={'/'} className={styles.link}>
            Главная
          </Link>
          <div> {'>'} </div>
          {subCategoryId ? (
            <>
              <Link to={`/${columnRoute}`} className={styles.link}>
                {
                  category
                    .find((c) => c.id === categoryId)
                    .columns.find((c) => c.id === columnsId).name
                }
              </Link>
              <div> {'>'} </div>
              <div className={styles.subLink}>
                {
                  category
                    .find((c) => c.id === categoryId)
                    .columns.find((c) => c.id === columnsId)
                    .subCategory.find((s) => s.id === subCategoryId).name
                }
              </div>
            </>
          ) : (
            <div className={styles.subLink}>
              {
                category
                  .find((c) => c.id === categoryId)
                  .columns.find((c) => c.id === columnsId).name
              }
            </div>
          )}
        </div>
      )}
      <div className={styles.rowContainer}>
        <div className={styles.col1}>{children}</div>
        <ProductFilter
          categoryRoute={categoryRoute}
          columnRoute={columnRoute}
          subCategoryRoute={subCategoryRoute}
          brands={brands}
        />
      </div>
    </main>
  )
}

export default PageContainer
