import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenWardrobeFutbolki() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const aksessuary = filtersAll.filter((item) => item.type === 47)
  const brands = filtersAll.filter((item) => item.type === 'accessoriesBrand')
  const sexChild = filtersAll.filter((item) => item.type === 'sexChild')
  const yearsChild = filtersAll.filter((item) => item.type === 'yearsChild')
  const stateProduct = filtersAll.filter((item) => item.type === 'stateProduct')

  return (
    <PageWrapper
      isLinks={true}
      filtersAll={filtersAll}
      isFiltersAll={true}
      categoryRoute={null}
      columnRoute={'detskaya-odezhda'}
      subCategoryRoute={'futbolki'}
      category={category}
      categoryId={1}
      columnsId={3}
      subCategoryId={47}
      isAksessuary={true}
      aksessuary={aksessuary}
      brands={brands}
      isBrands={true}
      title={'Футболки'}
      subTitle={'Детский гардероб'}
      isSex={true}
      sex={sexChild}
      isYearsChild={true}
      yearsChild={yearsChild}
      isStateProduct={true}
      stateProduct={stateProduct}
    />
  )
}
export default ChildrenWardrobeFutbolki
