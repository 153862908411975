import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import * as actions from '../../actions'

function IsActiveSubCategoryList({ subCategory, categoryRoute, columnRoute }) {
  const dispatch = useDispatch()
  return (
    <div
      className={styles.linkItem}
      onClick={() => {
        dispatch(actions.toggleFiltersReset())
      }}
    >
      <Link
        className={styles.link}
        to={
          categoryRoute
            ? `/${categoryRoute}/${columnRoute}/${subCategory.subCategoryRoute}`
            : `/${columnRoute}/${subCategory.subCategoryRoute}`
        }
      >
        {subCategory.name}
      </Link>
    </div>
  )
}
export default IsActiveSubCategoryList
